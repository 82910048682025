import React from "react";

export const OrganisationsIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none">
      <g filter="url(#filter0_i_7542_45037)">
        <rect
          y="0.429688"
          width="52"
          height="52"
          rx="7"
          fill="url(#paint0_linear_7542_45037)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.6668 39.4297C37.6668 39.982 37.2191 40.4297 36.6668 40.4297H31.6668C31.1145 40.4297 30.6668 39.982 30.6668 39.4297V36.763C30.6668 36.2107 30.2191 35.763 29.6668 35.763H22.3335C21.7812 35.763 21.3335 36.2107 21.3335 36.763V39.4297C21.3335 39.982 20.8858 40.4297 20.3335 40.4297H15.3335C14.7812 40.4297 14.3335 39.982 14.3335 39.4297V13.4297C14.3335 12.8774 14.7812 12.4297 15.3335 12.4297H36.6668C37.2191 12.4297 37.6668 12.8774 37.6668 13.4297V39.4297ZM33.0002 27.4297C33.0002 26.8774 32.5524 26.4297 32.0002 26.4297H29.3335C28.7812 26.4297 28.3335 26.8774 28.3335 27.4297V30.0964C28.3335 30.6486 28.7812 31.0964 29.3335 31.0964H32.0002C32.5524 31.0964 33.0002 30.6486 33.0002 30.0964V27.4297ZM19.0002 27.4297C19.0002 26.8774 19.4479 26.4297 20.0002 26.4297H22.6668C23.2191 26.4297 23.6668 26.8774 23.6668 27.4297V30.0964C23.6668 30.6486 23.2191 31.0964 22.6668 31.0964H20.0002C19.4479 31.0964 19.0002 30.6486 19.0002 30.0964V27.4297ZM33.0002 18.0964C33.0002 17.5441 32.5524 17.0964 32.0002 17.0964H29.3335C28.7812 17.0964 28.3335 17.5441 28.3335 18.0964V20.763C28.3335 21.3153 28.7812 21.763 29.3335 21.763H32.0002C32.5524 21.763 33.0002 21.3153 33.0002 20.763V18.0964ZM19.0002 18.0964C19.0002 17.5441 19.4479 17.0964 20.0002 17.0964H22.6668C23.2191 17.0964 23.6668 17.5441 23.6668 18.0964V20.763C23.6668 21.3153 23.2191 21.763 22.6668 21.763H20.0002C19.4479 21.763 19.0002 21.3153 19.0002 20.763V18.0964Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_7542_45037"
          x="0"
          y="0.429688"
          width="52"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7542_45037"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7542_45037"
          x1="52"
          y1="36.4297"
          x2="0"
          y2="0.429687"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDA45B" />
          <stop offset="1" stopColor="#FBEBD4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
