import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useTitle from "react-use/lib/useTitle";
import { useMediaQuery } from "@mui/material";
import { getAppName } from "app/utils/getAppName";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useStoreState } from "app/state/store/hooks";
import { Breadcrumbs } from "app/components/breadcrumb";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { useLocation, useNavigate } from "react-router-dom";
import { useCMSCollections } from "app/hooks/useCMSCollections";
import { FloatingButtons } from "app/components/floatingButtons";
import { BreadcrumbLinkModel } from "app/components/breadcrumb/data";

export function InfopageTemplate(props: {
  breadcrumb: BreadcrumbLinkModel;
  collectionKey: "about" | "results" | "feedback" | "statements";
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });
  const mobile = useMediaQuery("(max-width: 768px)");
  const collectionsData = useCMSCollections({ returnData: true });
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  useTitle(
    `${get(
      cmsData,
      props.breadcrumb.cmsKey ?? "",
      props.breadcrumb.label
    )} | ${getAppName(currentLanguage)}`
  );

  const data = React.useMemo(() => {
    return get(collectionsData, `[${props.collectionKey}]`, []);
  }, [collectionsData, props.collectionKey]);

  function getAnchorStatus(value: string) {
    return location.hash.replace(/%20/g, " ") === `#${value}`;
  }

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <React.Fragment>
      <CanonicalUrl
        canonicalUrl={location.pathname.replace(`/${currentLanguage}`, "")}
      />
      <Box
        sx={{
          "@media (max-width: 1280px)": {
            padding: "0px 48px",
          },
          "@media (max-width: 768px)": {
            padding: "0px 24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "20px 0",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            "@media (max-width: 768px)": {
              flexDirection: "column",
              alignItems: "flex-start",
            },
          }}
        >
          <Breadcrumbs
            route={[
              {
                label: "Homepage",
                path: "/:lang",
                cmsKey: "breadcrumbs.homepage",
              },
              props.breadcrumb,
            ]}
          />
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              "@media (max-width: 768px)": {
                width: "100%",
                justifyContent: "space-between",
              },
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBack fontSize="small" />}
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                padding: "4px 8px",
                textTransform: "none",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              {get(cmsData, "general.back", "Back")}
            </Button>
            {mobile && (
              <Box
                sx={{
                  gap: "5px",
                  display: "flex",
                  button: {
                    boxShadow: colors.shadows.inner,
                  },
                }}
              >
                <FloatingButtons noFloating />
              </Box>
            )}
            {!mobile && <FloatingButtons noFloating />}
          </Box>
        </Box>
        <Typography
          variant="h5"
          sx={{
            margin: "16px 0",
          }}
        >
          {get(data, "[0].title", props.breadcrumb.label)}
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={false} sm={false} md={4} lg={4}>
            <Box
              sx={{
                top: "96px",
                gap: "25px",
                display: "flex",
                position: "sticky",
                padding: "30px 20px",
                borderRadius: "30px",
                flexDirection: "column",
                background: colors.primary.gray,
                boxShadow: colors.shadows.inner,
                a: {
                  h6: {
                    lineHeight: "22px",
                  },
                },
              }}
            >
              {/* @ts-ignore */}
              {data.map((item: any) => (
                <a href={`#${item.title}`}>
                  <Typography
                    variant="subtitle1"
                    fontWeight={getAnchorStatus(item.title) ? 700 : 400}
                  >
                    {item.title}
                  </Typography>
                </a>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            {/* @ts-ignore */}
            {data.map((item: any, index: number) => (
              <React.Fragment key={item.title}>
                <Box
                  id={item.title}
                  sx={index === 0 ? {} : { height: "71px" }}
                />
                <Box>
                  <Typography variant="h4">{item.title}</Typography>
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                    sx={{
                      color: "#000",
                      a: {
                        color: "#000",
                      },
                    }}
                  />
                </Box>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Box sx={{ height: "40px" }} />
      </Box>
    </React.Fragment>
  );
}
