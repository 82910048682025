export interface FilterTabsProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const FILTER_TABS = [
  {
    id: "period",
    cmsKey: "filters.period",
    label: "Period",
  },
  {
    id: "priorities",
    cmsKey: "general.thematicareas",
    label: "Priorities",
  },
  {
    id: "sectors",
    cmsKey: "general.sectors",
    label: "Sectors",
  },
  {
    id: "locations",
    cmsKey: "viz.countriesregions",
    label: "Country & Region",
  },
  {
    id: "organisations",
    cmsKey: "general.organisations",
    label: "Organisations",
  },
  {
    id: "sdgs",
    cmsKey: "general.sdgs",
    label: "SDGs",
  },
  {
    id: "advanced",
    cmsKey: "filters.advancedfilters",
    label: "Advanced Filters",
  },
];
