import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import { CanvasRenderer } from "echarts/renderers";
import { formatLocale } from "app/utils/formatLocale";
import { useStoreState } from "app/state/store/hooks";
import { onEchartResize } from "app/utils/onEchartResize";
import { useSessionStorage, useUnmount } from "react-use";
import { MapChart, MapSeriesOption } from "echarts/charts";
import { GeomapChartTooltip } from "app/components/charts/tooltips/geomap";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";
import { GeomapChartODAPanel } from "app/components/charts/tooltips/geomap-oda";
import {
  VisualMapComponent,
  VisualMapComponentOption,
} from "echarts/components";

echarts.use([MapChart, CanvasRenderer, VisualMapComponent]);

interface GeomapProps {
  data: any;
  unallocable: number;
  countryPageName?: string;
  hideExtraElements?: boolean;
  disableInteraction?: boolean;
  centerCoords?: [number, number];
}

export function GeomapChart(props: GeomapProps) {
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });
  const mobile = useMediaQuery("(max-width: 768px)");

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [geoJson, setGeoJson] = React.useState(null);
  const [isLocked, setIsLocked] = useSessionStorage<boolean>(
    "geomap-is-locked",
    false
  );
  const [tooltip, setTooltip] = useSessionStorage<{
    code: string;
    label: string;
    value: number;
  } | null>("geomap-tooltip", null);

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const mapColors = [
    colors.graph.green[100],
    colors.graph.green[200],
    colors.graph.green[300],
    colors.graph.green[400],
    colors.graph.green[500],
    colors.graph.green[600],
    colors.graph.green[700],
    colors.graph.green[800],
    colors.graph.green[900],
  ];

  const minMax = React.useMemo(() => {
    const sizes = props.data.map((d: any) => d.value);
    return {
      min: Math.min(...sizes),
      max: Math.max(...sizes),
    };
  }, [props.data]);

  function clearTooltip() {
    setIsLocked(false);
    setTooltip(null);
  }

  React.useEffect(() => {
    if (!geoJson) {
      fetch("/static/world.geo.json")
        .then((res) => res.json())
        .then((data) => setGeoJson(data))
        .catch((err) => console.log(err));
    }
  }, []);

  React.useEffect(() => {
    clearTooltip();
  }, [location.pathname]);

  React.useEffect(() => {
    if (containerRef.current && geoJson) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "canvas",
      });

      echarts.registerMap("World", geoJson);

      const option: echarts.ComposeOption<
        MapSeriesOption | VisualMapComponentOption
      > = {
        geo: props.centerCoords
          ? {
              zoom: 6,
              map: "World",
              center: props.centerCoords,
              selectedMode: "single",
              emphasis: {
                label: {
                  show: false,
                },
                itemStyle: {
                  areaColor: colors.graph.green[500],
                },
              },
              select: {
                label: {
                  show: false,
                },
                itemStyle: {
                  areaColor: colors.graph.green[500],
                },
              },
            }
          : undefined,
        series: props.centerCoords
          ? undefined
          : [
              {
                name: "World",
                map: "World",
                type: "map",
                height: "475px",
                roam: true,
                data: props.data,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zoom: 1.1,
                center: !mobile ? [0, 10] : [90, 0],
                selectedMode: false,
                scaleLimit: {
                  min: 1,
                  max: 5,
                },
                emphasis: {
                  disabled: props.disableInteraction ?? mobile,
                  label: {
                    show: false,
                  },
                  itemStyle: {
                    areaColor: colors.primary.blue,
                  },
                },
                select: {
                  disabled: true,
                },
              },
            ],
        visualMap: {
          show: false,
          min: minMax.min,
          max: minMax.max,
          hoverLink: false,
          inRange: {
            color: mapColors,
          },
          outOfRange: {
            color: colors.primary.white,
          },
          calculable: true,
        },
      };

      if (containerRef.current) {
        new ResizeObserver(() =>
          onEchartResize(
            // @ts-ignore
            chart,
            "geomap-chart",
            containerRef.current?.clientHeight
          )
        ).observe(containerRef?.current);
      }

      chart.setOption(option);

      chart.on("click", (params: any) => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        const gtooltip = JSON.parse(
          sessionStorage.getItem("geomap-tooltip")?.toString() ?? "{code: ''}"
        );

        if (params.data) {
          if (
            params.data.iso2 === gtooltip?.code &&
            locked &&
            locked === "true"
          ) {
            setIsLocked(false);
          } else {
            setTooltip({
              code: params.data.iso2,
              label: params.data[getNameFieldBasedOnLang(currentLanguage)],
              value: params.data.value,
            });
            if (!mobile) {
              setIsLocked(true);
            }
          }
        } else {
          setIsLocked(false);
          if (tooltip) {
            setTooltip(null);
          }
        }
      });
      chart.on("mouseover", (params: any) => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (params.data && (!locked || locked === "false")) {
          setTooltip({
            code: params.data.iso2,
            label: params.data[getNameFieldBasedOnLang(currentLanguage)],
            value: params.data.value,
          });
        }
      });
      chart.on("mouseout", () => {
        const locked = sessionStorage.getItem("geomap-is-locked");
        if (!locked || locked === "false") {
          setTooltip(null);
        }
      });
      chart.dispatchAction({
        type: "geoSelect",
        name: props.countryPageName,
      });
    }
  }, [containerRef.current, geoJson, props.data, minMax, mobile]);

  useUnmount(() => {
    clearTooltip();
  });

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            width: "200%",
          },
        }}
      >
        <Box
          id="geomap-chart"
          ref={containerRef}
          sx={{
            width: "90%",
            height: "475px",
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        />
      </Box>
      {!props.hideExtraElements && (
        <React.Fragment>
          <Box
            sx={{
              right: 20,
              bottom: 20,
              width: "350px",
              display: "flex",
              position: "absolute",
              flexDirection: "column",
              "@media (max-width: 768px)": {
                width: "80%",
              },
            }}
          >
            <Typography fontSize="12px">
              {get(cmsData, "viz.disbursementsamount", "Total disbursements")} €
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "14px",
                display: "flex",
                padding: "0 7px",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "14px",
                justifyContent: "space-between",
                border: `1px solid ${colors.primary.blue}`,
                background: `linear-gradient(90deg, ${mapColors[0]} 5.78%, ${
                  mapColors[mapColors.length - 1]
                } 93.75%)`,
              }}
            >
              <Typography fontSize="12px">0</Typography>
              <Typography fontSize="12px" color={colors.primary.white}>
                {formatLocale(minMax.max)}
              </Typography>
            </Box>
          </Box>
          {tooltip && (
            <GeomapChartTooltip
              isLocked={isLocked}
              code={tooltip.code}
              label={tooltip.label}
              value={tooltip.value}
              lockUnlock={() => {
                setTooltip(null);
                setIsLocked(!isLocked);
              }}
            />
          )}
          <GeomapChartODAPanel value={props.unallocable} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
