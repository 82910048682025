import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useTitle from "react-use/lib/useTitle";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import Search from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { getAppName } from "app/utils/getAppName";
import Typography from "@mui/material/Typography";
import { useCMSData } from "app/hooks/useCMSData";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { InfoButton } from "app/components/infobutton";
import { Breadcrumbs } from "app/components/breadcrumb";
import { FloatingButtons } from "app/components/floatingButtons";
import { BreadcrumbLinkModel } from "app/components/breadcrumb/data";
import { useStoreActions, useStoreState } from "app/state/store/hooks";

const StyledInput = styled(InputBase)(() => ({
  input: {
    fontSize: 16,
    borderRadius: 32,
    color: colors.primary.blue,
    boxShadow: colors.shadows.inner,
    background: colors.secondary.grayLight,
    "&::placeholder": {
      color: colors.primary.blue,
      opacity: 1,
    },
  },
}));

interface TabViewProps {
  breadcrumbs: BreadcrumbLinkModel[];
  children: React.ReactNode | React.ReactNode[];
  header: {
    title: string;
    info?: string;
    titleCMSKey: string;
    icon?: React.ReactNode;
    subtitle: string | React.ReactNode;
    subtitleCMSKey: string;
  };
  csvExportInfo: {
    fileName: string;
    storeKey: string;
    headers: {
      label: string;
      key: string;
    }[];
  };
}

export function TabView(props: TabViewProps) {
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });
  const mobile = useMediaQuery("(max-width: 768px)");

  const selectedLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  useTitle(
    `${get(
      cmsData,
      props.header.titleCMSKey,
      props.header.title
    )} | ${getAppName(selectedLanguage)}`
  );

  const vizOrTable = useStoreState((state) => state.vizOrTable.value);
  const setVizOrTable = useStoreActions(
    (actions) => actions.vizOrTable.setValue
  );
  const searchValue = useStoreState((state) => state.tabViewTableSearch.value);
  const setSearchValue = useStoreActions(
    (actions) => actions.tabViewTableSearch.setValue
  );

  const handleVizOrTable =
    (value: "viz" | "table") => (e: React.MouseEvent<HTMLButtonElement>) => {
      setVizOrTable(value);
    };

  const showVizTableToggle = React.useMemo(() => {
    if (location.pathname.includes("activities")) {
      return false;
    }
    return true;
  }, [location.pathname]);

  const toolbar = React.useMemo(() => {
    const floatingButtons = (
      <FloatingButtons noFloating csvExportInfo={props.csvExportInfo} />
    );
    return (
      <Box
        id="tab-view-toolbar"
        sx={{
          gap: "15px",
          display: "flex",
          flexDirection: "row",
          "@media (max-width: 768px)": {
            width: "100%",
            justifyContent: "space-between",
          },
          "> *": {
            height: "fit-content",
          },
          "> .MuiIconButton-root": {
            padding: "5px",
            borderRadius: "50%",
            boxShadow: colors.shadows.main,
          },
          "> div > .MuiIconButton-root": {
            padding: "5px",
            borderRadius: "50%",
            boxShadow: colors.shadows.inner,
          },
        }}
      >
        {vizOrTable === "viz" && (
          <Button
            variant="text"
            // onClick={onBackClick}
            id="tab-view-back-button"
            startIcon={<ArrowBack fontSize="small" />}
            sx={{
              display: "none",
              fontWeight: 400,
              fontSize: "16px",
              padding: "4px 8px",
              textTransform: "none",
            }}
          >
            {get(cmsData, "general.back", "Back")}
          </Button>
        )}
        {(vizOrTable === "table" ||
          location.pathname.includes("/activities")) && (
          <StyledInput
            value={searchValue}
            aria-label="Search in table"
            placeholder={get(cmsData, "general.search", "Search")}
            inputProps={{ "aria-label": "Search in table", id: "search-input" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(e.target.value)
            }
            endAdornment={
              <Search
                sx={{
                  right: "8px",
                  position: "absolute",
                  fill: colors.primary.blue,
                  transform: "rotate(90deg)",
                }}
              />
            }
            sx={{
              width: "200px",
              input: {
                padding: "7px 15px",
              },
              "@media (max-width: 768px)": {
                display: location.pathname.includes("/activities")
                  ? "inherit"
                  : "none",
              },
            }}
          />
        )}
        {showVizTableToggle && (
          <Box
            sx={{
              padding: "6px",
              display: "flex",
              minWidth: "120px",
              borderRadius: "20px",
              flexDirection: "row",
              justifyContent: "space-between",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
              "> button": {
                height: "24px",
                minWidth: "57px",
                fontSize: "16px",
                textAlign: "center",
                borderStyle: "none",
                borderRadius: "30px",
                background: "transparent",
                transition: "all 0.1s ease",
                color: colors.secondary.grayDark,
                "&:hover": {
                  cursor: "pointer",
                  color: colors.primary.white,
                  boxShadow: colors.shadows.main,
                  background: colors.primary.blue,
                },
              },
            }}
          >
            <button
              onClick={handleVizOrTable("viz")}
              style={
                vizOrTable === "viz"
                  ? {
                      color: colors.primary.white,
                      boxShadow: colors.shadows.main,
                      background: colors.primary.blue,
                    }
                  : {}
              }
            >
              {get(cmsData, "general.chart", "Chart")}
            </button>
            <button
              onClick={handleVizOrTable("table")}
              style={
                vizOrTable === "table"
                  ? {
                      color: colors.primary.white,
                      background: colors.primary.blue,
                    }
                  : {}
              }
            >
              {get(cmsData, "general.table", "Table")}
            </button>
          </Box>
        )}
        {!mobile && floatingButtons}
        {mobile && (
          <Box
            sx={{
              gap: "5px",
              display: "flex",
            }}
          >
            {floatingButtons}
          </Box>
        )}
      </Box>
    );
  }, [
    cmsData,
    vizOrTable,
    searchValue,
    location.pathname,
    showVizTableToggle,
    props.csvExportInfo,
  ]);

  return (
    <Box
      sx={{
        gap: "20px",
        width: "100%",
        display: "flex",
        marginBottom: "20px",
        flexDirection: "column",
        "@media (max-width: 1280px)": {
          padding: "0px 48px",
        },
        "@media (max-width: 768px)": {
          padding: "0px 24px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          "@media (max-width: 768px)": {
            gap: "25px",
            flexDirection: "column",
            alignItems: "flex-start",
          },
        }}
      >
        <Breadcrumbs route={props.breadcrumbs} />
        {useMediaQuery("(max-width: 920px)") && toolbar}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            gap: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box>{props.header.icon}</Box>
          <Box>
            <Box
              sx={{
                gap: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  "@media (max-width: 768px)": {
                    fontSize: "18px",
                  },
                }}
              >
                {get(cmsData, props.header.titleCMSKey, props.header.title)}
              </Typography>
              <InfoButton
                text={get(cmsData, props.header.info || "", "")}
                fontSize="small"
                sx={{
                  color: colors.secondary.grayDark,
                  "@media (max-width: 768px)": {
                    display: "none",
                  },
                }}
              />
            </Box>
            <Typography
              component="div"
              sx={{
                "@media (max-width: 768px)": {
                  fontSize: "12px",
                },
              }}
            >
              {get(cmsData, props.header.subtitleCMSKey, props.header.subtitle)}
            </Typography>
          </Box>
        </Box>
        {useMediaQuery("(min-width: 921px)") && toolbar}
      </Box>
      <Box>{props.children}</Box>
    </Box>
  );
}
