import { AppliedFiltersStateModel } from "app/state/api/action-reducers/sync/appliedFilters";

export interface FiltersTabViewProps {
  activeTab: string;
  localAppliedFilters: AppliedFiltersStateModel;
  onFilterCheckboxChange: (value: string[], type: FILTER_TYPES) => void;
}

export interface APIFilterOption {
  name: string;
  code: string;
  name_fi?: string;
  name_se?: string;
  children?: APIFilterOption[];
}

export interface FilterOption {
  label: string;
  value: string;
  subItems?: FilterOption[];
  type?: FILTER_TYPES;
}

export interface FiltersCommonTabViewProps {
  activeTab: string;
  items: FilterOption[];
  localAppliedFilters: AppliedFiltersStateModel;
  onFilterCheckboxChange: (value: string[], type: FILTER_TYPES) => void;
}

export interface FiltersViewPeriodProps {
  localAppliedFilters: AppliedFiltersStateModel;
  onFilterCheckboxChange: (value: string[], type: FILTER_TYPES) => void;
}

export const HUMAN_RIGHTS_FILTER_OPTIONS = [
  {
    code: "Not defined",
    name: "Not defined",
    name_fi: "Ei määritelty",
    name_se: "Inte definierad",
  },
  {
    code: "Human rights blind",
    name: "Human rights blind",
    name_fi: "Ihmisoikeussokea",
    name_se: "Blind i fråga om mänskliga rättigheter",
  },
  {
    code: "Human rights sensitive",
    name: "Human rights sensitive",
    name_fi: "Ihmisoikeudet huomioiva",
    name_se: "Sensitiv i fråga om mänskliga rättigheter",
  },
  {
    code: "Human rights progressive",
    name: "Human rights progressive",
    name_fi: "Ihmisoikeuksia edistävä",
    name_se: "Progressiv i fråga mänskliga rättigheter",
  },
  {
    code: "Human rights transformative",
    name: "Human rights transformative",
    name_fi: "Ihmisoikeushaasteisiin tarttuva",
    name_se: "Aktiv i fråga om mänskliga rättigheter",
  },
];

export enum FILTER_TYPES {
  THEMATIC_AREAS,
  COUNTRIES,
  SECTORS,
  ORGANISATIONS,
  SDGS,
  ACTIVITY_STATUS,
  PERIOD,
  ADVANCED_FILTERS,
  POLICY_MARKERS,
  AID_TYPE,
  BUDGET_LINES,
  BI_MULTI,
  HUMAN_RIGHTS,
  ORGANISATION_TYPE,
}

export function fromTabViewToFilterType(tab: string) {
  switch (tab) {
    case "period":
      return FILTER_TYPES.PERIOD;
    case "priorities":
      return FILTER_TYPES.THEMATIC_AREAS;
    case "sectors":
      return FILTER_TYPES.SECTORS;
    case "locations":
      return FILTER_TYPES.COUNTRIES;
    case "organisations":
      return FILTER_TYPES.ORGANISATIONS;
    case "sdgs":
      return FILTER_TYPES.SDGS;
    case "activity-status":
      return FILTER_TYPES.ACTIVITY_STATUS;
    case "policy-markers":
      return FILTER_TYPES.POLICY_MARKERS;
    case "aid-type":
      return FILTER_TYPES.AID_TYPE;
    case "budget-lines":
      return FILTER_TYPES.BUDGET_LINES;
    case "bi-multi":
      return FILTER_TYPES.BI_MULTI;
    case "human-rights":
      return FILTER_TYPES.HUMAN_RIGHTS;
    case "organisation-type":
      return FILTER_TYPES.ORGANISATION_TYPE;
    default:
      return FILTER_TYPES.ADVANCED_FILTERS;
  }
}

export function fromTabViewToAppliedFilters(
  tab: string,
  appliedFilters: AppliedFiltersStateModel
): string[] {
  switch (tab) {
    case "period":
      return appliedFilters.years;
    case "priorities":
      return appliedFilters.tag;
    case "sectors":
      return appliedFilters.sectors;
    case "locations":
      return [...appliedFilters.regions, ...appliedFilters.countries];
    case "organisations":
      return [
        ...appliedFilters.organisationtypes,
        ...appliedFilters.organisations,
      ];
    case "sdgs":
      return appliedFilters.sdg;
    case "activity-status":
      return appliedFilters.activitystatus;
    case "policy-markers":
      return appliedFilters.policymarker;
    case "aid-type":
      return appliedFilters.defaultaidtype;
    case "budget-lines":
      return appliedFilters.budgetlines;
    case "bi-multi":
      return appliedFilters.defaultflowtype;
    case "human-rights":
      return appliedFilters.humanrights;
    case "organisation-type":
      return appliedFilters.organisationtypes;
    case "advanced":
      return [
        ...appliedFilters.policymarker,
        ...appliedFilters.defaultaidtype,
        ...appliedFilters.budgetlines,
        ...appliedFilters.collaborationtype,
        ...appliedFilters.humanrights,
      ];
    default:
      return [];
  }
}

export function getCheckboxStatus(
  item: FilterOption,
  appliedFilters: string[]
) {
  if (appliedFilters.length === 0) {
    return false;
  }
  if (appliedFilters.indexOf(item.value) > -1) {
    return true;
  }
  if (item.subItems) {
    return item.subItems.some(
      (subItem) => appliedFilters.indexOf(subItem.value) > -1
    );
  }
  return false;
}
