export interface MFATableColumnProps {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
}

export interface MFATableRowProps {
  level: number;
  expandable: boolean;
  columns: MFATableColumnProps[];
  data: Record<string, string | number | object | object[]>;
}

export interface MFATableProps {
  totalRows?: number;
  defaultSortKey?: string;
  columns: MFATableColumnProps[];
  page?: number;
  setPage?: (page: number) => void;
  rowsPerPage?: number;
  setRowsPerPage?: (rowsPerPage: number) => void;
  sortKey?: string;
  setSortKey?: (sortKey: string) => void;
  sortDirection?: "asc" | "desc";
  setSortDirection?: (sortDirection: "asc" | "desc") => void;
  rows: Record<string, string | number | object | object[]>[];
}

function recursion(
  value: string | number | object | object[],
  searchValue: string
): boolean {
  if (typeof value === "string") {
    return value.toLowerCase().includes(searchValue.toLowerCase());
  } else if (Array.isArray(value)) {
    return value.some((v) => recursion(v, searchValue));
  } else if (typeof value === "object") {
    return Object.values(value).some((v) => recursion(v, searchValue));
  }
  return false;
}

export function tableSearch(
  rows: Record<string, string | number | object | object[]>[],
  searchValue: string
): Record<string, string | number | object | object[]>[] {
  const result: Record<string, string | number | object | object[]>[] = [];

  rows.forEach((row) => {
    const values = Object.values(row);
    const found = values.some((value) => {
      if (typeof value === "string") {
        return recursion(value, searchValue);
      } else if (Array.isArray(value)) {
        return value.some((v) => recursion(v, searchValue));
      } else if (typeof value === "object") {
        return Object.values(value).some((v) => recursion(v, searchValue));
      } else {
        return false;
      }
    });
    if (found) {
      result.push(row);
    }
  });

  return result;
}
