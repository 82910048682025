import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

const top10activities: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-10-activities`),
};

export default top10activities;

export const top10activitiesPriority: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-10-activities`),
};

export const top10activitiesLocation: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-10-activities`),
};

export const top10activitiesSector: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-10-activities`),
};
