import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as echarts from "echarts/core";
import { useMediaQuery } from "@mui/material";
import { SVGRenderer } from "echarts/renderers";
import Typography from "@mui/material/Typography";
import { TooltipComponent } from "echarts/components";
import { useStoreState } from "app/state/store/hooks";
import { onEchartResize } from "app/utils/onEchartResize";
import { useLocation, useNavigate } from "react-router-dom";
import { SankeyChart as EchartsSankey } from "echarts/charts";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";
import { TooltipTouchContainer } from "app/components/charts/tooltips/common/touchcontainer";
import {
  SankeyChartTooltip,
  SankeyChartTooltipProps,
} from "app/components/charts/tooltips/sankey";
import {
  disbursementsConfig,
  locationPageConfig,
  organisationsConfig,
} from "app/components/charts/sankey/data";

echarts.use([TooltipComponent, EchartsSankey, SVGRenderer]);

interface SankeyProps {
  nodes: {
    name: string;
    name_fi?: string;
    name_se?: string;
    code?: string;
    value: number;
    commited?: number;
    percentage?: number;
    disbursements?: number;
  }[];
  links: {
    source: string;
    target: string;
    value: number;
    commited?: number;
    percentage?: number;
    disbursements?: number;
  }[];
  country?: boolean;
  levelTitles?: string[];
  organisation?: boolean;
}

export function SankeyChart(props: SankeyProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const isTouch = useMediaQuery("(hover: none)");
  const mobile = useMediaQuery("(max-width: 768px)");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [touchTooltip, setTouchTooltip] = React.useState<any | null>(null);

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );
  const totalValue = useStoreState((state) =>
    get(state.totalValue, "data.value", 0)
  );

  React.useEffect(() => {
    const chart = echarts.init(containerRef.current, undefined, {
      renderer: "svg",
    });
    let option = disbursementsConfig(
      props.nodes,
      props.links,
      totalValue,
      mobile,
      isTouch
    );
    if (props.organisation) {
      option = organisationsConfig(
        props.nodes,
        props.links,
        totalValue,
        mobile,
        isTouch
      );
      chart.on("click", (params) => {
        if (isTouch) {
          setTouchTooltip(params);
          return;
        }
        // @ts-ignore
        const code = params.data.code;
        if (params.dataType === "node" && code) {
          navigate(
            `/${currentLanguage}/organisations/${code}${location.search}`
          );
        }
      });
    } else if (props.country) {
      option = locationPageConfig(
        props.nodes,
        props.links,
        totalValue,
        mobile,
        isTouch
      );
      chart.on("click", (params) => {
        if (isTouch) {
          setTouchTooltip(params);
          return;
        }
        // @ts-ignore
        const code = params.data.code;
        if (params.dataType === "node" && code) {
          navigate(
            `/${currentLanguage}/organisations/${code}${location.search}`
          );
        }
      });
    } else {
      chart.on("click", (params) => {
        if (isTouch) {
          setTouchTooltip(params);
          return;
        }
      });
    }
    // @ts-ignore
    option.series.links = props.links.map((link) => {
      const sourceNode = props.nodes.find((node) => node.name === link.source);
      const targetNode = props.nodes.find((node) => node.name === link.target);
      if (!sourceNode || !targetNode) {
        return link;
      }
      return {
        ...link,
        source:
          sourceNode[getNameFieldBasedOnLang(currentLanguage)] ?? link.source,
        target:
          targetNode[getNameFieldBasedOnLang(currentLanguage)] ?? link.target,
      };
    });
    // @ts-ignore
    option.series.data = props.nodes.map((node) => ({
      ...node,
      name: node[getNameFieldBasedOnLang(currentLanguage)] ?? node.name,
    }));

    if (containerRef.current) {
      new ResizeObserver(() =>
        onEchartResize(
          // @ts-ignore
          chart,
          "sankey-chart",
          containerRef.current?.clientHeight
        )
      ).observe(containerRef?.current);
    }

    chart.setOption(option);
  }, [
    containerRef.current,
    props.nodes,
    props.links,
    totalValue,
    mobile,
    isTouch,
  ]);

  const formattedTooltipData = React.useMemo(() => {
    if (touchTooltip) {
      let data: SankeyChartTooltipProps;
      if (touchTooltip.dataType === "edge") {
        data = {
          value: touchTooltip.value,
          title: touchTooltip.data.target,
          ofWhatLabel: touchTooltip.data.source,
          percentage: touchTooltip.data.percentage,
        };
      } else {
        data = {
          value: touchTooltip.data.name.includes("Total")
            ? totalValue
            : touchTooltip.value,
          title: touchTooltip.data.name,
          ofWhatLabel: "of Total ODA",
          percentage: touchTooltip.data.percentage,
        };
      }
      return data;
    }
    return null;
  }, [touchTooltip, totalValue]);

  return (
    <React.Fragment>
      {props.levelTitles && props.levelTitles.length > 2 && (
        <Grid container justifyContent="space-between">
          <Grid item xs={4}>
            <Typography variant="subtitle2">{props.levelTitles[0]}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2">{props.levelTitles[1]}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              textAlign: "right",
            }}
          >
            <Typography variant="subtitle2">{props.levelTitles[2]}</Typography>
          </Grid>
        </Grid>
      )}
      <Box
        id="sankey-chart"
        ref={containerRef}
        sx={{
          width: "100%",
          height: "400px",
        }}
      />
      {formattedTooltipData && touchTooltip && isTouch && (
        <TooltipTouchContainer>
          <SankeyChartTooltip
            {...formattedTooltipData}
            onClose={() => setTouchTooltip(null)}
            goToDetail={
              (props.country || props.organisation) &&
              touchTooltip.dataType === "node" &&
              touchTooltip.data.code
                ? () => {
                    navigate(
                      `/${currentLanguage}/organisations/${touchTooltip.data.code}${location.search}`
                    );
                  }
                : undefined
            }
          />
        </TooltipTouchContainer>
      )}
    </React.Fragment>
  );
}
