import React from "react";

export const DisbursementsOverviewIcon = () => {
  return (
    <svg
      width="52"
      height="53"
      viewBox="0 0 52 53"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_7540_10788)">
        <rect
          y="0.5"
          width="52"
          height="52"
          rx="7"
          fill="url(#paint0_linear_7540_10788)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38 19.2777C38.5523 19.2777 39 18.83 39 18.2777V17.3889C39 16.8366 38.5523 16.3889 38 16.3889H14C13.4477 16.3889 13 16.8366 13 17.3889V18.2777C13 18.83 13.4477 19.2777 14 19.2777H38ZM35.1111 22.1666C35.6634 22.1666 36.1111 22.6144 36.1111 23.1666V24.0555C36.1111 24.6078 35.6634 25.0555 35.1111 25.0555H14C13.4477 25.0555 13 24.6078 13 24.0555V23.1666C13 22.6144 13.4477 22.1666 14 22.1666H35.1111ZM38 30.8333C38.5523 30.8333 39 30.3856 39 29.8333V28.9444C39 28.3921 38.5523 27.9444 38 27.9444H14C13.4477 27.9444 13 28.3921 13 28.9444V29.8333C13 30.3856 13.4477 30.8333 14 30.8333H38ZM25 36.6111C25.5523 36.6111 26 36.1634 26 35.6111V34.7222C26 34.1699 25.5523 33.7222 25 33.7222H14C13.4477 33.7222 13 34.1699 13 34.7222V35.6111C13 36.1634 13.4477 36.6111 14 36.6111H25Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_7540_10788"
          x="0"
          y="0.5"
          width="52"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7540_10788"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7540_10788"
          x1="52"
          y1="49.5"
          x2="3.66667"
          y2="0.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F6797" />
          <stop offset="0.0001" stopColor="#4F6797" />
          <stop offset="1" stopColor="#ECF1FA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
