import React from "react";

export const SDGsIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none">
      <g filter="url(#filter0_i_7542_45246)">
        <rect
          y="0.566406"
          width="52"
          height="52"
          rx="7"
          fill="url(#paint0_linear_7542_45246)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36 21.5664C36 16.0436 31.5228 11.5664 26 11.5664C20.4772 11.5664 16 16.0436 16 21.5664C16 25.0614 17.793 28.1377 20.5093 29.9255C20.8075 30.1218 21.0001 30.4485 21.0001 30.8054L21 39.1522C21 40.0431 22.0772 40.4893 22.7071 39.8593L25.2929 37.2735C25.6834 36.883 26.3166 36.883 26.7071 37.2735L29.2932 39.8596C29.9231 40.4896 31.0002 40.0435 31.0003 39.1527L31.0015 30.8042C31.0016 30.4474 31.194 30.1208 31.4921 29.9246C34.2076 28.1366 36 25.0608 36 21.5664ZM19.3333 21.5664C19.3333 17.8845 22.3181 14.8997 26 14.8997C29.6819 14.8997 32.6667 17.8845 32.6667 21.5664C32.6667 25.2483 29.6819 28.2331 26 28.2331C22.3181 28.2331 19.3333 25.2483 19.3333 21.5664Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_7542_45246"
          x="0"
          y="0.566406"
          width="52"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7542_45246"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7542_45246"
          x1="52"
          y1="49.5664"
          x2="3.66667"
          y2="0.566406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#91C4EF" />
          <stop offset="1" stopColor="#EFF6FB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
