/* eslint-disable no-param-reassign */
import { action } from "easy-peasy";
import { ValueSetValueModel } from "app/state/api/interfaces";

export const selectedLanguage: ValueSetValueModel = {
  value: "en",
  setValue: action((state, payload: string) => {
    state.value = payload;
  }),
  clear: action((state) => {
    state.value = "en";
  }),
};

export const isSearchFocused: ValueSetValueModel = {
  value: false,
  setValue: action((state, payload: boolean) => {
    state.value = payload;
  }),
  clear: action((state) => {
    state.value = false;
  }),
};

export const filterPanelSearch: ValueSetValueModel = {
  value: "",
  setValue: action((state, payload: string) => {
    state.value = payload;
  }),
  clear: action((state) => {
    state.value = "";
  }),
};

export const vizOrTable: ValueSetValueModel = {
  value: "viz",
  setValue: action((state, payload: "viz" | "table") => {
    state.value = payload;
  }),
  clear: action((state) => {
    state.value = "viz";
  }),
};

export const tabViewTableSearch: ValueSetValueModel = {
  value: "",
  setValue: action((state, payload: string) => {
    state.value = payload;
  }),
  clear: action((state) => {
    state.value = "";
  }),
};

export const accessibilitySwitch: ValueSetValueModel = {
  value: false,
  setValue: action((state, payload: boolean) => {
    state.value = payload;
  }),
  clear: action((state) => {
    state.value = false;
  }),
};
