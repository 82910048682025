import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { useMediaQuery } from "@mui/material";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Link as RouteLink } from "react-router-dom";
import { formatLocale } from "app/utils/formatLocale";
import { useStoreState } from "app/state/store/hooks";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

interface Props {
  items: {
    id: string;
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    sectors: string[];
    locations: string[];
    cooperationModality: string;
    commitment: number;
    organisations: string[];
    disbursement: number;
  }[];
}

export function ActivitiesChart(props: Props) {
  const cmsData = useCMSData({ returnData: true });
  const tablet = useMediaQuery("(max-width:920px)");
  const mobile = useMediaQuery("(max-width:768px)");
  const scrollableRef = React.useRef<HTMLDivElement>(null);
  const lang = useStoreState((state) => state.selectedLanguage.value as string);

  const [backBtnEnabled, setBackBtnEnabled] = React.useState(false);
  const [forwardBtnEnabled, setForwardBtnEnabled] = React.useState(true);
  const [mobileSelectedIndex, setMobileSelectedIndex] = React.useState(0);

  const onArrowButtonClick = (direction: "left" | "right") => () => {
    if (!scrollableRef.current) return;
    scrollableRef.current.scroll({
      left:
        scrollableRef.current.scrollLeft +
        scrollableRef.current.clientWidth * (direction === "left" ? -1 : 1),
      behavior: "smooth",
    });
  };

  const onDotClick = (index: number) => () => {
    setMobileSelectedIndex(index);
  };

  React.useEffect(() => {
    if (!mobile) return;
    setMobileSelectedIndex(0);
  }, [props.items]);

  const mobileItems = React.useMemo(() => {
    if (!mobile) return [];
    if (props.items.length >= mobileSelectedIndex + 1) {
      return [props.items[mobileSelectedIndex]];
    }
    return [];
  }, [mobile, props.items, mobileSelectedIndex]);

  React.useEffect(() => {
    if (!scrollableRef.current) return;
    const scrollable = scrollableRef.current;
    const onScroll = () => {
      if (!scrollable) return;
      const { scrollLeft, scrollWidth, clientWidth } = scrollable;
      const scrollRight = scrollWidth - scrollLeft - clientWidth;
      const backBtnEnabled = scrollLeft > 0;
      const forwardBtnEnabled = scrollRight > 0;
      setBackBtnEnabled(backBtnEnabled);
      setForwardBtnEnabled(forwardBtnEnabled);
    };
    scrollable.addEventListener("scroll", onScroll);
    return () => scrollable.removeEventListener("scroll", onScroll);
  }, [scrollableRef.current]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        "button.Mui-disabled": {
          opacity: 0.2,
        },
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Previous"
          aria-disabled={props.items.length === 0 || !backBtnEnabled}
          disabled={props.items.length === 0 || !backBtnEnabled}
          onClick={onArrowButtonClick("left")}
          sx={{
            "@media (max-width: 920px)": {
              padding: 0,
            },
          }}
        >
          <ArrowBackIos
            fontSize="large"
            sx={{
              transform: "translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
      <Box
        ref={scrollableRef}
        sx={{
          gap: "25px",
          padding: "10px",
          display: "flex",
          maxWidth: "100%",
          overflowX: "auto",
          width: "calc(100% - 70px)",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "@media (max-width: 768px)": {
            width: "100%",
          },
          "> div": {
            gap: "10px",
            display: "flex",
            minWidth: "100%",
            maxWidth: "100%",
            minHeight: "265px",
            maxHeight: "265px",
            padding: "30px 50px",
            borderRadius: "20px",
            flexDirection: "column",
            boxShadow: colors.shadows.main,
            background: colors.primary.white,
            "@media (max-width: 920px)": {
              padding: "30px",
            },
            "@media (max-width: 768px)": {
              padding: "16px",
              minHeight: "400px",
              maxHeight: "400px",
            },
          },
        }}
      >
        {(!mobile ? props.items : mobileItems).map((item) => (
          <Box key={item.id}>
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={700}
              sx={{
                "@media (max-width: 768px)": {
                  fontSize: "14px",
                },
              }}
            >
              <Link
                to={`/${lang}/activities/${item.id}`}
                component={RouteLink}
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkitLineClamp": "1",
                  textOverflow: "ellipsis",
                  color: colors.primary.blue,
                  "-webkitBoxOrient": "vertical",
                }}
              >
                {item.title}
              </Link>
            </Typography>
            <Box
              sx={{
                width: "100%",
                maxHeight: "40px",
                "@media (max-width: 768px)": {
                  maxHeight: "120px",
                },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkitLineClamp": "2",
                  textOverflow: "ellipsis",
                  "-webkitBoxOrient": "vertical",
                  "@media (max-width: 768px)": {
                    fontSize: "12px",
                    "-webkitLineClamp": "5",
                  },
                }}
              >
                {item.description}
              </Typography>
            </Box>
            <Grid
              container
              marginTop="5px"
              rowSpacing={2}
              columnSpacing={!tablet ? 2 : 1}
              sx={{
                "& > div": {
                  "> *": {
                    "@media (max-width: 920px)": {
                      fontSize: "12px",
                    },
                  },
                  "& > p": {
                    overflow: "hidden",
                    display: "-webkit-box",
                    "-webkitLineClamp": "1",
                    textOverflow: "ellipsis",
                    "-webkitBoxOrient": "vertical",
                  },
                },
              }}
            >
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(cmsData, "viz.startdate", "Planned start date")}
                </Typography>
                <Typography variant="body2">{item.startDate}</Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(cmsData, "viz.sector", "Sector")}
                </Typography>
                <Typography variant="body2">
                  {item.sectors.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(
                    cmsData,
                    "viz.cooperationModality",
                    "Cooperation Modality"
                  )}
                </Typography>
                <Typography variant="body2">
                  {item.cooperationModality}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(cmsData, "viz.reportingorg", "Organisation")}
                </Typography>
                <Typography variant="body2">
                  {item.organisations.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(cmsData, "viz.enddate", "Planned end date")}
                </Typography>
                <Typography variant="body2">{item.endDate}</Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(cmsData, "viz.countriesregions", "Country/region")}
                </Typography>
                <Typography variant="body2">
                  {item.locations.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(cmsData, "viz.committed", "Commitment")}
                </Typography>
                <Typography variant="body2">
                  EUR {formatLocale(item.commitment).slice(1)}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {get(cmsData, "viz.disbursements", "Disbursement")}
                </Typography>
                <Typography variant="body2">
                  EUR {formatLocale(item.disbursement).slice(1)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
        {props.items.length === 0 && (
          <Box
            component="span"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1">
              {get(cmsData, "general.nodataavailable", "No data available")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Next"
          aria-disabled={props.items.length === 0 || !forwardBtnEnabled}
          disabled={props.items.length === 0 || !forwardBtnEnabled}
          onClick={onArrowButtonClick("right")}
          sx={{
            "@media (max-width: 920px)": {
              padding: 0,
            },
          }}
        >
          <ArrowBackIos
            fontSize="large"
            sx={{
              transform: "rotate(180deg) translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "none",
          "@media (max-width: 768px)": {
            gap: "8px",
            display: "flex",
            marginTop: "16px",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        {props.items.map((item, index) => (
          <Box
            key={item.id}
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              background:
                mobileSelectedIndex === index
                  ? colors.secondary.grayDark
                  : colors.secondary.grayLight,
            }}
            onClick={onDotClick(index)}
          />
        ))}
      </Box>
    </Box>
  );
}
