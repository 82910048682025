import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as echarts from "echarts/core";
import { PieSeriesOption } from "echarts";
import Button from "@mui/material/Button";
import { PieChart } from "echarts/charts";
import useTitle from "react-use/lib/useTitle";
import { useMediaQuery } from "@mui/material";
import useMount from "react-use/lib/useMount";
import { SVGRenderer } from "echarts/renderers";
import Typography from "@mui/material/Typography";
import { getAppName } from "app/utils/getAppName";
import { useCMSData } from "app/hooks/useCMSData";
import { StatBox } from "app/pages/common/statBox";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { formatLocale } from "app/utils/formatLocale";
import { PageSection } from "app/pages/common/section";
import { InfoButton } from "app/components/infobutton";
import { SdgsChart } from "app/components/charts/sdgs";
import { Breadcrumbs } from "app/components/breadcrumb";
import { SDGsIcon } from "app/assets/section-icons/SDGs";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { SankeyChart } from "app/components/charts/sankey";
import { GeomapChart } from "app/components/charts/geomap";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { BarLineChart } from "app/components/charts/barline";
import { SectorsIcon } from "app/assets/section-icons/Sectors";
import { FloatingButtons } from "app/components/floatingButtons";
import { ActivitiesChart } from "app/components/charts/activities";
import { PrioritiesChart } from "app/components/charts/priorities";
import { PrioritiesIcon } from "app/assets/section-icons/Priorities";
import { BarLineDataItem } from "app/components/charts/barline/data";
import { ActivitiesIcon } from "app/assets/section-icons/Activities";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import { OrganisationsIcon } from "app/assets/section-icons/Organisations";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { CountriesRegionsIcon } from "app/assets/section-icons/CountriesRegions";
import { DisbursementsOverviewIcon } from "app/assets/section-icons/DisbursementsOverview";
import { pageChartTooltipCommonConfig } from "app/components/charts/tooltips/common/config";
import {
  LegendComponent,
  TooltipComponent,
  LegendComponentOption,
  TooltipComponentOption,
} from "echarts/components";

echarts.use([LegendComponent, TooltipComponent, PieChart, SVGRenderer]);

const SectorPageIntroBlock = () => {
  const params = useParams<{ id: string }>();
  const mobile = useMediaQuery("(max-width: 768px)");
  const tablet = useMediaQuery("(max-width: 920px)");
  const cmsData = useCMSData({ returnData: true });

  const pieContainerRef = React.useRef<HTMLDivElement>(null);

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const dataPageInfo = useStoreState((state) => state.sectorPageInfo.data);
  const fetchPageInfo = useStoreActions(
    (actions) => actions.sectorPageInfo.fetch
  );

  const dataBarChart = useStoreState(
    (state) =>
      get(
        state.disbursementsOverviewLocation,
        "data.vizData",
        []
      ) as BarLineDataItem[]
  );
  const fetchBarChart = useStoreActions(
    (actions) => actions.disbursementsOverviewLocation.fetch
  );

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const nameField = getNameFieldBasedOnLang(currentLanguage);

  const dataPieChart = React.useMemo(() => {
    if (dataBarChart.length === 0) {
      return [];
    }
    const item = dataBarChart[dataBarChart.length - 1];
    return [
      {
        name: "Exclusive ODA",
        value: item.exclusive,
        itemStyle: {
          color: colors.graph.green[500],
        },
      },
      {
        name: "Other ODA",
        value: item.other,
        itemStyle: {
          color: colors.primary.blue,
        },
      },
    ];
  }, [dataBarChart]);

  const pieChartYear = React.useMemo(() => {
    if (dataBarChart.length === 0) {
      return "";
    }
    return dataBarChart[dataBarChart.length - 1].name;
  }, [dataBarChart]);

  const pieTotal = React.useMemo(() => {
    if (dataBarChart.length === 0) {
      return 0;
    }
    const item = dataBarChart[dataBarChart.length - 1];
    return item.exclusive ?? 0 + (item.other ?? 0);
  }, [dataPieChart]);

  React.useEffect(() => {
    const filters = getAPIFormattedFilters({
      ...appliedFilters,
      sectors: [params.id ?? ""],
    });
    fetchPageInfo({
      values: {
        id: params.id,
        filters,
      },
    });
    fetchBarChart({
      values: {
        filters,
        type: "oda",
      },
    });
  }, [params.id, appliedFilters]);

  React.useEffect(() => {
    if (pieContainerRef.current) {
      const chart = echarts.init(pieContainerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<
        PieSeriesOption | TooltipComponentOption | LegendComponentOption
      > = {
        series: {
          top: 0,
          left: 0,
          right: 0,
          bottom: !mobile ? 0 : 20,
          type: "pie",
          radius: "70%",
          data: dataPieChart,
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
        },
        legend: {
          bottom: 0,
          itemGap: 40,
          itemWidth: 14,
          icon: "circle",
          orient: "horizontal",
          textStyle: {
            fontSize: 14,
            fontFamily: "Finlandica",
            color: colors.primary.blue,
          },
        },
        tooltip: {
          ...pageChartTooltipCommonConfig(mobile),
          valueFormatter: (value) => {
            return formatLocale(value as number);
          },
        },
      };

      chart.setOption(option);
    }
  }, [pieContainerRef.current, dataPieChart, mobile]);

  const pieWithStats = React.useMemo(() => {
    const sectorInfo = (
      <React.Fragment>
        <Typography variant="subtitle1">Sector Information</Typography>
        <Typography variant="body2">
          {get(dataPageInfo, `data.names["${nameField}"]`, "")}
          <br />
          <br />
          {get(dataPageInfo, "data.description", "")}
        </Typography>
      </React.Fragment>
    );

    const sectorStats = [
      <StatBox
        percentage={1.2}
        icon={<ActivitiesIcon />}
        key="number-of-activities"
        label="Number of activities"
        value={get(dataPageInfo, "data.activityCount", 0)}
        link={`/${currentLanguage}/activities?sector.code=${params.id}`}
        info={get(cmsData, "tooltips.sector_page_number_of_activities", "")}
      />,
      <StatBox
        percentage={0.6}
        icon={<OrganisationsIcon />}
        key="number-of-organisations"
        label="Number of organisations"
        value={get(dataPageInfo, "data.organisationCount", 0)}
        info={get(cmsData, "tooltips.sector_page_number_of_organisations", "")}
      />,
    ];

    const pieChart = (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: "20px",
          minHeight: "276px",
          borderRadius: "20px",
          boxShadow: colors.shadows.inner,
          background: colors.primary.white,
        }}
      >
        <Box
          sx={{
            gap: "5px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2">
            Disbursements for {pieChartYear}
          </Typography>
          <InfoButton
            fontSize="small"
            text={get(cmsData, "tooltips.overview_disbursements", "")}
          />
        </Box>
        <Typography variant="subtitle1">{formatLocale(pieTotal)}</Typography>
        <Box
          ref={pieContainerRef}
          sx={{
            width: "100%",
            height: "200px",
          }}
        />
      </Box>
    );
    const spacer = <Box sx={{ height: "20px" }} />;

    if (!mobile && !tablet) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {sectorInfo}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            {pieChart}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              "@media (max-width: 768px)": {
                gap: "20px",
              },
            }}
          >
            {sectorStats}
          </Grid>
        </React.Fragment>
      );
    } else if (mobile) {
      return (
        <React.Fragment>
          <Grid item xs={12}>
            {sectorInfo}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              gap: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {sectorStats}
          </Grid>
          <Grid item xs={12}>
            {pieChart}
          </Grid>
        </React.Fragment>
      );
    } else if (tablet) {
      return (
        <React.Fragment>
          <Grid item sm={6} md={6} lg={4}>
            {sectorInfo}
            {spacer}
            {sectorStats[0]}
          </Grid>
          <Grid item sm={6} md={6} lg={4}>
            {pieChart}
            {spacer}
            {sectorStats[1]}
          </Grid>
        </React.Fragment>
      );
    }
  }, [dataPageInfo, nameField, pieTotal, mobile, tablet]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "20px",
        marginBottom: "50px",
        boxShadow: colors.shadows.inner,
        background: colors.primary.gray,
      }}
    >
      <Grid container spacing={2}>
        {pieWithStats}
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              borderRadius: "20px",
              position: "relative",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Box
              sx={{
                gap: "5px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2">
                Disbursements Overview
              </Typography>
              <InfoButton
                fontSize="small"
                text={get(cmsData, "tooltips.overview_disbursements", "")}
              />
            </Box>
            <Typography variant="body2">
              Disbursements overview bar graph showing disbursed amount
              throughout years to{" "}
              {get(dataPageInfo, `data.names["${nameField}"]`, "")}.
            </Typography>
            <Box
              sx={{
                "> div": {
                  height: "250px",
                },
              }}
            >
              <BarLineChart
                detailPage
                dataVariant="oda"
                data={dataBarChart}
                disableDataZoomFeature={!mobile && !tablet}
                barWidth={!mobile ? (!tablet ? 60 : 25) : 15}
              />
            </Box>
            <Box
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "> div": {
                  gap: "5px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: colors.graph.green[500],
                  }}
                />
                <Typography variant="body2">Exclusive ODA</Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: colors.primary.blue,
                  }}
                />
                <Typography variant="body2">Other ODA</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "20px",
              borderRadius: "20px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Typography variant="subtitle1">News</Typography>
            <Box
              sx={{
                width: "100%",
                maxHeight: "191px",
                overflowY: "scroll",
                "> a": {
                  marginBottom: "10px",
                  color: colors.primary.blue,
                },
              }}
            >
              {get(dataPageInfo, "data.news", []).map((item: any) => (
                <a
                  target="_blank"
                  key={item.title}
                  href={item.link}
                  rel="noreferrer noopener"
                >
                  {item.title}
                </a>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "20px",
              borderRadius: "20px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Typography variant="subtitle1">
              Results’ Stories Related to the Country
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export function SectorPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const cmsData = useCMSData({ returnData: true });
  const tablet = useMediaQuery("(max-width: 920px)");
  const mobile = useMediaQuery("(max-width: 768px)");

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const dataPageInfo = useStoreState((state) => state.sectorPageInfo.data);

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );
  const nameField = getNameFieldBasedOnLang(currentLanguage);

  useTitle(
    `${get(dataPageInfo, `data.names["${nameField}"]`, "")} | ${getAppName(
      currentLanguage
    )}`
  );

  const fetchDisbursementsOverviewSankey = useStoreActions(
    (actions) => actions.disbursementsOverviewSankeySector.fetch
  );
  const dataDisbursementsOverviewSankey = useStoreState((state) =>
    get(state.disbursementsOverviewSankeySector, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingDisbursementsOverviewSankey = useStoreState(
    (state) => state.disbursementsOverviewSankeySector.loading
  );
  const fetchPrioritiesChart = useStoreActions(
    (actions) => actions.prioritiesChart.fetch
  );
  const dataPrioritiesChart = useStoreState((state) =>
    get(state.prioritiesChart, "data.vizData", [])
  );
  const loadingPrioritiesChart = useStoreState(
    (state) => state.prioritiesChart.loading
  );
  const fetchGeoChart = useStoreActions(
    (actions) => actions.geoChartSector.fetch
  );
  const dataGeoChart = useStoreState((state) => {
    if (!state.geoChart.data) {
      return {
        vizData: [],
        total: 0,
        totalDisbursements: 0,
        unallocablePercentage: 0,
      };
    }
    return {
      vizData: get(state.geoChartSector, "data.vizData", []),
      total: get(state.geoChartSector, "data.total", 0),
      totalDisbursements: get(
        state.geoChartSector,
        "data.totalDisbursements",
        0
      ),
      unallocablePercentage: get(
        state.geoChartSector,
        "data.unallocablePercentage",
        0
      ),
    };
  });
  const loadingGeoChart = useStoreState(
    (state) => state.geoChartSector.loading
  );
  const fetchOrganisationSankey = useStoreActions(
    (actions) => actions.organisationSankeySector.fetch
  );
  const dataOrganisationSankey = useStoreState((state) =>
    get(state.organisationSankeySector, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingOrganisationSankey = useStoreState(
    (state) => state.organisationSankeySector.loading
  );
  const fetchSDGs = useStoreActions((actions) => actions.sdgsSector.fetch);
  const dataSDGs = useStoreState((state) =>
    get(state.sdgsSector, "data.vizData", [])
  );
  const loadingSDGs = useStoreState((state) => state.sdgsSector.loading);
  const fetchTop10Activities = useStoreActions(
    (actions) => actions.top10activitiesSector.fetch
  );
  const dataTop10Activities = useStoreState((state) =>
    get(state.top10activitiesSector, "data.vizData", [])
  );
  const loadingTop10Activities = useStoreState(
    (state) => state.top10activitiesSector.loading
  );
  const fetchTotalValue = useStoreActions(
    (actions) => actions.totalValue.fetch
  );

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    const filters = getAPIFormattedFilters({
      ...appliedFilters,
      sectors: [params.id ?? ""],
    });
    fetchDisbursementsOverviewSankey({
      values: {
        filters,
        type: "orgtype-country",
      },
    });
    fetchPrioritiesChart({
      values: {
        filters,
      },
    });
    fetchGeoChart({
      values: {
        filters,
      },
    });
    fetchOrganisationSankey({
      values: {
        filters,
      },
    });
    fetchSDGs({
      values: {
        filters,
      },
    });
    fetchTotalValue({
      values: {
        filters,
      },
    });
    fetchTop10Activities({
      values: {
        filters,
      },
    });
  }, [params.id, appliedFilters]);

  const header = React.useMemo(() => {
    const breadcrumbs = (
      <Breadcrumbs
        route={[
          {
            label: "Homepage",
            path: "/:lang",
            cmsKey: "breadcrumbs.homepage",
          },
          {
            label: "Sectors",
            path: "/:lang/sectors",
            cmsKey: "breadcrumbs.sector",
          },
          {
            label: get(dataPageInfo, `data.names["${nameField}"]`, ""),
          },
        ]}
      />
    );

    const title = (
      <Box
        sx={{
          gap: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "@media (max-width: 920px)": {
            marginBottom: "20px",
          },
        }}
      >
        <Box>
          <SectorsIcon />
        </Box>
        <Box>
          <Box
            sx={{
              gap: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              {get(dataPageInfo, `data.names["${nameField}"]`, "")}
            </Typography>
            <InfoButton
              text={get(cmsData, "tooltips.sector_name", "")}
              fontSize="small"
              sx={{
                color: colors.secondary.grayDark,
                "@media (max-width: 920px)": {
                  display: "none",
                },
              }}
            />
          </Box>
          <Typography component="div">
            Showcasing {get(dataPageInfo, `data.names["${nameField}"]`, "")},
            sector of Finnish ODA.
          </Typography>
        </Box>
      </Box>
    );

    const toolbar = (
      <Box
        sx={{
          gap: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          "@media (max-width: 768px)": {
            width: "100%",
            justifyContent: "space-between",
          },
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBack fontSize="small" />}
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            padding: "4px 8px",
            textTransform: "none",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          {get(cmsData, "general.back", "Back")}
        </Button>
        {!mobile ? (
          <FloatingButtons noFloating />
        ) : (
          <Box>
            <FloatingButtons noFloating />
          </Box>
        )}
      </Box>
    );

    const spacer = <Box sx={{ height: "20px" }} />;

    if (!tablet && !mobile) {
      return (
        <React.Fragment>
          {breadcrumbs}
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              flexDirection: "row",
              marginBottom: "50px",
              justifyContent: "space-between",
            }}
          >
            {title}
            {toolbar}
          </Box>
        </React.Fragment>
      );
    } else if (mobile) {
      return (
        <React.Fragment>
          {breadcrumbs}
          {spacer}
          {toolbar}
          {spacer}
          {title}
        </React.Fragment>
      );
    } else if (tablet) {
      return (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              flexDirection: "row",
              marginBottom: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {breadcrumbs}
            {toolbar}
          </Box>
          {title}
        </React.Fragment>
      );
    }
  }, [tablet, mobile, cmsData, params.id, dataPageInfo]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl={`/sectors/${params.id}`} />
      <Box
        sx={{
          "@media (max-width: 920px)": {
            padding: "0 48px",
          },
          "@media (max-width: 768px)": {
            padding: "0 24px",
          },
        }}
      >
        {header}
        <SectorPageIntroBlock />
        <PageSection
          background="#F6F9FD"
          loading={loadingDisbursementsOverviewSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.SectorPageDisbursementsOverviewTitle",
              "Disbursements Overview"
            ),
            subtitle: get(
              cmsData,
              "pageSections.SectorPageDisbursementsOverviewSubtitle",
              "Disbursements overview of this sector to its high level country group to biggest 10 partner countries."
            ),
            info: get(cmsData, "tooltips.overview_disbursements", ""),
            icon: <DisbursementsOverviewIcon />,
          }}
        >
          <SankeyChart {...dataDisbursementsOverviewSankey} />
        </PageSection>
        <PageSection
          background="#FFFAF9"
          loading={loadingPrioritiesChart}
          header={{
            title: get(cmsData, "pageSections.PrioritiesTitle", "Priorities"),
            subtitle: get(
              cmsData,
              "pageSections.PrioritiesSubtitle",
              "Prioritised areas of Finnish ODA."
            ),
            info: get(cmsData, "tooltips.thematic_areas", ""),
            icon: <PrioritiesIcon />,
          }}
          sx={{ overflow: "visible" }}
        >
          <PrioritiesChart items={dataPrioritiesChart} />
        </PageSection>
        <PageSection
          background="#F9FBFA"
          loading={loadingGeoChart}
          header={{
            title: get(
              cmsData,
              "pageSections.CountriesRegionsTitle",
              "Countries & Regions"
            ),
            subtitle: get(
              cmsData,
              "pageSections.CountriesRegionsSubtitle",
              "Partner countries of Finnish ODA."
            ),
            info: get(cmsData, "tooltips.regions", ""),
            icon: <CountriesRegionsIcon />,
          }}
        >
          <GeomapChart
            data={dataGeoChart.vizData}
            unallocable={dataGeoChart.unallocablePercentage}
          />
        </PageSection>
        <PageSection
          background="#FFFCF7"
          loading={loadingOrganisationSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.OrganisationsTitle",
              "Organisations"
            ),
            subtitle: get(
              cmsData,
              "pageSections.OrganisationsSubtitle",
              "5 partner organisations of Finnish ODA with the largest budget."
            ),
            info: get(cmsData, "tooltips.organisations", ""),
            icon: <OrganisationsIcon />,
          }}
        >
          <SankeyChart organisation {...dataOrganisationSankey} />
        </PageSection>
        <PageSection
          background="#F9FCFE"
          loading={loadingSDGs}
          header={{
            title: get(cmsData, "pageSections.SDGsTitle", "SDGs"),
            subtitle: get(
              cmsData,
              "pageSections.SDGsSubtitle",
              "SDGs listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.sdg", ""),
            icon: <SDGsIcon />,
          }}
        >
          <SdgsChart items={dataSDGs} />
        </PageSection>
        <PageSection
          background="#FCFBF9"
          loading={loadingTop10Activities}
          header={{
            title: get(cmsData, "pageSections.ActivitiesTitle", "Activities"),
            subtitle: get(
              cmsData,
              "pageSections.ActivitiesSubtitle",
              "Top 10 largest activities listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.activities", ""),
            icon: <ActivitiesIcon />,
          }}
        >
          <Link
            id="see-all-activities-comp"
            to={`/${currentLanguage}/activities${
              location.search.length > 0 ? `${location.search}&` : "?"
            }sector.code=${params.id}`}
          >
            {get(cmsData, "viz.seeallactivities", "See All Activities")}{" "}
            <ArrowForward fontSize="small" />
          </Link>
          <ActivitiesChart items={dataTop10Activities} />
        </PageSection>
      </Box>
    </React.Fragment>
  );
}
