import React from "react";
import { ROUTES } from "app/router/data";
import SitemapPage from "app/pages/sitemap";
import { Header } from "app/components/header";
import { PageLoader } from "app/components/pageLoader";
import { LANGUAGES } from "app/components/header/data";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

export default function Router() {
  const setUpRouter = () => {
    const result: RouteObject[] = [];

    for (const route of ROUTES) {
      for (const lang of LANGUAGES) {
        result.push({
          ...route,
          path: route.path.replace(":lang", lang.code),
        });
      }
      result.push({
        ...route,
        path: route.path.replace(":lang", ""),
        element: (
          <React.Fragment>
            <Header />
            <PageLoader />
          </React.Fragment>
        ),
      });
    }

    result.push({ path: "get-sitemap", element: <SitemapPage /> });

    return createBrowserRouter(result);
  };

  const [router] = React.useState(setUpRouter);

  return <RouterProvider fallbackElement={<PageLoader />} router={router} />;
}
