import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FiltersViewToolbar } from "app/components/filters/view/components/toolbar";
import {
  FILTER_TYPES,
  FilterOption,
  fromTabViewToFilterType,
  FiltersCommonTabViewProps,
  getCheckboxStatus,
  fromTabViewToAppliedFilters,
} from "app/components/filters/view/data";

export function FiltersTabViewLayout(props: FiltersCommonTabViewProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string, parentPanel?: string) =>
    (_e: React.SyntheticEvent, newExpanded: boolean) => {
      if (!newExpanded && parentPanel) {
        setExpanded(parentPanel);
      } else {
        setExpanded(newExpanded ? panel : false);
      }
    };

  const onCheckBoxStatusChange =
    (type: FILTER_TYPES, value: string, subItems?: FilterOption[]) => () => {
      let valueParam: string[] = [];
      if (value.length > 0) {
        valueParam = [value];
      }
      if (subItems) {
        valueParam = [...valueParam, ...subItems.map((si) => si.value)];
      }
      props.onFilterCheckboxChange(valueParam, type);
    };

  const clearAllFilters = () => {
    props.onFilterCheckboxChange([], fromTabViewToFilterType(props.activeTab));
  };

  const selectAllFilters = () => {
    const allValues: string[] = [];
    props.items.forEach((item) => {
      allValues.push(item.value);
      if (item.subItems) {
        item.subItems.forEach((si) => {
          allValues.push(si.value);
          if (si.subItems) {
            si.subItems.forEach((ssi) => {
              allValues.push(ssi.value);
            });
          }
        });
      }
    });
    props.onFilterCheckboxChange(
      allValues,
      fromTabViewToFilterType(props.activeTab)
    );
  };

  const viewAppliedFilters = React.useMemo(() => {
    return fromTabViewToAppliedFilters(
      props.activeTab,
      props.localAppliedFilters
    );
  }, [props.activeTab, props.localAppliedFilters]);

  const advancedViewAppliedFilters = React.useMemo(() => {
    return {
      [FILTER_TYPES.POLICY_MARKERS]: props.localAppliedFilters.policymarker,
      [FILTER_TYPES.AID_TYPE]: props.localAppliedFilters.defaultaidtype,
      [FILTER_TYPES.BUDGET_LINES]: props.localAppliedFilters.budgetlines,
      [FILTER_TYPES.BI_MULTI]: props.localAppliedFilters.collaborationtype,
      [FILTER_TYPES.HUMAN_RIGHTS]: props.localAppliedFilters.humanrights,
    };
  }, [props.localAppliedFilters]);

  // console.log("items", props.items);

  return (
    <Box
      sx={{
        marginTop: "30px",
      }}
    >
      <FiltersViewToolbar
        clearAllFilters={clearAllFilters}
        selectAllFilters={selectAllFilters}
      />
      <Box
        className="blueSlimScrollbar"
        sx={{
          overflowY: "auto",
          padding: "0 10px",
          maxHeight: "300px",
          overflowX: "hidden",
        }}
      >
        {props.items.map((item) => (
          <Accordion
            elevation={0}
            disableGutters
            key={`${item.label}-${item.value}`}
            expanded={
              expanded === item.label ||
              get(item, "subItems", [])
                .map((si: { label: string; value: string }) => si.label)
                .indexOf(expanded as string) > -1
            }
            onChange={handleChange(item.label)}
          >
            <AccordionSummary
              id={`${item.label}-header`}
              aria-controls={`${item.label}-content`}
              expandIcon={<ArrowDropDownIcon sx={{ fontSize: "1.4rem" }} />}
            >
              <FormControlLabel
                label={item.label}
                control={
                  <Checkbox
                    checked={getCheckboxStatus(
                      item,
                      item.type
                        ? get(advancedViewAppliedFilters, item.type, [])
                        : viewAppliedFilters
                    )}
                    onChange={onCheckBoxStatusChange(
                      item.type ?? fromTabViewToFilterType(props.activeTab),
                      item.value,
                      item.subItems
                    )}
                  />
                }
              />
            </AccordionSummary>
            <AccordionDetails
              sx={
                item.subItems && item.subItems[0].subItems
                  ? {}
                  : {
                      borderRadius: "30px",
                      background: colors.primary.gray,
                    }
              }
            >
              <Grid container>
                {item.subItems?.map((subItem) => {
                  if (subItem.subItems) {
                    return (
                      <Accordion
                        elevation={0}
                        disableGutters
                        key={`${subItem.label}-${subItem.value}`}
                        expanded={expanded === subItem.label}
                        onChange={handleChange(subItem.label, item.label)}
                      >
                        <AccordionSummary
                          id={`${subItem.label}-header`}
                          aria-controls={`${subItem.label}-content`}
                          expandIcon={
                            <ArrowDropDownIcon sx={{ fontSize: "1.4rem" }} />
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={getCheckboxStatus(
                                  subItem,
                                  subItem.type
                                    ? get(
                                        advancedViewAppliedFilters,
                                        subItem.type,
                                        []
                                      )
                                    : viewAppliedFilters
                                )}
                                onChange={onCheckBoxStatusChange(
                                  subItem.type ??
                                    fromTabViewToFilterType(props.activeTab),
                                  subItem.value,
                                  subItem.subItems
                                )}
                              />
                            }
                            label={subItem.label}
                          />
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderRadius: "30px",
                            background: colors.primary.gray,
                          }}
                        >
                          <Grid container>
                            {subItem.subItems?.map((subSubItem) => (
                              <Grid
                                item
                                xs={6}
                                md={4}
                                key={`${subSubItem.label}-${subSubItem.value}`}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={getCheckboxStatus(
                                        subSubItem,
                                        subSubItem.type
                                          ? get(
                                              advancedViewAppliedFilters,
                                              subSubItem.type,
                                              []
                                            )
                                          : viewAppliedFilters
                                      )}
                                      onChange={onCheckBoxStatusChange(
                                        subSubItem.type ??
                                          fromTabViewToFilterType(
                                            props.activeTab
                                          ),
                                        subSubItem.value,
                                        subSubItem.subItems
                                      )}
                                    />
                                  }
                                  label={subSubItem.label}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                  return (
                    <Grid item xs={6} md={4} key={subItem.value}>
                      <FormControlLabel
                        label={subItem.label}
                        control={
                          <Checkbox
                            checked={getCheckboxStatus(
                              subItem,
                              subItem.type
                                ? get(
                                    advancedViewAppliedFilters,
                                    subItem.type,
                                    []
                                  )
                                : viewAppliedFilters
                            )}
                            onChange={onCheckBoxStatusChange(
                              subItem.type ??
                                fromTabViewToFilterType(props.activeTab),
                              subItem.value,
                              subItem.subItems
                            )}
                          />
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}
