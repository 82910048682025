import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { GenericMenu } from "app/components/menu";
import { useCMSData } from "app/hooks/useCMSData";
import { useUrlFilters } from "app/hooks/useUrlFilters";
import MenuRounded from "@mui/icons-material/MenuRounded";
import useWindowScroll from "react-use/lib/useWindowScroll";
import { LANGUAGES, PAGES } from "app/components/header/data";
import FormControlLabel from "@mui/material/FormControlLabel";
import LanguageRounded from "@mui/icons-material/LanguageRounded";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { SearchComponent } from "app/components/header/components/search";
import { MobileMenu } from "app/components/header/components/mobileMenuPanel";
import { SkipToMainButton } from "app/components/header/components/skipToMainButton";
import { ReactComponent as HeaderBgLogo } from "app/assets/vectors/HeaderBgLogo.svg";
import { AccessibilitySwitch } from "app/components/header/components/accessibilitySwitch";
import { ReactComponent as HeaderToolbarLogo } from "app/assets/vectors/HeaderToolbarLogo.svg";

/*
    Figma design component: https://www.figma.com/file/uZX8k1fv1MfzGTEBXuvjeV/MFA---Main-Design-2.0?type=design&node-id=6180-196363&mode=dev
*/

export function Header() {
  useUrlFilters();
  const location = useLocation();
  const navigate = useNavigate();
  const { y } = useWindowScroll();
  const cmsData = useCMSData({ returnData: true });

  const isSearchFocused = useStoreState(
    (state) => state.isSearchFocused.value as boolean
  );
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );
  const setCurrentLanguage = useStoreActions(
    (actions) => actions.selectedLanguage.setValue
  );
  const isFocused = useStoreState((state) => state.isSearchFocused.value);

  const onLanguageChange = (lang: string) => {
    setCurrentLanguage(lang);
    navigate(
      `${location.pathname.replace(`/${currentLanguage}`, `/${lang}`)}${
        location.search
      }`,
      {
        replace: true,
      }
    );
  };

  const languageItems = LANGUAGES.map((lang) => ({
    ...lang,
    action: () => onLanguageChange(lang.code),
  }));

  const pageItems = PAGES.map((page) => ({
    ...page,
    link: `/${currentLanguage}${page.link}${location.search}`,
    label: page.cmsKey ? get(cmsData, page.cmsKey, page.label) : page.label,
  }));

  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);

  React.useEffect(() => {
    const pathSplits = location.pathname.split("/");
    if (LANGUAGES.map((lang) => lang.code).indexOf(pathSplits[1]) === -1) {
      navigate(`/${currentLanguage}${location.pathname}${location.search}`, {
        replace: true,
      });
    }
  }, [location]);

  const isHomepage = React.useMemo(() => {
    const pathSplits = location.pathname.split("/");
    if (pathSplits.length > 3) {
      return false;
    }
    if (pathSplits.length === 3) {
      return (
        pathSplits[0] === "" &&
        pathSplits[1] === currentLanguage &&
        pathSplits[2] === ""
      );
    }
    return pathSplits.length === 2 && pathSplits[1] === currentLanguage;
  }, [location.pathname]);

  const accessibilitySwitch = useStoreState(
    (state) => state.accessibilitySwitch.value
  );
  const setAccessibilitySwitch = useStoreActions(
    (actions) => actions.accessibilitySwitch.setValue
  );

  const handleAccessibilitySwitch = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAccessibilitySwitch(e.target.checked);
  };

  return (
    <React.Fragment>
      {isHomepage && (
        <Box
          sx={{
            top: 0,
            left: 0,
            width: "100vw",
            height: "242px",
            position: "absolute",
            background: colors.primary.gray,
            boxShadow:
              "0px 1.446765661239624px 2.893531322479248px 0px rgba(97, 97, 97, 0.10)",
          }}
        >
          <Container
            maxWidth="lg"
            disableGutters
            sx={{
              width: "100vw",
              paddingTop: "96px",
              position: "relative",
              "@media (max-width: 1280px)": {
                paddingLeft: "48px",
                paddingRight: "48px",
              },
              "@media (max-width: 768px)": {
                paddingLeft: "24px",
                paddingRight: "24px",
              },
            }}
          >
            <Box
              sx={{
                top: 0,
                right: 0,
                position: "absolute",
              }}
            >
              <HeaderBgLogo />
            </Box>
            <Typography
              variant="h4"
              sx={{
                marginBottom: "15px",
                "@media (max-width: 768px)": {
                  fontSize: "18px",
                },
              }}
            >
              {get(
                cmsData,
                "general.pagetitle",
                "Databank on Finland's development cooperation"
                // remove this when cms is ready
              ).replace("OpenAid.fi - ", "")}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                "@media (min-width: 1200px)": {
                  maxWidth: accessibilitySwitch ? "1100px" : "862px",
                },
                "@media (max-width: 920px)": {
                  fontSize: "16px",
                },
                "@media (max-width: 768px)": {
                  fontSize: "12px",
                },
              }}
            >
              {get(
                cmsData,
                "general.pagesubtitle",
                "Openaid.fi is a user-friendly platform that provides comprehensive data on Finland's development cooperation funds, including information on their location, timing, and allocation."
              )}
            </Typography>
          </Container>
        </Box>
      )}
      <AppBar
        position="fixed"
        sx={{
          height: "60px",
          background:
            y > 0 || !isHomepage ? colors.primary.gray : "transparent",
          boxShadow:
            y > 0 || !isHomepage
              ? "0px 1.446765661239624px 2.893531322479248px 0px rgba(97, 97, 97, 0.10);"
              : "none",
          "@media (max-width: 1280px)": {
            left: 0,
            width: "100vw",
          },
        }}
      >
        <Container maxWidth="lg" disableGutters>
          <Toolbar
            sx={{
              "@media (min-width: 600px)": {
                minHeight: "60px",
              },
            }}
          >
            <SkipToMainButton />
            <Box
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Link
                aria-label="Home link"
                component={NavLink}
                to="/"
                sx={{
                  gap: "15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textDecoration: "none",
                  "@media (max-width: 1280px)": {
                    display: isFocused ? "none" : "flex",
                  },
                }}
              >
                <HeaderToolbarLogo />
                <Box display="flex" flexDirection="row">
                  <Typography variant="h5" color="#F84415">
                    BETA.
                  </Typography>
                  <Typography variant="h5">OpenAid.fi</Typography>
                </Box>
              </Link>
              {!isSearchFocused && (
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    "@media (max-width: 1280px)": {
                      left: "58px",
                      bottom: "-10px",
                      fontSize: "12px",
                      position: "absolute",
                      width: "max-content",
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: get(
                      cmsData,
                      "general.v2pagetitle",
                      "Databank on Finland's development cooperation"
                    ),
                  }}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <SearchComponent />
              <FormControlLabel
                label="accessibility switch"
                control={
                  <AccessibilitySwitch
                    sx={{ m: 1 }}
                    checked={accessibilitySwitch}
                    onChange={handleAccessibilitySwitch}
                    text={get(
                      cmsData,
                      "general.accessibilitybutton",
                      "Accessibility"
                    )}
                  />
                }
                sx={{
                  marginLeft: "12px",
                  marginRight: "0px",
                  ".MuiTypography-root": {
                    display: "none",
                  },
                  "@media (max-width: 768px)": {
                    display: "none",
                  },
                }}
              />
              <GenericMenu
                id="language-menu"
                items={languageItems}
                selected={currentLanguage}
                anchorElement={
                  <IconButton aria-label="language selector button">
                    <LanguageRounded
                      sx={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  </IconButton>
                }
                sx={{
                  "@media (max-width: 768px)": {
                    display: "none",
                  },
                }}
              />
              <GenericMenu
                id="pages-menu"
                anchorElement={
                  <IconButton aria-label="menu button">
                    <MenuRounded />
                  </IconButton>
                }
                items={pageItems}
                selected={`${location.pathname}${location.search}`}
                sx={{
                  "@media (max-width: 768px)": {
                    display: "none",
                  },
                }}
              />
              <IconButton
                aria-label="menu button"
                sx={{
                  "@media (min-width: 769px)": {
                    display: "none",
                  },
                }}
                onClick={() => setOpenMobileMenu(true)}
              >
                <MenuRounded
                  sx={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <MobileMenu
        open={openMobileMenu}
        onClose={() => setOpenMobileMenu(false)}
      />
    </React.Fragment>
  );
}
