import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { SearchResultNavItem } from "app/components/header/components/search/common/SearchResultNavItem";
import {
  searchNavItems,
  NavResultsModel,
} from "app/components/header/components/search/data";

type Props = {
  cmsData: any;
  noAll?: boolean;
  activeTab: string;
  onChange: Function;
  results: NavResultsModel;
};

export const SearchResultNavigation = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "15px 10px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        "@media (max-width: 600px)": {
          width: "100%",
          maxWidth: "100%",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: "4px",
            background: colors.secondary.blueGrayLight,
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "4px",
            background: colors.secondary.blueGrayLight,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            background: colors.primary.blue,
          },
        },
      }}
    >
      {(!props.noAll ? searchNavItems : searchNavItems.slice(0, 5)).map(
        (navItem: any, index: number) => {
          return (
            <SearchResultNavItem
              index={index}
              key={`search-nav-item-${navItem.name}`}
              active={props.activeTab === navItem.name}
              onClick={() => props.onChange(navItem.name)}
              name={get(props.cmsData, navItem.cmsKey, navItem.name)}
              count={
                get(props.results, `[${navItem.name}]`, { count: 0 }).count
              }
            />
          );
        }
      )}
    </Box>
  );
};
