import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

const organisationSankey: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/organisation-sankey`),
};

export default organisationSankey;

export const organisationSankeyPriority: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/organisation-sankey`),
};

export const organisationSankeyLocation: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/organisation-sankey`),
};

export const organisationSankeySector: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/organisation-sankey`),
};
