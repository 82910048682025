import React from "react";
import { Helmet } from "react-helmet-async";
import { useStoreState } from "app/state/store/hooks";

interface Props {
  canonicalUrl: string;
}

export function CanonicalUrl(props: Props) {
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  return (
    <Helmet>
      <link
        rel="canonical"
        href={`${window.location.origin}/${currentLanguage}${props.canonicalUrl}`}
      />
    </Helmet>
  );
}
