import get from "lodash/get";
import find from "lodash/find";
import { APIFilterOption } from "app/components/filters/view/data";
import { AppliedFiltersStateModel } from "app/state/api/action-reducers/sync/appliedFilters";

interface Item {
  label: string;
  value: string;
  category: string;
}

export function getAppliedFiltersPills(
  filters: AppliedFiltersStateModel,
  options: {
    locations: APIFilterOption[];
    sectors: APIFilterOption[];
    thematicareas: APIFilterOption[];
    organisations: APIFilterOption[];
    sdgs: APIFilterOption[];
    activitystatus: APIFilterOption[];
    policymarkers: APIFilterOption[];
    aidtypes: APIFilterOption[];
    budgetlines: APIFilterOption[];
  }
): Item[] {
  const items: Item[] = [];

  // console.log(filters);
  // console.log(options);

  if (filters.countries.length > 0) {
    let countryOptions: APIFilterOption[] = [];
    options.locations.forEach((item) => {
      if (item.children) {
        countryOptions = countryOptions.concat(item.children);
      }
    });
    filters.countries.forEach((item) => {
      items.push({
        label: get(find(countryOptions, { code: item }), "name", item),
        value: item,
        category: "countries",
      });
    });
  }
  if (filters.regions.length > 0) {
    filters.regions.forEach((item) => {
      items.push({
        label: get(find(options.locations, { code: item }), "name", item),
        value: item,
        category: "regions",
      });
    });
  }
  if (filters.sectors.length > 0) {
    let sectorOptions: APIFilterOption[] = options.sectors;
    options.sectors.forEach((item) => {
      if (item.children) {
        sectorOptions = sectorOptions.concat(item.children);
        if (item.children) {
          item.children.forEach((child) => {
            if (child.children) {
              sectorOptions = sectorOptions.concat(child.children);
            }
            if (child.children) {
              child.children.forEach((grandChild) => {
                if (grandChild.children) {
                  sectorOptions = sectorOptions.concat(grandChild.children);
                }
              });
            }
          });
        }
      }
    });
    filters.sectors.forEach((item) => {
      items.push({
        label: get(find(sectorOptions, { code: item }), "name", item),
        value: item,
        category: "sectors",
      });
    });
  }
  if (filters.tag.length > 0) {
    filters.tag.forEach((item) => {
      items.push({
        label: get(find(options.thematicareas, { code: item }), "name", item),
        value: item,
        category: "tag",
      });
    });
  }
  if (filters.organisationtypes.length > 0) {
    filters.organisationtypes.forEach((item) => {
      items.push({
        label: get(find(options.organisations, { code: item }), "name", item),
        value: item,
        category: "organisationtypes",
      });
    });
  }
  if (filters.organisations.length > 0) {
    let organisationOptions: APIFilterOption[] = [];
    options.organisations.forEach((item) => {
      if (item.children) {
        organisationOptions = organisationOptions.concat(item.children);
        item.children.forEach((child) => {
          if (child.children) {
            organisationOptions = organisationOptions.concat(child.children);
          }
          if (child.children) {
            child.children.forEach((grandChild) => {
              if (grandChild.children) {
                organisationOptions = organisationOptions.concat(
                  grandChild.children
                );
              }
            });
          }
        });
      }
    });
    filters.organisations.forEach((item) => {
      items.push({
        label: get(find(organisationOptions, { code: item }), "name", item),
        value: item,
        category: "organisations",
      });
    });
  }
  if (filters.sdg.length > 0) {
    filters.sdg.forEach((item) => {
      items.push({
        // @ts-ignore
        label: get(find(options.sdgs.goals, { code: item }), "name", item),
        value: item,
        category: "sdg",
      });
    });
  }
  if (filters.years.length > 1) {
    let label = `${filters.years[0]} - ${filters.years[1]}`;
    if (filters.years[0] === filters.years[1]) {
      label = `${filters.years[0]}`;
    }
    items.push({
      label,
      value: `${filters.years[0]} - ${filters.years[1]}`,
      category: "years",
    });
  }
  if (filters.policymarker.length > 0) {
    filters.policymarker.forEach((item) => {
      items.push({
        label: get(find(options.policymarkers, { code: item }), "name", item),
        value: item,
        category: "policymarkers",
      });
    });
  }
  if (filters.defaultaidtype.length > 0) {
    filters.defaultaidtype.forEach((item) => {
      items.push({
        label: get(find(options.aidtypes, { code: item }), "name", item),
        value: item,
        category: "defaultaidtype",
      });
    });
  }
  if (filters.budgetlines.length > 0) {
    filters.budgetlines.forEach((item) => {
      items.push({
        label: get(find(options.budgetlines, { code: item }), "name", item),
        value: item,
        category: "budgetlines",
      });
    });
  }
  if (filters.collaborationtype.length > 0) {
    filters.collaborationtype.forEach((item) => {
      items.push({
        label: item,
        value: item,
        category: "collaborationtype",
      });
    });
  }
  if (filters.humanrights.length > 0) {
    filters.humanrights.forEach((item) => {
      items.push({
        label: item,
        value: item,
        category: "humanrights",
      });
    });
  }

  return items;
}
