/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Link as RouteLink } from "react-router-dom";
import { useStoreState } from "app/state/store/hooks";

interface ResultItemParams {
  text: string;
  link: string;
  index?: number;
  handleResultClick: any;
}

export const SearchResultItem = (props: ResultItemParams) => {
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  return (
    <Box
      sx={{
        margin: "8px 0",
        display: "flex",
        cursor: "pointer",
        lineHeight: "24px",
        paddingLeft: "24px",
        paddingRight: "24px",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "background 0.1s linear",
        "&:hover": {
          background: colors.secondary.blueGrayLight,
          "> a": {
            textDecoration: "none",
            background: colors.secondary.blueGrayLight,
          },
        },
      }}
      onClick={props.handleResultClick}
    >
      <Link
        sx={{
          width: "100%",
          fontSize: "12px",
          textDecoration: "none",
          background: colors.primary.white,
          transition: "background 0.1s linear",
          color: colors.primary.blue,
        }}
        component={RouteLink}
        to={`/${currentLanguage === "se" ? "sv" : currentLanguage}${
          props.link
        }`}
      >
        {props.text}
      </Link>
    </Box>
  );
};
