import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

const prioritiesChart: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/priorities-chart`),
};

export default prioritiesChart;

export const prioritiesChartLocation: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/priorities-chart`),
};

export const prioritiesChartSector: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/priorities-chart`),
};
