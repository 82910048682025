import React from "react";
import get from "lodash/get";
import { useCMSData } from "app/hooks/useCMSData";
import { useStoreState } from "app/state/store/hooks";
import { FiltersViewSDGS } from "app/components/filters/view/views/sdgs";
import { FiltersTabViewLayout } from "app/components/filters/view/layout";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";
import { FiltersViewPeriod } from "app/components/filters/view/views/period";
import { FiltersViewAdvanced } from "app/components/filters/view/views/advanced";
import {
  FilterOption,
  APIFilterOption,
  FiltersTabViewProps,
  HUMAN_RIGHTS_FILTER_OPTIONS,
  FILTER_TYPES,
} from "app/components/filters/view/data";

export function FiltersTabView(props: FiltersTabViewProps) {
  const cmsData = useCMSData({ returnData: true });

  const filterOptions = useStoreState((state) => state.filterOptions);
  const search = useStoreState(
    (state) => state.filterPanelSearch.value as string
  );
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const priorities = React.useMemo(() => {
    return get(filterOptions.thematicareas, "data.data", []).map(
      (ta: APIFilterOption, index: number) => ({
        label: get(cmsData, `priorityAreas.Priorityarea${index + 1}`, ta.name),
        value: ta.code,
        subItems: ta.children?.map((c: APIFilterOption, index: number) => ({
          label: get(
            cmsData,
            `priorityAreas.${index === 0 ? "main" : "secondary"}`,
            c.name
          ),
          value: c.code,
        })),
      })
    );
  }, [filterOptions.thematicareas.data, cmsData?.priorityAreas]);

  const sectors = React.useMemo(() => {
    return get(filterOptions.sectors, "data.data", []).map(
      (s: APIFilterOption) => ({
        label: s[getNameFieldBasedOnLang(currentLanguage)] ?? s.name,
        value: s.code,
        subItems: s.children?.map((c: APIFilterOption) => ({
          label: c[getNameFieldBasedOnLang(currentLanguage)] ?? c.name,
          value: c.code,
          subItems: c.children?.map((cc: APIFilterOption) => ({
            label: cc[getNameFieldBasedOnLang(currentLanguage)] ?? cc.name,
            value: cc.code,
          })),
        })),
      })
    );
  }, [filterOptions.sectors.data]);

  const locations = React.useMemo(() => {
    return get(filterOptions.locations, "data.data", []).map(
      (ta: APIFilterOption) => ({
        label: ta[getNameFieldBasedOnLang(currentLanguage)] ?? ta.name,
        value: ta.code,
        subItems: ta.children?.map((c: APIFilterOption) => ({
          label: c[getNameFieldBasedOnLang(currentLanguage)] ?? c.name,
          value: c.code,
        })),
      })
    );
  }, [filterOptions.locations.data]);

  const organisations = React.useMemo(() => {
    return get(filterOptions.organisations, "data.data", []).map(
      (ta: APIFilterOption) => ({
        label: ta[getNameFieldBasedOnLang(currentLanguage)] ?? ta.name,
        value: ta.code,
        subItems: ta.children?.map((c: APIFilterOption) => ({
          label: c[getNameFieldBasedOnLang(currentLanguage)] ?? c.name,
          value: c.code,
          subItems: c.children?.map((cc: APIFilterOption) => ({
            label: cc[getNameFieldBasedOnLang(currentLanguage)] ?? cc.name,
            value: cc.code,
          })),
        })),
      })
    );
  }, [filterOptions.organisations.data]);

  const sdgs = React.useMemo(() => {
    return get(filterOptions.sdgs, "data.data.goals", []).map(
      (ta: APIFilterOption) => ({
        label: ta[getNameFieldBasedOnLang(currentLanguage)] ?? ta.name,
        value: ta.code,
      })
    );
  }, [filterOptions.sdgs.data]);

  const advanced = React.useMemo(() => {
    return [
      {
        label: get(
          cmsData,
          "filters.policymarkers",
          "Objectives monitored by the OECD/DAC"
        ),
        value: "",
        subItems: get(filterOptions.policymarkers, "data.data", []).map(
          (p: APIFilterOption) => ({
            label: p[getNameFieldBasedOnLang(currentLanguage)] ?? p.name,
            value: p.code,
            type: FILTER_TYPES.POLICY_MARKERS,
          })
        ),
        type: FILTER_TYPES.POLICY_MARKERS,
      },
      {
        label: get(cmsData, "filters.typeofaid", "Cooperation modalities"),
        value: "",
        subItems: get(filterOptions.aidtypes, "data.data", []).map(
          (at: APIFilterOption) => ({
            label: at[getNameFieldBasedOnLang(currentLanguage)] ?? at.name,
            value: at.code,
            type: FILTER_TYPES.AID_TYPE,
          })
        ),
        type: FILTER_TYPES.AID_TYPE,
      },
      {
        label: get(cmsData, "general.budgetlines", "Budget lines"),
        value: "",
        subItems: get(filterOptions.budgetlines, "data.data", []).map(
          (bl: APIFilterOption) => ({
            label: bl[getNameFieldBasedOnLang(currentLanguage)] ?? bl.name,
            value: bl.code,
            type: FILTER_TYPES.BUDGET_LINES,
          })
        ),
        type: FILTER_TYPES.BUDGET_LINES,
      },
      {
        label: get(
          cmsData,
          "filters.bimulti",
          "Core contribution (multi) / other support (bi)"
        ),
        value: "",
        subItems: [
          {
            label: "Bi",
            value: "1",
            type: FILTER_TYPES.BI_MULTI,
          },
          {
            label: "Multi",
            value: "2",
            type: FILTER_TYPES.BI_MULTI,
          },
        ],
        type: FILTER_TYPES.BI_MULTI,
      },
      {
        label: get(
          cmsData,
          "filters.humanrights",
          "Human rights-based approach"
        ),
        value: "",
        subItems: HUMAN_RIGHTS_FILTER_OPTIONS.map((as: APIFilterOption) => ({
          label: as[getNameFieldBasedOnLang(currentLanguage)] ?? as.name,
          value: as.code,
          type: FILTER_TYPES.HUMAN_RIGHTS,
        })),
        type: FILTER_TYPES.HUMAN_RIGHTS,
      },
    ];
  }, [
    filterOptions.aidtypes.data,
    filterOptions.activitystatus.data,
    filterOptions.budgetlines.data,
    filterOptions.policymarkers.data,
  ]);

  const filtersToShow = React.useMemo(() => {
    let viewOptions: FilterOption[] = [];
    switch (props.activeTab) {
      case "priorities":
        viewOptions = priorities;
        break;
      case "sectors":
        viewOptions = sectors;
        break;
      case "locations":
        viewOptions = locations;
        break;
      case "organisations":
        viewOptions = organisations;
        break;
      case "sdgs":
        return sdgs;
      case "advanced":
        viewOptions = advanced;
        break;
      default:
        break;
    }
    const fvalue = search.toLowerCase();
    const options: FilterOption[] = [];
    if (fvalue === "") {
      return viewOptions;
    }
    viewOptions.forEach((item: FilterOption) => {
      const cat = item.label.toLowerCase().indexOf(fvalue) > -1;
      if (cat) {
        options.push(item);
      } else if (item.subItems) {
        const fItem: FilterOption = {
          ...item,
          subItems: [],
        };
        item.subItems.forEach((child: FilterOption) => {
          if (child.label.toLowerCase().indexOf(fvalue) > -1) {
            fItem.subItems?.push(child);
          } else if (child.subItems) {
            const fChildItem: FilterOption = {
              ...child,
              subItems: [],
            };
            child.subItems.forEach((gchild: FilterOption) => {
              if (gchild.label.toLowerCase().indexOf(fvalue) > -1) {
                fChildItem.subItems?.push(gchild);
              } else if (gchild.subItems) {
                const fGChildItem: FilterOption = {
                  ...child,
                  subItems: [],
                };
                gchild.subItems.forEach((ggchild: FilterOption) => {
                  if (ggchild.label.toLowerCase().indexOf(fvalue) > -1) {
                    fGChildItem.subItems?.push(ggchild);
                  }
                });
                if (fGChildItem.subItems && fGChildItem.subItems.length > 0) {
                  fChildItem.subItems?.push(fGChildItem);
                }
              }
            });
            if (fChildItem.subItems && fChildItem.subItems.length > 0) {
              fItem.subItems?.push(fChildItem);
            }
          }
        });
        if (fItem.subItems && fItem.subItems.length > 0) {
          options.push({ ...fItem });
        }
      }
    });
    return options;
  }, [
    priorities,
    sectors,
    locations,
    organisations,
    sdgs,
    advanced,
    search,
    props.activeTab,
  ]);

  const view = React.useMemo(() => {
    switch (props.activeTab) {
      case "period":
        return (
          <FiltersViewPeriod
            localAppliedFilters={props.localAppliedFilters}
            onFilterCheckboxChange={props.onFilterCheckboxChange}
          />
        );
      case "priorities":
      case "sectors":
      case "locations":
      case "organisations":
        return (
          <FiltersTabViewLayout
            items={filtersToShow}
            activeTab={props.activeTab}
            localAppliedFilters={props.localAppliedFilters}
            onFilterCheckboxChange={props.onFilterCheckboxChange}
          />
        );
      case "sdgs":
        return (
          <FiltersViewSDGS
            items={sdgs}
            activeTab={props.activeTab}
            localAppliedFilters={props.localAppliedFilters}
            onFilterCheckboxChange={props.onFilterCheckboxChange}
          />
        );
      case "advanced":
        return (
          <FiltersViewAdvanced
            items={filtersToShow}
            activeTab={props.activeTab}
            localAppliedFilters={props.localAppliedFilters}
            onFilterCheckboxChange={props.onFilterCheckboxChange}
          />
        );
      default:
        return <></>;
    }
  }, [props.activeTab, filtersToShow, props.localAppliedFilters]);

  return view;
}
