import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { formatLocale } from "app/utils/formatLocale";
import ArrowRight from "@mui/icons-material/ArrowRight";
import {
  LegendPanelProps,
  LegendPanelItemProps,
} from "app/pages/common/legendPanel/data";

function LegendPanelItem(props: LegendPanelItemProps) {
  const [expanded, setExpanded] = React.useState(false);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props);
    }
    setExpanded(!expanded);
  };

  const value = React.useMemo(() => {
    if (typeof props.value === "number" && !props.noFormat) {
      return formatLocale(props.value);
    }
    return props.value;
  }, [props.value, props.noFormat]);

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          borderRadius: "10px",
          flexDirection: "column",
          padding: "2px 0 8px 24px",
          borderBottom: `1px solid ${colors.secondary.grayLight}`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: props.subItems || props.onClick ? "pointer" : "default",
          }}
          onClick={handleClick}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body2"
              component={props.color ? "div" : "p"}
              sx={{
                textDecoration:
                  props.onClick &&
                  (!props.subItems || props.subItems.length === 0)
                    ? "underline"
                    : "none",
              }}
            >
              {props.color && (
                <Box
                  component="span"
                  sx={{
                    width: "12px",
                    height: "12px",
                    marginRight: "5px",
                    borderRadius: "50%",
                    display: "inline-block",
                    background: props.color,
                  }}
                />
              )}
              {props.label}
            </Typography>
            <Typography variant="subtitle2" fontWeight={700}>
              {value}
            </Typography>
          </Box>
          {props.subItems && props.subItems.length > 0 && (
            <Box>
              <ArrowRight
                sx={{
                  transition: "transform 0.1s ease-in-out",
                  transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {expanded && props.subItems && (
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            "> div": {
              padding: "2px 0 8px 48px",
            },
          }}
        >
          {props.subItems.map((item) => (
            <LegendPanelItem key={item.label} {...item} />
          ))}
        </Box>
      )}
    </React.Fragment>
  );
}

export function LegendPanel(props: LegendPanelProps) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "70vh",
        borderRadius: "30px",
        padding: "20px",
        backgroundColor: colors.primary.gray,
        boxShadow: colors.shadows.inner,
        "@media (max-width: 920px)": {
          maxHeight: "700px",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            padding: "0 10px",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle2" fontWeight={700}>
            {props.title}
          </Typography>
          <Typography variant="body1">{formatLocale(props.value)}</Typography>
        </Box>
        <Divider
          sx={{
            width: "100%",
            margin: "14px 0 0 0",
          }}
        />
        <Box
          sx={{
            width: "100%",
            overflowY: "auto",
            marginTop: "14px",
            paddingRight: "10px",
            height: "calc(100% - 74px)",
            minHeight: "calc(100% - 74px)",
            maxHeight: "calc(100% - 74px)",
            "&::-webkit-scrollbar": {
              width: "6px",
              borderRadius: "19px",
            },
            "&::-webkit-scrollbar-track": {
              background: colors.secondary.grayLight,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "19px",
              background: colors.primary.lightBlueGray,
            },
          }}
        >
          <Box
            sx={{
              gap: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {props.items.map((item) => (
              <LegendPanelItem
                {...item}
                onClick={props.onItemClick}
                key={item.label + item.value}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
