import { useMount } from "react-use";
import { useStoreActions } from "app/state/store/hooks";

export function useInitialLoad() {
  const locationsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.locations.fetch
  );
  const sectorsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.sectors.fetch
  );
  const thematicareasfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.thematicareas.fetch
  );
  const organisationsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.organisations.fetch
  );
  const activitystatusfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.activitystatus.fetch
  );
  const sdgsfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.sdgs.fetch
  );
  const policymarkersfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.policymarkers.fetch
  );
  const aidtypesfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.aidtypes.fetch
  );
  const budgetlinesfilterOptionsAction = useStoreActions(
    (actions) => actions.filterOptions.budgetlines.fetch
  );

  function filterOptionsCall() {
    locationsfilterOptionsAction({
      values: {
        filter_type: "locations",
      },
    });
    sectorsfilterOptionsAction({
      values: {
        filter_type: "sectors",
      },
    });
    thematicareasfilterOptionsAction({
      values: {
        filter_type: "thematicareas",
      },
    });
    organisationsfilterOptionsAction({
      values: {
        filter_type: "organisations",
      },
    });
    sdgsfilterOptionsAction({
      values: {
        filter_type: "sdgs",
      },
    });
    activitystatusfilterOptionsAction({
      values: {
        filter_type: "activitystatus",
      },
    });
    policymarkersfilterOptionsAction({
      values: {
        filter_type: "policymarker",
      },
    });
    aidtypesfilterOptionsAction({
      values: {
        filter_type: "defaultaidtype",
      },
    });
    budgetlinesfilterOptionsAction({
      values: {
        filter_type: "budgetlines",
      },
    });
  }

  useMount(() => {
    filterOptionsCall();
  });

  return null;
}
