import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ClickAwayListener, useMediaQuery } from "@mui/material";
import { TooltipPercentageBarBlock } from "app/components/charts/tooltips/common/percentagebar";

export interface SunburstChartTooltipProps {
  title: string;
  value: number;
  percentage: number;
  commitment: number;
  onClose?: () => void;
  drilldown?: () => void;
  goToDetail?: () => void;
  drilldownLabel?: string;
  goToDetailLabel?: string;
  commitmentsLabel: string;
  disbursementsLabel: string;
  titleNode?: React.ReactNode;
}

export function SunburstChartTooltip(props: SunburstChartTooltipProps) {
  const isTouch = useMediaQuery("(hover: none)");

  const onClickAway = () => {
    if (props.onClose && isTouch) {
      props.onClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        sx={{
          width: "370px",
          display: "flex",
          padding: "15px",
          position: "relative",
          borderRadius: "16px",
          flexDirection: "column",
          background: colors.primary.white,
          "@media (max-width: 400px)": {
            width: "calc(100vw - 48px)",
          },
          "*": {
            fontSize: "14px",
            color: colors.primary.blue,
            fontFamily: `Finlandica !important`,
          },
        }}
      >
        {!props.titleNode ? (
          <Typography
            fontWeight="700"
            variant="subtitle2"
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              marginBottom: "10px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.title}
          </Typography>
        ) : (
          props.titleNode
        )}
        {props.onClose && (
          <Box
            sx={{
              padding: 0,
              top: "10px",
              right: "10px",
              position: "absolute",
              color: colors.primary.blue,
              "@media (min-width: 768px)": {
                display: "none",
              },
            }}
            onClick={props.onClose}
          >
            <Close htmlColor={colors.primary.blue} />
          </Box>
        )}
        <TooltipPercentageBarBlock
          percentage={props.percentage}
          disbursements={props.value}
          commitments={props.commitment}
          commitmentsLabel={props.commitmentsLabel}
          disbursementsLabel={props.disbursementsLabel}
        />
        {(props.goToDetail || props.drilldown) && (
          <Box
            sx={{
              gap: "10px",
              display: "none",
              marginTop: "20px",
              justifyContent: "flex-end",
              "@media (hover: none)": {
                display: "flex",
              },
              "> button": {
                padding: "4px 12px",
                borderRadius: "20px",
                textTransform: "none",
              },
            }}
          >
            {props.drilldown && (
              <Button onClick={props.drilldown} variant="contained">
                {props.drilldownLabel ?? "Drilldown"}
              </Button>
            )}
            {props.goToDetail && (
              <Button onClick={props.goToDetail} variant="contained">
                {props.goToDetailLabel ?? "Go to detail"}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}
