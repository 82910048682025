import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

const geoChart: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/geo`),
};

export default geoChart;

export const geoChartPriority: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/geo`),
};

export const geoChartSector: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/geo`),
};
