import { CommonPropTypes } from "react-csv/components/CommonPropTypes";

export function vizDataToCSV(
  data: any,
  csvInfo: {
    fileName: string;
    headers: { label: string; key: string }[];
  }
): CommonPropTypes {
  return {
    data,
    separator: ";",
    filename: `${csvInfo.fileName}.csv`,
    headers: csvInfo.headers,
  };
}
