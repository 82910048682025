import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";
import { NavLink } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { SxProps, styled } from "@mui/material/styles";

interface Props {
  id: string;
  selected?: string;
  anchorElement: React.ReactNode;
  items: {
    label: string;
    code?: string;
    link?: string;
    divider?: boolean;
    action?: () => void;
  }[];
  sx?: SxProps;
}

const StyledMenu = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    width: "240px",
    padding: "8px",
    borderRadius: "12px",
    background: colors.primary.white,
    boxShadow:
      "0px 4px 8px 0px rgba(97, 97, 97, 0.18), 0px 2px 4px 0px rgba(97, 97, 97, 0.18)",
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  height: "40px",
  fontSize: "16px",
  padding: "4px 12px",
  borderRadius: "8px",
  color: colors.secondary.midnightBlue,
  "&:hover": {
    background: colors.secondary.blueGrayDark,
  },
  "> a": {
    width: "100%",
    textDecoration: "none",
  },
  "&.MuiMenuItem-divider": {
    padding: 0,
    height: "1px",
    margin: "12px 0",
    boxShadow: "none",
    pointerEvents: "none",
  },
  "&.Mui-selected": {
    fontWeight: "700",
    background: colors.primary.white,
  },
}));

export function GenericMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Box onClick={handleClick} sx={props.sx}>
        {props.anchorElement}
      </Box>
      <StyledMenu
        open={open}
        id={props.id}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props.items.map((item) => {
          if (item.divider) {
            return <StyledMenuItem key={item.label} divider />;
          }
          if (item.link) {
            return (
              <StyledMenuItem
                key={item.label}
                onClick={handleClose}
                selected={props.selected === item.link}
              >
                <Link component={NavLink} to={item.link}>
                  {item.label}
                </Link>
              </StyledMenuItem>
            );
          }
          return (
            <StyledMenuItem
              key={item.label}
              selected={props.selected === item.code}
              onClick={() => {
                handleClose();
                if (item.action) item.action();
              }}
            >
              {item.label}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </React.Fragment>
  );
}
