import React from "react";
import Router from "app/router";
import { useCMSData } from "app/hooks/useCMSData";
import { createGlobalStyle } from "styled-components";
import { useInitialLoad } from "app/hooks/useInitialLoad";
import { useCMSCollections } from "app/hooks/useCMSCollections";
import { useStoreActions, useStoreState } from "app/state/store/hooks";

const GlobalStyle = createGlobalStyle<{ $wcag?: boolean }>`
  ${(props) =>
    props.$wcag &&
    `
    * {
      line-height: 1.5em;
      // word-spacing: 0.16em;
      // letter-spacing: 0.12em;
    }

    h4 {
      line-height: 48px;
      letter-spacing: 3.84px;
    }

    h5 {
      line-height: 36px;
      letter-spacing: 2.4px;
    }

    h6 {
      line-height: 27px;
      letter-spacing: 2.16px;
    }

    .MuiTypography-subtitle1 {
      line-height: 24px;
      letter-spacing: 1.92px;
    }

    .MuiTypography-subtitle2 {
      line-height: 24px;
      letter-spacing: 1.92px;
    }

    .MuiTypography-body1 {
      line-height: 24px;
      letter-spacing: 1.92px;
    }

    .MuiTypography-body2 {
      line-height: 24px;
      letter-spacing: 1.92px;
    }

    .MuiTypography-overline {
      line-height: 18px;
      letter-spacing: 1.44px;
    }

    .MuiTypography-overline2 {
      line-height: 15px;
      letter-spacing: 1.2px;
    }

    .MuiMenuItem-root {
      line-height: 24px;
      letter-spacing: 1.92px;
    }

    .MuiTooltip-popper {
      line-height: 18px;
      letter-spacing: 1.28px;
    }

    .search-results-container {
      line-height: 15px;
      letter-spacing: 1.2px;
    }

    input {
      line-height: 24px !important;
      letter-spacing: 1.92px !important;
    }

    button {
      line-height: 21px !important;
      letter-spacing: 1.68px !important;
    }

    table {
      line-height: 24px;
      letter-spacing: 1.92px;
    }

    a {
      line-height: 21px;
      letter-spacing: 1.68px;
    }

    p {
      margin: 2em 0;
    }

    foreignObject {
      a {
        line-height: 15px;
        letter-spacing: 1.44px;
      }
    }

    text {
      letter-spacing: 1.44px;
    }
  `}
`;

function App() {
  useInitialLoad();
  useCMSData({ loadData: true });
  useCMSCollections({ loadData: true });

  const $wcag = useStoreState((state) => state.accessibilitySwitch.value);

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value
  );

  const setCurrentLanguage = useStoreActions(
    (state) => state.selectedLanguage.setValue
  );

  React.useEffect(() => {
    const urlParamLang = window.location.pathname.split("/")[1];
    if (
      window.location.pathname.split("/")[1] &&
      currentLanguage !== urlParamLang &&
      (urlParamLang === "en" || urlParamLang === "fi" || urlParamLang === "sv")
    ) {
      setCurrentLanguage(urlParamLang === "sv" ? "se" : urlParamLang);
    }
  }, []);

  return (
    <React.Fragment>
      <GlobalStyle $wcag={Boolean($wcag)} />
      <Router />
    </React.Fragment>
  );
}

export default App;
