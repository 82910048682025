import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Share from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import { useCMSData } from "app/hooks/useCMSData";
import CloudDownload from "@mui/icons-material/CloudDownload";
import { ShareTooltip } from "app/components/floatingButtons/common/share";
import { ExportTooltip } from "app/components/floatingButtons/common/export";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const FloatingButtonsLightTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      leaveDelay={100}
      enterTouchDelay={0}
      PopperProps={{
        disablePortal: true,
      }}
      aria-pressed="false"
      classes={{ popper: className }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 15,
    boxShadow: theme.shadows[1],
    color: colors.secondary.midnightBlue,
    backgroundColor: colors.primary.gray,
    "> div": {
      borderRadius: 15,
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.primary.gray,
  },
}));

interface FloatingButtonsProps {
  noFloating?: boolean;
  csvExportInfo?: {
    fileName: string;
    storeKey: string;
    headers: {
      label: string;
      key: string;
    }[];
  };
}

export function FloatingButtons(props: FloatingButtonsProps) {
  const cmsData = useCMSData({ returnData: true });

  const content = React.useMemo(() => {
    return (
      <React.Fragment>
        <FloatingButtonsLightTooltip
          title={<ShareTooltip cmsData={cmsData} />}
          placement={props.noFloating ? "bottom-start" : "left"}
        >
          <IconButton
            aria-label="Share button"
            sx={{
              width: "34px",
              height: "34px",
              padding: "5px",
              borderRadius: "50%",
              background: colors.primary.white,
              boxShadow: colors.shadows.section,
              marginRight: props.noFloating ? 0 : "6px",
            }}
          >
            <Share />
          </IconButton>
        </FloatingButtonsLightTooltip>
        <FloatingButtonsLightTooltip
          title={
            <ExportTooltip
              csvExportInfo={props.csvExportInfo}
              cmsData={cmsData}
            />
          }
          placement={props.noFloating ? "bottom-start" : "left"}
        >
          <IconButton
            aria-label="Export button"
            sx={{
              width: "34px",
              height: "34px",
              padding: "5px",
              borderRadius: "50%",
              background: colors.primary.white,
              boxShadow: colors.shadows.section,
            }}
          >
            <CloudDownload />
          </IconButton>
        </FloatingButtonsLightTooltip>
      </React.Fragment>
    );
  }, [cmsData, props.csvExportInfo]);

  if (props.noFloating) {
    return content;
  }

  return (
    <Box
      sx={{
        top: 74,
        right: "calc((100vw - 1350px) / 2)",
        gap: "16px",
        zIndex: 1000,
        padding: "16px",
        display: "flex",
        position: "fixed",
        flexDirection: "column",
        "@media (max-width: 1280px)": {
          display: "none",
        },
      }}
    >
      {content}
    </Box>
  );
}
