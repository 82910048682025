import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import findIndex from "lodash/findIndex";
import { useMediaQuery } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import { SVGRenderer } from "echarts/renderers";
import { useCMSData } from "app/hooks/useCMSData";
import { useStoreState } from "app/state/store/hooks";
import { onEchartResize } from "app/utils/onEchartResize";
import { chartTooltipCommonConfig } from "app/components/charts/tooltips/common/config";
import { TooltipTouchContainer } from "app/components/charts/tooltips/common/touchcontainer";
import {
  GridComponent,
  TooltipComponent,
  DataZoomSliderComponent,
} from "echarts/components";
import {
  SunburstChartTooltip,
  SunburstChartTooltipProps,
} from "app/components/charts/tooltips/sunburst";
import {
  fillYearGaps,
  ODAVariantConfig,
  BarLineChartProps,
  SectorsVariantConfig,
  PrioritiesVariantConfig,
  prioritiesColorsIndexing,
  BudgetLinesVariantConfig,
  budgetLinesColorsIndexing,
  TooltipTitleNodeWColorCircle,
} from "app/components/charts/barline/data";
import {
  BarChart as EchartsBar,
  LineChart as EchartsLine,
} from "echarts/charts";

echarts.use([
  TooltipComponent,
  EchartsBar,
  EchartsLine,
  GridComponent,
  DataZoomSliderComponent,
  SVGRenderer,
]);

export function BarLineChart(props: BarLineChartProps) {
  const isTouch = useMediaQuery("(hover: none)");
  const cmsData = useCMSData({ returnData: true });
  const mobile = useMediaQuery("(max-width: 768px)");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [touchTooltip, setTouchTooltip] = React.useState<any | null>(null);
  const [savedChart, setSavedChart] = React.useState<echarts.ECharts | null>(
    null
  );

  const appliedFilters = useStoreState((state) => state.appliedFilters);

  const yearLabel = React.useMemo(() => {
    return get(cmsData, "viz.year", "Year");
  }, [cmsData]);

  const commitmentsLabel = React.useMemo(() => {
    return get(cmsData, "viz.commitments", "Commitments");
  }, [cmsData]);

  const disbursementsLabel = React.useMemo(() => {
    return get(cmsData, "viz.disbursements", "Disbursements");
  }, [cmsData]);

  const formatTooltipData = React.useCallback(
    (params: any) => {
      const completedata = fillYearGaps(props.data);
      let data: SunburstChartTooltipProps = {
        value: 0,
        title: "",
        percentage: 0,
        commitment: 0,
        commitmentsLabel,
        disbursementsLabel,
      };
      let subItems = get(completedata, `[${params.dataIndex}].subItems`, {});
      let dataObj = {
        value: 0,
        other: 0,
        name: "",
        commitment: 0,
        disbursements: 0,
        percentage: 0,
      };
      switch (props.dataVariant) {
        case "oda":
          dataObj = get(completedata, `[${params.dataIndex}]`, {
            value: 0,
            other: 0,
            name: "",
            commitment: 0,
            disbursements: 0,
            percentage: 0,
          });
          data = {
            ...data,
            value: dataObj.value,
            title: `${yearLabel} ${params.name}`,
            percentage: dataObj.percentage,
            commitment: dataObj.commitment,
          };
          break;
        case "budgetlines":
          data = {
            ...data,
            title: "",
            value: params.value,
            percentage: params.data.percentage,
            commitment: params.data.commitment,
            titleNode: (
              <TooltipTitleNodeWColorCircle
                subItems={subItems}
                seriesIndex={params.seriesIndex}
                colors={budgetLinesColorsIndexing}
                year={get(completedata, `[${params.dataIndex}].name`, "")}
              />
            ),
          };
          break;
        case "sectors":
          // let completedata = fillYearGaps(props.data);
          // completedata = completedata.map((item: any) => {
          //   const other = {
          //     name: "Other",
          //     name_fi: "Other",
          //     name_se: "Other",
          //     value: sumBy(map(item.subItems).slice(10), "disbursements"),
          //   };
          //   return {
          //     ...item,
          //     subItems: orderBy(
          //       [
          //         ...orderBy(item.subItems, "value", "desc").slice(0, 10),
          //         other,
          //       ],
          //       "value",
          //       "desc"
          //     ),
          //   };
          // });
          // subItems = get(completedata, `[${params.dataIndex}].subItems`, {});
          const seriesIndex = findIndex(map(subItems), {
            name: params.seriesName,
          });
          data = {
            ...data,
            title: "",
            value: params.value,
            commitment: params.data.commitment,
            percentage: params.data.percentage,
            titleNode: (
              <TooltipTitleNodeWColorCircle
                colors={[]}
                subItems={subItems}
                seriesIndex={seriesIndex}
                year={get(completedata, `[${params.dataIndex}].name`, "")}
              />
            ),
          };
          break;
        case "priorities":
          dataObj = get(
            params.data,
            `subItems["Priority area ${params.seriesIndex + 1}"]`,
            {
              value: 0,
              commitment: 0,
              disbursements: 0,
              percentage: 0,
            }
          );
          data = {
            ...data,
            title: "",
            value: dataObj.value,
            commitment: dataObj.commitment,
            percentage: dataObj.percentage,
            titleNode: (
              <TooltipTitleNodeWColorCircle
                subItems={subItems}
                seriesIndex={params.seriesIndex}
                colors={prioritiesColorsIndexing}
                year={get(completedata, `[${params.dataIndex}].name`, "")}
              />
            ),
          };
          break;
        default:
          break;
      }
      return data;
    },
    [props.data]
  );

  const filtersApplied = React.useMemo(() => {
    return (
      appliedFilters.countries.length +
        appliedFilters.regions.length +
        appliedFilters.sectors.length +
        appliedFilters.tag.length +
        appliedFilters.organisationtypes.length +
        appliedFilters.organisations.length +
        appliedFilters.sdg.length +
        appliedFilters.policymarker.length +
        appliedFilters.defaultaidtype.length +
        appliedFilters.budgetlines.length +
        appliedFilters.collaborationtype.length +
        appliedFilters.humanrights.length +
        appliedFilters.years.length >
      0
    );
  }, [appliedFilters]);

  React.useEffect(() => {
    if (savedChart) {
      savedChart.clear();
    }
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });

      let option;

      let completedata = fillYearGaps(props.data);

      switch (props.dataVariant) {
        case "oda":
          option = ODAVariantConfig(
            completedata,
            mobile,
            !props.detailPage && !filtersApplied
          );
          break;
        case "priorities":
          option = PrioritiesVariantConfig(completedata, mobile);
          break;
        case "budgetlines":
          let dataZoomLevel = 0;
          if (mobile) {
            dataZoomLevel = 80;
          } else if (props.dataVariant === "budgetlines") {
            dataZoomLevel = 50;
          }
          option = BudgetLinesVariantConfig(completedata, dataZoomLevel);
          break;
        case "sectors":
          // completedata = completedata.map((item: any) => {
          //   const subItems = orderBy(item.subItems, "value", "desc");
          //   const other = {
          //     name: "Other",
          //     name_fi: "Other",
          //     name_se: "Other",
          //     value: sumBy(map(subItems).slice(10), "value"),
          //     itemStyle: {
          //       color: "#808080",
          //     },
          //   };
          //   return {
          //     ...item,
          //     subItems: orderBy(
          //       [...orderBy(subItems, "value", "desc").slice(0, 10), other],
          //       "value",
          //       "desc"
          //     ),
          //   };
          // });
          option = SectorsVariantConfig(completedata);
          break;
        default:
          option = ODAVariantConfig(
            props.data,
            mobile,
            !props.detailPage && !filtersApplied
          );
      }

      if (mobile) {
        option = {
          ...option,
          grid: {
            ...option.grid,
            left: 40,
            right: props.dataVariant === "oda" ? 35 : 10,
          },
        };
      }

      option = {
        ...option,
        tooltip: {
          ...chartTooltipCommonConfig(isTouch),
          formatter: (params: any) => {
            const html = ReactDOMServer.renderToString(
              <SunburstChartTooltip {...formatTooltipData(params)} />
            );
            return html;
          },
        },
      };

      if (props.disableDataZoomFeature) {
        option = {
          ...option,
          dataZoom: undefined,
          grid: {
            ...option.grid,
            bottom: 50,
          },
        };
      }

      // TODO: find solution to remove ignore statements
      // @ts-ignore
      if (props.barWidth && option.series && option.series.length > 1) {
        // @ts-ignore
        option.series[0].barWidth = props.barWidth;
        // @ts-ignore
        option.series[1].barWidth = props.barWidth;
      }

      if (containerRef.current) {
        new ResizeObserver(() =>
          onEchartResize(
            // @ts-ignore
            chart,
            "barline-chart",
            containerRef.current?.clientHeight
          )
        ).observe(containerRef?.current);
      }

      chart.setOption(option);

      if (isTouch) {
        chart.on("click", (params: any) => {
          setTouchTooltip(params);
        });
      }

      setSavedChart(chart);
    }
  }, [
    filtersApplied,
    containerRef.current,
    props.data,
    yearLabel,
    commitmentsLabel,
    disbursementsLabel,
    isTouch,
  ]);

  return (
    <React.Fragment>
      <Box
        id="barline-chart"
        ref={containerRef}
        sx={{
          width: "100%",
          height: "450px",
        }}
      />
      {touchTooltip && isTouch && (
        <TooltipTouchContainer>
          <SunburstChartTooltip
            {...formatTooltipData(touchTooltip)}
            onClose={() => setTouchTooltip(null)}
          />
        </TooltipTouchContainer>
      )}
    </React.Fragment>
  );
}
