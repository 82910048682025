import React from "react";
import { FiltersTabViewLayout } from "app/components/filters/view/layout";
import { FiltersCommonTabViewProps } from "app/components/filters/view/data";

export function FiltersViewAdvanced(props: FiltersCommonTabViewProps) {
  return (
    <FiltersTabViewLayout
      items={props.items}
      activeTab={props.activeTab}
      localAppliedFilters={props.localAppliedFilters}
      onFilterCheckboxChange={props.onFilterCheckboxChange}
    />
  );
}
