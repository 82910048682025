import React from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { getAppliedFiltersPills } from "app/components/filters/panel/utils";

interface Item {
  label: string;
  value: string;
  category: string;
}

export function FiltersApplied() {
  const filterOptions = useStoreState((state) => state.filterOptions);
  const appliedFilters = useStoreState((state) => state.appliedFilters);

  const setAppliedFilters = useStoreActions(
    (actions) => actions.appliedFilters
  );

  const filters = React.useMemo(() => {
    return getAppliedFiltersPills(appliedFilters, {
      locations: get(filterOptions.locations, "data.data", []),
      sectors: get(filterOptions.sectors, "data.data", []),
      thematicareas: get(filterOptions.thematicareas, "data.data", []),
      organisations: get(filterOptions.organisations, "data.data", []),
      sdgs: get(filterOptions.sdgs, "data.data", []),
      activitystatus: get(filterOptions.activitystatus, "data.data", []),
      policymarkers: get(filterOptions.policymarkers, "data.data", []),
      aidtypes: get(filterOptions.aidtypes, "data.data", []),
      budgetlines: get(filterOptions.budgetlines, "data.data", []),
    });
  }, [appliedFilters, filterOptions]);

  const onRemove = (item: Item) => () => {
    let func = null;
    switch (item.category) {
      case "countries":
        func = setAppliedFilters.setCountries;
        break;
      case "regions":
        func = setAppliedFilters.setRegions;
        break;
      case "sectors":
        func = setAppliedFilters.setSectors;
        break;
      case "organisations":
        func = setAppliedFilters.setOrganisations;
        break;
      case "organisationstypes":
        func = setAppliedFilters.setOrganisationtypes;
        break;
      case "tag":
        func = setAppliedFilters.setTag;
        break;
      case "sdg":
        func = setAppliedFilters.setSdg;
        break;
      case "defaultaidtype":
        func = setAppliedFilters.setDefaultaidtype;
        break;
      case "collaborationtype":
        func = setAppliedFilters.setCollaborationtype;
        break;
      case "policymarkers":
        func = setAppliedFilters.setPolicymarker;
        break;
      case "humanrights":
        func = setAppliedFilters.setHumanrights;
        break;
      case "budgetlines":
        func = setAppliedFilters.setBudgetlines;
        break;
      case "years":
        func = setAppliedFilters.setYears;
        break;
      default:
        break;
    }
    if (func) {
      const updatedFilters = get(appliedFilters, item.category, []).filter(
        (i) => i !== item.value
      );
      func(item.category === "years" ? [] : updatedFilters);
    }
  };

  return (
    <Box
      sx={{
        gap: "8px",
        display: "flex",
        paddingTop: "15px",
        flexDirection: "row",
        alignItems: "center",
        "> div": {
          gap: "8px",
          display: "flex",
          fontSize: "14px",
          whiteSpace: "nowrap",
          flexDirection: "row",
          padding: "4px 5px 4px 10px",
          justifyContent: "space-between",
          "> button": {
            width: "20px",
            height: "20px",
            padding: "0px",
          },
        },
      }}
    >
      {filters.map((item) => (
        <Box key={item.value}>
          <Typography variant="body2">{item.label}</Typography>
          <IconButton
            aria-label={`Remove applied filter ${item.label}`}
            onClick={onRemove(item)}
          >
            <Close />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
}
