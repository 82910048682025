/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";

interface NavItemParams {
  name: string;
  count: number;
  index?: number;
  active: boolean;
  onClick: () => void;
}

export const SearchResultNavItem = (props: NavItemParams) => {
  return (
    <Box
      onClick={props.onClick}
      sx={{
        display: "flex",
        cursor: "pointer",
        marginRight: "15px",
        flexDirection: "row",
        paddingBottom: "8px",
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
        transition: "border-bottom-color 0.1s linear",
        borderBottomColor: props.active ? colors.primary.blue : "transparent",
        "&:hover": {
          borderBottomColor: colors.primary.blue,
        },
        "@media (max-width: 600px)": {
          marginRight: "12px",
        },
      }}
    >
      <Box
        sx={{
          margin: "0 2px",
          fontSize: "12px",
          whiteSpace: "pre",
          fontWeight: "bold",
          lineHeight: "18px",
          fontStyle: "normal",
          textTransform: "capitalize",
          color: colors.primary.blue,
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        {props.count}
      </Box>
      <Box
        sx={{
          margin: "0 2px",
          fontSize: "12px",
          whiteSpace: "pre",
          fontWeight: "bold",
          lineHeight: "18px",
          fontStyle: "normal",
          textTransform: "capitalize",
          color: colors.primary.blue,
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        {props.name}
      </Box>
    </Box>
  );
};
