import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCMSData } from "app/hooks/useCMSData";
import { useStoreState } from "app/state/store/hooks";
import { FilterTabsProps, FILTER_TABS } from "app/components/filters/tabs/data";

export function FiltersTabs(props: FilterTabsProps) {
  const { activeTab, setActiveTab } = props;

  const cmsData = useCMSData({ returnData: true });
  const appliedFilters = useStoreState((state) => state.appliedFilters);

  function getTabAppliedFiltersCount(tabId: string) {
    let count = 0;
    switch (tabId) {
      case FILTER_TABS[0].id:
        count = 1;
        break;
      case FILTER_TABS[1].id:
        count = appliedFilters.tag.length;
        break;
      case FILTER_TABS[2].id:
        count = appliedFilters.sectors.length;
        break;
      case FILTER_TABS[3].id:
        count = appliedFilters.countries.length + appliedFilters.regions.length;
        break;
      case FILTER_TABS[4].id:
        count =
          appliedFilters.organisations.length +
          appliedFilters.organisationtypes.length;
        break;
      case FILTER_TABS[5].id:
        count = appliedFilters.sdg.length;
        break;
      case FILTER_TABS[6].id:
        count =
          appliedFilters.budgetlines.length +
          appliedFilters.collaborationtype.length +
          appliedFilters.humanrights.length +
          appliedFilters.policymarker.length +
          appliedFilters.defaultaidtype.length;
        break;
      default:
        break;
    }
    if (count > 0) {
      return ` (${count})`;
    }
    return "";
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        padding: "12px 15px 6px 0",
        justifyContent: "space-between",
        borderBottom: `3px solid ${colors.primary.gray}`,
        "> button": {
          fontSize: "16px",
          fontWeight: "400",
          textTransform: "none",
          "&:hover": {
            color: colors.primary.blue,
          },
          "@media (max-width: 920px)": {
            fontSize: "12px",
          },
        },
      }}
    >
      {FILTER_TABS.map((tab) => (
        <Button
          key={tab.id}
          sx={{
            color:
              activeTab === tab.id
                ? colors.primary.blue
                : colors.secondary.grayDark,
          }}
          onClick={() => setActiveTab(tab.id)}
        >
          {get(cmsData, tab.cmsKey, tab.label)}
          {getTabAppliedFiltersCount(tab.id)}
        </Button>
      ))}
    </Box>
  );
}
