import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { SxProps } from "@mui/material";
import { hexToRGBA } from "app/utils/hexToRGBA";
import Typography from "@mui/material/Typography";
import { InfoButton } from "app/components/infobutton";

interface PageSectionProps {
  sx?: any;
  loading?: boolean;
  background?: string;
  children: React.ReactNode | React.ReactNode[];
  header?: {
    title: string;
    info?: string;
    titleLink?: string;
    icon?: React.ReactNode;
    subtitle: string | React.ReactNode;
  };
}

export function PageSection(props: PageSectionProps) {
  return (
    <Box
      sx={{
        ...props.sx,
        width: "100%",
        display: "flex",
        padding: "20px",
        marginBottom: "50px",
        borderRadius: "20px",
        position: "relative",
        flexDirection: "column",
        boxShadow: colors.shadows.inner,
        background: props.background ?? "#fff",
        overflow: props.sx?.overflow ?? "hidden",
        "@media (max-width: 920px)": {
          padding: "20px",
        },
      }}
    >
      {props.loading && (
        <Box
          sx={{
            top: 0,
            left: 0,
            zIndex: 100,
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            background: hexToRGBA(colors.primary.blue, 0.2),
          }}
        >
          <Typography
            sx={{
              padding: "10px 20px",
              borderRadius: "15px",
              background: colors.primary.white,
            }}
          >
            Loading..
          </Typography>
        </Box>
      )}
      {props.header && (
        <Box
          sx={{
            gap: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "25px",
          }}
        >
          <Box>{props.header.icon}</Box>
          <Box>
            <Box
              sx={{
                gap: "5px",
                display: "flex",
                alignItems: "center",
                a: {
                  fontSize: "24px",
                  fontWeight: "700",
                  color: colors.primary.blue,
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  "@media (max-width: 768px)": {
                    fontSize: "18px",
                  },
                },
                h5: {
                  "@media (max-width: 768px)": {
                    fontSize: "18px",
                  },
                },
                div: {
                  "@media (max-width: 768px)": {
                    fontSize: "14px",
                  },
                },
              }}
            >
              {props.header.titleLink ? (
                <Link to={props.header.titleLink}>
                  <Typography variant="h5">{props.header.title}</Typography>
                </Link>
              ) : (
                <Typography variant="h5">{props.header.title}</Typography>
              )}
              <InfoButton
                fontSize="small"
                text={props.header.info}
                sx={{
                  color: colors.secondary.grayDark,
                }}
              />
            </Box>
            <Typography component="div">{props.header.subtitle}</Typography>
          </Box>
        </Box>
      )}
      {props.children}
    </Box>
  );
}
