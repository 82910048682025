import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { formatLocale } from "app/utils/formatLocale";
import { useStoreState } from "app/state/store/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatFinancialValue } from "app/utils/formatFinancialValue";
import { TooltipTouchContainer } from "../tooltips/common/touchcontainer";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";
import { SectorBlocksTooltip } from "app/components/charts/tooltips/sector-blocks";

interface SectorsChartProps {
  items: {
    code: string;
    name: string;
    value: number;
    name_fi: string;
    name_se: string;
    commitment: number;
    percentage: number;
  }[];
}

export function SectorsChart(props: SectorsChartProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const isTouch = useMediaQuery("(hover: none)");
  const cmsData = useCMSData({ returnData: true });

  const scrollableRef = React.useRef<HTMLDivElement>(null);

  const [backBtnEnabled, setBackBtnEnabled] = React.useState(false);
  const [forwardBtnEnabled, setForwardBtnEnabled] = React.useState(true);
  const [hoveredSector, setHoveredSector] = React.useState<string | null>(null);

  const lang = useStoreState((state) => state.selectedLanguage.value as string);

  const onArrowButtonClick = (direction: "left" | "right") => () => {
    if (!scrollableRef.current) return;
    scrollableRef.current.scroll({
      left:
        scrollableRef.current.scrollLeft +
        scrollableRef.current.clientWidth * (direction === "left" ? -1 : 1),
      behavior: "smooth",
    });
  };

  const onSectorHover = (sector: string | null) => () => {
    setHoveredSector(sector);
  };

  React.useEffect(() => {
    if (!scrollableRef.current) return;
    const scrollable = scrollableRef.current;
    const onScroll = () => {
      if (!scrollable) return;
      const { scrollLeft, scrollWidth, clientWidth } = scrollable;
      const scrollRight = scrollWidth - scrollLeft - clientWidth;
      const backBtnEnabled = scrollLeft > 0;
      const forwardBtnEnabled = scrollRight > 0;
      setBackBtnEnabled(backBtnEnabled);
      setForwardBtnEnabled(forwardBtnEnabled);
    };
    scrollable.addEventListener("scroll", onScroll);
    return () => scrollable.removeEventListener("scroll", onScroll);
  }, [scrollableRef.current]);

  const tooltipInfo = React.useMemo(() => {
    if (!hoveredSector) {
      return null;
    }

    const item = props.items.find((item) => item.code === hoveredSector);

    if (!item) {
      return null;
    }

    return {
      title: get(
        cmsData,
        `priorityAreas.${item.code.replace(/ /g, "")}`,
        item.name
      ),
      value: formatFinancialValue(item.value),
      commitment: formatFinancialValue(item.commitment),
    };
  }, [hoveredSector]);

  const tooltipContent = React.useMemo(() => {
    return (
      <SectorBlocksTooltip
        hoveredNode={hoveredSector}
        onClose={onSectorHover(null)}
        title={get(tooltipInfo, "title", "")}
        value={get(tooltipInfo, "value", "")}
        containerId="sectors-chart-container"
        commitment={get(tooltipInfo, "commitment", "")}
        goToDetailLabel={get(cmsData, "viz.goToDetail", "Go to detail")}
        goToDetail={
          isTouch
            ? () => navigate(`/sectors/${hoveredSector}${location.search}`)
            : undefined
        }
      />
    );
  }, [tooltipInfo, hoveredSector, onSectorHover, isTouch]);

  return (
    <Box
      id="sectors-chart-container"
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        justifyContent: "space-between",
        "button.Mui-disabled": {
          opacity: 0.2,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Scroll left"
          aria-disabled={props.items.length === 0 || !backBtnEnabled}
          onClick={onArrowButtonClick("left")}
          disabled={props.items.length === 0 || !backBtnEnabled}
        >
          <ArrowBackIos
            fontSize="large"
            sx={{
              transform: "translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
      <Box
        ref={scrollableRef}
        sx={{
          gap: "15px",
          padding: "10px",
          display: "flex",
          maxWidth: "100%",
          overflowX: "auto",
          width: "calc(100% - 70px)",
          "@media (max-width: 768px)": {
            width: "100%",
            flexDirection: "column",
          },
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "> div": {
            display: "flex",
            padding: "20px",
            minWidth: "240px",
            maxWidth: "240px",
            minHeight: "150px",
            maxHeight: "150px",
            alignItems: "center",
            borderRadius: "20px",
            flexDirection: "column",
            boxShadow: colors.shadows.main,
            justifyContent: "space-between",
            "*": {
              textAlign: "center",
              color: colors.graph.purple[500],
            },
            "@media (max-width: 768px)": {
              padding: "10px",
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "80px",
              maxHeight: "80px",
            },
          },
        }}
      >
        {props.items.map((item, index) => (
          <Box
            key={item.code}
            onMouseLeave={onSectorHover(null)}
            onMouseEnter={onSectorHover(item.code)}
            sx={{
              background: `rgba(185, 176, 217, ${
                1 - index * (1 / props.items.length)
              })`,
            }}
          >
            <Link
              to={`/${lang}/sectors/${item.code}${location.search}`}
              onClick={
                isTouch
                  ? (e) => {
                      e.preventDefault();
                      onSectorHover(item.code);
                    }
                  : undefined
              }
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  "@media (max-width: 768px)": {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(100% - 20px)",
                  },
                }}
              >
                {item[getNameFieldBasedOnLang(lang)] ?? item.name}
              </Typography>
            </Link>
            <Typography variant="body1">
              {formatLocale(item.value)} |{" "}
              {item.percentage.toFixed(2).split(".")[0]}%
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Scroll right"
          aria-disabled={props.items.length === 0 || !forwardBtnEnabled}
          onClick={onArrowButtonClick("right")}
          disabled={props.items.length === 0 || !forwardBtnEnabled}
        >
          <ArrowBackIos
            fontSize="large"
            sx={{
              transform: "rotate(180deg) translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
      {isTouch && hoveredSector && (
        <TooltipTouchContainer>{tooltipContent}</TooltipTouchContainer>
      )}
      {!isTouch && tooltipContent}
    </Box>
  );
}
