import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import TwitterIcon from "./twitter.svg";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";

export function ShareTooltip(cmsData: any) {
  const location = useLocation();

  const url = React.useMemo(() => {
    return `https://openaid.fi${location.pathname}${location.search}`;
  }, [location.pathname, location.search]);

  return (
    <Box
      sx={{
        gap: "20px",
        padding: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: colors.primary.gray,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {get(cmsData.cmsData, "tooltips.share_button", "Share the link via")}
      </Typography>
      <Box
        sx={{
          gap: "12px",
          display: "flex",
          flexDirection: "row",
          "> button": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <FacebookShareButton
          type="button"
          autoFocus
          url={url}
          quote="OpenAid.fi"
        >
          <FacebookIcon size={38} round />
        </FacebookShareButton>
        <LinkedinShareButton type="button" url={url} title="OpenAid.fi">
          <LinkedinIcon size={38} round />
        </LinkedinShareButton>
        <TwitterShareButton type="button" url={url} title="#OpenAidFi">
          <Box
            sx={{
              width: "38px",
              height: "38px",
              display: "flex",
              background: "#000",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
              "> img": {
                width: "20px",
                height: "20px",
              },
            }}
          >
            <img src={TwitterIcon} alt="Twitter" />
          </Box>
        </TwitterShareButton>
      </Box>
    </Box>
  );
}
