import React from "react";
import { colors } from "app/theme";
import * as echarts from "echarts/core";
import ReactDOMServer from "react-dom/server";
import { SankeySeriesOption } from "echarts/charts";
import { formatLocale } from "app/utils/formatLocale";
import { TooltipComponentOption } from "echarts/components";
import { formatFinancialValue } from "app/utils/formatFinancialValue";
import { chartTooltipCommonConfig } from "app/components/charts/tooltips/common/config";
import {
  SankeyChartTooltip,
  SankeyChartTooltipProps,
} from "app/components/charts/tooltips/sankey";

const tooltipConfig = (
  totalValue: number,
  isTouch: boolean
): TooltipComponentOption => {
  return {
    ...chartTooltipCommonConfig(isTouch),
    formatter: (params: any) => {
      let data: SankeyChartTooltipProps;
      if (params.dataType === "edge") {
        data = {
          value: params.value,
          title: params.data.target,
          ofWhatLabel: params.data.source,
          percentage: params.data.percentage ?? 0,
        };
      } else {
        data = {
          value: params.data.name.includes("Total") ? totalValue : params.value,
          title: params.data.name,
          ofWhatLabel: "of Total ODA",
          percentage: params.data.percentage ?? 0,
        };
      }
      const html = ReactDOMServer.renderToString(
        <SankeyChartTooltip {...data} />
      );
      return html;
    },
  };
};

export function disbursementsConfig(
  nodes: {
    name: string;
  }[],
  links: {
    source: string;
    target: string;
    value: number;
  }[],
  totalValue: number,
  mobile: boolean,
  isTouch: boolean
): echarts.ComposeOption<SankeySeriesOption | TooltipComponentOption> {
  return {
    tooltip: tooltipConfig(totalValue, isTouch),
    series: {
      links,
      left: 0,
      right: 0,
      bottom: 0,
      top: "27px",
      data: nodes,
      type: "sankey",
      draggable: false,
      layoutIterations: 0,
      emphasis: {
        focus: "adjacency",
      },
      lineStyle: {
        opacity: 0.1,
        curveness: 0.5,
        color: colors.primary.blue,
      },
      levels: [
        {
          depth: 0,
          label: {
            position: "right",
          },
        },
        {
          depth: 1,
          label: {
            position: "left",
          },
        },
        {
          depth: 2,
          label: {
            position: "left",
          },
        },
        {
          depth: 3,
          label: {
            position: "left",
          },
        },
      ],
      nodeWidth: !mobile ? 15 : 4,
      label: {
        fontSize: !mobile ? "12px" : "9px",
        fontWeight: "normal",
        textShadowColor: "none",
        fontFamily: "Finlandica",
        color: colors.primary.blue,
        formatter: (params: any) => {
          const limiter = !mobile ? 40 : 20;
          if (params.data.name.length > limiter) {
            return params.data.name.slice(0, limiter) + "...";
          }
          return params.data.name;
        },
      },
      itemStyle: {
        color: colors.primary.blue,
      },
    },
  };
}

export function organisationsConfig(
  nodes: {
    name: string;
  }[],
  links: {
    source: string;
    target: string;
    value: number;
  }[],
  totalValue: number,
  mobile: boolean,
  isTouch: boolean
): echarts.ComposeOption<SankeySeriesOption | TooltipComponentOption> {
  return {
    tooltip: tooltipConfig(totalValue, isTouch),
    series: {
      links,
      left: !mobile ? 200 : 0,
      bottom: 10,
      right: !mobile ? 200 : 0,
      top: "17px",
      nodeGap: !mobile ? 50 : 35,
      data: nodes,
      type: "sankey",
      draggable: false,
      emphasis: {
        focus: "adjacency",
      },
      lineStyle: {
        opacity: 0.1,
        curveness: 0.5,
        color: colors.graph.warmYellow[900],
      },
      levels: [
        {
          depth: 0,
          label: {
            distance: !mobile ? 50 : 35,
            position: !mobile ? "left" : "right",
            formatter: (params: any) => {
              return [
                `{value|${formatFinancialValue(params.data.value)}\n}`,
                `{label|of ${formatLocale(totalValue).slice(1)}\n${
                  params.data.name
                }}`,
              ].join("\n");
            },
            rich: {
              value: {
                fontWeight: 700,
                fontSize: !mobile ? "24px" : "16px",
                fontFamily: "Finlandica",
                color: colors.graph.warmYellow[900],
              },
              label: {
                fontSize: !mobile ? "14px" : "12px",
                fontFamily: "Finlandica",
                color: colors.graph.warmYellow[900],
              },
            },
          },
        },
        {
          depth: 1,
          label: {
            distance: !mobile ? 10 : 5,
            position: !mobile ? "right" : "left",
            formatter: (params: any) => {
              let label = params.data.name;
              const limiter = !mobile ? 25 : 20;
              if (label.length > limiter) {
                label = label.slice(0, limiter) + "...";
              }
              return [
                `{label|${label}}`,
                `{value|${formatLocale(params.value)}}`,
              ].join("\n");
            },
            rich: {
              value: {
                fontSize: !mobile ? "14px" : "12px",
                fontFamily: "Finlandica",
                color: colors.graph.warmYellow[900],
              },
              label: {
                fontSize: !mobile ? "14px" : "12px",
                fontFamily: "Finlandica",
                color: colors.graph.warmYellow[900],
                fontWeight: !mobile ? 400 : 700,
              },
            },
          },
        },
      ],
      nodeWidth: 15,
      label: {
        fontSize: !mobile ? "12px" : "9px",
        fontWeight: "normal",
        textShadowColor: "none",
        fontFamily: "Finlandica",
        color: colors.graph.warmYellow[900],
        formatter: (params: any) => {
          const limiter = !mobile ? 50 : 20;
          if (params.data.name.length > limiter) {
            return params.data.name.slice(0, limiter) + "...";
          }
          return params.data.name;
        },
      },
      itemStyle: {
        color: colors.graph.warmYellow[900],
      },
    },
  };
}

export function locationPageConfig(
  nodes: {
    name: string;
  }[],
  links: {
    source: string;
    target: string;
    value: number;
  }[],
  totalValue: number,
  mobile: boolean,
  isTouch: boolean
): echarts.ComposeOption<SankeySeriesOption | TooltipComponentOption> {
  return {
    tooltip: {
      ...tooltipConfig(totalValue, isTouch),
      formatter: (params: any) => {
        let data: SankeyChartTooltipProps;
        if (params.dataType === "edge") {
          data = {
            value: params.value,
            title: params.data.source,
            ofWhatLabel: "of Country's ODA",
            percentage: params.data.percentage,
          };
        } else {
          data = {
            value: params.value,
            title: params.data.name,
            ofWhatLabel: "of Country's ODA",
            percentage: params.data.percentage,
          };
        }
        const html = ReactDOMServer.renderToString(
          <SankeyChartTooltip {...data} />
        );
        return html;
      },
    },
    series: {
      links,
      left: 250,
      bottom: 10,
      right: 100,
      top: "27px",
      nodeGap: 25,
      data: nodes,
      type: "sankey",
      draggable: false,
      emphasis: {
        focus: "adjacency",
      },
      lineStyle: {
        opacity: 0.1,
        curveness: 0.5,
        color: colors.primary.blue,
      },
      levels: [
        {
          depth: 0,
          label: {
            position: "left",
          },
        },
        {
          depth: 1,
          label: {
            position: "right",
          },
        },
      ],
      nodeWidth: 15,
      label: {
        fontSize: "12px",
        fontWeight: "normal",
        textShadowColor: "none",
        fontFamily: "Finlandica",
        color: colors.primary.blue,
      },
      itemStyle: {
        color: colors.primary.blue,
      },
    },
  };
}
