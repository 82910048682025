import React from "react";

export const SectorsIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none">
      <g filter="url(#filter0_i_7542_45155)">
        <rect
          y="0.566406"
          width="52"
          height="52"
          rx="7"
          fill="url(#paint0_linear_7542_45155)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7867 37.0647C21.5488 38.2913 23.6906 39.0105 26.0003 39.0105C28.3102 39.0105 30.452 38.2913 32.2142 37.0646C34.3636 38.0072 36.9239 37.2014 38.1247 35.1215C39.3257 33.0414 38.7432 30.421 36.8518 29.0309C36.8766 28.7311 36.8892 28.4278 36.8892 28.1216C36.8892 23.7665 34.3325 20.0086 30.6382 18.2669C30.379 15.935 28.4014 14.1216 26.0003 14.1216C23.5992 14.1216 21.6217 15.935 21.3625 18.2669C17.6681 20.0086 15.1114 23.7665 15.1114 28.1216C15.1114 28.4279 15.1241 28.7312 15.1489 29.0311C13.2575 30.4212 12.675 33.0416 13.876 35.1217C15.0769 37.2017 17.6372 38.0075 19.7867 37.0647ZM29.8817 21.38C29.0446 22.6311 27.6187 23.4549 26.0003 23.4549C24.382 23.4549 22.956 22.6311 22.1189 21.38C19.79 22.7237 18.2226 25.2396 18.2226 28.1216C18.2226 28.1248 18.2226 28.128 18.2226 28.1312C19.7241 28.2309 21.15 29.0539 21.9589 30.455C22.7681 31.8565 22.7676 33.5033 22.1027 34.8538C23.2487 35.5187 24.5801 35.8994 26.0003 35.8994C27.4207 35.8994 28.7521 35.5186 29.8981 34.8537C29.2331 33.5032 29.2326 31.8564 30.0418 30.4548C30.8508 29.0537 32.2766 28.2308 33.7781 28.131L33.7781 28.1216C33.7781 25.2396 32.2106 22.7238 29.8817 21.38Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_7542_45155"
          x="0"
          y="0.566406"
          width="52"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7542_45155"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7542_45155"
          x1="52"
          y1="28.5664"
          x2="0"
          y2="0.566406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A195CC" />
          <stop offset="1" stopColor="#EAE7F4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
