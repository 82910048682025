import React from "react";
import { ROUTES } from "app/router/data";
import { PageLoader } from "app/components/pageLoader";
import { LANGUAGES } from "app/components/header/data";
import {
  getActivitiesPaths,
  getCountryPaths,
  getOrganisationPaths,
  getPrioritiesPaths,
  getSectorsPaths,
} from "app/pages/sitemap/getPaths";

const baseURL = "https://openaid.fi";

const SitemapPage = () => {
  const [loading, setLoading] = React.useState(true);
  const [allPath, setAllPath] = React.useState<string[]>([]);

  React.useEffect(() => {
    (async () => {
      const paths = [];
      setLoading(true);
      const activityPaths = await getActivitiesPaths();
      const organisationPaths = await getOrganisationPaths();
      const countryPaths = await getCountryPaths();
      const sectorPaths = await getSectorsPaths();
      const priorityPaths = await getPrioritiesPaths();

      const pathGroups = {
        "activities/:id": activityPaths,
        "organisations/:id": organisationPaths,
        "countries/:id": countryPaths,
        "sectors/:id": sectorPaths,
        "priorities/:id": priorityPaths,
      };

      for (const route of ROUTES) {
        for (const lang of LANGUAGES) {
          for (const subRoute of route.children) {
            if (
              subRoute.path.endsWith("/:id") &&
              pathGroups[subRoute.path as keyof typeof pathGroups]
            ) {
              for (const path of pathGroups[
                subRoute.path as keyof typeof pathGroups
              ]) {
                paths.push(
                  `${baseURL}${route.path.replace(
                    ":lang",
                    lang.code
                  )}/${subRoute.path.replace(":id", path)}`
                );
              }
            } else {
              paths.push(
                `${baseURL}${route.path.replace(":lang", lang.code)}/${
                  subRoute.path
                }`
              );
            }
          }
        }
      }
      setAllPath([...paths]);
      setLoading(false);
    })();
  }, []);

  const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${allPath
        .map((url) => {
          return `
            <url>
              <loc>${url}</loc>
              <lastmod>${new Date().toISOString()}</lastmod>
              <changefreq>monthly</changefreq>
              <priority>1.0</priority>
            </url>
          `;
        })
        .join("\n")}
    </urlset>
  `;

  return loading ? (
    <PageLoader />
  ) : (
    <pre style={{ fontFamily: "initial" }}>{sitemap}</pre>
  );
};

export default SitemapPage;
