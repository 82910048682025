import React from "react";
import get from "lodash/get";
import find from "lodash/find";
import sumBy from "lodash/sumBy";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { useMediaQuery } from "@mui/material";
import ReactDOMServer from "react-dom/server";
import { SunburstSeriesOption } from "echarts";
import { SVGRenderer } from "echarts/renderers";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import { useStoreState } from "app/state/store/hooks";
import { onEchartResize } from "app/utils/onEchartResize";
import { useLocation, useNavigate } from "react-router-dom";
import { SunburstChart as EchartsSunburst } from "echarts/charts";
import { formatFinancialValue } from "app/utils/formatFinancialValue";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";
import { TooltipComponent, TooltipComponentOption } from "echarts/components";
import { chartTooltipCommonConfig } from "app/components/charts/tooltips/common/config";
import { TooltipTouchContainer } from "app/components/charts/tooltips/common/touchcontainer";
import {
  SunbursChartProps,
  SunburstDataItem,
} from "app/components/charts/sunburst/data";
import {
  SunburstChartTooltip,
  SunburstChartTooltipProps,
} from "app/components/charts/tooltips/sunburst";

echarts.use([TooltipComponent, EchartsSunburst, SVGRenderer]);

export function SunburstChart(props: SunbursChartProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const cmsData = useCMSData({ returnData: true });
  const isTouch = useMediaQuery("(hover: none)");
  const containerRef = React.useRef<HTMLDivElement>(null);
  const selectionsRef = React.useRef<
    {
      dataIndex: number;
      item: SunburstDataItem;
    }[]
  >([]);

  const [savedChart, setSavedChart] = React.useState<echarts.ECharts | null>(
    null
  );
  const [selections, setSelections] = React.useState<
    {
      dataIndex: number;
      item: SunburstDataItem;
    }[]
  >([]);
  const [touchTooltip, setTouchTooltip] = React.useState<{
    item: any;
    value: number;
    dataIndex: number;
  } | null>(null);

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const flattenData = React.useMemo(() => {
    let data: any[] = [];
    props.data.forEach((item) => {
      data.push(item);
      if (item.children) {
        item.children.forEach((child) => {
          data.push(child);
          if (child.children) {
            child.children.forEach((grandChild) => {
              data.push(grandChild);
              if (grandChild.children) {
                grandChild.children.forEach((grandGrandChild) => {
                  data.push(grandGrandChild);
                });
              }
            });
          }
        });
      }
    });
    return data;
  }, [props.data]);

  const dataToShow = React.useMemo(() => {
    if (selections.length === 0) {
      return props.data;
    }
    return [
      find(flattenData, { name: selections[selections.length - 1].item.name }),
    ];
  }, [flattenData, selections]);

  const total = React.useMemo(() => sumBy(dataToShow, "value"), [dataToShow]);

  const commitmentsLabel = React.useMemo(() => {
    return get(cmsData, "viz.commitments", "Commitments");
  }, [cmsData]);

  const disbursementsLabel = React.useMemo(() => {
    return get(cmsData, "viz.disbursements", "Disbursements");
  }, [cmsData]);

  function onBackClick() {
    if (selectionsRef.current && selectionsRef.current.length > 1) {
      setSelections(
        selectionsRef.current.slice(0, selectionsRef.current.length - 1)
      );
    } else {
      setSelections([]);
    }
  }

  React.useEffect(() => {
    if (containerRef.current) {
      const chart = echarts.init(containerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<
        SunburstSeriesOption | TooltipComponentOption
      > = {
        series: {
          id: "sectors-sunburst",
          type: "sunburst",
          data: dataToShow,
          radius: ["50%", "100%"],
          nodeClick: false,
          emphasis: {
            focus: "ancestor",
          },
          label: {
            show: false,
          },
          itemStyle: {
            borderWidth: 2,
            color: colors.primary.gray,
          },
          levels: [
            {
              itemStyle: {
                opacity: 1,
              },
            },
            {
              itemStyle: {
                opacity: 0.8,
              },
            },
            {
              itemStyle: {
                opacity: 0.6,
              },
            },
          ],
        },
        tooltip: {
          ...chartTooltipCommonConfig(isTouch),
          formatter: (params: any) => {
            let data: SunburstChartTooltipProps = {
              value: params.value,
              title: params.data[getNameFieldBasedOnLang(currentLanguage)],
              percentage: params.data.percentage,
              commitment: params.data.commitment,
              commitmentsLabel,
              disbursementsLabel,
            };
            const html = ReactDOMServer.renderToString(
              <SunburstChartTooltip {...data} />
            );
            return html;
          },
        },
      };

      if (containerRef.current) {
        new ResizeObserver(() =>
          onEchartResize(
            // @ts-ignore
            chart,
            "sunburst-chart",
            containerRef.current?.clientHeight
          )
        ).observe(containerRef?.current);
      }

      chart.setOption(option);

      chart.on("click", (params: any) => {
        if (isTouch) {
          setTouchTooltip({
            item: params.data,
            value: params.value,
            dataIndex: params.dataIndex,
          });
          return;
        }
        setSelections((prev) => {
          if (
            prev.length === 0 ||
            prev[prev.length - 1].dataIndex !== params.dataIndex
          ) {
            return [
              ...prev,
              {
                item: params.data,
                dataIndex: params.dataIndex,
              },
            ];
          }
          return prev;
        });
      });

      setSavedChart(chart);
    }
  }, [
    containerRef.current,
    commitmentsLabel,
    disbursementsLabel,
    currentLanguage,
    isTouch,
  ]);

  React.useEffect(() => {
    if (savedChart) {
      savedChart.setOption({
        series: {
          data: dataToShow,
        },
      });
    }
  }, [savedChart, dataToShow]);

  React.useEffect(() => {
    selectionsRef.current = selections;
    const backButton = document.getElementById("tab-view-back-button");
    if (selections.length === 0) {
      props.setSelectedItem(null);
      if (backButton) {
        backButton.style.display = "none";
      }
    } else {
      props.setSelectedItem(selections[selections.length - 1]);
      if (backButton) {
        backButton.style.display = "flex";
      }
    }
  }, [selections]);

  React.useEffect(() => {
    if (
      props.selectedItem &&
      (selections.length === 0 ||
        selections[selections.length - 1].dataIndex !==
          props.selectedItem.dataIndex)
    ) {
      setSelections((prev) => {
        if (props.selectedItem) {
          return [
            ...prev,
            {
              item: props.selectedItem.item,
              dataIndex: props.selectedItem.dataIndex,
            },
          ];
        }
        return prev;
      });
    }
  }, [props.selectedItem]);

  React.useEffect(() => {
    const backButton = document.querySelector("#tab-view-back-button");
    if (backButton) {
      backButton.addEventListener("click", onBackClick);
    }

    return () => {
      setSelections([]);
      if (backButton) {
        backButton.removeEventListener("click", onBackClick);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          id="sunburst-chart"
          ref={containerRef}
          sx={{
            width: "100%",
            height: "550px",
            "@media (max-width: 920px)": {
              height: "400px",
            },
            "@media (max-width: 768px)": {
              height: "300px",
            },
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            zIndex: -1,
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" fontWeight={700}>
            {formatFinancialValue(total)}
          </Typography>
        </Box>
      </Box>
      {isTouch && touchTooltip && (
        <TooltipTouchContainer>
          <SunburstChartTooltip
            value={touchTooltip.value}
            onClose={() => setTouchTooltip(null)}
            title={touchTooltip.item[getNameFieldBasedOnLang(currentLanguage)]}
            percentage={touchTooltip.item.percentage}
            commitment={touchTooltip.item.commitment}
            commitmentsLabel={commitmentsLabel}
            disbursementsLabel={disbursementsLabel}
            drilldownLabel={get(cmsData, "viz.drilldown", "Drilldown")}
            goToDetailLabel={get(cmsData, "viz.goToDetail", "Go to detail")}
            drilldown={
              touchTooltip.item.children?.length > 0
                ? () => {
                    setTouchTooltip(null);
                    setSelections((prev) => [
                      ...prev,
                      {
                        item: touchTooltip.item,
                        dataIndex: touchTooltip.dataIndex,
                      },
                    ]);
                  }
                : undefined
            }
            goToDetail={
              touchTooltip.item.ref && touchTooltip.item.children?.length === 0
                ? () => {
                    setTouchTooltip(null);
                    navigate(
                      `/${currentLanguage}/sectors/${touchTooltip.item.ref}${location.search}`
                    );
                  }
                : undefined
            }
          />
        </TooltipTouchContainer>
      )}
    </React.Fragment>
  );
}
