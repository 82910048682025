import React from "react";
import { colors } from "app/theme";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

export const AccessibilitySwitch = styled(Switch)((props) => ({
  width: 110,
  height: 34,
  padding: 0,
  "& .MuiSwitch-root": {
    margin: 0,
  },
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(1px)",
    "&.Mui-checked": {
      color: colors.primary.white,
      transform: "translateX(76px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: colors.primary.blue,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 30,
    height: 30,
    marginTop: 1,
    boxShadow:
      "0px 1.446765661239624px 2.893531322479248px 0px rgba(97, 97, 97, 0.20), 0px 0.723382830619812px 1.446765661239624px 0px rgba(97, 97, 97, 0.20)",
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    borderRadius: "20px",
    background: "rgba(227, 227, 227, 0.5)",
    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.10) inset",
  },
  "& .MuiSwitch-track:after": {
    top: props.checked ? 5 : 8,
    [props.checked ? "left" : "right"]: 11,
    fontSize: 12,
    position: "absolute",
    content: `"${props.text}"`,
    color: props.checked ? colors.primary.white : colors.primary.blue,
  },
}));
