import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import filter from "lodash/filter";
import Box from "@mui/material/Box";
import orderBy from "lodash/orderBy";
import Typography from "@mui/material/Typography";
import { useCMSData } from "app/hooks/useCMSData";
import IconButton from "@mui/material/IconButton";
import { useStoreState } from "app/state/store/hooks";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

interface SdgsChartProps {
  items: {
    code: string;
    label: string;
    value: number;
  }[];
  minimal?: boolean;
}

export function SdgsChart(props: SdgsChartProps) {
  const cmsData = useCMSData({ returnData: true });
  const scrollableRef = React.useRef<HTMLDivElement>(null);

  const [backBtnEnabled, setBackBtnEnabled] = React.useState(false);
  const [forwardBtnEnabled, setForwardBtnEnabled] = React.useState(true);

  const selectedLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const onArrowButtonClick = (direction: "left" | "right") => () => {
    if (!scrollableRef.current) return;
    scrollableRef.current.scroll({
      left:
        scrollableRef.current.scrollLeft +
        scrollableRef.current.clientWidth * (direction === "left" ? -1 : 1),
      behavior: "smooth",
    });
  };

  const items = React.useMemo(() => {
    const temp = filter(props.items, (item) => item.value > 0);
    if (!props.minimal) {
      return temp;
    }
    return orderBy(temp, (item) => parseInt(item.code, 10), "asc");
  }, [props.items, props.minimal]);

  React.useEffect(() => {
    if (!scrollableRef.current) return;
    const scrollable = scrollableRef.current;
    const onScroll = () => {
      if (!scrollable) return;
      const { scrollLeft, scrollWidth, clientWidth } = scrollable;
      const scrollRight = scrollWidth - scrollLeft - clientWidth;
      const backBtnEnabled = scrollLeft > 0;
      const forwardBtnEnabled = scrollRight > 0;
      setBackBtnEnabled(backBtnEnabled);
      setForwardBtnEnabled(forwardBtnEnabled);
    };
    scrollable.addEventListener("scroll", onScroll);
    return () => scrollable.removeEventListener("scroll", onScroll);
  }, [scrollableRef.current]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: !props.minimal ? "100%" : "71px",
        "button.Mui-disabled": {
          opacity: 0.2,
        },
        "@media (max-width: 768px)": {
          height: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Scroll left"
          aria-disabled={props.items.length === 0 || !backBtnEnabled}
          onClick={onArrowButtonClick("left")}
          disabled={props.items.length === 0 || !backBtnEnabled}
        >
          <ArrowBackIos
            fontSize="large"
            sx={{
              transform: "translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
      <Box
        ref={scrollableRef}
        sx={{
          gap: "20px",
          display: "flex",
          maxWidth: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          width: "calc(100% - 70px)",
          padding: !props.minimal ? "10px" : 0,
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "@media (max-width: 768px)": {
            width: "100%",
            "&::-webkit-scrollbar": {
              height: "5px",
              display: "block",
              background: colors.secondary.blueGrayLight,
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "4px",
              background: colors.secondary.blueGrayLight,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              background: colors.primary.blue,
            },
          },
          "> div": {
            borderRadius: "10px",
            boxShadow: !props.minimal ? colors.shadows.main : "none",
            "> div": {
              width: "100%",
              height: "63px",
              display: "flex",
              borderRadius: "10px",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              background: !props.minimal ? colors.primary.white : "transparent",
            },
            "> img": {
              borderRadius: !props.minimal ? "10px 10px 0 0" : "10px",
            },
          },
        }}
      >
        {items.map((item) => (
          <Box key={item.label}>
            <img
              width={!props.minimal ? 142 : 71}
              height={!props.minimal ? 142 : 71}
              alt={item.label}
              src={`/sdgs/${selectedLanguage}/${item.code}.png`}
            />
            {!props.minimal && (
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  {item.value}
                </Typography>
                <Typography variant="body1">Activities</Typography>
              </Box>
            )}
          </Box>
        ))}
        {items.length === 0 && (
          <Box
            component="span"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1">
              {get(cmsData, "general.nodataavailable", "No data available")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        <IconButton
          aria-label="Scroll right"
          aria-disabled={items.length === 0 || !forwardBtnEnabled}
          onClick={onArrowButtonClick("right")}
          disabled={items.length === 0 || !forwardBtnEnabled}
        >
          <ArrowBackIos
            fontSize="large"
            sx={{
              transform: "rotate(180deg) translate(5px, 0)",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}
