import React from "react";
import Box from "@mui/material/Box";

interface Props {
  children: React.ReactNode;
}

export function TooltipTouchContainer(props: Props) {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 1200,
        width: "100vw",
        height: "100vh",
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0,0,0,0.5)",
      }}
    >
      {props.children}
    </Box>
  );
}
