import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

const sdgs: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/sdgs`),
};

export default sdgs;

export const sdgsPriority: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/sdgs`),
};

export const sdgsLocation: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/sdgs`),
};

export const sdgsSector: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/sdgs`),
};
