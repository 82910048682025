import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatLocale } from "app/utils/formatLocale";

interface Props {
  percentage: number;
  commitments: number;
  disbursements: number;
  commitmentsLabel: string;
  disbursementsLabel: string;
}

export function TooltipPercentageBarBlock(props: Props) {
  return (
    <Box
      sx={{
        gap: "5px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle2" fontWeight={700}>
          {props.disbursementsLabel}
        </Typography>
        <Typography variant="subtitle2" fontWeight={700}>
          {formatLocale(props.disbursements)}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2">{props.commitmentsLabel}</Typography>
        <Typography variant="body2">
          {formatLocale(props.commitments)}
        </Typography>
      </Box>
    </Box>
  );
}
