import axios from "axios";

const AxiosInstance = axios.create({
  baseURL: "https://iati.cloud/api/v2",
  headers: {
    Accept: "application/json",
  },
});

const MFA_PUBLISHER_REF = "FI-3";
const AF_TAG_CODE = "tag.code";
const AF_TAG_VOCABULARY = "tag.vocabulary";
const AF_TRANSACTION_TYPE_CODE = "transaction.transaction-type.code";

export const pathIds = {
  activities: async () =>
    AxiosInstance.get(
      `/activity/?q=reporting-org.ref:${MFA_PUBLISHER_REF}&fl=iati-identifier&rows=1100`
    ),
  organizations: async () =>
    AxiosInstance.get(
      `/activity/?q=reporting-org.ref:${MFA_PUBLISHER_REF} AND participating-org.narrative:*&fl=participating-org.ref&rows=3000`
    ),
  countries: async () =>
    AxiosInstance.get(
      `/activity/?q=reporting-org.ref:${MFA_PUBLISHER_REF}&fl=recipient-country.code&rows=3000`
    ),
  sectors: async () =>
    AxiosInstance.get(
      `/activity/?q=reporting-org.ref:${MFA_PUBLISHER_REF}&fl=sector.code&rows=1100`
    ),
  priorities: async () =>
    AxiosInstance.get(
      `/activity/?q=reporting-org.ref:${MFA_PUBLISHER_REF} AND ${AF_TAG_CODE}:Priority* AND ${AF_TRANSACTION_TYPE_CODE}:3 AND ${AF_TAG_VOCABULARY}:99&fl=${AF_TAG_CODE}&rows=1100`
    ),
};
