import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import useTitle from "react-use/lib/useTitle";
import useMount from "react-use/lib/useMount";
import Share from "@mui/icons-material/Share";
import { MFATable } from "app/components/table";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import { getAppName } from "app/utils/getAppName";
import IconButton from "@mui/material/IconButton";
import { useCMSData } from "app/hooks/useCMSData";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { formatLocale } from "app/utils/formatLocale";
import { InfoButton } from "app/components/infobutton";
import { PageSection } from "app/pages/common/section";
import { PageLoader } from "app/components/pageLoader";
import { Breadcrumbs } from "app/components/breadcrumb";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams } from "react-router-dom";
import { CanonicalUrl } from "app/components/canonicalUrl";
import CloudDownload from "@mui/icons-material/CloudDownload";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { ActivitiesChart } from "app/components/charts/activities";
import { ActivitiesIcon } from "app/assets/section-icons/Activities";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { ShareTooltip } from "app/components/floatingButtons/common/share";
import { ExportTooltip } from "app/components/floatingButtons/common/export";
import { FloatingButtonsLightTooltip } from "app/components/floatingButtons";

const Spacer = () => <Box sx={{ width: "100%", height: "20px" }} />;

export function ActivityPage() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const cmsData = useCMSData({ returnData: true });
  const mobile = useMediaQuery("(max-width:768px)");

  const data = useStoreState((state) => state.activityPage.data);
  const fetchPageInfo = useStoreActions(
    (actions) => actions.activityPage.fetch
  );
  const loading = useStoreState((state) => state.activityPage.loading);
  const relatedActivities = useStoreState((state) =>
    get(state.relatedActvities, "data.vizData", [])
  );
  const fetchRelatedActivities = useStoreActions(
    (actions) => actions.relatedActvities.fetch
  );
  const loadingRelatedActivities = useStoreState(
    (state) => state.relatedActvities.loading
  );
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  useTitle(
    `${get(data, "data.metadata.title", params.id ?? "")} | ${getAppName(
      currentLanguage
    )}`
  );

  const transactionsTableData = React.useMemo(() => {
    return get(data, "data.transactions", []).map((t: any) => {
      return {
        date: t.year.slice(0, 4),
        disbursement: t.disbursed,
        commitment: t.commitment,
      };
    });
  }, [data]);

  const organisationsTableData = React.useMemo(() => {
    return get(data, "data.metadata.participating_orgs", []).map((o: any) => {
      return {
        name: {
          label: o.name,
          link: `/${currentLanguage}${o.url}`,
        },
        ref: o.reference,
        type: o.type,
      };
    });
  }, [data]);

  const countriesTableData = React.useMemo(() => {
    return get(data, "data.metadata.countries", []).map((c: any) => {
      return {
        name: {
          label: c.name,
          link: `/${currentLanguage}${c.url}`,
        },
        code: c.code,
        percentage: c.percentage,
      };
    });
  }, [data]);

  const regionsTableData = React.useMemo(() => {
    return get(data, "data.metadata.regions", []).map((r: any) => {
      return {
        name: r.name,
        code: r.code,
        percentage: r.percentage,
      };
    });
  }, [data]);

  const cooperationModalitiesTableData = React.useMemo(() => {
    return get(data, "data.metadata.default_aid_types", []).map((t: any) => {
      return {
        code: t.code,
        name: t.name,
        modality: t.vocabulary,
      };
    });
  }, [data]);

  const policyMarkersTableData = React.useMemo(() => {
    return get(data, "data.metadata.policy_markers", []).map((t: any) => {
      return {
        name: t.name,
        code: t.code,
        significance: t.significance,
        vocabularyUri: t.vocabulary_uri,
        vocabulary: t.vocabulary,
      };
    });
  }, [data]);

  const relatedActivitiesRefs = React.useMemo(() => {
    return get(data, "data.metadata.related_activities", []);
  }, [data]);

  const transactionsTableColumns = React.useMemo(() => {
    return [
      {
        id: "date",
        label: get(cmsData, "viz.year", "Year"),
      },
      {
        id: "disbursement",
        label: get(cmsData, "viz.disbursementsamount", "Total disbursements"),
      },
      {
        id: "commitment",
        label: get(cmsData, "viz.committed", "Committed"),
      },
    ];
  }, [cmsData?.viz]);

  const organisationsTableColumns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "ref",
      label: "Reference",
    },
    {
      id: "type",
      label: "Type",
    },
  ];

  const locationTableColumns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "code",
      label: "Reference",
    },
    {
      id: "percentage",
      label: "Percentage",
    },
  ];

  const cooperationModalitiesTableColumns = [
    {
      id: "code",
      label: "Code",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "modality",
      label: "Modality",
    },
  ];

  const policyMarkersTableColumns = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "code",
      label: "Code",
    },
    {
      id: "significance",
      label: "Significance",
    },
    {
      id: "vocabularyUri",
      label: "Vocabulary Uri",
    },
    {
      id: "vocabulary",
      label: "Vocabulary",
    },
  ];

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    fetchPageInfo({
      values: {
        activityId: params.id,
        lang: currentLanguage,
      },
    });
  }, [params.id, currentLanguage]);

  React.useEffect(() => {
    if (relatedActivitiesRefs.length > 0) {
      fetchRelatedActivities({
        values: {
          filters: {
            iati_identifier: relatedActivitiesRefs,
          },
          lang: currentLanguage,
        },
      });
    }
  }, [relatedActivitiesRefs, currentLanguage]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl={`/activities/${params.id}`} />
      <Box
        sx={{
          "@media (max-width: 920px)": {
            padding: "0 48px",
          },
          "@media (max-width: 768px)": {
            padding: "0 24px",
          },
          ".MuiAccordion-region": {
            div: {
              background: colors.primary.white,
            },
          },
        }}
      >
        {loading && <PageLoader />}
        <Breadcrumbs
          route={[
            {
              label: "Homepage",
              path: "/:lang",
              cmsKey: "breadcrumbs.homepage",
            },
            {
              label: "Activities",
              path: "/:lang/activities",
              cmsKey: "pageSections.ActivitiesTitle",
            },
            {
              label: get(data, "data.metadata.title", params.id ?? ""),
            },
          ]}
        />
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            flexDirection: "row",
            marginBottom: "20px",
            justifyContent: "space-between",
            "@media (max-width: 768px)": {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Box
            sx={{
              gap: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box>
              <ActivitiesIcon />
            </Box>
            <Box>
              <Box
                sx={{
                  gap: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">
                  {get(data, "data.metadata.title", params.id ?? "")}
                </Typography>
                <InfoButton
                  text={get(cmsData, "tooltips.activity_title", "")}
                  fontSize="small"
                  sx={{
                    color: colors.secondary.grayDark,
                    "@media (max-width: 768px)": {
                      display: "none",
                    },
                  }}
                />
              </Box>
              <Typography component="div">
                {!mobile
                  ? `Showcasing ${get(
                      data,
                      "data.metadata.title",
                      params.id ?? ""
                    )}
                as a partner country of the Finnish ODA`
                  : params.id}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              gap: "10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              "@media (max-width: 768px)": {
                width: "100%",
                justifyContent: "space-between",
              },
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBack fontSize="small" />}
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                padding: "4px 8px",
                textTransform: "none",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              {get(cmsData, "general.back", "Back")}
            </Button>
            <Box
              sx={{
                gap: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FloatingButtonsLightTooltip
                placement="bottom-start"
                title={<ShareTooltip cmsData={cmsData} />}
              >
                <IconButton
                  aria-label="Share button"
                  sx={{
                    width: "34px",
                    height: "34px",
                    padding: "5px",
                    borderRadius: "50%",
                    background: colors.primary.white,
                    boxShadow: colors.shadows.section,
                  }}
                >
                  <Share />
                </IconButton>
              </FloatingButtonsLightTooltip>
              <FloatingButtonsLightTooltip
                placement="bottom-start"
                title={<ExportTooltip cmsData={cmsData} />}
              >
                <IconButton
                  aria-label="Export button"
                  sx={{
                    width: "34px",
                    height: "34px",
                    padding: "5px",
                    borderRadius: "50%",
                    background: colors.primary.white,
                    boxShadow: colors.shadows.section,
                  }}
                >
                  <CloudDownload />
                </IconButton>
              </FloatingButtonsLightTooltip>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            "@media (max-width: 768px)": {
              flexWrap: "wrap",
            },
            "> *": {
              textTransform: "uppercase",
            },
            "> hr": {
              borderColor: colors.primary.blue,
            },
          }}
        >
          <Typography variant="body2">
            {get(data, "data.metadata.reporting_org_narrative")}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="body2">
            {get(data, "data.metadata.reporting_org_ref")}
          </Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="body2">
            {get(data, "data.metadata.reporting_org_type")}
          </Typography>
        </Box>
        <Box
          sx={{
            gap: "20px",
            width: "100%",
            display: "flex",
            marginTop: "20px",
            borderRadius: "20px",
            padding: "15px 20px",
            background: "#fcfbf9",
            flexDirection: "column",
            boxShadow: colors.shadows.inner,
          }}
        >
          <Typography variant="subtitle1" fontWeight={700}>
            {get(cmsData, "general.summary", "Summary")}
          </Typography>
          <Typography variant="body2">
            {get(data, "data.metadata.description", "")}
          </Typography>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Typography variant="subtitle2" fontWeight={700}>
                {get(
                  cmsData,
                  "general.contactdepartment",
                  "Contact Department"
                )}
              </Typography>
              <Typography variant="body2">
                {get(data, "data.metadata.contact_department", "")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Typography variant="subtitle2" fontWeight={700}>
                {get(cmsData, "general.website", "Website")}
              </Typography>
              <Typography variant="body2">
                {get(data, "data.metadata.website", "")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="subtitle2" fontWeight={700}>
                {get(cmsData, "viz.committed", "Commitment")}
              </Typography>
              <Typography variant="body2">
                {formatLocale(get(data, "data.commitmentsTotal", 0))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Typography variant="subtitle2" fontWeight={700}>
                {get(cmsData, "viz.startdate", "Planned start date")}
              </Typography>
              <Typography variant="body2">
                {get(data, "data.metadata.dates[0]", "")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Typography variant="subtitle2" fontWeight={700}>
                {get(cmsData, "viz.sector", "Sector")}
              </Typography>
              <Typography variant="body2">
                {get(data, "data.metadata.sectors", [])
                  .map((s: any) => s.name)
                  .join(", ")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="subtitle2" fontWeight={700}>
                {get(cmsData, "viz.disbursements", "Disbursements")}
              </Typography>
              <Typography variant="body2">
                {formatLocale(get(data, "data.disbursementsTotal", 0))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Typography variant="subtitle2" fontWeight={700}>
                {get(cmsData, "viz.enddate", "Planned end date")}
              </Typography>
              <Typography variant="body2">
                {get(data, "data.metadata.dates[1]", "")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Typography variant="subtitle2" fontWeight={700}>
                {get(cmsData, "viz.countriesregions", "Country/region")}
              </Typography>
              <Typography variant="body2">
                {[
                  ...get(data, "data.metadata.countries", []),
                  ...get(data, "data.metadata.regions", []),
                ]
                  .map((l: any) => l.name)
                  .join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Spacer />
        <MFATable
          rows={transactionsTableData}
          columns={transactionsTableColumns}
        />
        <Accordion
          sx={{
            width: "100%",
            marginTop: "20px",
            padding: "0px 20px",
            borderRadius: "20px",
            background: "#fcfbf9",
            boxShadow: colors.shadows.inner,
          }}
        >
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Typography variant="subtitle1" fontWeight={700}>
              {get(cmsData, "viz.reportingorg", "Organisations")}
            </Typography>
          </AccordionSummary>
          <MFATable
            rows={organisationsTableData}
            columns={organisationsTableColumns}
          />
        </Accordion>
        <Accordion
          sx={{
            width: "100%",
            marginTop: "20px",
            padding: "0px 20px",
            borderRadius: "20px",
            background: "#fcfbf9",
            boxShadow: colors.shadows.inner,
          }}
        >
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Typography variant="subtitle1" fontWeight={700}>
              {get(cmsData, "general.countries", "Countries")}
            </Typography>
          </AccordionSummary>
          <MFATable rows={countriesTableData} columns={locationTableColumns} />
        </Accordion>
        <Accordion
          sx={{
            width: "100%",
            marginTop: "20px",
            padding: "0px 20px",
            borderRadius: "20px",
            background: "#fcfbf9",
            boxShadow: colors.shadows.inner,
          }}
        >
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Typography variant="subtitle1" fontWeight={700}>
              {get(cmsData, "general.regions", "Regions")}
            </Typography>
          </AccordionSummary>
          <MFATable rows={regionsTableData} columns={locationTableColumns} />
        </Accordion>
        <Accordion
          sx={{
            width: "100%",
            marginTop: "20px",
            padding: "0px 20px",
            borderRadius: "20px",
            background: "#fcfbf9",
            boxShadow: colors.shadows.inner,
          }}
        >
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Typography variant="subtitle1" fontWeight={700}>
              {get(
                cmsData,
                "viz.cooperationmodality",
                "Cooperation modalities"
              )}
            </Typography>
          </AccordionSummary>
          <MFATable
            rows={cooperationModalitiesTableData}
            columns={cooperationModalitiesTableColumns}
          />
        </Accordion>
        <Accordion
          sx={{
            width: "100%",
            marginTop: "20px",
            padding: "0px 20px",
            borderRadius: "20px",
            background: "#fcfbf9",
            boxShadow: colors.shadows.inner,
          }}
        >
          <AccordionSummary expandIcon={<ArrowDropDown />}>
            <Typography variant="subtitle1" fontWeight={700}>
              {get(cmsData, "filters.policymarkers", "Policy Markers")}
            </Typography>
          </AccordionSummary>
          <MFATable
            rows={policyMarkersTableData}
            columns={policyMarkersTableColumns}
          />
        </Accordion>
        <Spacer />
        <PageSection
          background="#FCFBF9"
          loading={loadingRelatedActivities}
          header={{
            title: get(
              cmsData,
              "pageSections.RelatedActivitiesTitle",
              "Related Activities"
            ),
            subtitle: get(
              cmsData,
              "pageSections.RelatedActivitiesSubtitle",
              `Activties related to ${get(
                data,
                "data.metadata.title",
                params.id ?? ""
              )}.`
            ),
            info: get(cmsData, "tooltips.related_activities", ""),
            icon: <ActivitiesIcon />,
          }}
        >
          <ActivitiesChart items={relatedActivities} />
        </PageSection>
        <Spacer />
      </Box>
    </React.Fragment>
  );
}
