import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Remove from "@mui/icons-material/Remove";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useStoreState } from "app/state/store/hooks";
import { NavLink, useLocation } from "react-router-dom";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { FiltersPanel } from "app/components/filters/panel";
import { FiltersApplied } from "app/components/filters/applied";

export const TABS = [
  {
    label: "Data Summary",
    link: "/",
    cmsKey: "pageSections.DataSummary",
  },
  {
    label: "Disbursements Overview",
    link: "/disbursements-overview",
    cmsKey: "pageSections.DisbursementsOverviewTitle",
  },
  {
    label: "Priorities",
    count: 4,
    link: "/priorities",
    cmsKey: "pageSections.PrioritiesTitle",
  },
  {
    label: "Sectors",
    count: 40,
    link: "/sectors",
    cmsKey: "pageSections.SectorsTitle",
  },
  {
    label: "Countries & Regions",
    count: 54,
    link: "/countries-regions",
    cmsKey: "pageSections.CountriesRegionsTitle",
  },
  {
    label: "Organisations",
    count: 109,
    link: "/organisations",
    cmsKey: "pageSections.OrganisationsTitle",
  },
  {
    label: "Activities",
    count: 1098,
    link: "/activities",
    cmsKey: "pageSections.ActivitiesTitle",
  },
];

const Tab = ({
  label,
  count,
  link,
  cmsKey,
}: {
  link: string;
  label: string;
  count?: number;
  cmsKey: string;
}) => {
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });

  const isActive = `${location.pathname}${location.search}` === link;

  return (
    <Link
      to={link}
      component={NavLink}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "26px",
        textDecoration: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&.active": {
          fontWeight: "700",
        },
        "&:hover": {
          textDecoration: "underline",
        },
        "&.disabled": {
          pointerEvents: "none",
          color: colors.secondary.grayDark,
        },
      }}
    >
      <Typography
        variant="body2"
        sx={
          isActive
            ? {
                fontWeight: "700 !important",
              }
            : {}
        }
      >
        {get(cmsData, cmsKey, label)}
      </Typography>
    </Link>
  );
};

export function DataSummaryBar() {
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });

  const [openPanel, setOpenPanel] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const onExploreMore = () => {
    setOpenPanel((prev) => !prev);
  };

  const handleClickFilterPanel = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseFilterPanel = () => {
    setAnchorEl(null);
  };

  const filtersApplied = React.useMemo(() => {
    return (
      appliedFilters.countries.length +
        appliedFilters.regions.length +
        appliedFilters.sectors.length +
        appliedFilters.tag.length +
        appliedFilters.organisationtypes.length +
        appliedFilters.organisations.length +
        appliedFilters.sdg.length +
        appliedFilters.policymarker.length +
        appliedFilters.defaultaidtype.length +
        appliedFilters.budgetlines.length +
        appliedFilters.collaborationtype.length +
        appliedFilters.humanrights.length +
        appliedFilters.years.length >
      0
    );
  }, [appliedFilters]);

  React.useEffect(() => {
    const onScroll = () => setAnchorEl(null);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const openFilterPanel = Boolean(anchorEl);
  const id = openFilterPanel ? "filter-panel-popover" : undefined;

  return (
    <Box
      sx={{
        zIndex: 10,
        top: "59px",
        position: "sticky",
        background: colors.primary.white,
        "@media (max-width: 768px)": {
          padding: "10px 0",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          minHeight: "65px",
          padding: "15px 0",
          flexDirection: "row",
          position: "relative",
          "@media (max-width: 920px)": {
            paddingBottom: 0,
            minHeight: "60px",
            paddingLeft: "48px",
          },
          "@media (max-width: 768px)": {
            display: "none",
          },
        }}
      >
        {!openPanel && (
          <Button
            sx={{
              zIndex: 2,
              width: "122px",
              height: "35px",
              fontSize: "14px",
              paddingTop: "8px",
              fontWeight: "400",
              borderRadius: "26px",
              textTransform: "none",
              "@media (max-width: 920px)": {
                paddingLeft: "0",
              },
            }}
            onClick={onExploreMore}
          >
            {get(cmsData, "filters.exploremore", "Explore more")}
          </Button>
        )}
        <Box
          sx={{
            top: 15,
            left: 0,
            zIndex: 1,
            height: "36px",
            borderRadius: "26px",
            position: "absolute",
            paddingRight: "36px",
            background: colors.primary.gray,
            transition: "width 0.5s ease-in-out",
            width: openPanel ? "calc(100% - 156px)" : "151px",
            "@media (max-width: 920px)": {
              left: "48px",
              paddingLeft: "12px",
              width: openPanel ? "calc(100% - 92px)" : "120px",
            },
          }}
        >
          <Box
            sx={{
              gap: "40px",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              opacity: openPanel ? 1 : 0,
              justifyContent: "space-between",
              visibility: openPanel ? "visible" : "hidden",
              transition: "visibility 0.25s linear, opacity 0.25s linear",
              "@media (max-width: 920px)": {
                gap: "unset",
                justifyContent: "space-between",
              },
              "> a:first-of-type": {
                paddingLeft: "20px",
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.label}
                label={tab.label}
                count={tab.count}
                cmsKey={tab.cmsKey}
                link={`/${currentLanguage}${tab.link}${location.search}`}
              />
            ))}
          </Box>
          <IconButton
            sx={{
              top: 0,
              right: 0,
              width: "36px",
              height: "36px",
              position: "absolute",
              transition: "transform 0.5s ease-in-out",
              transform: openPanel ? "rotate(180deg)" : "rotate(0deg)",
            }}
            onClick={onExploreMore}
            aria-label="Navigation tabs more button"
          >
            <ArrowRight />
          </IconButton>
        </Box>
        <Button
          color="primary"
          variant="contained"
          startIcon={
            !openFilterPanel ? (
              <Add sx={{ color: colors.primary.white }} />
            ) : (
              <Remove sx={{ color: colors.primary.white }} />
            )
          }
          sx={{
            right: 0,
            zIndex: 2,
            width: "133px",
            height: "35px",
            fontSize: "14px",
            fontWeight: "700",
            padding: "4px 8px",
            position: "absolute",
            borderRadius: "26px",
            textTransform: "none",
            opacity: openPanel ? 1 : 0,
            visibility: openPanel ? "visible" : "hidden",
            transition: "visibility 0.25s linear, opacity 0.25s linear",
            "@media (max-width: 920px)": {
              display: "none",
            },
          }}
          onClick={
            !openFilterPanel ? handleClickFilterPanel : handleCloseFilterPanel
          }
        >
          {get(cmsData, "filters.addfilters", "Add Filters")}
        </Button>
        {openFilterPanel && (
          <Box
            sx={{
              "@media (max-width: 920px)": {
                display: "none",
              },
            }}
          >
            <svg
              width="149"
              height="49"
              viewBox="0 0 149 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                right: 0,
                position: "absolute",
                filter: "drop-shadow(2px 5px 6px rgba(0, 0, 0, 0.1))",
              }}
            >
              <path
                d="M30.9898 22.0663V20C30.9898 20 31.0128 20.7806 30.9898 22.0663V49H10.9898C10.9898 49 19.4898 49 25.4898 43C30.2041 38.2857 30.9056 26.7806 30.9898 22.0663Z"
                fill="#fff"
              />
              <path
                d="M31.0001 20C31.0001 8.9543 39.9544 0 51.0001 0H129C140.046 0 149 8.95431 149 20V49H31.0001V20Z"
                fill="#fff"
              />
            </svg>
          </Box>
        )}
        <Popover
          id={id}
          disableScrollLock
          anchorEl={anchorEl}
          open={openFilterPanel}
          onClose={handleCloseFilterPanel}
          anchorOrigin={{
            vertical: 50,
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPopover-paper": {
              borderRadius: "20px 0 20px 20px",
              "@media (max-width: 768px)": {
                top: "0 !important",
                left: "0 !important",
                right: "0 !important",
                bottom: "0 !important",
                width: "100vw !important",
                height: "100vh !important",
                maxWidth: "100vw !important",
                maxHeight: "100vh !important",
                "> div": {
                  width: "100vw !important",
                  height: "100vh !important",
                  maxWidth: "100vw !important",
                  borderRadius: "0 !important",
                  maxHeight: "100vh !important",
                },
              },
            },
          }}
        >
          <FiltersPanel onClose={handleCloseFilterPanel} />
        </Popover>
      </Box>
      <Box
        sx={{
          gap: "10px",
          width: "100%",
          display: "flex",
          paddingLeft: "20px",
          flexDirection: "row",
          alignItems: "center",
          paddingBottom: filtersApplied ? "10px" : 0,
          "@media (max-width: 920px)": {
            padding: "0 48px",
            justifyContent: "flex-end",
          },
          "@media (max-width: 768px)": {
            padding: "0 24px",
          },
        }}
      >
        {filtersApplied && (
          <React.Fragment>
            <Typography
              variant="body2"
              sx={{
                width: "105px",
                "@media (max-width: 920px)": {
                  fontSize: "12px",
                },
                "@media (max-width: 768px)": {
                  display: "none",
                },
              }}
            >
              {get(cmsData, "filters.activefilters", "Active Filters")}
            </Typography>
            <Box
              sx={{
                width: "calc(100% - 115px)",
                "> div": {
                  width: "100%",
                  paddingTop: 0,
                  overflowX: "auto",
                  "&::-webkit-scrollbar": {
                    height: "5px",
                    background: colors.secondary.blueGrayLight,
                  },
                  "&::-webkit-scrollbar-track": {
                    borderRadius: "4px",
                    background: colors.secondary.blueGrayLight,
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    background: colors.primary.blue,
                  },
                  "> div": {
                    borderRadius: "25px",
                    background: colors.primary.gray,
                  },
                },
              }}
            >
              <FiltersApplied />
            </Box>
          </React.Fragment>
        )}
        <Button
          color="primary"
          variant="contained"
          startIcon={
            !openFilterPanel ? (
              <Add sx={{ color: colors.primary.white }} />
            ) : (
              <Remove sx={{ color: colors.primary.white }} />
            )
          }
          sx={{
            right: 0,
            zIndex: 2,
            width: "110px",
            height: "25px",
            fontSize: "12px",
            fontWeight: "700",
            minWidth: "110px",
            padding: "4px 8px",
            borderRadius: "26px",
            textTransform: "none",
            position: "relative",
            "@media (min-width: 920px)": {
              display: "none",
            },
          }}
          onClick={
            !openFilterPanel ? handleClickFilterPanel : handleCloseFilterPanel
          }
        >
          {get(cmsData, "filters.addfilters", "Add Filters")}
          {openFilterPanel && (
            <svg
              width="149"
              height="49"
              viewBox="0 0 149 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                right: 0,
                bottom: "-51px",
                position: "absolute",
                filter: "drop-shadow(2px 5px 6px rgba(0, 0, 0, 0.1))",
              }}
            >
              <path
                d="M30.9898 22.0663V20C30.9898 20 31.0128 20.7806 30.9898 22.0663V49H10.9898C10.9898 49 19.4898 49 25.4898 43C30.2041 38.2857 30.9056 26.7806 30.9898 22.0663Z"
                fill="#fff"
              />
              <path
                d="M31.0001 20C31.0001 8.9543 39.9544 0 51.0001 0H129C140.046 0 149 8.95431 149 20V49H31.0001V20Z"
                fill="#fff"
              />
            </svg>
          )}
        </Button>
      </Box>
    </Box>
  );
}
