import React from "react";
import Box from "@mui/material/Box";
import { styled } from "styled-components/macro";
import { useStoreState } from "app/state/store/hooks";
import {
  FILTER_TYPES,
  FiltersCommonTabViewProps,
} from "app/components/filters/view/data";
import { FiltersViewToolbar } from "app/components/filters/view/components/toolbar";

const Button = styled.button`
  padding: 0;
  opacity: 0.5;
  border: none;
  background: none;
  border-radius: 8px;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export function FiltersViewSDGS(props: FiltersCommonTabViewProps) {
  const selectedLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const clearAllFilters = () => {
    props.onFilterCheckboxChange([], FILTER_TYPES.SDGS);
  };

  const selectAllFilters = () => {
    const allValues = props.items.map((item) => item.value);
    props.onFilterCheckboxChange(allValues, FILTER_TYPES.SDGS);
  };

  return (
    <Box
      sx={{
        marginTop: "30px",
      }}
    >
      <FiltersViewToolbar
        hideSearch
        clearAllFilters={clearAllFilters}
        selectAllFilters={selectAllFilters}
      />
      <Box
        sx={{
          gap: "16px",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          overflowY: "auto",
          maxHeight: "300px",
          flexDirection: "row",
        }}
      >
        {props.items.map((item) => (
          <Button
            key={item.label}
            aria-label={`SDG: ${item.value} - ${item.label}`}
            onClick={() => {
              props.onFilterCheckboxChange([item.value], FILTER_TYPES.SDGS);
            }}
            style={
              props.localAppliedFilters.sdg.includes(item.value)
                ? { opacity: 1 }
                : {}
            }
          >
            <img
              width={98}
              height={94}
              alt={item.label}
              src={`/sdgs/${selectedLanguage}/${item.value}.png`}
            />
          </Button>
        ))}
      </Box>
    </Box>
  );
}
