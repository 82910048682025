export const chartTooltipCommonConfig = (touch: boolean): any => ({
  padding: 0,
  show: !touch,
  confine: true,
  borderWidth: 0,
  trigger: "item",
  borderRadius: 16,
  triggerOn: "mousemove",
});

export const pageChartTooltipCommonConfig = (touch: boolean): any => ({
  show: !touch,
  confine: true,
  borderWidth: 0,
  trigger: "item",
  borderRadius: 16,
  triggerOn: "mousemove",
});
