import React from "react";
import { get } from "lodash";
import Box from "@mui/material/Box";
import useMount from "react-use/lib/useMount";
import { MFATable } from "app/components/table";
import { useCMSData } from "app/hooks/useCMSData";
import useDebounce from "react-use/lib/useDebounce";
import { PageLoader } from "app/components/pageLoader";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";

export function ActivitiesTabView() {
  const cmsData = useCMSData({ returnData: true });

  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortKey, setSortKey] = React.useState("stratDate");
  const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">(
    "desc"
  );

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );
  const searchValue = useStoreState(
    (state) => state.tabViewTableSearch.value as string
  );
  const fetchData = useStoreActions((actions) => actions.activities.fetch);
  const data = useStoreState((state) =>
    get(state.activities, "data.activities", [])
  );
  const total = useStoreState((state) =>
    get(state.activities, "data.count", 0)
  );
  const loading = useStoreState((state) => state.activities.loading);

  const tableData = React.useMemo(() => {
    return data.map((item: any) => ({
      name: {
        label: item.title,
        link: `/${currentLanguage}/activities/${item.id}`,
      },
      startDate: item.latestCommitmentDate,
      organisation: item.organisations.join(", "),
      locations: item.locations.join(", "),
      commitment: item.commitment,
      value: item.disbursement,
    }));
  }, [data, currentLanguage]);

  const tableColumns = React.useMemo(() => {
    return [
      {
        id: "name",
        label: "Name",
      },
      {
        id: "startDate",
        label: get(
          cmsData,
          "viz.latestcommitmentdate",
          "Latest commitment date"
        ),
      },
      {
        id: "organisation",
        label: get(cmsData, "viz.reportingorg", "Organisation"),
      },
      {
        id: "locations",
        label: get(cmsData, "viz.countriesregions", "Country/region"),
      },
      {
        id: "commitment",
        label: get(cmsData, "viz.committed", "Commitment"),
      },
      {
        id: "value",
        label: get(cmsData, "viz.disbursements", "Disbursement"),
      },
    ];
  }, [cmsData?.viz]);

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    const filters = getAPIFormattedFilters(appliedFilters);
    fetchData({
      values: {
        page,
        search,
        filters,
        lang: currentLanguage,
        sort: `${sortKey} ${sortDirection}`,
      },
    });
  }, [page, appliedFilters, sortKey, sortDirection, search, currentLanguage]);

  useDebounce(
    () => {
      if (searchValue.length > 3) {
        setSearch(searchValue);
        setPage(0);
      } else {
        setSearch("");
      }
    },
    500,
    [searchValue]
  );

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="/activities" />
      <Box
        sx={{
          td: {
            borderStyle: "none",
            padding: "14px 16px",
          },
        }}
      >
        {loading && <PageLoader />}
        <MFATable
          rows={tableData}
          totalRows={total}
          defaultSortKey="value"
          columns={tableColumns}
          page={page}
          rowsPerPage={rowsPerPage}
          sortKey={sortKey}
          sortDirection={sortDirection}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          setSortDirection={setSortDirection}
          setSortKey={setSortKey}
        />
      </Box>
    </React.Fragment>
  );
}
