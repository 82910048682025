import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useWindowScroll } from "react-use";
import styled from "styled-components/macro";
import Close from "@mui/icons-material/Close";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import useMousePosition from "app/hooks/useMousePosition";
import { ClickAwayListener, useMediaQuery } from "@mui/material";

interface Props {
  title: string;
  value: string;
  commitment: string;
  onClose?: () => void;
  containerId?: string;
  goToDetail?: () => void;
  goToDetailLabel?: string;
  hoveredNode: string | null;
}

const Container = styled.div`
  width: 400px;
  display: flex;
  padding: 15px;
  z-index: 1000;
  position: absolute;
  border-radius: 16px;
  flex-direction: column;
  box-shadow: ${colors.shadows.main};
  background: ${colors.primary.white};

  @media (max-width: 400px) {
    width: calc(100vw - 48px);
  }

  * {
    font-size: 14px;
    color: ${colors.primary.blue};
    font-family: Finlandica !important;
  }

  button {
    color: ${colors.primary.white};
  }
`;

export function SectorBlocksTooltip(props: Props) {
  const { x, y } = useMousePosition();
  const windowScroll = useWindowScroll();
  const isTouch = useMediaQuery("(hover: none)");
  const cmsData = useCMSData({ returnData: true });

  const [placement, setPlacement] = React.useState({ top: 0, left: 0 });

  const onClickAway = () => {
    if (props.onClose && isTouch) {
      props.onClose();
    }
  };

  React.useEffect(() => {
    if (props.containerId) {
      const container = document.getElementById(props.containerId);
      if (container) {
        const containerBounds = container.getBoundingClientRect();
        setPlacement({
          top: y - containerBounds.top + 40,
          left: x - containerBounds.left - 150,
        });
      }
    } else {
      setPlacement({
        top: y - 100 + windowScroll.y,
        left: x - 350,
      });
    }
  }, [x, y, props.hoveredNode, windowScroll.y]);

  if (!props.hoveredNode) {
    return null;
  }

  const content = (
    <React.Fragment>
      {props.onClose && (
        <Box
          sx={{
            padding: 0,
            top: "10px",
            right: "10px",
            position: "absolute",
            color: colors.primary.blue,
            "@media (min-width: 768px)": {
              display: "none",
            },
          }}
          onClick={props.onClose}
        >
          <Close htmlColor={colors.primary.blue} />
        </Box>
      )}
      <Typography
        fontWeight="700"
        variant="subtitle2"
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          marginBottom: "16px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {props.title}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginBottom: "8px",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle2" fontWeight={700}>
          {get(cmsData, "viz.disbursementsamount", "Total disbursements")}
        </Typography>
        <Typography variant="subtitle2" fontWeight={700}>
          {props.value}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2">
          {get(cmsData, "viz.commitmentsamount", "Total commitment")}
        </Typography>
        <Typography variant="body2">{props.commitment}</Typography>
      </Box>
      {props.goToDetail && (
        <Box
          sx={{
            gap: "10px",
            display: "none",
            marginTop: "20px",
            justifyContent: "flex-end",
            "@media (hover: none)": {
              display: "flex",
            },
            "> button": {
              padding: "4px 12px",
              borderRadius: "20px",
              textTransform: "none",
            },
          }}
        >
          {props.goToDetail && (
            <Button onClick={props.goToDetail} variant="contained">
              {props.goToDetailLabel ?? "Go to detail"}
            </Button>
          )}
        </Box>
      )}
    </React.Fragment>
  );

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Container
        style={
          !isTouch
            ? {
                top: placement.top,
                left: placement.left,
              }
            : {}
        }
      >
        {content}
      </Container>
    </ClickAwayListener>
  );
}
