/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import { CSVLink } from "react-csv";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { exportPage } from "app/utils/exportPage";
import { useStoreState } from "app/state/store/hooks";
import { vizDataToCSV } from "app/utils/vizDataToCSV";
import { downloadActivitiesCSV } from "app/utils/downloadActivitiesCSV";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";

interface Props {
  cmsData: any;
  csvExportInfo?: {
    fileName: string;
    storeKey: string;
    headers: {
      label: string;
      key: string;
    }[];
  };
}

export function ExportTooltip(props: Props) {
  const location = useLocation();

  const state = useStoreState((state) => state);

  const isActivitiesPage = React.useMemo(() => {
    return (
      location.pathname ===
      `/${state.selectedLanguage.value as string}/activities`
    );
  }, [location.pathname, state.selectedLanguage.value]);

  function downloadCSV() {
    const temp = get(state, get(props, "csvExportInfo.storeKey", "-"), []);
    if (!props.csvExportInfo || temp.length === 0) {
      return {
        data: [],
        headers: [],
      };
    }
    const data: {
      [key: string]: any;
    }[] = [];

    temp.forEach((item: any) => {
      data.push(item);
      const subItems = [
        ...get(item, "children", []),
        ...get(item, "subItems", []),
      ];
      if (subItems) {
        subItems.forEach((subItem: any) => {
          data.push(subItem);
          const subSubItems = [
            ...get(subItem, "children", []),
            ...get(subItem, "subItems", []),
          ];
          if (subSubItems) {
            subSubItems.forEach((subSubItem: any) => {
              data.push(subSubItem);
            });
          }
        });
      }
    });

    return vizDataToCSV(data, props.csvExportInfo);
  }

  function downloadActivities() {
    let activitiesSearchKey;
    const searchInputEl = document.getElementById("search-input");
    if (searchInputEl) {
      activitiesSearchKey = (searchInputEl as HTMLInputElement).value;
    }
    const filters = getAPIFormattedFilters(state.appliedFilters);
    downloadActivitiesCSV(filters, undefined, activitiesSearchKey);
  }

  return (
    <Box
      sx={{
        gap: "20px",
        padding: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        background: colors.primary.gray,
        color: colors.secondary.midnightBlue,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {get(props.cmsData, "tooltips.download_button", "Download")}
      </Typography>
      <Box
        sx={{
          gap: "12px",
          display: "flex",
          flexDirection: "row",
          "> button, a": {
            width: "38px",
            height: "38px",
            display: "flex",
            fontSize: "16px",
            borderRadius: "50%",
            alignItems: "center",
            textDecoration: "none",
            justifyContent: "center",
            background: colors.secondary.midnightBlue,
            color: `${colors.primary.white} !important`,
            border: `1px solid ${colors.secondary.midnightBlue}`,
            "&:hover": {
              cursor: "pointer",
              background: colors.primary.white,
              color: `${colors.secondary.midnightBlue} !important`,
              border: `1px solid ${colors.secondary.midnightBlue}`,
            },
          },
        }}
      >
        {props.csvExportInfo && (
          <React.Fragment>
            {props.csvExportInfo && isActivitiesPage ? (
              <button onClick={downloadActivities}>CSV</button>
            ) : (
              <CSVLink target="_blank" id="download-csv" {...downloadCSV()}>
                CSV
              </CSVLink>
            )}
          </React.Fragment>
        )}
        <button
          aria-label="Download this view in png"
          onClick={() => exportPage("png", "#f8f8f8")}
        >
          PNG
        </button>
        <button
          aria-label="Download this view in pdf"
          onClick={() => exportPage("pdf", "#f8f8f8")}
        >
          PDF
        </button>
        <button
          aria-label="Download this view in svg"
          onClick={() => exportPage("svg", "#f8f8f8")}
        >
          SVG
        </button>
      </Box>
    </Box>
  );
}
