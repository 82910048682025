import React from "react";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import orderBy from "lodash/orderBy";
import Grid from "@mui/material/Grid";
import useMount from "react-use/lib/useMount";
import { MFATable } from "app/components/table";
import { useCMSData } from "app/hooks/useCMSData";
import { PageLoader } from "app/components/pageLoader";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { LegendPanel } from "app/pages/common/legendPanel";
import { useLocation, useNavigate } from "react-router-dom";
import { TreemapChart } from "app/components/charts/treemap";
import { TreemapDataItem } from "app/components/charts/treemap/data";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { LegendPanelItemProps } from "app/pages/common/legendPanel/data";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";

export function CountriesRegionsTabView() {
  const location = useLocation();
  const navigate = useNavigate();
  const cmsData = useCMSData({ returnData: true });

  const [selectedItem, setSelectedItem] = React.useState<{
    dataIndex: number;
    item: TreemapDataItem;
  } | null>(null);

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const fetchData = useStoreActions(
    (actions) => actions.countriesRegionsTreemap.fetch
  );
  const data = useStoreState(
    (state) =>
      get(
        state.countriesRegionsTreemap,
        "data.vizData",
        []
      ) as TreemapDataItem[]
  );
  const loading = useStoreState(
    (state) => state.countriesRegionsTreemap.loading
  );
  const vizOrTable = useStoreState((state) => state.vizOrTable.value);
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const chart = React.useMemo(() => {
    return (
      <TreemapChart
        data={data}
        dataType="countries"
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    );
  }, [data, selectedItem]);

  const totalValue = React.useMemo(() => {
    if (selectedItem) {
      return selectedItem.item.value;
    }
    return sumBy(data, (item) => item.value);
  }, [data, selectedItem]);

  const legendItems = React.useMemo(() => {
    let items = orderBy(data, "value", "desc");
    if (selectedItem) {
      items = orderBy(selectedItem.item.children, "value", "desc");
    }
    items.forEach((item: TreemapDataItem) => {
      if (item.children) {
        item.children = orderBy(item.children, "value", "desc");
        item.children.forEach((subItem: TreemapDataItem) => {
          if (subItem.children) {
            subItem.children = orderBy(subItem.children, "value", "desc");
            subItem.children.forEach((subSubItem: TreemapDataItem) => {
              if (subSubItem.children) {
                subSubItem.children = orderBy(
                  subSubItem.children,
                  "value",
                  "desc"
                );
              }
            });
          }
        });
      }
    });
    return items.map((item: any) => ({
      label: item[getNameFieldBasedOnLang(currentLanguage)],
      value: item.value,
      color: item.color,
      subItems: item.children?.map((subItem: any) => ({
        label: subItem[getNameFieldBasedOnLang(currentLanguage)],
        value: subItem.value,
        color: subItem.color,
        subItems: subItem.children?.map((subSubItem: any) => ({
          label: subSubItem[getNameFieldBasedOnLang(currentLanguage)],
          value: subSubItem.value,
          color: subSubItem.color,
        })),
      })),
    }));
  }, [data, selectedItem, currentLanguage]);

  const flattenData = React.useMemo(() => {
    let flatten: any[] = [];
    data.forEach((item) => {
      flatten.push(item);
      if (item.children) {
        item.children.forEach((child) => {
          flatten.push(child);
          if (child.children) {
            child.children.forEach((grandChild) => {
              flatten.push(grandChild);
              if (grandChild.children) {
                grandChild.children.forEach((grandGrandChild) => {
                  flatten.push(grandGrandChild);
                });
              }
            });
          }
        });
      }
    });
    return flatten;
  }, [data]);

  const tableData = React.useMemo(() => {
    return data.map((item: any) => ({
      name: item[getNameFieldBasedOnLang(currentLanguage)],
      value: item.value,
      commitment: item.commitment,
      children: item.children?.map((subItem: any) => ({
        name: {
          label: subItem[getNameFieldBasedOnLang(currentLanguage)],
          link: `/${currentLanguage}/countries/${subItem.ref}`,
        },
        value: subItem.value,
        commitment: subItem.commitment,
        children:
          subItem.children && subItem.children.length > 0
            ? subItem.children?.map((subSubItem: any) => ({
                name: {
                  label: subSubItem[getNameFieldBasedOnLang(currentLanguage)],
                  link: `/${currentLanguage}/countries/${subSubItem.ref}`,
                },
                value: subSubItem.value,
                commitment: subSubItem.commitment,
              }))
            : undefined,
      })),
    }));
  }, [data, currentLanguage]);

  const tableColumns = React.useMemo(() => {
    return [
      {
        id: "name",
        label: "Name",
      },
      {
        id: "value",
        label: get(cmsData, "viz.disbursementsamount", "Total disbursements"),
      },
      {
        id: "commitment",
        label: get(cmsData, "viz.commitmentsamount", "Total commitment"),
      },
    ];
  }, [cmsData?.viz]);

  function onLegendItemClick(item: LegendPanelItemProps) {
    const index = flattenData.findIndex((d) => d.name === item.label);
    if (index >= 0) {
      if (item.subItems && item.subItems.length > 0) {
        setSelectedItem({
          dataIndex: index,
          item: flattenData[index],
        });
      } else {
        navigate(
          `/${currentLanguage}/countries/${flattenData[index].ref}${location.search}`
        );
      }
    }
  }

  const view = React.useMemo(() => {
    return (
      <React.Fragment>
        <CanonicalUrl canonicalUrl="/countries-regions" />
        {loading && <PageLoader />}
        {vizOrTable === "table" && (
          <MFATable
            rows={tableData}
            defaultSortKey="value"
            columns={tableColumns}
          />
        )}
        {vizOrTable === "viz" && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              {chart}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <LegendPanel
                title={get(cmsData, "viz.budget", "Budget")}
                value={totalValue}
                items={legendItems}
                onItemClick={onLegendItemClick}
              />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }, [
    loading,
    vizOrTable,
    tableData,
    tableColumns,
    chart,
    selectedItem,
    totalValue,
    legendItems,
    onLegendItemClick,
  ]);

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    const filters = getAPIFormattedFilters(appliedFilters);
    fetchData({
      values: {
        filters,
      },
    });
  }, [appliedFilters]);

  return view;
}
