import React from "react";

export const CountriesRegionsIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none">
      <g filter="url(#filter0_i_7540_11179)">
        <rect
          y="0.5"
          width="52"
          height="52"
          rx="7"
          fill="url(#paint0_linear_7540_11179)"
        />
        <g clipPath="url(#clip0_7540_11179)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 26.5C12 18.768 18.268 12.5 26 12.5C33.732 12.5 40 18.768 40 26.5C40 34.232 33.732 40.5 26 40.5C18.268 40.5 12 34.232 12 26.5ZM15.1111 26.5C15.1111 25.1004 15.3752 23.7625 15.8562 22.5333C16.8752 23.4508 18.6999 24.9444 19.7778 24.9444H22.8889C24.4444 24.9444 25.2222 23.7778 26 22.6111C26.7778 21.4444 27.5556 20.2778 29.1111 20.2778C29.8889 20.2778 30.6667 19.5 30.6667 18.7222V16.659C34.3455 18.4066 36.8889 22.1563 36.8889 26.5C36.8889 28.8135 36.1674 30.9585 34.9372 32.7222H32.2222C31.3284 32.7222 30.9424 31.4896 30.5672 30.2913C30.2124 29.1581 29.8672 28.0555 29.1111 28.0555L22.8889 28.0556C20.084 28.0556 21.7141 30.4789 23.11 32.554C23.8064 33.5892 24.4444 34.5378 24.4444 35.0556V37.2786C19.1675 36.5238 15.1111 31.9856 15.1111 26.5Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_7540_11179"
          x="0"
          y="0.5"
          width="52"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7540_11179"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7540_11179"
          x1="52"
          y1="31.1667"
          x2="0"
          y2="0.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#709884" />
          <stop offset="1" stopColor="#CEDAD4" />
        </linearGradient>
        <clipPath id="clip0_7540_11179">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(12 12.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
