import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { formatLocale } from "app/utils/formatLocale";
import { useStoreState } from "app/state/store/hooks";
import { useNavigate, useLocation } from "react-router-dom";

interface TooltipProps {
  code: string;
  label: string;
  value: number;
  isLocked: boolean;
  lockUnlock: () => void;
}

export const GeomapChartTooltip = React.memo(function TooltipMemoized(
  props: TooltipProps
) {
  const location = useLocation();
  const navigate = useNavigate();
  const cmsData = useCMSData({ returnData: true });

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  return (
    <Box
      sx={{
        left: 20,
        bottom: 20,
        width: "286px",
        padding: "15px",
        position: "absolute",
        borderRadius: "14px",
        boxShadow: colors.shadows.main,
        background: colors.primary.white,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1">{props.label}</Typography>
        {props.isLocked && (
          <IconButton
            aria-label="close"
            onClick={props.lockUnlock}
            sx={{
              padding: "0",
              "@media (max-width: 768px)": {
                display: "none",
              },
            }}
          >
            <Close />
          </IconButton>
        )}
      </Box>
      <Typography
        fontSize="12px"
        sx={{
          marginBottom: "4px",
        }}
      >
        {get(cmsData, "viz.disbursed", "Disbursements")}
      </Typography>
      <Typography variant="h5">{formatLocale(props.value)}</Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: "32px",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={() =>
            navigate(
              `/${currentLanguage}/countries/${props.code}${location.search}`
            )
          }
          sx={{
            height: "25px",
            minWidth: "90px",
            fontSize: "14px",
            borderRadius: "19px",
            textTransform: "none",
            color: colors.primary.white,
            background: colors.primary.blue,
          }}
        >
          {get(cmsData, "viz.mapcountrypagebutton", "Country Page")}
        </Button>
      </Box>
    </Box>
  );
});
