import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import Search from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useCMSData } from "app/hooks/useCMSData";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useStoreState, useStoreActions } from "app/state/store/hooks";

const StyledInput = styled(InputBase)(() => ({
  input: {
    fontSize: 16,
    borderRadius: 32,
    color: colors.primary.blue,
    boxShadus: colors.shadows.inner,
    background: colors.secondary.grayLight,
    "&::placeholder": {
      color: colors.primary.blue,
      opacity: 1,
    },
  },
}));

interface Props {
  hideSearch?: boolean;
  clearAllFilters: () => void;
  selectAllFilters: () => void;
}

export function FiltersViewToolbar(props: Props) {
  const cmsData = useCMSData({ returnData: true });

  const checkboxRef = React.useRef<HTMLInputElement>(null);

  const value = useStoreState((state) => state.filterPanelSearch.value);
  const setValue = useStoreActions(
    (actions) => actions.filterPanelSearch.setValue
  );

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (checked) {
      props.selectAllFilters();
    } else {
      props.clearAllFilters();
    }
  };

  const onClearClick = () => {
    if (checkboxRef.current?.checked) {
      checkboxRef.current?.click();
    } else {
      props.clearAllFilters();
    }
  };

  return (
    <Box
      sx={{
        gap: "15px",
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        justifyContent: "flex-end",
        "@media (max-width: 768px)": {
          display: "block",
          "> button": {
            float: "right",
          },
        },
      }}
    >
      <FormControlLabel
        onChange={handleChange}
        control={<Checkbox inputRef={checkboxRef} />}
        label={get(cmsData, "filters.selectall", "Select all")}
        sx={{
          "@media (max-width: 768px)": {
            margin: 0,
            alignItems: "center",
            "> span": {
              fontSize: "14px",
            },
          },
        }}
      />
      <Button
        sx={{
          fontWeight: "400",
          textTransform: "none",
          "@media (max-width: 768px)": {
            fontSize: "14px",
          },
        }}
        startIcon={<Close fontSize="large" />}
        onClick={onClearClick}
      >
        {get(cmsData, "filters.clearselection", "Clear Selection")}
      </Button>
      {!props.hideSearch && (
        <StyledInput
          value={value}
          aria-label="Search in filters"
          inputProps={{ "aria-label": "Search in filters" }}
          placeholder={get(cmsData, "general.search", "Search")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          endAdornment={
            <Search
              sx={{
                right: "8px",
                position: "absolute",
                fill: colors.primary.blue,
              }}
            />
          }
          sx={{
            width: "200px",
            input: {
              padding: "5px 15px",
            },
            "@media (max-width: 768px)": {
              width: "100%",
              marginTop: "15px",
              marginBottom: "15px",
            },
          }}
        />
      )}
    </Box>
  );
}
