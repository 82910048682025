import React from "react";

export const ActivitiesIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none">
      <g filter="url(#filter0_i_7542_45370)">
        <rect y="0.566406" width="52" height="52" rx="7" fill="#E5F0F0" />
        <rect
          y="0.566406"
          width="52"
          height="52"
          rx="7"
          fill="url(#paint0_linear_7542_45370)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.39 26.4317C41.4155 26.5211 41.4284 26.6135 41.4284 26.7065V35.1378C41.4284 37.0314 39.8934 38.5664 37.9999 38.5664H13.9999C12.1063 38.5664 10.5713 37.0314 10.5713 35.1378V26.7065C10.5713 26.6135 10.5842 26.5211 10.6098 26.4317L13.7926 15.2917C13.9153 14.8624 14.3077 14.5664 14.7542 14.5664H37.2456C37.692 14.5664 38.0844 14.8624 38.2071 15.2917L41.39 26.4317ZM14.5013 25.2917C14.3187 25.9305 14.7984 26.5664 15.4628 26.5664H21.5713C22.1236 26.5664 22.5713 27.0141 22.5713 27.5664V28.2807C22.5713 30.1742 24.1063 31.7093 25.9999 31.7093C27.8934 31.7093 29.4284 30.1742 29.4284 28.2807V27.5664C29.4284 27.0141 29.8762 26.5664 30.4284 26.5664H36.5369C37.2013 26.5664 37.681 25.9305 37.4985 25.2917L35.6209 18.7203C35.4983 18.291 35.1059 17.995 34.6594 17.995H17.3403C16.8939 17.995 16.5015 18.291 16.3788 18.7203L14.5013 25.2917Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_7542_45370"
          x="0"
          y="0.566406"
          width="52"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7542_45370"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7542_45370"
          x1="52"
          y1="37.6732"
          x2="1.54564"
          y2="13.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BDA48E" />
          <stop offset="1" stopColor="#E9E0D7" />
        </linearGradient>
      </defs>
    </svg>
  );
};
