import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import { useStoreState } from "app/state/store/hooks";
import OpenInNew from "@mui/icons-material/OpenInNew";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as FooterLogo } from "app/assets/vectors/FooterLogo.svg";
import { ReactComponent as TwitterLogo } from "app/assets/vectors/TwitterLogo.svg";
import { ReactComponent as YouTubeLogo } from "app/assets/vectors/YouTubeLogo.svg";
import { ReactComponent as FacebookLogo } from "app/assets/vectors/FacebookLogo.svg";
import { ReactComponent as LinkedInLogo } from "app/assets/vectors/LinkedInLogo.svg";

/*
    Figma design component: https://www.figma.com/file/uZX8k1fv1MfzGTEBXuvjeV/MFA---Main-Design-2.0?type=design&node-id=5651-98972&mode=dev
*/

const FooterContainer = styled.footer`
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 180px;
  padding: 10px 0;
  position: absolute;
  border-radius: 30px 30px 0px 0px;
  background: ${colors.primary.gray};
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.05) inset;
`;

const FooterLink = (props: { page: string; label: string }) => {
  const { page, label } = props;
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  return (
    <Link
      component={NavLink}
      sx={{
        textDecoration: "none",
        color: colors.primary.blue,
        "&:hover": {
          textDecoration: "underline",
        },
        "@media (max-width: 920px)": {
          fontSize: "14px",
        },
      }}
      to={`/${currentLanguage === "se" ? "sv" : currentLanguage}/${page}`}
    >
      <Typography variant="body2">{label}</Typography>
    </Link>
  );
};

const SOCIAL_MEDIA_LINKS = [
  {
    icon: <FacebookLogo />,
    arialLabel: "Go to our Facebook",
    cmsAccessKey: "menu.facebooklink",
    cmsFallback: "https://www.facebook.com",
  },
  {
    icon: <LinkedInLogo />,
    arialLabel: "Go to our LinkedIn",
    cmsAccessKey: "menu.linkedinlink",
    cmsFallback: "https://www.linkedin.com",
  },
  {
    icon: <TwitterLogo />,
    arialLabel: "Go to our Twitter",
    cmsAccessKey: "menu.twitterlink",
    cmsFallback: "https://www.twitter.com",
  },
  {
    icon: <YouTubeLogo />,
    arialLabel: "Go to our YouTube",
    cmsAccessKey: "menu.youtubelink",
    cmsFallback: "https://www.youtube.com",
  },
];

export function Footer() {
  const cmsData = useCMSData({ returnData: true });

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "180px",
        }}
      />
      <FooterContainer>
        <Container
          maxWidth="lg"
          disableGutters
          sx={{
            height: "180px",
            "@media (max-width: 1280px)": {
              padding: "15px 48px",
            },
            "@media (max-width: 768px)": {
              padding: "15px 24px",
            },
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              height: "100%",
              "> div": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <Grid
              item
              xs={4}
              sm={3}
              md={3}
              lg={2}
              xl={2}
              sx={{
                "@media (max-width: 768px)": {
                  "> svg": {
                    transform: "scale(0.7)",
                  },
                },
              }}
            >
              <FooterLogo />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={2}
              xl={2}
              sx={{
                "@media (max-width: 768px)": {
                  display: "none !important",
                },
              }}
            >
              <Typography variant="h6">
                {get(cmsData, "menu.information", "Information")}
              </Typography>
              <FooterLink
                page="feedback"
                label={get(cmsData, "menu.feedback", "Feedback")}
              />
              <FooterLink
                page="about"
                label={get(cmsData, "menu.about", "About the site")}
              />
              <FooterLink
                page="data"
                label={get(cmsData, "menu.result", "About the data")}
              />
              <FooterLink
                page="accessibility"
                label={get(cmsData, "menu.statements", "Accessibility")}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3} lg={6} xl={6}>
              <Typography variant="h6">
                {get(cmsData, "menu.links", "Links")}
              </Typography>
              <Box
                sx={{
                  gap: "20px",
                  display: "flex",
                  flexDirection: "row",
                  "@media (max-width: 920px)": {
                    gap: "0px",
                    marginLeft: "-5px",
                    a: {
                      transform: "scale(0.9)",
                    },
                  },
                }}
              >
                {SOCIAL_MEDIA_LINKS.map((link) => (
                  <IconButton
                    target="_blank"
                    LinkComponent={Link}
                    key={link.cmsFallback}
                    rel="noopener noreferrer"
                    aria-label={link.arialLabel}
                    href={get(cmsData, link.cmsAccessKey, link.cmsFallback)}
                  >
                    {link.icon}
                  </IconButton>
                ))}
              </Box>
              <Link
                target="_blank"
                component={Link}
                rel="noopener noreferrer"
                href={`https://${get(cmsData, "menu.website", "um.fi")}`}
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                  "@media (max-width: 920px)": {
                    fontSize: "14px",
                  },
                  "@media (max-width: 860px)": {
                    fontSize: "12px",
                  },
                  "@media (max-width: 768px)": {
                    marginBottom: "-5px",
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  Ministry of Foreign Affairs of Finland
                  <OpenInNew
                    sx={{
                      width: "15px",
                      height: "15px",
                      marginLeft: "5px",
                      "@media (max-width: 920px)": {
                        width: "12px",
                        height: "12px",
                      },
                    }}
                  />
                </Typography>
              </Link>
              <Box sx={{ height: "22px" }} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={3}
              lg={2}
              xl={2}
              sx={{
                alignItems: "flex-end",
                "@media (max-width: 768px)": {
                  display: "none !important",
                },
              }}
            >
              <Button
                variant="text"
                aria-label="back to top"
                endIcon={<KeyboardArrowUp />}
                sx={{
                  fontWeight: "700",
                  textTransform: "none",
                  color: colors.primary.blue,
                  "@media (max-width: 920px)": {
                    fontSize: "10px",
                  },
                }}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {get(cmsData, "menu.backtotop", "Back to top")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </FooterContainer>
    </React.Fragment>
  );
}
