// base
import { createStore, persist } from "easy-peasy";
import { StoreModel } from "app/state/api/interfaces";
// global search actions-reducers
import searchDonors from "app/state/api/action-reducers/global-search/donors";
import searchSectors from "app/state/api/action-reducers/global-search/sectors";
import searchCountries from "app/state/api/action-reducers/global-search/countries";
import searchActivities from "app/state/api/action-reducers/global-search/activities";
import searchOrganisations from "app/state/api/action-reducers/global-search/organisations";
import searchThematicareas from "app/state/api/action-reducers/global-search/thematicareas";
// sync variables
import {
  accessibilitySwitch,
  filterPanelSearch,
  isSearchFocused,
  selectedLanguage,
  tabViewTableSearch,
  vizOrTable,
} from "app/state/api/action-reducers/sync";
// cms
import general from "app/state/api/action-reducers/cms/general";
import tooltips from "app/state/api/action-reducers/cms/tooltips";
import regions from "app/state/api/action-reducers/cms/regions";
import viz from "app/state/api/action-reducers/cms/viz";
import filters from "app/state/api/action-reducers/cms/filters";
import menu from "app/state/api/action-reducers/cms/menu";
import pages from "app/state/api/action-reducers/cms/pages";
import priorityAreas from "app/state/api/action-reducers/cms/priorityareas";
import breadcrumbs from "app/state/api/action-reducers/cms/breadcrumbs";
import pageSections from "app/state/api/action-reducers/cms/pagesections";
import vizTabs from "app/state/api/action-reducers/cms/viztabs";
import {
  aboutPage,
  feedbackPage,
  resultsPage,
  statementsPage,
} from "app/state/api/action-reducers/cms/collections";
import {
  formattedData,
  formattedCollections,
} from "app/state/api/action-reducers/cms/formatted";
// filtering
import {
  locations,
  sectors as fsectors,
  thematicareas as fthematicareas,
  organisations as forganisations,
  sdgs as fsdgs,
  activitystatus,
  policymarkers,
  aidtypes,
  budgetlines,
  organisations,
} from "app/state/api/action-reducers/filterOptions";
import { appliedFilters } from "app/state/api/action-reducers/sync/appliedFilters";
// viz
import organisationSankey, {
  organisationSankeyLocation,
  organisationSankeyPriority,
  organisationSankeySector,
} from "app/state/api/action-reducers/viz/organisationSankey";
import prioritiesChart, {
  prioritiesChartLocation,
  prioritiesChartSector,
} from "app/state/api/action-reducers/viz/prioritiesChart";
import top5sectors, {
  top5sectorsLocation,
  top5sectorsPriority,
} from "app/state/api/action-reducers/viz/top5sectors";
import sdgsViz, {
  sdgsLocation,
  sdgsPriority,
  sdgsSector,
} from "app/state/api/action-reducers/viz/sdgs";
import geoChart, {
  geoChartPriority,
  geoChartSector,
} from "app/state/api/action-reducers/viz/geoChart";
import top10activities, {
  top10activitiesLocation,
  top10activitiesPriority,
  top10activitiesSector,
} from "app/state/api/action-reducers/viz/top10activities";
import disbursementsOverviewSankey, {
  disbursementsOverviewSankeyLocation,
  disbursementsOverviewSankeyPriority,
  disbursementsOverviewSankeySector,
} from "../api/action-reducers/viz/disbursementsOverviewSankey";
import disbursementsOverview, {
  disbursementsOverviewLocation,
} from "../api/action-reducers/viz/disbursementsOverview";
import sectorsSunburst from "../api/action-reducers/viz/sectorsSunburst";
import countriesRegionsTreemap from "../api/action-reducers/viz/countriesRegionsTreemap";
import organisationsTreemap from "../api/action-reducers/viz/organisationsTreemap";
import activities from "../api/action-reducers/viz/activties";
import totalValue from "../api/action-reducers/viz/totalValue";
import priorityBarchart from "../api/action-reducers/viz/priorityBarchart";
import {
  legacylocationPageInfo,
  locationPageInfo,
} from "../api/action-reducers/viz/locationPage";
import { sectorPageInfo } from "../api/action-reducers/viz/sectorPage";
import { organisationPageInfo } from "../api/action-reducers/viz/organisationPage";
import {
  activityPage,
  relatedActvities,
} from "../api/action-reducers/viz/activityPage";

const storeContent: StoreModel = {
  // filtering
  filterOptions: {
    locations: persist(locations, {
      storage: "localStorage",
    }),
    sectors: persist(fsectors, {
      storage: "localStorage",
    }),
    thematicareas: persist(fthematicareas, {
      storage: "localStorage",
    }),
    organisations: persist(forganisations, {
      storage: "localStorage",
    }),
    sdgs: persist(fsdgs, {
      storage: "localStorage",
    }),
    activitystatus: persist(activitystatus, {
      storage: "localStorage",
    }),
    policymarkers: persist(policymarkers, {
      storage: "localStorage",
    }),
    aidtypes: persist(aidtypes, {
      storage: "localStorage",
    }),
    budgetlines: persist(budgetlines, {
      storage: "localStorage",
    }),
  },
  // viz
  disbursementsOverviewSankey: persist(disbursementsOverviewSankey),
  organisationSankey: persist(organisationSankey),
  prioritiesChart: persist(prioritiesChart),
  top5sectors: persist(top5sectors),
  sdgs: persist(sdgsViz),
  geoChart: persist(geoChart),
  top10activities: persist(top10activities),
  disbursementsOverview: persist(disbursementsOverview),
  sectorsSunburst: persist(sectorsSunburst),
  countriesRegionsTreemap: persist(countriesRegionsTreemap),
  organisationsTreemap: persist(organisationsTreemap),
  activities: persist(activities),
  totalValue: persist(totalValue),
  priorityBarchart: persist(priorityBarchart),
  disbursementsOverviewSankeyPriority: persist(
    disbursementsOverviewSankeyPriority
  ),
  top5sectorsPriority: persist(top5sectorsPriority),
  geoChartPriority: persist(geoChartPriority),
  geoChartSector: persist(geoChartSector),
  organisationSankeyPriority: persist(organisationSankeyPriority),
  sdgsPriority: persist(sdgsPriority),
  top10activitiesPriority: persist(top10activitiesPriority),
  locationPageInfo: persist(locationPageInfo),
  legacylocationPageInfo: persist(legacylocationPageInfo),
  disbursementsOverviewLocation: persist(disbursementsOverviewLocation),
  disbursementsOverviewSankeyLocation: persist(
    disbursementsOverviewSankeyLocation
  ),
  disbursementsOverviewSankeySector: persist(disbursementsOverviewSankeySector),
  prioritiesChartLocation: persist(prioritiesChartLocation),
  sectorPageInfo: persist(sectorPageInfo),
  prioritiesChartSector: persist(prioritiesChartSector),
  top5sectorsLocation: persist(top5sectorsLocation),
  organisationSankeyLocation: persist(organisationSankeyLocation),
  organisationSankeySector: persist(organisationSankeySector),
  sdgsLocation: persist(sdgsLocation),
  sdgsSector: persist(sdgsSector),
  top10activitiesLocation: persist(top10activitiesLocation),
  top10activitiesSector: persist(top10activitiesSector),
  organisationPageInfo: persist(organisationPageInfo),
  activityPage: persist(activityPage),
  relatedActvities: persist(relatedActvities),
  // global search
  searchDonors,
  searchSectors,
  searchCountries,
  searchActivities,
  searchOrganisations,
  searchThematicareas,
  // sync
  selectedLanguage: persist(selectedLanguage),
  isSearchFocused,
  filterPanelSearch,
  vizOrTable,
  tabViewTableSearch,
  accessibilitySwitch: persist(accessibilitySwitch),
  appliedFilters,
  // cms
  cms: {
    general: persist(general),
    viz: persist(viz),
    filters: persist(filters),
    menu: persist(menu),
    pages: persist(pages),
    tooltips: persist(tooltips),
    regions: persist(regions),
    priorityAreas: persist(priorityAreas),
    breadcrumbs: persist(breadcrumbs),
    pageSections: persist(pageSections),
    vizTabs: persist(vizTabs),
    collections: {
      aboutPage: persist(aboutPage),
      feedbackPage: persist(feedbackPage),
      statementsPage: persist(statementsPage),
      resultsPage: persist(resultsPage),
    },
    formattedCollections: persist(formattedCollections),
    formattedData: persist(formattedData),
  },
};

export const store = createStore(storeContent);
