import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import { InfoButton } from "app/components/infobutton";
import ArrowForward from "@mui/icons-material/ArrowForward";

interface Props {
  label: string;
  value: number;
  percentage: number;
  icon: React.ReactNode;
  link?: string;
  info: string;
}

export function StatBox(props: Props) {
  const location = useLocation();

  return (
    <Box
      sx={{
        gap: "15px",
        width: "100%",
        height: "116px",
        display: "flex",
        padding: "20px",
        flexDirection: "row",
        borderRadius: "20px",
        alignItems: "flex-start",
        boxShadow: colors.shadows.inner,
        background: colors.primary.white,
        "@media (max-width: 920px)": {
          height: "auto",
          minHeight: "116px",
          h6: {
            lineHeight: "1.5",
          },
        },
      }}
    >
      {props.icon}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            gap: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {props.link ? (
            <Link to={`${props.link}${location.search}`}>
              <Typography variant="subtitle1" fontWeight="700">
                {props.label}
              </Typography>
            </Link>
          ) : (
            <Typography variant="subtitle1" fontWeight="700">
              {props.label}
            </Typography>
          )}
          <InfoButton fontSize="small" text={props.info} />
        </Box>
        <Typography variant="subtitle1" fontWeight={500}>
          {props.value}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "flex",
            marginTop: "10px",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {props.percentage.toFixed(2)}% from last year
          <ArrowForward
            fontSize="small"
            sx={{
              transform: `rotate(${props.percentage < 0 ? 45 : -45}deg)`,
              fill:
                props.percentage < 0
                  ? colors.graph.coral[800]
                  : colors.graph.green[800],
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}
