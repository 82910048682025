import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { styled, SxProps, Theme } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    leaveDelay={100}
    placement="bottom"
    enterTouchDelay={0}
    aria-pressed="false"
    disableFocusListener
    disableHoverListener
    disableTouchListener
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "15px",
    fontSize: "14px",
    borderRadius: "15px",
    boxShadow: theme.shadows[1],
    color: colors.secondary.midnightBlue,
    backgroundColor: colors.primary.gray,
    "> div": {
      borderRadius: "15px",
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.primary.gray,
  },
}));

interface Props {
  text?: string;
  sx?: SxProps<Theme>;
  fontSize?: "inherit" | "medium" | "small" | "large";
}

export function InfoButton(props: Props) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={{ display: "flex", cursor: "pointer" }}>
        <LightTooltip
          open={open}
          title={props.text ?? ""}
          onClose={handleTooltipClose}
        >
          <InfoOutlined
            sx={props.sx}
            fontSize={props.fontSize}
            onClick={handleTooltipOpen}
          />
        </LightTooltip>
      </Box>
    </ClickAwayListener>
  );
}
