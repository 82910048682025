import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { hexToRGBA } from "app/utils/hexToRGBA";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { lightenDarkenColor } from "app/utils/lightenDarkenColor";
import { formatFinancialValue } from "app/utils/formatFinancialValue";
import { Link as RouteLink, useLocation, useNavigate } from "react-router-dom";
import { PriorityBlocksTooltip } from "app/components/charts/tooltips/priority-blocks";
import { TooltipTouchContainer } from "app/components/charts/tooltips/common/touchcontainer";

interface Props {
  items: {
    name: string;
    code: string;
    color: string;
    value: number;
    commitment: number;
    subItems: { area: string; name: string; value: number }[];
  }[];
}

const BackgroundCircles = (props: { color: string }) => (
  <Box
    sx={{
      top: 80,
      zIndex: 1,
      left: "-50%",
      width: "200%",
      height: "200%",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",
      div: {
        borderRadius: "50%",
      },
      "@media (max-width: 920px)": {
        top: 60,
        left: 0,
        width: "100%",
        height: "120%",
      },
      "@media (max-width: 768px)": {
        top: 40,
        left: 0,
        width: "100%",
        height: "200%",
      },
    }}
  >
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: hexToRGBA(props.color, 0.1),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "calc(100% - 80px)",
          height: "calc(100% - 80px)",
          background: hexToRGBA(props.color, 0.2),
          "@media (max-width: 920px)": {
            width: "calc(100% - 40px)",
            height: "calc(100% - 40px)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "calc(100% - 80px)",
            height: "calc(100% - 80px)",
            background: hexToRGBA(props.color, 0.3),
            "@media (max-width: 920px)": {
              width: "calc(100% - 40px)",
              height: "calc(100% - 40px)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "calc(100% - 80px)",
              height: "calc(100% - 80px)",
              background: hexToRGBA(props.color, 0.4),
              "@media (max-width: 920px)": {
                width: "calc(100% - 40px)",
                height: "calc(100% - 40px)",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  </Box>
);

export function PrioritiesChart(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const isTouch = useMediaQuery("(hover: none)");
  const cmsData = useCMSData({ returnData: true });

  const [hoveredPriority, setHoveredPriority] = React.useState<string | null>(
    null
  );

  function onPriorityHover(value: string | null) {
    setHoveredPriority(value);
  }

  const tooltipInfo = React.useMemo(() => {
    if (!hoveredPriority) {
      return null;
    }

    const item = props.items.find((item) => item.code === hoveredPriority);

    if (!item) {
      return null;
    }

    return {
      title: get(
        cmsData,
        `priorityAreas.${item.code.replace(/ /g, "")}`,
        item.name
      ),
      color: item.color,
      value: formatFinancialValue(item.value),
      commitment: formatFinancialValue(item.commitment),
      subItems: item.subItems.map((subItem) => ({
        name: get(
          cmsData,
          `priorityAreas.${
            subItem.name.indexOf("primary") > -1 ? "main" : "secondary"
          }`,
          subItem.name
        ),
        value: (subItem.value * 100) / item.value,
      })),
    };
  }, [hoveredPriority]);

  const tooltipContent = React.useMemo(() => {
    return (
      <PriorityBlocksTooltip
        hoveredNode={hoveredPriority}
        title={get(tooltipInfo, "title", "")}
        value={get(tooltipInfo, "value", "")}
        color={get(tooltipInfo, "color", "")}
        onClose={() => onPriorityHover(null)}
        containerId="priorities-chart-container"
        subItems={get(tooltipInfo, "subItems", [])}
        commitment={get(tooltipInfo, "commitment", "")}
        goToDetailLabel={get(cmsData, "viz.goToDetail", "Go to detail")}
        goToDetail={
          isTouch
            ? () => navigate(`/priorities/${hoveredPriority}${location.search}`)
            : undefined
        }
      />
    );
  }, [tooltipInfo, hoveredPriority, onPriorityHover, isTouch]);

  return (
    <Box
      id="priorities-chart-container"
      sx={{
        gap: "15px",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
        "> a": {
          flexGrow: 1,
          flexBasis: 0,
          textDecoration: "none",
          "> div": {
            display: "flex",
            height: "289px",
            overflow: "hidden",
            position: "relative",
            padding: "20px 25px",
            borderRadius: "20px",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: colors.shadows.inner,
            background: colors.primary.white,
            "@media (max-width: 920px)": {
              width: "100%",
              height: "135px",
              padding: "16px",
            },
          },
        },
      }}
    >
      {props.items.map((item) => (
        <Link
          key={item.code}
          component={RouteLink}
          onMouseLeave={() => onPriorityHover(null)}
          onMouseEnter={() => onPriorityHover(item.code)}
          to={`/priorities/${item.code}${location.search}`}
          onClick={
            isTouch
              ? (e) => {
                  e.preventDefault();
                  onPriorityHover(item.code);
                }
              : undefined
          }
          sx={{
            "*": {
              color: lightenDarkenColor(item.color, -79),
            },
          }}
        >
          <Box>
            <BackgroundCircles color={item.color} />
            <Typography
              zIndex={2}
              variant="body1"
              textAlign="start"
              sx={{
                textDecoration: "underline",
                "@media (max-width: 920px)": {
                  fontSize: "12px",
                  fontWeight: "700",
                },
                "@media (max-width: 768px)": {
                  fontSize: "16px",
                },
              }}
            >
              {get(
                cmsData,
                `priorityAreas.${item.code.replace(/ /g, "")}`,
                item.name
              )}
            </Typography>
            <Typography
              zIndex={2}
              variant="h4"
              fontWeight="700"
              textAlign="center"
              sx={{
                "@media (max-width: 920px)": {
                  fontSize: "22px",
                },
                "@media (max-width: 768px)": {
                  fontSize: "24px",
                },
              }}
            >
              {formatFinancialValue(item.value)}
            </Typography>
          </Box>
        </Link>
      ))}
      {isTouch && hoveredPriority && (
        <TooltipTouchContainer>{tooltipContent}</TooltipTouchContainer>
      )}
      {!isTouch && tooltipContent}
    </Box>
  );
}
