import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

const top5sectors: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-5-sectors`),
};

export default top5sectors;

export const top5sectorsPriority: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-5-sectors`),
};

export const top5sectorsLocation: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-5-sectors`),
};
