export const AppNameENG =
  "OpenAid.fi | Databank on Finland's development cooperation";
export const AppNameFIN = "OpenAid.fi | Kehitysyhteistyön tietopankki";
export const AppNameSWE = "OpenAid.fi | Data om Finlands utvecklingssamarbete";

export function getAppName(currentLanguage: string) {
  if (currentLanguage === "se") return AppNameSWE;
  if (currentLanguage === "en") return AppNameENG;
  return AppNameFIN;
}
