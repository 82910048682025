import React from "react";
import get from "lodash/get";
import find from "lodash/find";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as echarts from "echarts/core";
import { PieSeriesOption } from "echarts";
import Button from "@mui/material/Button";
import useTitle from "react-use/lib/useTitle";
import { useMediaQuery } from "@mui/material";
import useMount from "react-use/lib/useMount";
import { hexToRGBA } from "app/utils/hexToRGBA";
import { SVGRenderer } from "echarts/renderers";
import Typography from "@mui/material/Typography";
import { getAppName } from "app/utils/getAppName";
import { useCMSData } from "app/hooks/useCMSData";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { formatLocale } from "app/utils/formatLocale";
import { PageSection } from "app/pages/common/section";
import { SdgsChart } from "app/components/charts/sdgs";
import { InfoButton } from "app/components/infobutton";
import { Breadcrumbs } from "app/components/breadcrumb";
import { getCMSContent } from "app/utils/getCMSContent";
import { SDGsIcon } from "app/assets/section-icons/SDGs";
import { onEchartResize } from "app/utils/onEchartResize";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { SankeyChart } from "app/components/charts/sankey";
import { GeomapChart } from "app/components/charts/geomap";
import { BarSeriesOption, PieChart } from "echarts/charts";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { SectorsChart } from "app/components/charts/sectors";
import { SectorsIcon } from "app/assets/section-icons/Sectors";
import { FloatingButtons } from "app/components/floatingButtons";
import { ActivitiesChart } from "app/components/charts/activities";
import { PrioritiesIcon } from "app/assets/section-icons/Priorities";
import { ActivitiesIcon } from "app/assets/section-icons/Activities";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import { OrganisationsIcon } from "app/assets/section-icons/Organisations";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { CountriesRegionsIcon } from "app/assets/section-icons/CountriesRegions";
import { DisbursementsOverviewIcon } from "app/assets/section-icons/DisbursementsOverview";
import { pageChartTooltipCommonConfig } from "app/components/charts/tooltips/common/config";
import {
  getRange,
  getFinancialValueWithMetricPrefix,
} from "app/utils/getFinancialValueWithMetricPrefix";
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
  LegendComponentOption,
  TooltipComponentOption,
  GridComponentOption,
} from "echarts/components";

echarts.use([
  LegendComponent,
  TooltipComponent,
  PieChart,
  SVGRenderer,
  GridComponent,
]);

const PriorityPageIntroBlock = () => {
  const params = useParams<{ id: string }>();
  const cmsData = useCMSData({ returnData: true });
  const mobile = useMediaQuery("(max-width: 768px)");

  const pieContainerRef = React.useRef<HTMLDivElement>(null);
  const barContainerRef = React.useRef<HTMLDivElement>(null);

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const dataPriorities = useStoreState(
    (state) =>
      get(state.prioritiesChart, "data.vizData", []) as {
        code: string;
        name: string;
        value: number;
        color: string;
        subItems: {
          code: string;
          name: string;
          value: number;
          color: string;
        }[];
      }[]
  );
  const fetchPriorities = useStoreActions(
    (actions) => actions.prioritiesChart.fetch
  );

  const dataBarChart = useStoreState(
    (state) =>
      get(state.priorityBarchart, "data.vizData", []) as {
        name: string;
        value: number;
        main: number;
        secondary: number;
      }[]
  );
  const fetchBarChart = useStoreActions(
    (actions) => actions.priorityBarchart.fetch
  );

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const priorityData = React.useMemo(() => {
    return find(dataPriorities, { code: params.id });
  }, [dataPriorities, params.id]);

  const priorityResultsText = React.useMemo(() => {
    switch (params.id) {
      case "Priority area 1":
        return get(
          cmsData,
          "general.thematicareas_detail_strengthening_of_rights",
          ""
        );
      case "Priority area 2":
        return get(cmsData, "general.thematicareas_detail_generating_jobs", "");
      case "Priority area 3":
        return get(
          cmsData,
          "general.thematicareas_detail_improving_democracy",
          ""
        );
      case "Priority area 4":
        return get(
          cmsData,
          "general.thematicareas_detail_improving_food_security",
          ""
        );
      default:
        return "";
    }
  }, [params.id]);

  const priorityMainColor = React.useMemo(() => {
    return priorityData?.color;
  }, [priorityData]);

  const prioritySecondaryColor = React.useMemo(() => {
    return hexToRGBA(priorityData?.color ?? "", 0.5);
  }, [priorityData]);

  React.useEffect(() => {
    const filters = getAPIFormattedFilters({
      ...appliedFilters,
      tag: params.id
        ? [`${params.id}| primary`, `${params.id}| secondary`]
        : [],
    });
    if (!priorityData && params.id) {
      fetchPriorities({
        values: {
          filters,
        },
      });
    }
    if (params.id) {
      fetchBarChart({
        values: {
          filters,
          id: params.id,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (pieContainerRef.current) {
      const chart = echarts.init(pieContainerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<
        PieSeriesOption | TooltipComponentOption | LegendComponentOption
      > = {
        series: {
          top: 0,
          left: 0,
          right: 0,
          bottom: !mobile ? 0 : 20,
          type: "pie",
          radius: "70%",
          data: get(priorityData, "subItems", []).map((item) => {
            if (item.name.includes("primary")) {
              return {
                name: get(cmsData, "priorityAreas.main", "Main priority"),
                value: item.value,
                itemStyle: {
                  color: priorityMainColor,
                },
              };
            }
            return {
              name: get(
                cmsData,
                "priorityAreas.secondary",
                "Secondary priority"
              ),
              value: item.value,
              itemStyle: {
                color: prioritySecondaryColor,
              },
            };
          }),
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
        },
        legend: {
          bottom: 0,
          itemGap: 40,
          itemWidth: 14,
          icon: "circle",
          orient: "horizontal",
          textStyle: {
            fontSize: 14,
            fontFamily: "Finlandica",
            color: colors.primary.blue,
          },
        },
        tooltip: {
          ...pageChartTooltipCommonConfig(mobile),
          valueFormatter: (value) => {
            return formatLocale(value as number);
          },
        },
      };

      chart.setOption(option);
    }
    if (barContainerRef.current) {
      const chart = echarts.init(barContainerRef.current, undefined, {
        renderer: "svg",
      });

      const range = getRange(
        dataBarChart,
        ["main", "secondary"],
        currentLanguage
      );
      const option: echarts.ComposeOption<
        BarSeriesOption | GridComponentOption | LegendComponentOption
      > = {
        xAxis: [
          {
            type: "category",
            data: dataBarChart.map((item) => item.name),
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontSize: "14px",
              fontFamily: "Finlandica",
              color: colors.primary.blue,
            },
            axisLabel: {
              fontSize: 14,
              fontFamily: "Finlandica",
              color: colors.primary.blue,
            },
            axisLine: {
              lineStyle: {
                width: 1,
                color: colors.secondary.grayLight,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: range.abbr,
            position: "left",
            alignTicks: true,
            nameTextStyle: {
              fontSize: "14px",
              fontFamily: "Finlandica",
              color: colors.primary.blue,
            },
            axisLabel: {
              fontSize: 14,
              fontFamily: "Finlandica",
              color: colors.primary.blue,
              formatter: (value: number) => {
                return getFinancialValueWithMetricPrefix(value, range.index);
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        grid: {
          top: 50,
          left: !mobile ? 50 : 40,
          right: !mobile ? 50 : 20,
          bottom: 70,
          borderWidth: 1,
          borderColor: colors.secondary.grayLight,
        },
        series: [
          {
            data: dataBarChart.map((item) => item.main),
            type: "bar",
            name: range.abbr,
            stack: "total",
            barWidth: !mobile ? 45 : 15,
            yAxisIndex: 0,
            itemStyle: {
              color: priorityMainColor,
              borderRadius: [0, 0, 5, 5],
            },
            emphasis: {
              disabled: true,
            },
          },
          {
            data: dataBarChart.map((item) => item.secondary),
            type: "bar",
            name: range.abbr,
            stack: "total",
            barWidth: !mobile ? 45 : 15,
            yAxisIndex: 0,
            itemStyle: {
              borderRadius: [5, 5, 0, 0],
              color: prioritySecondaryColor,
            },
            emphasis: {
              disabled: true,
            },
          },
        ],
      };

      if (barContainerRef.current) {
        new ResizeObserver(() =>
          onEchartResize(
            // @ts-ignore
            chart,
            "bar-chart",
            barContainerRef.current?.clientHeight
          )
        ).observe(barContainerRef?.current);
      }

      chart.setOption(option);
    }
  }, [
    mobile,
    pieContainerRef.current,
    barContainerRef.current,
    priorityData,
    dataBarChart,
  ]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "20px",
        marginBottom: "50px",
        boxShadow: colors.shadows.inner,
        background: colors.primary.gray,
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={7}
          xl={8}
          sx={{
            gap: "15px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2">
            {get(
              cmsData,
              "general.thematicareas_detail_title",
              "Priority area info"
            )}
          </Typography>
          <Typography variant="body2">{priorityResultsText}</Typography>
          <Typography variant="subtitle2">
            {get(cmsData, "general.result", "Result")}
          </Typography>
          <Typography variant="body2" component="div">
            <Box
              className="no-margin"
              sx={{
                a: {
                  color: colors.primary.blue,
                  textDecoration: "underline",
                },
                p: {
                  margin: "0 !important",
                },
              }}
              dangerouslySetInnerHTML={{
                __html: getCMSContent(cmsData, "pages.home_result"),
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={5} xl={4}>
          <Box
            ref={pieContainerRef}
            sx={{
              width: "100%",
              height: "100%",
              padding: "20px",
              minHeight: "250px",
              borderRadius: "20px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
              "@media (max-width: 768px)": {
                height: "200px",
              },
            }}
          >
            <Box
              sx={{
                gap: "5px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1">Priority Type</Typography>
              <InfoButton
                fontSize="small"
                text={get(
                  cmsData,
                  "tooltips.priority_page_priority_type",
                  "Activity can have primary or secondary priority area. One activity can have maximum one primary and none to three secondary priorities."
                )}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: "20px",
              borderRadius: "20px",
              position: "relative",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Box
              sx={{
                gap: "5px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1">
                Disbursements Overview
              </Typography>
              <InfoButton
                fontSize="small"
                text={get(
                  cmsData,
                  "tooltips.priority_page_disbursements_overview",
                  ""
                )}
              />
            </Box>
            <Typography variant="body2">
              Disbursements overview bar graph showing disbursed amount
              throughout years of this prioritised area.
            </Typography>
            <Box
              id="bar-chart"
              ref={barContainerRef}
              sx={{
                width: "100%",
                height: "310px",
                "@media (max-width: 768px)": {
                  height: "340px",
                },
              }}
            />
            <Box
              sx={{
                gap: "20px",
                left: "20px",
                bottom: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                position: "absolute",
                "> div": {
                  gap: "5px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: priorityMainColor,
                  }}
                />
                <Typography variant="body2">Main priority</Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: prioritySecondaryColor,
                  }}
                />
                <Typography variant="body2">Secondary priority</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "20px",
              borderRadius: "20px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Typography variant="subtitle1">News</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "20px",
              borderRadius: "20px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Typography variant="subtitle1">Results Stories</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export function PriorityPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const cmsData = useCMSData({ returnData: true });
  const tablet = useMediaQuery("(max-width: 920px)");
  const mobile = useMediaQuery("(max-width: 768px)");

  const selectedLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );
  const fetchDisbursementsOverviewSankey = useStoreActions(
    (actions) => actions.disbursementsOverviewSankeyPriority.fetch
  );
  const dataDisbursementsOverviewSankey = useStoreState((state) =>
    get(state.disbursementsOverviewSankeyPriority, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingDisbursementsOverviewSankey = useStoreState(
    (state) => state.disbursementsOverviewSankeyPriority.loading
  );
  const fetchTop5Sectors = useStoreActions(
    (actions) => actions.top5sectorsPriority.fetch
  );
  const dataTop5Sectors = useStoreState((state) =>
    get(state.top5sectorsPriority, "data.vizData", [])
  );
  const loadingTop5Sectors = useStoreState(
    (state) => state.top5sectorsPriority.loading
  );
  const fetchGeoChart = useStoreActions(
    (actions) => actions.geoChartPriority.fetch
  );
  const dataGeoChart = useStoreState((state) => {
    if (!state.geoChart.data) {
      return {
        vizData: [],
        total: 0,
        totalDisbursements: 0,
        unallocablePercentage: 0,
      };
    }
    return {
      vizData: get(state.geoChartPriority, "data.vizData", []),
      total: get(state.geoChartPriority, "data.total", 0),
      totalDisbursements: get(
        state.geoChartPriority,
        "data.totalDisbursements",
        0
      ),
      unallocablePercentage: get(
        state.geoChartPriority,
        "data.unallocablePercentage",
        0
      ),
    };
  });
  const loadingGeoChart = useStoreState(
    (state) => state.geoChartPriority.loading
  );
  const fetchOrganisationSankey = useStoreActions(
    (actions) => actions.organisationSankeyPriority.fetch
  );
  const dataOrganisationSankey = useStoreState((state) =>
    get(state.organisationSankeyPriority, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingOrganisationSankey = useStoreState(
    (state) => state.organisationSankeyPriority.loading
  );
  const fetchSDGs = useStoreActions((actions) => actions.sdgsPriority.fetch);
  const dataSDGs = useStoreState((state) =>
    get(state.sdgsPriority, "data.vizData", [])
  );
  const loadingSDGs = useStoreState((state) => state.sdgsPriority.loading);
  const fetchTop10Activities = useStoreActions(
    (actions) => actions.top10activitiesPriority.fetch
  );
  const dataTop10Activities = useStoreState((state) =>
    get(state.top10activitiesPriority, "data.vizData", [])
  );
  const loadingTop10Activities = useStoreState(
    (state) => state.top10activitiesPriority.loading
  );
  const fetchTotalValue = useStoreActions(
    (actions) => actions.totalValue.fetch
  );

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const dataPriorities = useStoreState(
    (state) =>
      get(state.prioritiesChart, "data.vizData", []) as {
        code: string;
        name: string;
        value: number;
        color: string;
        subItems: {
          code: string;
          name: string;
          value: number;
          color: string;
        }[];
      }[]
  );

  const priorityData = React.useMemo(() => {
    return find(dataPriorities, { code: params.id });
  }, [dataPriorities, params.id]);

  useTitle(
    `${get(priorityData, "name", params.id)} | ${getAppName(selectedLanguage)}`
  );

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    const filters = getAPIFormattedFilters({
      ...appliedFilters,
      tag: params.id
        ? [`${params.id}| primary`, `${params.id}| secondary`]
        : [],
    });
    fetchTotalValue({
      values: {
        filters,
      },
    });
    fetchDisbursementsOverviewSankey({
      values: {
        filters,
        type: "sector-region",
      },
    });
    fetchTop5Sectors({
      values: {
        filters,
      },
    });
    fetchGeoChart({
      values: {
        filters,
      },
    });
    fetchOrganisationSankey({
      values: {
        filters,
      },
    });
    fetchSDGs({
      values: {
        filters,
      },
    });
    fetchTop10Activities({
      values: {
        filters,
      },
    });
  }, [params.id, appliedFilters]);

  const header = React.useMemo(() => {
    const breadcrumbs = (
      <Breadcrumbs
        route={[
          {
            label: "Homepage",
            path: "/:lang",
            cmsKey: "breadcrumbs.homepage",
          },
          {
            label: "Priorities",
            path: "/:lang/priorities",
            cmsKey: "breadcrumbs.thematic",
          },
          {
            label: get(priorityData, "name", params.id ?? ""),
          },
        ]}
      />
    );

    const title = (
      <Box
        sx={{
          gap: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "@media (max-width: 920px)": {
            marginBottom: "20px",
          },
        }}
      >
        <Box>
          <PrioritiesIcon />
        </Box>
        <Box>
          <Box
            sx={{
              gap: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              {get(priorityData, "name", params.id)}
            </Typography>
            <InfoButton
              text={get(cmsData, "tooltips.priority_title", "")}
              fontSize="small"
              sx={{
                color: colors.secondary.grayDark,
                "@media (max-width: 920px)": {
                  display: "none",
                },
              }}
            />
          </Box>
          <Typography component="div">
            Showcasing {get(priorityData, "name", params.id)}, ones of the
            prioritized areas of Finnish ODA.
          </Typography>
        </Box>
      </Box>
    );

    const toolbar = (
      <Box
        sx={{
          gap: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          "@media (max-width: 768px)": {
            width: "100%",
            justifyContent: "space-between",
          },
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBack fontSize="small" />}
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            padding: "4px 8px",
            textTransform: "none",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          {get(cmsData, "general.back", "Back")}
        </Button>
        {!mobile ? (
          <FloatingButtons noFloating />
        ) : (
          <Box>
            <FloatingButtons noFloating />
          </Box>
        )}
      </Box>
    );

    const spacer = <Box sx={{ height: "20px" }} />;

    if (!tablet && !mobile) {
      return (
        <React.Fragment>
          {breadcrumbs}
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              flexDirection: "row",
              marginBottom: "50px",
              justifyContent: "space-between",
            }}
          >
            {title}
            {toolbar}
          </Box>
        </React.Fragment>
      );
    } else if (mobile) {
      return (
        <React.Fragment>
          {breadcrumbs}
          {spacer}
          {toolbar}
          {spacer}
          {title}
        </React.Fragment>
      );
    } else if (tablet) {
      return (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              flexDirection: "row",
              marginBottom: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {breadcrumbs}
            {toolbar}
          </Box>
          {title}
        </React.Fragment>
      );
    }
  }, [tablet, mobile, cmsData, params.id, priorityData]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl={`/priorities/${params.id}`} />
      <Box
        sx={{
          "@media (max-width: 920px)": {
            padding: "0 48px",
          },
          "@media (max-width: 768px)": {
            padding: "0 24px",
          },
        }}
      >
        {header}
        <PriorityPageIntroBlock />
        <PageSection
          background="#F6F9FD"
          loading={loadingDisbursementsOverviewSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.PriorityPageDisbursementsOverviewTitle",
              "Disbursements Overview"
            ),
            subtitle: get(
              cmsData,
              "pageSections.PriorityPageDisbursementsOverviewSubtitle",
              "Disbursements overview of this priority through high level sectors and 10 biggest partner regions."
            ),
            info: get(cmsData, "tooltips.disbursements_overview", ""),
            icon: <DisbursementsOverviewIcon />,
          }}
        >
          <SankeyChart
            {...dataDisbursementsOverviewSankey}
            levelTitles={[
              get(cmsData, "general.thematicarea", "Priority"),
              get(cmsData, "general.sectors", "Sectors"),
              get(cmsData, "general.regions", "Regions"),
            ]}
          />
        </PageSection>
        <PageSection
          background="#F9F9FC"
          loading={loadingTop5Sectors}
          header={{
            title: get(cmsData, "pageSections.SectorsTitle", "Sectors"),
            subtitle: get(
              cmsData,
              "pageSections.SectorsSubtitle",
              "The 5 Largest Sectors of Finnish ODA in a descending order."
            ),
            info: get(cmsData, "tooltips.sectors", ""),
            icon: <SectorsIcon />,
          }}
          sx={{ overflow: "visible" }}
        >
          <SectorsChart items={dataTop5Sectors} />
        </PageSection>
        <PageSection
          background="#F9FBFA"
          loading={loadingGeoChart}
          header={{
            title: get(
              cmsData,
              "pageSections.CountriesRegionsTitle",
              "Countries & Regions"
            ),
            subtitle: get(
              cmsData,
              "pageSections.CountriesRegionsSubtitle",
              "Partner countries of Finnish ODA."
            ),
            info: get(cmsData, "tooltips.countries_regions", ""),
            icon: <CountriesRegionsIcon />,
          }}
        >
          <GeomapChart
            data={dataGeoChart.vizData}
            unallocable={dataGeoChart.unallocablePercentage}
          />
        </PageSection>
        <PageSection
          background="#FFFCF7"
          loading={loadingOrganisationSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.OrganisationsTitle",
              "Organisations"
            ),
            subtitle: get(
              cmsData,
              "pageSections.OrganisationsSubtitle",
              "5 partner organisations of Finnish ODA with the largest budget."
            ),
            info: get(cmsData, "tooltips.organisations", ""),
            icon: <OrganisationsIcon />,
          }}
        >
          <SankeyChart organisation {...dataOrganisationSankey} />
        </PageSection>
        <PageSection
          background="#F9FCFE"
          loading={loadingSDGs}
          header={{
            title: get(cmsData, "pageSections.SDGsTitle", "SDGs"),
            subtitle: get(
              cmsData,
              "pageSections.SDGsSubtitle",
              "SDGs listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.sdg", ""),
            icon: <SDGsIcon />,
          }}
        >
          <SdgsChart items={dataSDGs} />
        </PageSection>
        <PageSection
          background="#FCFBF9"
          loading={loadingTop10Activities}
          header={{
            title: get(cmsData, "pageSections.ActivitiesTitle", "Activities"),
            subtitle: get(
              cmsData,
              "pageSections.ActivitiesSubtitle",
              "Top 10 largest activities listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.activities", ""),
            icon: <ActivitiesIcon />,
          }}
        >
          <Link
            id="see-all-activities-comp"
            to={`/${selectedLanguage}/activities${
              location.search.length > 0 ? `${location.search}&` : "?"
            }tag.narrative=${params.id}| primary,${params.id}| secondary`}
          >
            {get(cmsData, "viz.seeallactivities", "See All Activities")}{" "}
            <ArrowForward fontSize="small" />
          </Link>
          <ActivitiesChart items={dataTop10Activities} />
        </PageSection>
      </Box>
    </React.Fragment>
  );
}
