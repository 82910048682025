import React from "react";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import orderBy from "lodash/orderBy";
import reverse from "lodash/reverse";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import useMount from "react-use/lib/useMount";
import { MFATable } from "app/components/table";
import Typography from "@mui/material/Typography";
import { useCMSData } from "app/hooks/useCMSData";
import { PageLoader } from "app/components/pageLoader";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { LegendPanel } from "app/pages/common/legendPanel";
import { BarLineChart } from "app/components/charts/barline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { LegendPanelItemProps } from "app/pages/common/legendPanel/data";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import {
  BarLineDataItem,
  budgetLinesColorsIndexing,
  prioritiesColorsIndexing,
} from "app/components/charts/barline/data";

interface OptionModel {
  label: string;
  value: "oda" | "budgetlines" | "priorities" | "sectors";
}

export function DisbursementsOverviewTabView() {
  const cmsData = useCMSData({ returnData: true });
  const [selectedOption, setSelectedOption] = React.useState<OptionModel>({
    value: "oda",
    label: "Total ODA",
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const fetchData = useStoreActions(
    (actions) => actions.disbursementsOverview.fetch
  );
  const data = useStoreState(
    (state) =>
      get(state.disbursementsOverview, "data.vizData", []) as BarLineDataItem[]
  );
  const loading = useStoreState((state) => state.disbursementsOverview.loading);
  const vizOrTable = useStoreState((state) => state.vizOrTable.value);

  const handleOptionClick =
    (option: OptionModel) => (e: React.MouseEvent<HTMLButtonElement>) => {
      setSelectedOption(option);
      setAnchorEl(null);
    };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleButtonClose = () => {
    setAnchorEl(null);
  };

  const filtersApplied = React.useMemo(() => {
    return (
      appliedFilters.countries.length +
        appliedFilters.regions.length +
        appliedFilters.sectors.length +
        appliedFilters.tag.length +
        appliedFilters.organisationtypes.length +
        appliedFilters.organisations.length +
        appliedFilters.sdg.length +
        appliedFilters.policymarker.length +
        appliedFilters.defaultaidtype.length +
        appliedFilters.budgetlines.length +
        appliedFilters.collaborationtype.length +
        appliedFilters.humanrights.length +
        appliedFilters.years.length >
      0
    );
  }, [appliedFilters]);

  const chart = React.useMemo(() => {
    return <BarLineChart data={data} dataVariant={selectedOption.value} />;
  }, [selectedOption.value, data]);

  const totalValue = React.useMemo(() => {
    return sumBy(data, (item) => item.value);
  }, [data]);

  const exclusiveOdaLabel = React.useMemo(() => {
    return get(cmsData, "viz.exclusiveoda", "Exclusive ODA");
  }, [cmsData]);

  const otherOdaLabel = React.useMemo(() => {
    return get(cmsData, "viz.otheroda", "Other ODA");
  }, [cmsData]);

  const yearLabel = React.useMemo(() => {
    return get(cmsData, "viz.year", "Years");
  }, [cmsData]);

  const disbursementsLabel = React.useMemo(() => {
    return get(cmsData, "viz.disbursementsamount", "Total disbursements");
  }, [cmsData]);

  const commitmentLabel = React.useMemo(() => {
    return get(cmsData, "viz.commitmentsamount", "Total commitment");
  }, [cmsData]);

  const sectorsLabel = React.useMemo(() => {
    return get(cmsData, "general.sectors", "Sectors");
  }, [cmsData]);

  const prioritiesLabel = React.useMemo(() => {
    return get(cmsData, "general.thematicareas", "Priorities");
  }, [cmsData]);

  const budgetlinesLabel = React.useMemo(() => {
    return get(cmsData, "general.budgetlines", "Budget lines");
  }, [cmsData]);

  const legendItems = React.useMemo(() => {
    const items = [...data];
    reverse(items);
    switch (selectedOption.value) {
      case "oda":
        return items.map((item: BarLineDataItem) => {
          const subItems: LegendPanelItemProps[] = [
            {
              label: exclusiveOdaLabel,
              value: item.exclusive ?? 0,
              color: colors.graph.green[500],
            },
            {
              label: otherOdaLabel,
              value: item.other ?? 0,
              color: colors.secondary.midnightBlue,
            },
          ];
          if (!filtersApplied && item.gni && item.gni > 0) {
            subItems.push({
              label: "ODA/GNI",
              value: `${item.gni}%`,
              color: colors.graph.coral[500],
              noFormat: true,
            });
          }
          return {
            label: item.name,
            value: item.value,
            subItems,
          };
        });
      case "budgetlines":
        return items.map((item: BarLineDataItem) => {
          const subItems: {
            label: string;
            value: number;
            color?: string;
          }[] = [];
          if (item.subItems) {
            const keys = Object.keys(item.subItems);
            keys.forEach((key, index) => {
              subItems.push({
                label: get(item, `subItems["${key}"].name`, ""),
                value: get(item, `subItems["${key}"].value`, 0),
                color: get(budgetLinesColorsIndexing, `[${index}]`, undefined),
              });
            });
          }
          return {
            label: item.name,
            value: item.value,
            subItems: orderBy(subItems, "value", "desc"),
          };
        });
      case "sectors":
        return items.map((item: BarLineDataItem) => {
          const subItems: {
            label: string;
            value: number;
            color?: string;
          }[] = [];
          if (item.subItems) {
            const keys = Object.keys(item.subItems);
            keys.forEach((key) => {
              subItems.push({
                label: get(item, `subItems["${key}"].name`, ""),
                value: get(item, `subItems["${key}"].value`, 0),
                color: get(
                  item,
                  `subItems["${key}"].itemStyle.color`,
                  undefined
                ),
              });
            });
          }
          return {
            label: item.name,
            value: item.value,
            subItems: orderBy(subItems, "value", "desc"),
          };
        });
      case "priorities":
        return items.map((item: BarLineDataItem) => {
          const subItems: {
            label: string;
            value: number;
            color?: string;
          }[] = [];
          if (item.subItems) {
            const keys = Object.keys(item.subItems);
            keys.forEach((key, index) => {
              const name = get(item, `subItems["${key}"].name`, "");
              subItems.push({
                label: get(
                  cmsData,
                  `priorityAreas.${name.replace(/ /g, "")}`,
                  name
                ),
                value: get(item, `subItems["${key}"].value`, 0),
                color: get(prioritiesColorsIndexing, `[${index}]`, undefined),
              });
            });
          }
          return {
            label: item.name,
            value: item.value,
            subItems: orderBy(subItems, "value", "desc"),
          };
        });
      default:
        return data.map((item) => ({
          label: item.name,
          value: item.value,
          color: get(item, "itemStyle.color", undefined),
        }));
    }
  }, [data, exclusiveOdaLabel, otherOdaLabel]);

  const tableColumns = React.useMemo(() => {
    switch (selectedOption.value) {
      case "oda":
        return [
          {
            id: "name",
            label: yearLabel,
          },
          {
            id: "exclusive",
            label: exclusiveOdaLabel,
          },
          {
            id: "other",
            label: otherOdaLabel,
          },
          {
            id: "gni",
            label: "ODA/GNI",
          },
          {
            id: "value",
            label: disbursementsLabel,
          },
        ];
      case "budgetlines":
        return [
          {
            id: "name",
            label: yearLabel,
          },
          {
            id: "budgetline",
            label: budgetlinesLabel,
          },
          {
            id: "value",
            label: disbursementsLabel,
          },
          {
            id: "commitment",
            label: commitmentLabel,
          },
        ];
      case "priorities":
        return [
          {
            id: "name",
            label: yearLabel,
          },
          {
            id: "priority",
            label: prioritiesLabel,
          },
          {
            id: "value",
            label: disbursementsLabel,
          },
          {
            id: "commitment",
            label: commitmentLabel,
          },
        ];
      case "sectors":
        return [
          {
            id: "name",
            label: yearLabel,
          },
          {
            id: "sector",
            label: sectorsLabel,
          },
          {
            id: "value",
            label: disbursementsLabel,
          },
          {
            id: "commitment",
            label: commitmentLabel,
          },
        ];
      default:
        return [];
    }
  }, [
    selectedOption.value,
    exclusiveOdaLabel,
    otherOdaLabel,
    yearLabel,
    disbursementsLabel,
    commitmentLabel,
    sectorsLabel,
    prioritiesLabel,
    budgetlinesLabel,
  ]);

  const tableData = React.useMemo(() => {
    switch (selectedOption.value) {
      case "oda":
        return data.map((item: BarLineDataItem) => ({
          name: item.name,
          exclusive: item.exclusive ?? 0,
          other: item.other ?? 0,
          gni: `${(item.gni ?? 0).toString()}%`,
          value: item.value,
        }));
      case "budgetlines":
        return data.map((item: BarLineDataItem) => {
          const subItems: {
            value: number;
            budgetline: string;
            commitment: number;
          }[] = [];
          if (item.subItems) {
            const keys = Object.keys(item.subItems);
            keys.forEach((key) => {
              subItems.push({
                value: get(item, `subItems["${key}"].value`, 0),
                budgetline: get(item, `subItems["${key}"].name`, ""),
                commitment: get(item, `subItems["${key}"].commitment`, 0),
              });
            });
          }
          return {
            name: item.name.toString(),
            value: item.value,
            commitment: item.commitment,
            children: orderBy(subItems, "value", "desc"),
          };
        });
      case "priorities":
        return data.map((item: BarLineDataItem) => {
          const subItems: {
            value: number;
            priority: string;
            commitment: number;
          }[] = [];
          if (item.subItems) {
            const keys = Object.keys(item.subItems);
            keys.forEach((key) => {
              const name = get(item, `subItems["${key}"].name`, "");
              subItems.push({
                value: get(item, `subItems["${key}"].value`, 0),
                priority: get(
                  cmsData,
                  `priorityAreas.${name.replace(/ /g, "")}`,
                  name
                ),
                commitment: get(item, `subItems["${key}"].commitment`, 0),
              });
            });
          }
          return {
            name: item.name.toString(),
            value: item.value,
            commitment: item.commitment,
            children: orderBy(subItems, "value", "desc"),
          };
        });
      case "sectors":
        return data.map((item: BarLineDataItem) => {
          const subItems: {
            value: number;
            sector: string;
            commitment: number;
          }[] = [];
          if (item.subItems) {
            const keys = Object.keys(item.subItems);
            keys.forEach((key) => {
              subItems.push({
                value: get(item, `subItems["${key}"].value`, 0),
                sector: get(item, `subItems["${key}"].name`, ""),
                commitment: get(item, `subItems["${key}"].commitment`, 0),
              });
            });
          }
          return {
            name: item.name.toString(),
            value: item.value,
            commitment: item.commitment,
            children: orderBy(subItems, "value", "desc"),
          };
        });
      default:
        return [];
    }
  }, [selectedOption.value, data]);

  const view = React.useMemo(() => {
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "options-popover" : undefined;
    return (
      <React.Fragment>
        <CanonicalUrl canonicalUrl="/disbursements-overview" />
        {loading && <PageLoader />}
        {vizOrTable === "table" && (
          <MFATable columns={tableColumns} rows={tableData} />
        )}
        {vizOrTable === "viz" && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 768px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {get(
                    cmsData,
                    "vizTabs.DisbursementsOverviewSelectorBefore",
                    "Showcasing disbursements overview of"
                  )}
                </Typography>
                <Button
                  sx={{
                    margin: "0 16px",
                    fontSize: "14px",
                    padding: "7px 18px",
                    lineHeight: "normal",
                    borderRadius: "21px",
                    textTransform: "none",
                    boxShadow: colors.shadows.main,
                    background: colors.primary.white,
                    "@media (max-width: 768px)": {
                      fontSize: "12px",
                      margin: "0 12px",
                      padding: "4px 6px",
                    },
                  }}
                  onClick={handleButtonClick}
                >
                  {selectedOption.label} <ArrowDropDownIcon fontSize="small" />
                </Button>
                <Popover
                  id={id}
                  anchorEl={anchorEl}
                  open={openPopover}
                  onClose={handleButtonClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box
                    sx={{
                      width: "337px",
                      padding: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        padding: "0 12px",
                        alignItems: "center",
                        borderBottom: `1px solid ${colors.secondary.grayLight}`,
                      }}
                    >
                      <Typography variant="body2">
                        {get(
                          cmsData,
                          "vizTabs.DisbursementsOverviewSelectorBefore",
                          "Showcasing disbursements overview of"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        padding: "4px 0",
                        flexDirection: "column",
                        "> button": {
                          fontWeight: "400",
                          padding: "6px 14px",
                          textTransform: "none",
                          justifyContent: "flex-start",
                        },
                      }}
                    >
                      <Button
                        onClick={handleOptionClick({
                          label: "Total ODA",
                          value: "oda",
                        })}
                      >
                        Total ODA
                      </Button>
                      <Button
                        onClick={handleOptionClick({
                          label: "Budget Lines",
                          value: "budgetlines",
                        })}
                      >
                        Budget Lines
                      </Button>
                      <Button
                        onClick={handleOptionClick({
                          label: "Priorities",
                          value: "priorities",
                        })}
                      >
                        Priorities
                      </Button>
                      <Button
                        onClick={handleOptionClick({
                          label: "Sectors",
                          value: "sectors",
                        })}
                      >
                        Sectors
                      </Button>
                    </Box>
                  </Box>
                </Popover>
                <Typography
                  variant="body2"
                  sx={{
                    "@media (max-width: 768px)": {
                      display: "none",
                    },
                  }}
                >
                  {get(
                    cmsData,
                    "vizTabs.DisbursementsOverviewSelectorAfter",
                    "throughout the years."
                  )}
                </Typography>
              </Box>
              {selectedOption.value === "oda" && (
                <Box
                  sx={{
                    gap: "12px",
                    width: "100%",
                    display: "flex",
                    marginTop: "16px",
                    flexDirection: "row",
                    marginBottom: "-38px",
                    justifyContent: "center",
                    "> div": {
                      gap: "6px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      "> div": {
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                      },
                    },
                  }}
                >
                  <Box>
                    <Box bgcolor={colors.graph.green[500]} />{" "}
                    <Typography variant="body2" component="span">
                      {exclusiveOdaLabel}
                    </Typography>
                  </Box>
                  <Box>
                    <Box bgcolor={colors.secondary.midnightBlue} />{" "}
                    <Typography variant="body2" component="span">
                      {otherOdaLabel}
                    </Typography>
                  </Box>
                  {!filtersApplied && (
                    <Box>
                      <Box bgcolor={colors.graph.coral[500]} /> ODA/GNI
                    </Box>
                  )}
                </Box>
              )}
              {chart}
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <LegendPanel
                title={get(cmsData, "viz.budget", "Budget")}
                value={totalValue}
                items={legendItems}
              />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }, [
    loading,
    anchorEl,
    vizOrTable,
    tableData,
    tableColumns,
    chart,
    totalValue,
    legendItems,
    exclusiveOdaLabel,
    otherOdaLabel,
    selectedOption,
  ]);

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    const filters = getAPIFormattedFilters(appliedFilters);
    fetchData({
      values: {
        filters,
        type: selectedOption.value,
      },
    });
  }, [selectedOption.value, appliedFilters]);

  return view;
}
