export const LANGUAGES = [
  {
    code: "en",
    label: "English",
  },
  {
    code: "fi",
    label: "Suomi",
  },
  {
    code: "sv",
    label: "Svenska",
  },
];

export const PAGES = [
  {
    label: "Data Summary",
    link: "/",
    cmsKey: "pageSections.DataSummary",
  },
  {
    label: "Disbursements Overview",
    link: "/disbursements-overview",
    cmsKey: "pageSections.DisbursementsOverviewTitle",
  },
  {
    label: "Priorities",
    link: "/priorities",
    cmsKey: "pageSections.PrioritiesTitle",
  },
  {
    label: "Sectors",
    link: "/sectors",
    cmsKey: "pageSections.SectorsTitle",
  },
  {
    label: "Countries & Regions",
    link: "/countries-regions",
    cmsKey: "pageSections.CountriesRegionsTitle",
  },
  {
    label: "Organisations",
    link: "/organisations",
    cmsKey: "pageSections.OrganisationsTitle",
  },
  {
    label: "Activities",
    link: "/activities",
    cmsKey: "pageSections.ActivitiesTitle",
  },
  {
    label: "divider-1",
    divider: true,
  },
  {
    label: "About this site",
    link: "/about",
    cmsKey: "menu.aboutthesite",
  },
  {
    label: "About Open Aid",
    link: "/data",
    cmsKey: "menu.aboutthedata",
  },
  {
    label: "divider-2",
    divider: true,
  },
  {
    label: "Feedback",
    link: "/feedback",
    cmsKey: "menu.feedback",
  },
  {
    label: "Accessibility",
    link: "/accessibility",
    cmsKey: "menu.accessibility",
  },
];
