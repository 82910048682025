import React from "react";
import Box from "@mui/material/Box";
import { Header } from "app/components/header";
import { Footer } from "app/components/footer";
import { useStoreState } from "app/state/store/hooks";
import { Outlet, useLocation } from "react-router-dom";
import { DataSummaryBar } from "app/components/dataSummaryBar";

export function Page() {
  const location = useLocation();

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const isHomepage = React.useMemo(() => {
    const pathSplits = location.pathname.split("/");
    if (pathSplits.length > 3) {
      return false;
    }
    if (pathSplits.length === 3) {
      return (
        pathSplits[0] === "" &&
        pathSplits[1] === currentLanguage &&
        pathSplits[2] === ""
      );
    }
    return pathSplits.length === 2 && pathSplits[1] === currentLanguage;
  }, [location.pathname]);

  const hideDataSummaryBar = React.useMemo(() => {
    return (
      location.pathname.includes("/data") ||
      location.pathname.includes("/about") ||
      location.pathname.includes("/feedback") ||
      location.pathname.includes("/accessibility")
    );
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Header />
      <Box
        sx={{
          paddingTop: isHomepage ? "242px" : "76px",
        }}
      >
        {!hideDataSummaryBar && <DataSummaryBar />}
        <Box id="main">
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </React.Fragment>
  );
}
