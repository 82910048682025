import { get, uniq } from "lodash";
import { pathIds } from "./api";
import exp from "constants";

export const getActivitiesPaths = async () => {
  const response = await pathIds.activities();
  const activitiesPath = get(response.data, "response.docs");
  return activitiesPath
    .map(
      (name: { "iati-identifier": string }) =>
        `${get(name, "iati-identifier", "")}`
    )
    .filter((activity: string) => activity);
};

export const getOrganisationPaths = async () => {
  const response = await pathIds.organizations();
  const organisationPath = get(response.data, "response.docs");

  return uniq(
    organisationPath.map(
      (name: { "participating-org.ref": string }) =>
        `${name["participating-org.ref"][1]}`
    )
  ).filter((organisation) => organisation);
};

export const getCountryPaths = async () => {
  const response = await pathIds.countries();
  const countryPath = get(response.data, "response.docs");
  return uniq(
    countryPath.map(
      (name: { "recipient-country.code": string }) =>
        `${get(name, "recipient-country.code", [""])[0]}`
    )
  ).filter((country) => country);
};

export const getSectorsPaths = async () => {
  const response = await pathIds.sectors();
  const sectorPath = get(response.data, "response.docs");
  return uniq(
    sectorPath.map(
      (name: { "sector.code": string }) =>
        `${get(name, "sector.code", [""])[0]}`
    )
  ).filter((sector) => sector);
};

export const getPrioritiesPaths = async () => {
  const response = await pathIds.priorities();
  const priorityPath = get(response.data, "response.docs");
  return uniq(
    priorityPath
      .map((name: { "tag.code": string }) => get(name, "tag.code", [""]))
      .flat(1)
  )
    .filter((priority: any) => priority?.startsWith("Priority"))
    .map((priority: any) => priority.split(",")[0]);
};
