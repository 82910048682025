import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import { formatLocale } from "app/utils/formatLocale";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { PageSection } from "app/pages/common/section";
import { InfoButton } from "app/components/infobutton";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { YEAR_HIGH, YEAR_LOW } from "app/components/filters/view/views/period";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    leaveDelay={100}
    placement="bottom"
    enterTouchDelay={0}
    aria-pressed="false"
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "16px",
    padding: "13px 16px",
    borderRadius: "15px",
    boxShadow: theme.shadows[1],
    color: colors.secondary.midnightBlue,
    backgroundColor: colors.primary.white,
    "> div": {
      borderRadius: "15px",
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.primary.white,
  },
}));

export function HomePageODA() {
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });

  const [selectedYear, setSelectedYear] = React.useState<null | number>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );
  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const setAppliedFilters = useStoreActions(
    (actions) => actions.appliedFilters.setYears
  );
  const totalValue = useStoreState((state) =>
    get(state.totalValue, "data.value", 0)
  );
  const fetchTotalValue = useStoreActions(
    (actions) => actions.totalValue.fetch
  );
  const appliedFromYear = useStoreState((state) =>
    get(state.appliedFilters.years, "[0]", null)
  );
  const appliedToYear = useStoreState((state) =>
    get(state.appliedFilters.years, "[1]", null)
  );
  const accessibilitySwitch = useStoreState(
    (state) => state.accessibilitySwitch.value
  );

  const years = React.useMemo(() => {
    const t = [];
    for (let i = YEAR_LOW; i <= YEAR_HIGH; i++) {
      t.push(i);
    }
    return t;
  }, []);

  const handleYearFilterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleYearFilterClose = () => {
    setAnchorEl(null);
  };

  const buttonLabel = React.useMemo(() => {
    if (appliedFromYear && appliedToYear) {
      if (appliedFromYear === appliedToYear) {
        return appliedFromYear;
      }
      return `${appliedFromYear} - ${appliedToYear}`;
    }
    return selectedYear ?? "All Years";
  }, [selectedYear, appliedFromYear, appliedToYear]);

  React.useEffect(() => {
    const onScroll = () => setAnchorEl(null);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  React.useEffect(() => {
    let filters = appliedFilters;
    if (selectedYear) {
      filters = {
        ...appliedFilters,
        years: [selectedYear.toString(), selectedYear.toString()],
      };
    }
    filters = getAPIFormattedFilters(filters);
    fetchTotalValue({
      values: {
        filters,
      },
    });
  }, [selectedYear, appliedFilters]);

  React.useEffect(() => {
    if (appliedFilters.years.length === 0) {
      setSelectedYear(null);
    } else {
      const year = parseInt(appliedFilters.years[0]);
      setSelectedYear(year);
    }
  }, [appliedFilters.years]);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "oda-year-filter-popover" : undefined;

  return (
    <PageSection
      background={colors.primary.gray}
      sx={{
        "@media (max-width: 920px)": {
          padding: "12px 16px",
        },
        "@media (max-width: 768px)": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          fontSize={accessibilitySwitch ? "22px" : "24px"}
          sx={{
            "@media (max-width: 920px)": {
              fontSize: "16px",
            },
          }}
        >
          {get(
            cmsData,
            "general.homesummarybar",
            "Reported Open Development Assistance of Finnish Aid for"
          )}
        </Typography>
        <LightTooltip
          title={get(cmsData, "tooltips.changeyear", "Change the Year")}
        >
          <Button
            sx={{
              margin: "0 4px",
              fontSize: "24px",
              padding: "7px 18px",
              lineHeight: "normal",
              borderRadius: "21px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
              "@media (max-width: 920px)": {
                fontSize: "18px",
              },
            }}
            onClick={handleYearFilterClick}
          >
            {buttonLabel}
          </Button>
        </LightTooltip>
        <Popover
          id={id}
          disableScrollLock
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleYearFilterClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              width: "375px",
              padding: "8px",
              height: "268px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "40px",
                display: "flex",
                padding: "0 12px",
                alignItems: "center",
                borderBottom: `1px solid ${colors.secondary.grayLight}`,
              }}
            >
              <Typography>
                Showcasing {!selectedYear ? "all " : ""}the year
                {!selectedYear ? "s" : ` ${selectedYear}`}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                padding: "4px 12px",
              }}
            >
              <Grid
                container
                rowSpacing={2}
                sx={{
                  "> div > button": {
                    padding: "10px",
                    cursor: "pointer",
                    fontWeight: "700",
                    borderStyle: "none",
                    borderRadius: "31px",
                    "&:hover": {
                      color: colors.primary.blue + " !important",
                      background: colors.secondary.blueGrayDark + " !important",
                    },
                  },
                }}
              >
                {years.map((year) => (
                  <Grid key={year} item xs={3}>
                    <button
                      key={year}
                      onClick={() => {
                        if (selectedYear === year) {
                          setSelectedYear(null);
                          setAppliedFilters([]);
                          return;
                        }
                        setSelectedYear(year);
                        setAppliedFilters([`${year},${year}`]);
                      }}
                      style={{
                        color:
                          selectedYear === year
                            ? colors.primary.white
                            : colors.primary.blue,
                        background:
                          selectedYear === year
                            ? colors.primary.blue
                            : colors.primary.white,
                      }}
                    >
                      {year}
                    </button>
                  </Grid>
                ))}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  marginTop: "15px",
                  flexDirection: "row",
                  "> a": {
                    color: colors.primary.blue,
                    "> svg": {
                      marginLeft: "10px",
                      marginBottom: "-4px",
                    },
                  },
                }}
              >
                <Link
                  to={`/${currentLanguage}/disbursements-overview${location.search}`}
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <Typography variant="body1">
                    Explore the Total ODA of Past Years
                  </Typography>
                  <OpenInNew fontSize="small" />
                </Link>
              </Box>
            </Box>
          </Box>
        </Popover>
        <Typography
          variant="h5"
          fontSize={accessibilitySwitch ? "22px" : "24px"}
          sx={{
            "@media (max-width: 920px)": {
              fontSize: "18px",
            },
          }}
        >
          : {formatLocale(totalValue)}.
        </Typography>
        <InfoButton
          text={get(cmsData, "tooltips.oda_statement_section", "")}
          sx={{
            marginTop: "-2px",
            marginLeft: "12px",
            "@media (max-width: 920px)": {
              display: "none",
            },
          }}
        />
      </Box>
    </PageSection>
  );
}
