import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const locationPageInfo: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/location-page-info`),
};

export const legacylocationPageInfo: ApiCallModel = {
  ...APIModel("https://api.mfa.nyuki.io/api/detail-page-name"),
};
