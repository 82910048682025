import React from "react";
import get from "lodash/get";
import sumBy from "lodash/sumBy";
import Box from "@mui/material/Box";
import orderBy from "lodash/orderBy";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import useMount from "react-use/lib/useMount";
import { hexToRGBA } from "app/utils/hexToRGBA";
import { MFATable } from "app/components/table";
import { useCMSData } from "app/hooks/useCMSData";
import Typography from "@mui/material/Typography";
import { Link as RouteLink } from "react-router-dom";
import { formatLocale } from "app/utils/formatLocale";
import { PageLoader } from "app/components/pageLoader";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { LegendPanel } from "app/pages/common/legendPanel";
import { useNavigate, useLocation } from "react-router-dom";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { LegendPanelItemProps } from "app/pages/common/legendPanel/data";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import { PrioritiesSankey } from "app/components/charts/priorities/sankey";

export function PrioritiesTabView() {
  const location = useLocation();
  const navigate = useNavigate();
  const cmsData = useCMSData({ returnData: true });

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const fetchData = useStoreActions((actions) => actions.prioritiesChart.fetch);
  const data = useStoreState(
    (state) =>
      get(state.prioritiesChart, "data.vizData", []) as {
        code: string;
        name: string;
        value: number;
        color: string;
        commitment: number;
        subItems: {
          code: string;
          name: string;
          value: number;
          color: string;
        }[];
      }[]
  );
  const loading = useStoreState((state) => state.prioritiesChart.loading);
  const vizOrTable = useStoreState((state) => state.vizOrTable.value);
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const chart = React.useMemo(() => {
    return (
      <Box>
        <PrioritiesSankey
          data={{
            nodes: [
              ...data.map((item) => ({
                id: item.code,
                name: get(
                  cmsData,
                  `priorityAreas.${item.code.replace(/ /g, "")}`,
                  item.name
                ),
                color: item.color,
              })),
              {
                id: "root",
                name: "",
                color: "#fff",
              },
            ],
            links: data.map((item) => ({
              target: "root",
              source: item.code,
              value: item.value,
              color: item.color,
              commitment: item.commitment,
              subItems: item.subItems.map((subItem) => ({
                name: get(
                  cmsData,
                  `priorityAreas.${
                    subItem.name.indexOf("primary") > -1 ? "main" : "secondary"
                  }`,
                  subItem.name
                ),
                value: (subItem.value * 100) / item.value,
              })),
            })),
          }}
        />
        <Typography
          variant="h6"
          fontWeight={700}
          sx={{
            "@media (max-width: 920px)": {
              display: "none",
            },
          }}
        >
          {get(cmsData, "priorityAreas.main", "Main priority")}/
          {get(cmsData, "priorityAreas.secondary", "Secondary priority")}
        </Typography>
        <Box
          sx={{
            gap: "28px",
            width: "100%",
            display: "flex",
            marginTop: "28px",
            flexDirection: "column",
            "@media (max-width: 920px)": {
              display: "none",
            },
          }}
        >
          {data.map((item) => (
            <Box
              key={item.code}
              sx={{
                gap: "5px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link
                to={`${item.code}${location.search}`}
                component={RouteLink}
                sx={{
                  fontWeight: 700,
                }}
              >
                <Typography variant="body1">
                  {get(
                    cmsData,
                    `priorityAreas.${item.code.replace(/ /g, "")}`,
                    item.name
                  )}
                </Typography>
              </Link>
              <Box
                sx={{
                  width: "100%",
                  height: "26px",
                  display: "flex",
                  padding: "0 11px",
                  alignItems: "center",
                  borderRadius: "27px",
                  background: hexToRGBA(item.color, 0.4),
                }}
              >
                <Box
                  sx={{
                    width: `${(item.subItems[0].value / item.value) * 100}%`,
                    height: "18px",
                    borderRadius: "27px",
                    background: item.color,
                  }}
                />
              </Box>
              <Typography variant="body1">
                {formatLocale(item.subItems[0].value)}/
                {formatLocale(item.subItems[1].value)}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }, [data, cmsData?.priorityAreas]);

  const totalValue = React.useMemo(() => {
    return sumBy(data, (item) => item.value);
  }, [data]);

  const legendItems = React.useMemo(() => {
    let items = orderBy(data, "value", "desc");
    return items.map((item) => ({
      code: item.code,
      label: get(
        cmsData,
        `priorityAreas.${item.code.replace(/ /g, "")}`,
        item.name
      ),
      value: item.value,
      color: item.color,
    }));
  }, [data, cmsData?.priorityAreas]);

  const tableData = React.useMemo(() => {
    return data.map((item) => {
      return {
        name: {
          label: get(
            cmsData,
            `priorityAreas.${item.code.replace(/ /g, "")}`,
            item.name
          ),
          link: `/priorities/${item.code}`,
        },
        value: item.value,
      };
    });
  }, [data, cmsData?.priorityAreas]);

  const tableColumns = React.useMemo(() => {
    return [
      {
        id: "name",
        label: get(cmsData, "general.thematicareas", "Priorities"),
      },
      {
        id: "value",
        label: get(cmsData, "viz.disbursementsamount", "Total disbursements"),
      },
    ];
  }, [cmsData?.general, cmsData?.viz]);

  function onLegendItemClick(item: LegendPanelItemProps) {
    const index = legendItems.findIndex((l) => l.label === item.label);
    if (index >= 0) {
      navigate(
        `/${currentLanguage}/priorities/${legendItems[index].code}${location.search}`
      );
    }
  }

  const view = React.useMemo(() => {
    return (
      <React.Fragment>
        <CanonicalUrl canonicalUrl="/priorities" />
        {loading && <PageLoader />}
        {vizOrTable === "table" && (
          <MFATable
            rows={tableData}
            defaultSortKey="value"
            columns={tableColumns}
          />
        )}
        {vizOrTable === "viz" && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                {chart}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <LegendPanel
                  title={get(cmsData, "general.thematicareas", "Priorities")}
                  value={totalValue}
                  items={legendItems}
                  onItemClick={onLegendItemClick}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </React.Fragment>
    );
  }, [chart, legendItems, loading, tableData, totalValue, vizOrTable]);

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    const filters = getAPIFormattedFilters(appliedFilters);
    fetchData({
      values: {
        filters,
      },
    });
  }, [appliedFilters]);

  return view;
}
