import React from "react";
import axios from "axios";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import useTitle from "react-use/lib/useTitle";
import useMount from "react-use/lib/useMount";
import { useCMSData } from "app/hooks/useCMSData";
import { getAppName } from "app/utils/getAppName";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import { SdgsChart } from "app/components/charts/sdgs";
import { PageSection } from "app/pages/common/section";
import { SDGsIcon } from "app/assets/section-icons/SDGs";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { SankeyChart } from "app/components/charts/sankey";
import { GeomapChart } from "app/components/charts/geomap";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { HomePageODA } from "app/pages/home/components/oda";
import { SectorsChart } from "app/components/charts/sectors";
import { SectorsIcon } from "app/assets/section-icons/Sectors";
import { FloatingButtons } from "app/components/floatingButtons";
import { useComponentWillMount } from "app/hooks/useCompWillMount";
import { ActivitiesChart } from "app/components/charts/activities";
import { PrioritiesChart } from "app/components/charts/priorities";
import { PrioritiesIcon } from "app/assets/section-icons/Priorities";
import { ActivitiesIcon } from "app/assets/section-icons/Activities";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import { OrganisationsIcon } from "app/assets/section-icons/Organisations";
import { CountriesRegionsIcon } from "app/assets/section-icons/CountriesRegions";
import { DisbursementsOverviewIcon } from "app/assets/section-icons/DisbursementsOverview";

export function HomePage() {
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });
  const selectedLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  useTitle(getAppName(selectedLanguage));

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const setAppliedFilters = useStoreActions(
    (actions) => actions.appliedFilters
  );
  const fetchDisbursementsOverviewSankey = useStoreActions(
    (actions) => actions.disbursementsOverviewSankey.fetch
  );
  const dataDisbursementsOverviewSankey = useStoreState((state) =>
    get(state.disbursementsOverviewSankey, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingDisbursementsOverviewSankey = useStoreState(
    (state) => state.disbursementsOverviewSankey.loading
  );
  const fetchGeoChart = useStoreActions((actions) => actions.geoChart.fetch);
  const dataGeoChart = useStoreState((state) => {
    if (!state.geoChart.data) {
      return {
        vizData: [],
        total: 0,
        totalDisbursements: 0,
        unallocablePercentage: 0,
      };
    }
    return {
      vizData: get(state.geoChart, "data.vizData", []),
      total: get(state.geoChart, "data.total", 0),
      totalDisbursements: get(state.geoChart, "data.totalDisbursements", 0),
      unallocablePercentage: get(
        state.geoChart,
        "data.unallocablePercentage",
        0
      ),
    };
  });
  const loadingGeoChart = useStoreState((state) => state.geoChart.loading);
  const fetchOrganisationSankey = useStoreActions(
    (actions) => actions.organisationSankey.fetch
  );
  const dataOrganisationSankey = useStoreState((state) =>
    get(state.organisationSankey, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingOrganisationSankey = useStoreState(
    (state) => state.organisationSankey.loading
  );
  const fetchPrioritiesChart = useStoreActions(
    (actions) => actions.prioritiesChart.fetch
  );
  const dataPrioritiesChart = useStoreState((state) =>
    get(state.prioritiesChart, "data.vizData", [])
  );
  const loadingPrioritiesChart = useStoreState(
    (state) => state.prioritiesChart.loading
  );
  const fetchTop5Sectors = useStoreActions(
    (actions) => actions.top5sectors.fetch
  );
  const dataTop5Sectors = useStoreState((state) =>
    get(state.top5sectors, "data.vizData", [])
  );
  const loadingTop5Sectors = useStoreState(
    (state) => state.top5sectors.loading
  );
  const fetchSDGs = useStoreActions((actions) => actions.sdgs.fetch);
  const dataSDGs = useStoreState((state) =>
    get(state.sdgs, "data.vizData", [])
  );
  const loadingSDGs = useStoreState((state) => state.sdgs.loading);
  const fetchTop10Activities = useStoreActions(
    (actions) => actions.top10activities.fetch
  );
  const dataTop10Activities = useStoreState((state) =>
    get(state.top10activities, "data.vizData", [])
  );
  const loadingTop10Activities = useStoreState(
    (state) => state.top10activities.loading
  );

  useComponentWillMount({
    action: () => {
      if (window.location.search.length === 0) {
        axios
          .get(
            `${process.env.REACT_APP_CMS_API}/singletons/get/Filters?token=${process.env.REACT_APP_CMS_TOKEN}`
          )
          .then((res) => {
            const defaultPeriodFilter: string | undefined =
              res.data.default_period_filter_value;

            if (defaultPeriodFilter) {
              setAppliedFilters.setYears([
                defaultPeriodFilter,
                defaultPeriodFilter,
              ]);
            }
          })
          .catch(() => {});
      }
    },
  });

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  const [disbursementsOverviewType, setDisbursementsOverviewType] =
    React.useState("oda-budgetlines");

  const disbursementsOverviewSubtitleText = React.useMemo(() => {
    if (disbursementsOverviewType === "oda-budgetlines") {
      return get(cmsData, "pageSections.DisbursementsOverviewSubtitle", "");
    }
    if (disbursementsOverviewType === "oda-sectors") {
      return get(
        cmsData,
        "pageSections.DisbursementsOverviewSubtitle",
        ""
      ).replace("Budget lines", "Sectors");
    }
    if (disbursementsOverviewType === "oda-countries") {
      return get(
        cmsData,
        "pageSections.DisbursementsOverviewSubtitle",
        ""
      ).replace("Budget lines", "Partner countries");
    }
    return get(cmsData, "pageSections.DisbursementsOverviewSubtitle", "");
  }, [disbursementsOverviewType, cmsData]);

  const sankeyLevelTitle = React.useMemo(() => {
    if (disbursementsOverviewType === "oda-budgetlines") {
      return get(cmsData, "general.budgetlines", "Budget Lines");
    }
    if (disbursementsOverviewType === "oda-sectors") {
      return get(cmsData, "general.sectors", "Sectors");
    }
    if (disbursementsOverviewType === "oda-countries") {
      return get(cmsData, "general.countries", "Countries");
    }
    return get(cmsData, "general.budgetlines", "Budget Lines");
  }, [disbursementsOverviewType]);

  React.useEffect(() => {
    const filters = getAPIFormattedFilters(appliedFilters);
    fetchOrganisationSankey({
      values: {
        filters,
      },
    });
    fetchPrioritiesChart({
      values: {
        filters,
      },
    });
    fetchTop5Sectors({
      values: {
        filters,
      },
    });
    fetchSDGs({
      values: {
        filters,
      },
    });
    fetchGeoChart({
      values: {
        filters,
      },
    });
    fetchTop10Activities({
      values: {
        filters,
      },
    });
  }, [appliedFilters]);

  React.useEffect(() => {
    const filters = getAPIFormattedFilters(appliedFilters);
    fetchDisbursementsOverviewSankey({
      values: {
        filters,
        type: disbursementsOverviewType,
      },
    });
  }, [appliedFilters, disbursementsOverviewType]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl="" />
      <Box
        sx={{
          "@media (max-width: 920px)": {
            padding: "0 48px",
          },
          "@media (max-width: 768px)": {
            padding: "0 24px",
          },
        }}
      >
        <FloatingButtons />
        <HomePageODA />
        <PageSection
          background="#F6F9FD"
          loading={loadingDisbursementsOverviewSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.DisbursementsOverviewTitle",
              "Disbursements Overview"
            ),
            subtitle: (
              <HomePageSankeyControl
                value={disbursementsOverviewType}
                handleODASankeyControlValueChange={setDisbursementsOverviewType}
                buttonLabel={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: disbursementsOverviewSubtitleText,
                    }}
                  />
                }
              />
            ),
            info: get(cmsData, "tooltips.overview_disbursements", ""),
            icon: <DisbursementsOverviewIcon />,
            titleLink: `/${selectedLanguage}/disbursements-overview${location.search}`,
          }}
        >
          <SankeyChart
            {...dataDisbursementsOverviewSankey}
            levelTitles={[
              get(cmsData, "viz.totaloda", "Total ODA"),
              get(cmsData, "viz.typeofoda", "Type of ODA"),
              sankeyLevelTitle,
            ]}
          />
        </PageSection>
        <PageSection
          background="#F9FBFA"
          loading={loadingGeoChart}
          header={{
            title: get(
              cmsData,
              "pageSections.CountriesRegionsTitle",
              "Countries & Regions"
            ),
            subtitle: get(
              cmsData,
              "pageSections.CountriesRegionsSubtitle",
              "Partner countries of Finnish ODA."
            ),
            info: get(cmsData, "tooltips.map", ""),
            icon: <CountriesRegionsIcon />,
            titleLink: `/${selectedLanguage}/countries-regions${location.search}`,
          }}
        >
          <GeomapChart
            data={dataGeoChart.vizData}
            unallocable={dataGeoChart.unallocablePercentage}
          />
        </PageSection>
        <PageSection
          background="#FFFCF7"
          loading={loadingOrganisationSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.OrganisationsTitle",
              "Organisations"
            ),
            subtitle: get(
              cmsData,
              "pageSections.OrganisationsSubtitle",
              "5 partner organisations of Finnish ODA with the largest budget."
            ),
            info: get(cmsData, "tooltips.organisations", ""),
            icon: <OrganisationsIcon />,
            titleLink: `/${selectedLanguage}/organisations${location.search}`,
          }}
        >
          <SankeyChart organisation {...dataOrganisationSankey} />
        </PageSection>
        <PageSection
          background="#FFFAF9"
          loading={loadingPrioritiesChart}
          header={{
            title: get(cmsData, "pageSections.PrioritiesTitle", "Priorities"),
            subtitle: get(
              cmsData,
              "pageSections.PrioritiesSubtitle",
              "Prioritised areas of Finnish ODA."
            ),
            info: get(cmsData, "tooltips.thematic_areas", ""),
            icon: <PrioritiesIcon />,
            titleLink: `/${selectedLanguage}/priorities${location.search}`,
          }}
          sx={{ overflow: "visible" }}
        >
          <PrioritiesChart items={dataPrioritiesChart} />
        </PageSection>
        <PageSection
          background="#F9F9FC"
          loading={loadingTop5Sectors}
          header={{
            title: get(cmsData, "pageSections.SectorsTitle", "Sectors"),
            subtitle: get(
              cmsData,
              "pageSections.SectorsSubtitle",
              "The 5 Largest Sectors of Finnish ODA in a descending order."
            ),
            info: get(cmsData, "tooltips.sectors", ""),
            icon: <SectorsIcon />,
            titleLink: `/${selectedLanguage}/sectors${location.search}`,
          }}
          sx={{ overflow: "visible" }}
        >
          <SectorsChart items={dataTop5Sectors} />
        </PageSection>
        <PageSection
          background="#F9FCFE"
          loading={loadingSDGs}
          header={{
            title: get(cmsData, "pageSections.SDGsTitle", "SDGs"),
            subtitle: get(
              cmsData,
              "pageSections.SDGsSubtitle",
              "SDGs listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.sdg", ""),
            icon: <SDGsIcon />,
          }}
        >
          <SdgsChart items={dataSDGs} />
        </PageSection>
        <PageSection
          background="#FCFBF9"
          loading={loadingTop10Activities}
          header={{
            title: get(cmsData, "pageSections.ActivitiesTitle", "Activities"),
            subtitle: get(
              cmsData,
              "pageSections.ActivitiesSubtitle",
              "Top 10 largest activities listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.activities", ""),
            icon: <ActivitiesIcon />,
            titleLink: `/${selectedLanguage}/activities${location.search}`,
          }}
        >
          <Link
            id="see-all-activities-comp"
            to={`/${selectedLanguage}/activities${location.search}`}
          >
            {get(cmsData, "viz.seeallactivities", "See All Activities")}{" "}
            <ArrowForward fontSize="small" />
          </Link>
          <ActivitiesChart items={dataTop10Activities} />
        </PageSection>
        <PageSection
          background="#FEFEFE"
          header={{
            title: get(cmsData, "pageSections.AboutTitle", "About"),
            subtitle: get(
              cmsData,
              "pageSections.AboutSubtitle",
              "Lorem ipsum."
            ),
          }}
          sx={{
            "@media (max-width: 768px)": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              gap: "30px",
              width: "100%",
              display: "flex",
              "> div": {
                gap: "20px",
                flexGrow: 1,
                flexBasis: 0,
                display: "flex",
                padding: "26px 24px",
                borderRadius: "30px",
                flexDirection: "column",
                justifyContent: "center",
                background: colors.primary.gray,
                boxShadow: colors.shadows.section,
                a: {
                  color: colors.primary.blue,
                },
              },
            }}
          >
            <Box>
              <Typography variant="subtitle1">
                {get(cmsData, "general.about", "About")}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{
                  lineHeight: "18px",
                  fontSize: "16px",
                  a: {
                    textDecoration: "underline",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: get(cmsData, "pages.home_about", "") ?? "",
                }}
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">
                {get(
                  cmsData,
                  "general.result",
                  "Results of Finland's development cooperation"
                )}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{
                  lineHeight: "18px",
                  fontSize: "16px",
                  a: {
                    textDecoration: "underline",
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: get(cmsData, "pages.home_result", "") ?? "",
                }}
              />
            </Box>
          </Box>
        </PageSection>
      </Box>
    </React.Fragment>
  );
}

function HomePageSankeyControl(props: {
  value: string;
  buttonLabel: React.ReactNode;
  handleODASankeyControlValueChange: (value: string) => void;
}) {
  const cmsData = useCMSData({ returnData: true });

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleLabelClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleButtonClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    handleButtonClose();
  }, [props.value]);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "oda-sankey-control-popover" : undefined;

  return (
    <React.Fragment>
      <Typography
        // onClick={handleLabelClick}
        sx={{
          u: {
            textDecoration: "none",
            // cursor: "pointer",
          },
        }}
      >
        {props.buttonLabel}
      </Typography>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={openPopover}
        onClose={handleButtonClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: "328px",
            padding: "8px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "40px",
              display: "flex",
              padding: "0 12px",
              alignItems: "center",
              borderBottom: `1px solid ${colors.secondary.grayLight}`,
            }}
          >
            <Typography variant="body2">
              {get(
                cmsData,
                "vizTabs.DisbursementsOverviewSelectorHomepageSankey",
                "Showcasing disbursements flow to"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              padding: "4px 0",
              flexDirection: "column",
              "> button": {
                fontWeight: "400",
                padding: "6px 14px",
                textTransform: "none",
                justifyContent: "flex-start",
              },
            }}
          >
            <Button
              onClick={() =>
                props.handleODASankeyControlValueChange("oda-budgetlines")
              }
            >
              Budget Lines
            </Button>
            <Button
              onClick={() =>
                props.handleODASankeyControlValueChange("oda-sectors")
              }
            >
              10 Largest Sectors
            </Button>
            <Button
              onClick={() =>
                props.handleODASankeyControlValueChange("oda-countries")
              }
            >
              10 Biggest Partner Countries
            </Button>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  );
}
