import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import orderBy from "lodash/orderBy";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import { useStoreState } from "app/state/store/hooks";
import { formatLocale } from "app/utils/formatLocale";
import TableContainer from "@mui/material/TableContainer";
import { Link as RouteLink, useLocation } from "react-router-dom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  MFATableRowProps,
  MFATableProps,
  tableSearch,
} from "app/components/table/data";

function MFATableRow(props: MFATableRowProps) {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const childrenRows = React.useMemo(() => {
    if (!props.data.children) return [];
    if (
      typeof props.data.children === "number" ||
      typeof props.data.children === "string"
    )
      return [];
    if (!Array.isArray(props.data.children)) return [];
    return props.data.children;
  }, [props.data]);

  const background = React.useMemo(() => {
    if (props.level === 1) return colors.primary.white;
    if (props.level === 2) return colors.secondary.grayLight;
    return "transparent";
  }, [props.level, props.expandable]);

  const getValue = React.useCallback(
    (columnId: string) => {
      const value = get(props, `data[${columnId}]`, "");
      const link = get(value, "link", null);

      if (typeof value === "string") return value;
      if (typeof value === "number") return formatLocale(value);
      if (link) {
        return (
          <Link to={`${link}${location.search}`} component={RouteLink}>
            {get(value, "label", link)}
          </Link>
        );
      }
      return value.toString();
    },
    [props.data]
  );

  const isActivitiesOrPrioritiesTable = React.useMemo(() => {
    return (
      location.pathname.includes("activities") ||
      location.pathname.includes("priorities") ||
      location.pathname.includes("disbursements-overview")
    );
  }, [location.pathname]);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          background,
        }}
      >
        {!props.expandable && !isActivitiesOrPrioritiesTable && (
          <TableCell width={25} />
        )}
        {props.expandable && (
          <TableCell
            sx={{
              paddingRight: 0,
            }}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{
                padding: 0,
              }}
            >
              {open ? (
                <KeyboardArrowUpIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </IconButton>
          </TableCell>
        )}
        {props.columns.map((column) => (
          <TableCell key={column.id} align={column.align}>
            {getValue(column.id)}
          </TableCell>
        ))}
      </TableRow>
      {open &&
        childrenRows.length > 0 &&
        childrenRows.map((row: any) => (
          <MFATableRow
            data={row}
            key={row.id as string}
            level={props.level + 1}
            columns={props.columns}
            expandable={Boolean(row.children)}
          />
        ))}
    </React.Fragment>
  );
}

export function MFATable(props: MFATableProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortKey, setSortKey] = React.useState(
    props.defaultSortKey ? props.defaultSortKey : props.columns[0].id
  );
  const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">(
    "desc"
  );

  const searchValue = useStoreState(
    (state) => state.tabViewTableSearch.value as string
  );

  const expandable = React.useMemo(() => {
    return props.rows.some((row) => row.children);
  }, [props.rows]);

  const handleChangePage = (newPage: number) => {
    if (props.setPage) {
      props.setPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (props.setRowsPerPage) {
      props.setRowsPerPage(parseInt(event.target.value, 10));
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
    }
    if (props.setPage) {
      props.setPage(0);
    } else {
      setPage(0);
    }
  };

  const hanldeChangeSort = (columnId: string) => () => {
    if ((props.sortKey ? props.sortKey : sortKey) === columnId) {
      if (props.setSortDirection) {
        props.setSortDirection(
          (props.sortDirection ? props.sortDirection : sortDirection) === "asc"
            ? "desc"
            : "asc"
        );
      } else {
        setSortDirection(
          (props.sortDirection ? props.sortDirection : sortDirection) === "asc"
            ? "desc"
            : "asc"
        );
      }
    } else {
      if (props.setSortKey) {
        props.setSortKey(columnId);
      } else {
        setSortKey(columnId);
      }
      if (props.setSortDirection) {
        props.setSortDirection("desc");
      } else {
        setSortDirection("desc");
      }
    }
  };

  const visibleRows = React.useMemo(() => {
    if (props.page !== undefined) {
      return props.rows;
    }
    return orderBy(
      tableSearch(props.rows, searchValue),
      props.sortKey ? props.sortKey : sortKey,
      props.sortDirection ? props.sortDirection : sortDirection
    ).slice(
      (props.page ? props.page : page) *
        (props.rowsPerPage ? props.rowsPerPage : rowsPerPage),
      (props.page ? props.page : page) *
        (props.rowsPerPage ? props.rowsPerPage : rowsPerPage) +
        (props.rowsPerPage ? props.rowsPerPage : rowsPerPage)
    );
  }, [
    props.rows,
    page,
    rowsPerPage,
    sortDirection,
    sortKey,
    searchValue,
    props.page,
    props.rowsPerPage,
    props.sortDirection,
    props.sortKey,
  ]);

  const sortDirectionIcon = React.useMemo(() => {
    if ((props.sortDirection ? props.sortDirection : sortDirection) === "asc")
      return "▲";
    return "▼";
  }, [sortDirection, props.sortDirection]);

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        padding: "8px 30px",
        borderRadius: "30px",
        background: colors.primary.gray,
        boxShadow: colors.shadows.inner,
      }}
    >
      <TablePagination
        onPageChange={handleChangePage}
        page={props.page ? props.page : page}
        count={props.totalRows ? props.totalRows : props.rows.length}
        rowsPerPage={props.rowsPerPage ? props.rowsPerPage : rowsPerPage}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {expandable && <TableCell width={25} />}
              {props.columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  onClick={hanldeChangeSort(column.id)}
                  className={
                    index !== props.columns.length - 1
                      ? "tableHeaderSeparator"
                      : ""
                  }
                >
                  {column.label}{" "}
                  {(props.sortKey ? props.sortKey : sortKey) === column.id
                    ? sortDirectionIcon
                    : ""}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => (
              <MFATableRow
                level={0}
                data={row}
                key={row.id as string}
                columns={props.columns}
                expandable={expandable}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const TablePagination = (props: {
  page: number;
  count: number;
  rowsPerPage: number;
  onPageChange: (newPage: number) => void;
}) => {
  const pages = React.useMemo(() => {
    return Math.ceil(props.count / props.rowsPerPage);
  }, [props.count, props.rowsPerPage]);

  const pageShown = React.useMemo(() => {
    if (pages <= 7) {
      return Array.from(Array(pages).keys());
    }
    const result = [];
    for (let i = 0; i < pages; i++) {
      if (i < props.page - 1) continue;
      if (i > props.page + 1) continue;
      result.push(i);
    }
    if (props.page > 2) {
      result.unshift(-1);
      result.unshift(0);
    } else if (result[0] !== 0) {
      result.unshift(0);
    }
    if (
      props.page === 0 &&
      !result.includes(2) &&
      !result.includes(3) &&
      !result.includes(4)
    ) {
      result.push(2);
      result.push(3);
      result.push(4);
    } else if (props.page === 1 && !result.includes(3) && !result.includes(4)) {
      result.push(3);
      result.push(4);
    } else if (props.page === 2 && !result.includes(4)) {
      result.push(4);
    }

    if (props.page === pages - 3) {
      if (!result.includes(pages - 4))
        result.splice(result.length - 2, 0, pages - 4);
      if (!result.includes(pages - 2))
        result.splice(result.length - 2, 0, pages - 2);
      if (!result.includes(pages - 1)) result.push(pages - 1);
    } else if (props.page === pages - 2) {
      if (!result.includes(pages - 4))
        result.splice(result.length - 3, 0, pages - 4);
      if (!result.includes(pages - 3))
        result.splice(result.length - 3, 0, pages - 3);
      if (!result.includes(pages - 1))
        result.splice(result.length - 3, 0, pages - 1);
    } else if (props.page === pages - 1) {
      if (!result.includes(pages - 4))
        result.splice(result.length - 2, 0, pages - 4);
      if (!result.includes(pages - 3))
        result.splice(result.length - 2, 0, pages - 3);
      if (!result.includes(pages - 2))
        result.splice(result.length - 2, 0, pages - 2);
    }
    if (props.page < pages - 3) {
      result.push(-2);
      result.push(pages - 1);
    } else if (
      props.page !== pages - 1 &&
      result[result.length - 1] !== pages - 1
    ) {
      result.push(pages - 1);
    }
    return result;
  }, [props.page, pages]);

  return (
    <Box
      sx={{
        gap: "5px",
        width: "100%",
        display: "flex",
        padding: "15px 20px",
        alignItems: "center",
        justifyContent: "flex-end",
        "> button": {
          width: "32px",
          height: "32px",
          fontSize: "12px",
          borderRadius: "8px",
          border: "1px solid #f1f1f1",
          background: colors.primary.white,
          "&:disabled": {
            background: colors.primary.gray,
          },
        },
        "@media (max-width: 600px)": {
          justifyContent: "center",
        },
      }}
    >
      <IconButton
        aria-label="previous page"
        disabled={props.page === 0}
        aria-disabled={props.page === 0}
        onClick={() => props.onPageChange(props.page - 1)}
      >
        <KeyboardArrowLeft fontSize="small" />
      </IconButton>
      {pageShown.map((page) => {
        if (page < 0) {
          return (
            <IconButton aria-label="" aria-disabled key={page} disabled>
              ...
            </IconButton>
          );
        }
        return (
          <IconButton
            aria-label={`page ${page + 1}`}
            key={page}
            onClick={() => props.onPageChange(page)}
            sx={
              props.page === page
                ? {
                    color: colors.primary.white,
                    background: colors.primary.blue + " !important",
                  }
                : {}
            }
          >
            {page + 1}
          </IconButton>
        );
      })}
      <IconButton
        aria-label="next page"
        disabled={pages < 2 || props.page === pages - 1}
        onClick={() => props.onPageChange(props.page + 1)}
        aria-disabled={pages < 2 || props.page === pages - 1}
      >
        <KeyboardArrowRight fontSize="small" />
      </IconButton>
    </Box>
  );
};
