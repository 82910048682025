import React from "react";
import get from "lodash/get";
import find from "lodash/find";
import filter from "lodash/filter";
import remove from "lodash/remove";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useCMSData } from "app/hooks/useCMSData";
import { FiltersTabs } from "app/components/filters/tabs";
import { FiltersTabView } from "app/components/filters/view";
import { FILTER_TABS } from "app/components/filters/tabs/data";
import { FILTER_TYPES } from "app/components/filters/view/data";
import { FiltersApplied } from "app/components/filters/applied";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { defaultfilters } from "app/state/api/action-reducers/sync/appliedFilters";
import { MobileFiltersPanel } from "../mobile/panel";

interface FiltersPanelProps {
  onClose: () => void;
}

export function FiltersPanel(props: FiltersPanelProps) {
  const cmsData = useCMSData({ returnData: true });

  const [activeTab, setActiveTab] = React.useState(FILTER_TABS[0].id);

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const setAppliedFilters = useStoreActions(
    (actions) => actions.appliedFilters.setAllFilters
  );

  const [localAppliedFilters, setLocalAppliedFilters] =
    React.useState(appliedFilters);

  React.useEffect(() => {
    setLocalAppliedFilters(appliedFilters);
  }, [appliedFilters]);

  function onFilterCheckboxChange(param: string[], type: FILTER_TYPES): void {
    // console.log("onFilterCheckboxChange", param, type);
    const isEmpty = param.length === 0;
    const isSingleValue = param.length === 1;
    const singleValue = param[0] as string;
    const value = isSingleValue ? singleValue : param;
    const updatedAppliedFilters = { ...localAppliedFilters };
    switch (type) {
      case FILTER_TYPES.THEMATIC_AREAS:
        if (isEmpty) {
          updatedAppliedFilters.tag = [];
        } else if (isSingleValue) {
          if (updatedAppliedFilters.tag.indexOf(value as string) > -1) {
            updatedAppliedFilters.tag = filter(
              updatedAppliedFilters.tag,
              (t: string) => t !== value
            );
          } else {
            updatedAppliedFilters.tag = [
              ...updatedAppliedFilters.tag,
              singleValue,
            ];
          }
        } else {
          let areParamValuesApplied = true;
          const fParam = filter(param, (p: string) => p !== "");
          fParam.forEach((pvalue: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.tag.indexOf(pvalue) > -1;
          });
          if (areParamValuesApplied) {
            updatedAppliedFilters.tag = filter(
              updatedAppliedFilters.tag,
              (t: string) => fParam.indexOf(t) === -1
            );
          } else {
            updatedAppliedFilters.tag = [
              ...updatedAppliedFilters.tag,
              ...fParam,
            ];
          }
        }
        break;
      case FILTER_TYPES.COUNTRIES:
        if (isEmpty) {
          updatedAppliedFilters.countries = [];
        } else if (isSingleValue) {
          const isRegion = !Number.isNaN(parseInt(singleValue, 10));
          if (!isRegion) {
            if (updatedAppliedFilters.countries.indexOf(singleValue) > -1) {
              updatedAppliedFilters.countries = filter(
                updatedAppliedFilters.countries,
                (t: string) => t !== value
              );
            } else {
              updatedAppliedFilters.countries = [
                ...updatedAppliedFilters.countries,
                singleValue,
              ];
            }
          } else if (updatedAppliedFilters.regions.indexOf(singleValue) > -1) {
            updatedAppliedFilters.regions = filter(
              updatedAppliedFilters.regions,
              (t: string) => t !== value
            );
          } else {
            updatedAppliedFilters.regions = [
              ...updatedAppliedFilters.regions,
              singleValue,
            ];
          }
        } else {
          let areParamValuesApplied = true;
          const regions = filter(
            param,
            (pvalue: string) => !Number.isNaN(parseInt(pvalue, 10))
          );
          const countries = filter(
            param,
            (pvalue: string) =>
              Number.isNaN(parseInt(pvalue, 10)) && pvalue !== ""
          );
          countries.forEach((c: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.countries.indexOf(c) > -1;
          });
          regions.forEach((r: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.regions.indexOf(r) > -1;
          });
          if (areParamValuesApplied) {
            updatedAppliedFilters.countries = filter(
              updatedAppliedFilters.countries,
              (t: string) => countries.indexOf(t) === -1
            );
            updatedAppliedFilters.regions = filter(
              updatedAppliedFilters.regions,
              (t: string) => regions.indexOf(t) === -1
            );
          } else {
            updatedAppliedFilters.countries = [
              ...updatedAppliedFilters.countries,
              ...countries,
            ];
            updatedAppliedFilters.regions = [
              ...updatedAppliedFilters.regions,
              ...regions,
            ];
          }
        }
        break;
      case FILTER_TYPES.SECTORS:
        if (isEmpty) {
          updatedAppliedFilters.sectors = [];
        } else if (isSingleValue) {
          if (updatedAppliedFilters.sectors.indexOf(singleValue) > -1) {
            remove(updatedAppliedFilters.sectors, (t: string) => t === value);
          } else {
            updatedAppliedFilters.sectors = [
              ...updatedAppliedFilters.sectors,
              singleValue,
            ];
          }
        } else {
          let areParamValuesApplied = true;
          param.forEach((pvalue: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.sectors.indexOf(pvalue) > -1;
          });
          if (areParamValuesApplied) {
            remove(
              updatedAppliedFilters.sectors,
              (t: string) => param.indexOf(t) > -1
            );
          } else {
            updatedAppliedFilters.sectors = [
              ...updatedAppliedFilters.sectors,
              ...param,
            ];
          }
        }
        break;
      case FILTER_TYPES.ORGANISATIONS:
        if (isEmpty) {
          updatedAppliedFilters.organisations = [];
          updatedAppliedFilters.organisationtypes = [];
        } else if (isSingleValue) {
          if (updatedAppliedFilters.organisations.indexOf(singleValue) > -1) {
            remove(
              updatedAppliedFilters.organisations,
              (t: string) => t === value
            );
          } else {
            updatedAppliedFilters.organisations = [
              ...updatedAppliedFilters.organisations,
              singleValue,
            ];
          }
        } else {
          let areParamValuesApplied = true;
          const orgType = find(param, (pvalue: string) => pvalue.length === 2);
          const onlyOrgs = filter(param, (pvalue: string) => pvalue.length > 2);
          onlyOrgs.forEach((org: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.organisations.indexOf(org) > -1;
          });
          if (areParamValuesApplied) {
            remove(
              updatedAppliedFilters.organisations,
              (t: string) => onlyOrgs.indexOf(t) > -1
            );
          } else {
            updatedAppliedFilters.organisations = [
              ...updatedAppliedFilters.organisations,
              ...onlyOrgs,
            ];
          }
          if (orgType) {
            if (updatedAppliedFilters.organisationtypes.indexOf(orgType) > -1) {
              remove(
                updatedAppliedFilters.organisationtypes,
                (t: string) => t === orgType
              );
            } else {
              updatedAppliedFilters.organisationtypes = [
                ...updatedAppliedFilters.organisationtypes,
                orgType,
              ];
            }
          }
        }
        break;
      case FILTER_TYPES.SDGS:
        if (isEmpty) {
          updatedAppliedFilters.sdg = [];
        } else if (updatedAppliedFilters.sdg.indexOf(singleValue) > -1) {
          remove(updatedAppliedFilters.sdg, (t: string) => t === value);
        } else if (isSingleValue) {
          updatedAppliedFilters.sdg = [
            ...updatedAppliedFilters.sdg,
            singleValue,
          ];
        } else {
          updatedAppliedFilters.sdg = [...param];
        }
        break;
      case FILTER_TYPES.ACTIVITY_STATUS:
        if (isEmpty) {
          updatedAppliedFilters.activitystatus = [];
        } else if (
          updatedAppliedFilters.activitystatus.indexOf(singleValue) > -1
        ) {
          remove(
            updatedAppliedFilters.activitystatus,
            (t: string) => t === singleValue
          );
        } else {
          updatedAppliedFilters.activitystatus = [
            ...updatedAppliedFilters.activitystatus,
            singleValue,
          ];
        }
        break;
      case FILTER_TYPES.PERIOD:
        updatedAppliedFilters.years = singleValue.split(",");
        break;
      case FILTER_TYPES.POLICY_MARKERS:
        if (isEmpty) {
          updatedAppliedFilters.policymarker = [];
        } else if (
          isSingleValue &&
          updatedAppliedFilters.policymarker.indexOf(singleValue) > -1
        ) {
          remove(
            updatedAppliedFilters.policymarker,
            (t: string) => t === value
          );
        } else if (isSingleValue) {
          updatedAppliedFilters.policymarker = [
            ...updatedAppliedFilters.policymarker,
            singleValue,
          ];
        } else {
          let areParamValuesApplied = true;
          param.forEach((pvalue: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.policymarker.indexOf(pvalue) > -1;
          });
          if (areParamValuesApplied) {
            remove(
              updatedAppliedFilters.policymarker,
              (t: string) => param.indexOf(t) > -1
            );
          } else {
            updatedAppliedFilters.policymarker = [...param];
          }
        }
        break;
      case FILTER_TYPES.AID_TYPE:
        if (isEmpty) {
          updatedAppliedFilters.defaultaidtype = [];
        } else if (
          isSingleValue &&
          updatedAppliedFilters.defaultaidtype.indexOf(singleValue) > -1
        ) {
          remove(
            updatedAppliedFilters.defaultaidtype,
            (t: string) => t === singleValue
          );
        } else {
          let areParamValuesApplied = true;
          param.forEach((pvalue: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.defaultaidtype.indexOf(pvalue) > -1;
          });
          if (areParamValuesApplied) {
            remove(
              updatedAppliedFilters.defaultaidtype,
              (t: string) => param.indexOf(t) > -1
            );
          } else {
            updatedAppliedFilters.defaultaidtype = [...param];
          }
        }
        break;
      case FILTER_TYPES.BUDGET_LINES:
        if (isEmpty) {
          updatedAppliedFilters.budgetlines = [];
        } else if (
          isSingleValue &&
          updatedAppliedFilters.budgetlines.indexOf(singleValue) > -1
        ) {
          remove(updatedAppliedFilters.budgetlines, (t: string) => t === value);
        } else {
          let areParamValuesApplied = true;
          param.forEach((pvalue: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.budgetlines.indexOf(pvalue) > -1;
          });
          if (areParamValuesApplied) {
            remove(
              updatedAppliedFilters.budgetlines,
              (t: string) => param.indexOf(t) > -1
            );
          } else {
            updatedAppliedFilters.budgetlines = [...param];
          }
        }
        break;
      case FILTER_TYPES.BI_MULTI:
        if (isEmpty) {
          updatedAppliedFilters.collaborationtype = [];
        } else if (
          isSingleValue &&
          updatedAppliedFilters.collaborationtype.indexOf(singleValue) > -1
        ) {
          remove(
            updatedAppliedFilters.collaborationtype,
            (t: string) => t === value
          );
        } else {
          let areParamValuesApplied = true;
          param.forEach((pvalue: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.collaborationtype.indexOf(pvalue) > -1;
          });
          if (areParamValuesApplied) {
            remove(
              updatedAppliedFilters.collaborationtype,
              (t: string) => param.indexOf(t) > -1
            );
          } else {
            updatedAppliedFilters.collaborationtype = [...param];
          }
        }
        break;
      case FILTER_TYPES.HUMAN_RIGHTS:
        if (isEmpty) {
          updatedAppliedFilters.humanrights = [];
        } else if (
          isSingleValue &&
          updatedAppliedFilters.humanrights.indexOf(singleValue) > -1
        ) {
          remove(updatedAppliedFilters.humanrights, (t: string) => t === value);
        } else {
          let areParamValuesApplied = true;
          param.forEach((pvalue: string) => {
            areParamValuesApplied =
              areParamValuesApplied &&
              updatedAppliedFilters.humanrights.indexOf(pvalue) > -1;
          });
          if (areParamValuesApplied) {
            remove(
              updatedAppliedFilters.humanrights,
              (t: string) => param.indexOf(t) > -1
            );
          } else {
            updatedAppliedFilters.humanrights = [...param];
          }
        }
        break;
      default:
        break;
    }
    setLocalAppliedFilters(updatedAppliedFilters);
  }

  function onApplyFilters() {
    setAppliedFilters(localAppliedFilters);
    props.onClose();
  }

  function onResetFilters() {
    setAppliedFilters(defaultfilters);
    props.onClose();
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        width: "1200px",
        minHeight: "370px",
        padding: "0px !important",
        boxShadow: colors.shadows.main,
        "@media (max-width: 1200px)": {
          width: "calc(100vw - 32px)",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "20px 50px",
          "@media (max-width: 920px)": {
            padding: "16px 20px",
          },
          "@media (max-width: 768px)": {
            height: "100%",
            padding: "0px",
          },
        }}
      >
        <MobileFiltersPanel
          onClose={props.onClose}
          onApplyFilters={onApplyFilters}
          onResetFilters={onResetFilters}
          localAppliedFilters={localAppliedFilters}
          onFilterCheckboxChange={onFilterCheckboxChange}
        />
        <Box
          sx={{
            "@media (max-width: 768px)": {
              display: "none",
            },
          }}
        >
          <FiltersTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <Box
            sx={{
              width: "100%",
              "> div": {
                width: "100%",
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  height: "5px",
                  background: colors.secondary.blueGrayLight,
                },
                "&::-webkit-scrollbar-track": {
                  borderRadius: "4px",
                  background: colors.secondary.blueGrayLight,
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "4px",
                  background: colors.primary.blue,
                },
              },
            }}
          >
            <FiltersApplied />
          </Box>
          <FiltersTabView
            activeTab={activeTab}
            localAppliedFilters={localAppliedFilters}
            onFilterCheckboxChange={onFilterCheckboxChange}
          />
          <Box
            sx={{
              gap: "30px",
              width: "100%",
              display: "flex",
              marginTop: "30px",
              flexDirection: "row",
              justifyContent: "flex-end",
              "> button": {
                fontWeight: "700",
                borderRadius: "30px",
                textTransform: "none",
              },
            }}
          >
            <Button variant="text" onClick={onResetFilters}>
              {get(cmsData, "filters.reset", "Reset Filters")}
            </Button>
            <Button variant="contained" onClick={onApplyFilters}>
              {get(cmsData, "filters.apply", "Apply Filters")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
