import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Flag from "react-world-flags";
import Grid from "@mui/material/Grid";
import * as echarts from "echarts/core";
import { PieSeriesOption } from "echarts";
import Button from "@mui/material/Button";
import { PieChart } from "echarts/charts";
import useTitle from "react-use/lib/useTitle";
import { useMediaQuery } from "@mui/material";
import useMount from "react-use/lib/useMount";
import { SVGRenderer } from "echarts/renderers";
import Typography from "@mui/material/Typography";
import { getAppName } from "app/utils/getAppName";
import { useCMSData } from "app/hooks/useCMSData";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { formatLocale } from "app/utils/formatLocale";
import { SdgsChart } from "app/components/charts/sdgs";
import { InfoButton } from "app/components/infobutton";
import { PageSection } from "app/pages/common/section";
import { Breadcrumbs } from "app/components/breadcrumb";
import { SDGsIcon } from "app/assets/section-icons/SDGs";
import { CanonicalUrl } from "app/components/canonicalUrl";
import { GeomapChart } from "app/components/charts/geomap";
import { SankeyChart } from "app/components/charts/sankey";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { SectorsChart } from "app/components/charts/sectors";
import { BarLineChart } from "app/components/charts/barline";
import { SectorsIcon } from "app/assets/section-icons/Sectors";
import { FloatingButtons } from "app/components/floatingButtons";
import { PrioritiesChart } from "app/components/charts/priorities";
import { ActivitiesChart } from "app/components/charts/activities";
import { BarLineDataItem } from "app/components/charts/barline/data";
import { PrioritiesIcon } from "app/assets/section-icons/Priorities";
import { ActivitiesIcon } from "app/assets/section-icons/Activities";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { getCoordinatesFromISO2 } from "app/utils/getCoordinatesFromIso2";
import { getAPIFormattedFilters } from "app/utils/getAPIFormattedFilters";
import { OrganisationsIcon } from "app/assets/section-icons/Organisations";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { CountriesRegionsIcon } from "app/assets/section-icons/CountriesRegions";
import { DisbursementsOverviewIcon } from "app/assets/section-icons/DisbursementsOverview";
import { pageChartTooltipCommonConfig } from "app/components/charts/tooltips/common/config";
import {
  LegendComponent,
  TooltipComponent,
  LegendComponentOption,
  TooltipComponentOption,
} from "echarts/components";

echarts.use([LegendComponent, TooltipComponent, PieChart, SVGRenderer]);

const LocationPageIntroBlock = () => {
  const params = useParams<{ id: string }>();
  const cmsData = useCMSData({ returnData: true });
  const mobile = useMediaQuery("(max-width: 768px)");
  const tablet = useMediaQuery("(max-width: 920px)");

  const pieContainerRef = React.useRef<HTMLDivElement>(null);

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const dataPageInfo = useStoreState((state) => state.locationPageInfo.data);
  const fetchPageInfo = useStoreActions(
    (actions) => actions.locationPageInfo.fetch
  );
  const legacydataPageInfo = useStoreState(
    (state) => state.legacylocationPageInfo.data
  );
  const fetchLegacyPageInfo = useStoreActions(
    (actions) => actions.legacylocationPageInfo.fetch
  );

  const dataBarChart = useStoreState(
    (state) =>
      get(
        state.disbursementsOverviewLocation,
        "data.vizData",
        []
      ) as BarLineDataItem[]
  );
  const fetchBarChart = useStoreActions(
    (actions) => actions.disbursementsOverviewLocation.fetch
  );

  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const dataPieChart = React.useMemo(() => {
    if (dataBarChart.length === 0) {
      return [];
    }
    const item = dataBarChart[dataBarChart.length - 1];
    return [
      {
        name: "Exclusive ODA",
        value: item.exclusive,
        itemStyle: {
          color: colors.graph.green[500],
        },
      },
      {
        name: "Other ODA",
        value: item.other,
        itemStyle: {
          color: colors.primary.blue,
        },
      },
    ];
  }, [dataBarChart]);

  const pieChartYear = React.useMemo(() => {
    if (dataBarChart.length === 0) {
      return "";
    }
    return dataBarChart[dataBarChart.length - 1].name;
  }, [dataBarChart]);

  const pieTotal = React.useMemo(() => {
    if (dataBarChart.length === 0) {
      return 0;
    }
    const item = dataBarChart[dataBarChart.length - 1];
    return item.exclusive ?? 0 + (item.other ?? 0);
  }, [dataPieChart]);

  const coordinates = React.useMemo(() => {
    return getCoordinatesFromISO2(params.id ?? "");
  }, [params.id]);

  React.useEffect(() => {
    const filters = getAPIFormattedFilters({
      ...appliedFilters,
      countries: [params.id ?? ""],
    });
    fetchPageInfo({
      values: {
        filters,
      },
    });
    fetchBarChart({
      values: {
        filters,
        type: "oda",
      },
    });
    fetchLegacyPageInfo({
      values: {
        detail_type: "recipient-country.code",
        filters,
      },
    });
  }, [params.id, appliedFilters]);

  React.useEffect(() => {
    if (pieContainerRef.current) {
      const chart = echarts.init(pieContainerRef.current, undefined, {
        renderer: "svg",
      });

      const option: echarts.ComposeOption<
        PieSeriesOption | TooltipComponentOption | LegendComponentOption
      > = {
        series: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          type: "pie",
          radius: "70%",
          data: dataPieChart,
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
        },
        legend: {
          bottom: 0,
          itemGap: 40,
          itemWidth: 14,
          icon: "circle",
          orient: "horizontal",
          textStyle: {
            fontSize: 14,
            fontFamily: "Finlandica",
            color: colors.primary.blue,
          },
        },
        tooltip: {
          ...pageChartTooltipCommonConfig(mobile),
          valueFormatter: (value) => {
            return formatLocale(value as number);
          },
        },
      };

      chart.setOption(option);
    }
  }, [pieContainerRef.current, dataPieChart]);

  const news = React.useMemo(() => {
    const items = get(legacydataPageInfo, "data.news", []);
    if (items.length === 0) {
      return null;
    }
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: "20px",
          borderRadius: "20px",
          boxShadow: colors.shadows.inner,
          background: colors.primary.white,
        }}
      >
        <Typography sx={{ marginBottom: "8px" }} variant="subtitle1">
          {get(
            cmsData,
            "pages.country_rss_feed",
            "Current Affairs Related to the Country"
          )}
        </Typography>
        <Box
          sx={{
            width: "100%",
            maxHeight: "152px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              background: colors.secondary.blueGrayLight,
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: "4px",
              background: colors.secondary.blueGrayLight,
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              background: colors.primary.blue,
            },
            "> a": {
              fontSize: "14px",
              display: "block",
              marginBottom: "10px",
              color: colors.primary.blue,
            },
          }}
        >
          {get(legacydataPageInfo, "data.news", []).map((item: any) => (
            <a
              target="_blank"
              key={item.title}
              href={item.link}
              rel="noreferrer noopener"
            >
              {item.title}
            </a>
          ))}
        </Box>
      </Box>
    );
  }, [legacydataPageInfo]);

  const results = React.useMemo(() => {
    const items = get(legacydataPageInfo, "data.results", []);
    if (items.length === 0) {
      return null;
    }
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: "20px",
          borderRadius: "20px",
          boxShadow: colors.shadows.inner,
          background: colors.primary.white,
        }}
      >
        <Typography variant="subtitle1">
          Results’ Stories Related to the Country
        </Typography>
      </Box>
    );
  }, [legacydataPageInfo]);

  const contact = React.useMemo(() => {
    const items = get(legacydataPageInfo, "data.contact", {});
    if (Object.keys(items).length === 0) {
      return null;
    }
    return (
      <Box
        sx={{
          width: "100%",
          padding: "20px",
          borderRadius: "20px",
          boxShadow: colors.shadows.inner,
          background: colors.primary.white,
          h6: {
            marginBottom: "8px",
          },
          a: {
            fontSize: "14px",
            display: "block",
            marginBottom: "8px",
            color: colors.primary.blue,
          },
        }}
      >
        <Typography variant="subtitle1">Contact Details</Typography>
        <a
          href={get(legacydataPageInfo, "data.contact.link", "")}
          target="_blank"
          rel="noreferrer noopener"
        >
          {get(legacydataPageInfo, "data.contact.title", "")}
        </a>
        <a
          href={get(legacydataPageInfo, "data.contact.embassy.link", "")}
          target="_blank"
          rel="noreferrer noopener"
        >
          {get(legacydataPageInfo, "data.contact.embassy.title", "")}
        </a>
        <Typography variant="subtitle1">Other Links</Typography>
        <div
          dangerouslySetInnerHTML={{
            __html:
              get(
                legacydataPageInfo,
                `data.contact.strategy["${currentLanguage}"]`,
                ""
              ) ?? "",
          }}
        />
      </Box>
    );
  }, [legacydataPageInfo, currentLanguage]);

  const gridView = React.useMemo(() => {
    if (news && results && contact) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {news}
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {results}
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            {contact}
          </Grid>
        </React.Fragment>
      );
    }
    if (!news && results && contact) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            {results}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            {contact}
          </Grid>
        </React.Fragment>
      );
    }
    if (news && !results && contact) {
      return (
        <React.Fragment>
          <Grid item xs={12} sm={6} md={8} lg={8}>
            {news}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            {contact}
          </Grid>
        </React.Fragment>
      );
    }
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {contact}
      </Grid>
    );
  }, [news, results, contact]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "20px",
        marginBottom: "50px",
        boxShadow: colors.shadows.inner,
        background: colors.primary.gray,
      }}
    >
      <Box
        sx={{
          gap: "20px",
          width: "100%",
          display: "flex",
          marginBottom: "20px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Flag code={params.id} width={62} />
        <Typography variant="h3" fontWeight="700">
          {get(dataPageInfo, "data.name", params.id)}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Box
            sx={{
              width: "100%",
              height: "276px",
              padding: "20px",
              borderRadius: "20px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Box
              sx={{
                gap: "5px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle2">
                Disbursements for {pieChartYear}
              </Typography>
              <InfoButton
                fontSize="small"
                text={get(cmsData, "tooltips.disbursements_overview", "")}
              />
            </Box>
            <Typography variant="subtitle1">
              {formatLocale(pieTotal)}
            </Typography>
            <Box
              ref={pieContainerRef}
              sx={{
                width: "100%",
                height: "200px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={8}>
          <Box
            sx={{
              width: "100%",
              height: "276px",
              borderRadius: "20px",
              pointerEvents: "none",
              "> div": {
                "> div": {
                  width: "100%",
                  height: "276px",
                },
              },
              canvas: {
                borderRadius: "20px",
                boxShadow: colors.shadows.inner,
              },
            }}
          >
            <GeomapChart
              data={[]}
              unallocable={0}
              hideExtraElements
              disableInteraction
              centerCoords={coordinates}
              countryPageName={get(dataPageInfo, "data.name", params.id)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              borderRadius: "20px",
              position: "relative",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
            }}
          >
            <Box
              sx={{
                gap: "5px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle2">
                Disbursements Overview
              </Typography>
              <InfoButton
                fontSize="small"
                text={get(cmsData, "tooltips.disbursements_overview", "")}
              />
            </Box>
            <Typography variant="body2">
              Disbursements overview bar graph showing disbursed amount
              throughout years to {get(dataPageInfo, "data.name", params.id)}.
            </Typography>
            <Box
              sx={{
                "> div": {
                  height: "250px",
                },
              }}
            >
              <BarLineChart
                detailPage
                dataVariant="oda"
                data={dataBarChart}
                disableDataZoomFeature={!mobile && !tablet}
                barWidth={!mobile ? (!tablet ? 60 : 25) : 15}
              />
            </Box>
            <Box
              sx={{
                gap: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "> div": {
                  gap: "5px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: colors.graph.green[500],
                  }}
                />
                <Typography variant="body2">Exclusive ODA</Typography>
              </Box>
              <Box>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    background: colors.primary.blue,
                  }}
                />
                <Typography variant="body2">Other ODA</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        {gridView}
      </Grid>
    </Box>
  );
};

export function LocationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const cmsData = useCMSData({ returnData: true });
  const tablet = useMediaQuery("(max-width: 920px)");
  const mobile = useMediaQuery("(max-width: 768px)");

  const appliedFilters = useStoreState((state) => state.appliedFilters);
  const dataPageInfo = useStoreState((state) => state.locationPageInfo.data);
  const selectedLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  useTitle(
    `${get(dataPageInfo, "data.name", params.id ?? "")} | ${getAppName(
      selectedLanguage
    )}`
  );

  const fetchDisbursementsOverviewSankey = useStoreActions(
    (actions) => actions.disbursementsOverviewSankeyLocation.fetch
  );
  const dataDisbursementsOverviewSankey = useStoreState((state) =>
    get(state.disbursementsOverviewSankeyLocation, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingDisbursementsOverviewSankey = useStoreState(
    (state) => state.disbursementsOverviewSankeyLocation.loading
  );
  const fetchPrioritiesChart = useStoreActions(
    (actions) => actions.prioritiesChartLocation.fetch
  );
  const dataPrioritiesChart = useStoreState((state) =>
    get(state.prioritiesChartLocation, "data.vizData", [])
  );
  const loadingPrioritiesChart = useStoreState(
    (state) => state.prioritiesChartLocation.loading
  );
  const fetchTop5Sectors = useStoreActions(
    (actions) => actions.top5sectorsLocation.fetch
  );
  const dataTop5Sectors = useStoreState((state) =>
    get(state.top5sectorsLocation, "data.vizData", [])
  );
  const loadingTop5Sectors = useStoreState(
    (state) => state.top5sectorsLocation.loading
  );
  const fetchOrganisationSankey = useStoreActions(
    (actions) => actions.organisationSankeyLocation.fetch
  );
  const dataOrganisationSankey = useStoreState((state) =>
    get(state.organisationSankeyLocation, "data.vizData", {
      nodes: [],
      links: [],
    })
  );
  const loadingOrganisationSankey = useStoreState(
    (state) => state.organisationSankeyLocation.loading
  );
  const fetchSDGs = useStoreActions((actions) => actions.sdgsLocation.fetch);
  const dataSDGs = useStoreState((state) =>
    get(state.sdgsLocation, "data.vizData", [])
  );
  const loadingSDGs = useStoreState((state) => state.sdgsLocation.loading);
  const fetchTop10Activities = useStoreActions(
    (actions) => actions.top10activitiesLocation.fetch
  );
  const dataTop10Activities = useStoreState((state) =>
    get(state.top10activitiesLocation, "data.vizData", [])
  );
  const loadingTop10Activities = useStoreState(
    (state) => state.top10activitiesLocation.loading
  );
  const fetchTotalValue = useStoreActions(
    (actions) => actions.totalValue.fetch
  );

  useMount(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  React.useEffect(() => {
    const filters = getAPIFormattedFilters({
      ...appliedFilters,
      countries: [params.id ?? ""],
    });
    fetchDisbursementsOverviewSankey({
      values: {
        filters,
        id: params.id,
        type: "org-country",
      },
    });
    fetchPrioritiesChart({
      values: {
        filters,
      },
    });
    fetchTop5Sectors({
      values: {
        filters,
      },
    });
    fetchOrganisationSankey({
      values: {
        filters,
      },
    });
    fetchSDGs({
      values: {
        filters,
      },
    });
    fetchTop10Activities({
      values: {
        filters,
      },
    });
    fetchTotalValue({
      values: {
        filters,
      },
    });
  }, [params.id, appliedFilters]);

  const header = React.useMemo(() => {
    const breadcrumbs = (
      <Breadcrumbs
        route={[
          {
            label: "Homepage",
            path: "/:lang",
            cmsKey: "breadcrumbs.homepage",
          },
          {
            label: "Countries & Regions",
            path: "/:lang/countries-regions",
            cmsKey: "pageSections.CountriesRegionsTitle",
          },
          {
            label: get(dataPageInfo, "data.name", params.id ?? ""),
          },
        ]}
      />
    );

    const title = (
      <Box
        sx={{
          gap: "15px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          "@media (max-width: 920px)": {
            marginBottom: "20px",
          },
        }}
      >
        <Box>
          <CountriesRegionsIcon />
        </Box>
        <Box>
          <Box
            sx={{
              gap: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              {get(dataPageInfo, "data.name", params.id)}
            </Typography>
            <InfoButton
              fontSize="small"
              text={get(cmsData, "tooltips.location_name", "")}
            />
          </Box>
          <Typography component="div">
            Showcasing {get(dataPageInfo, "data.name", params.id)} as a partner
            country of the Finnish ODA
          </Typography>
        </Box>
      </Box>
    );

    const toolbar = (
      <Box
        sx={{
          gap: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          "@media (max-width: 768px)": {
            width: "100%",
            justifyContent: "space-between",
          },
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBack fontSize="small" />}
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            padding: "4px 8px",
            textTransform: "none",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          {get(cmsData, "general.back", "Back")}
        </Button>
        {!mobile ? (
          <FloatingButtons noFloating />
        ) : (
          <Box>
            <FloatingButtons noFloating />
          </Box>
        )}
      </Box>
    );

    const spacer = <Box sx={{ height: "20px" }} />;

    if (!tablet && !mobile) {
      return (
        <React.Fragment>
          {breadcrumbs}
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              flexDirection: "row",
              marginBottom: "50px",
              justifyContent: "space-between",
            }}
          >
            {title}
            {toolbar}
          </Box>
        </React.Fragment>
      );
    } else if (mobile) {
      return (
        <React.Fragment>
          {breadcrumbs}
          {spacer}
          {toolbar}
          {spacer}
          {title}
        </React.Fragment>
      );
    } else if (tablet) {
      return (
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              flexDirection: "row",
              marginBottom: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {breadcrumbs}
            {toolbar}
          </Box>
          {title}
        </React.Fragment>
      );
    }
  }, [tablet, mobile, cmsData, params.id, dataPageInfo]);

  return (
    <React.Fragment>
      <CanonicalUrl canonicalUrl={`/countries/${params.id}`} />
      <Box
        sx={{
          "@media (max-width: 920px)": {
            padding: "0 48px",
          },
          "@media (max-width: 768px)": {
            padding: "0 24px",
          },
        }}
      >
        {header}
        <LocationPageIntroBlock />
        <PageSection
          background="#F6F9FD"
          loading={loadingDisbursementsOverviewSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.LocationPageDisbursementsOverviewTitle",
              "Disbursements Overview"
            ),
            subtitle: get(
              cmsData,
              "pageSections.LocationPageDisbursementsOverviewSubtitle",
              "Disbursements overview of organisations to this country."
            ),
            info: get(cmsData, "tooltips.disbursements_overview", ""),
            icon: <DisbursementsOverviewIcon />,
          }}
        >
          <SankeyChart country {...dataDisbursementsOverviewSankey} />
        </PageSection>
        <PageSection
          background="#FFFAF9"
          loading={loadingPrioritiesChart}
          header={{
            title: get(cmsData, "pageSections.PrioritiesTitle", "Priorities"),
            subtitle: get(
              cmsData,
              "pageSections.PrioritiesSubtitle",
              "Prioritised areas of Finnish ODA."
            ),
            info: get(cmsData, "tooltips.thematic_areas", ""),
            icon: <PrioritiesIcon />,
          }}
          sx={{ overflow: "visible" }}
        >
          <PrioritiesChart items={dataPrioritiesChart} />
        </PageSection>
        <PageSection
          background="#F9F9FC"
          loading={loadingTop5Sectors}
          header={{
            title: get(cmsData, "pageSections.SectorsTitle", "Sectors"),
            subtitle: get(
              cmsData,
              "pageSections.SectorsSubtitle",
              "The 5 Largest Sectors of Finnish ODA in a descending order."
            ),
            info: get(cmsData, "tooltips.sectors", ""),
            icon: <SectorsIcon />,
          }}
          sx={{ overflow: "visible" }}
        >
          <SectorsChart items={dataTop5Sectors} />
        </PageSection>
        <PageSection
          background="#FFFCF7"
          loading={loadingOrganisationSankey}
          header={{
            title: get(
              cmsData,
              "pageSections.OrganisationsTitle",
              "Organisations"
            ),
            subtitle: get(
              cmsData,
              "pageSections.OrganisationsSubtitle",
              "5 partner organisations of Finnish ODA with the largest budget."
            ),
            info: get(cmsData, "tooltips.organisations", ""),
            icon: <OrganisationsIcon />,
          }}
        >
          <SankeyChart organisation {...dataOrganisationSankey} />
        </PageSection>
        <PageSection
          background="#F9FCFE"
          loading={loadingSDGs}
          header={{
            title: get(cmsData, "pageSections.SDGsTitle", "SDGs"),
            subtitle: get(
              cmsData,
              "pageSections.SDGsSubtitle",
              "SDGs listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.sdg", ""),
            icon: <SDGsIcon />,
          }}
        >
          <SdgsChart items={dataSDGs} />
        </PageSection>
        <PageSection
          background="#FCFBF9"
          loading={loadingTop10Activities}
          header={{
            title: get(cmsData, "pageSections.ActivitiesTitle", "Activities"),
            subtitle: get(
              cmsData,
              "pageSections.ActivitiesSubtitle",
              "Top 10 largest activities listed from descending order for Finnish ODA."
            ),
            info: get(cmsData, "tooltips.activities", ""),
            icon: <ActivitiesIcon />,
          }}
        >
          <Link
            id="see-all-activities-comp"
            to={`/${selectedLanguage}/activities${
              location.search.length > 0 ? `${location.search}&` : "?"
            }recipient-country.code=${params.id}`}
          >
            {get(cmsData, "viz.seeallactivities", "See All Activities")}{" "}
            <ArrowForward fontSize="small" />
          </Link>
          <ActivitiesChart items={dataTop10Activities} />
        </PageSection>
      </Box>
    </React.Fragment>
  );
}
