const data = [
  {
    Country: "Afghanistan",
    ISO2: "AF",
    ISO3: "AFG",
    "Numeric code": 4,
    Latitude: 33,
    Longitude: 65,
  },
  {
    Country: "Albania",
    ISO2: "AL",
    ISO3: "ALB",
    "Numeric code": 8,
    Latitude: 41,
    Longitude: 20,
  },
  {
    Country: "Algeria",
    ISO2: "DZ",
    ISO3: "DZA",
    "Numeric code": 12,
    Latitude: 28,
    Longitude: 3,
  },
  {
    Country: "American Samoa",
    ISO2: "AS",
    ISO3: "ASM",
    "Numeric code": 16,
    Latitude: -14.3333,
    Longitude: -170,
  },
  {
    Country: "Andorra",
    ISO2: "AD",
    ISO3: "AND",
    "Numeric code": 20,
    Latitude: 42.5,
    Longitude: 1.6,
  },
  {
    Country: "Angola",
    ISO2: "AO",
    ISO3: "AGO",
    "Numeric code": 24,
    Latitude: -12.5,
    Longitude: 18.5,
  },
  {
    Country: "Anguilla",
    ISO2: "AI",
    ISO3: "AIA",
    "Numeric code": 660,
    Latitude: 18.25,
    Longitude: -63.1667,
  },
  {
    Country: "Antarctica",
    ISO2: "AQ",
    ISO3: "ATA",
    "Numeric code": 10,
    Latitude: -90,
    Longitude: 0,
  },
  {
    Country: "Antigua and Barbuda",
    ISO2: "AG",
    ISO3: "ATG",
    "Numeric code": 28,
    Latitude: 17.05,
    Longitude: -61.8,
  },
  {
    Country: "Argentina",
    ISO2: "AR",
    ISO3: "ARG",
    "Numeric code": 32,
    Latitude: -34,
    Longitude: -64,
  },
  {
    Country: "Armenia",
    ISO2: "AM",
    ISO3: "ARM",
    "Numeric code": 51,
    Latitude: 40,
    Longitude: 45,
  },
  {
    Country: "Aruba",
    ISO2: "AW",
    ISO3: "ABW",
    "Numeric code": 533,
    Latitude: 12.5,
    Longitude: -69.9667,
  },
  {
    Country: "Australia",
    ISO2: "AU",
    ISO3: "AUS",
    "Numeric code": 36,
    Latitude: -27,
    Longitude: 133,
  },
  {
    Country: "Austria",
    ISO2: "AT",
    ISO3: "AUT",
    "Numeric code": 40,
    Latitude: 47.3333,
    Longitude: 13.3333,
  },
  {
    Country: "Azerbaijan",
    ISO2: "AZ",
    ISO3: "AZE",
    "Numeric code": 31,
    Latitude: 40.5,
    Longitude: 47.5,
  },
  {
    Country: "Bahamas",
    ISO2: "BS",
    ISO3: "BHS",
    "Numeric code": 44,
    Latitude: 24.25,
    Longitude: -76,
  },
  {
    Country: "Bahrain",
    ISO2: "BH",
    ISO3: "BHR",
    "Numeric code": 48,
    Latitude: 26,
    Longitude: 50.55,
  },
  {
    Country: "Bangladesh",
    ISO2: "BD",
    ISO3: "BGD",
    "Numeric code": 50,
    Latitude: 24,
    Longitude: 90,
  },
  {
    Country: "Barbados",
    ISO2: "BB",
    ISO3: "BRB",
    "Numeric code": 52,
    Latitude: 13.1667,
    Longitude: -59.5333,
  },
  {
    Country: "Belarus",
    ISO2: "BY",
    ISO3: "BLR",
    "Numeric code": 112,
    Latitude: 53,
    Longitude: 28,
  },
  {
    Country: "Belgium",
    ISO2: "BE",
    ISO3: "BEL",
    "Numeric code": 56,
    Latitude: 50.8333,
    Longitude: 4,
  },
  {
    Country: "Belize",
    ISO2: "BZ",
    ISO3: "BLZ",
    "Numeric code": 84,
    Latitude: 17.25,
    Longitude: -88.75,
  },
  {
    Country: "Benin",
    ISO2: "BJ",
    ISO3: "BEN",
    "Numeric code": 204,
    Latitude: 9.5,
    Longitude: 2.25,
  },
  {
    Country: "Bermuda",
    ISO2: "BM",
    ISO3: "BMU",
    "Numeric code": 60,
    Latitude: 32.3333,
    Longitude: -64.75,
  },
  {
    Country: "Bhutan",
    ISO2: "BT",
    ISO3: "BTN",
    "Numeric code": 64,
    Latitude: 27.5,
    Longitude: 90.5,
  },
  {
    Country: "Bolivia, Plurinational State of",
    ISO2: "BO",
    ISO3: "BOL",
    "Numeric code": 68,
    Latitude: -17,
    Longitude: -65,
  },
  {
    Country: "Bolivia",
    ISO2: "BO",
    ISO3: "BOL",
    "Numeric code": 68,
    Latitude: -17,
    Longitude: -65,
  },
  {
    Country: "Bosnia and Herzegovina",
    ISO2: "BA",
    ISO3: "BIH",
    "Numeric code": 70,
    Latitude: 44,
    Longitude: 18,
  },
  {
    Country: "Botswana",
    ISO2: "BW",
    ISO3: "BWA",
    "Numeric code": 72,
    Latitude: -22,
    Longitude: 24,
  },
  {
    Country: "Bouvet Island",
    ISO2: "BV",
    ISO3: "BVT",
    "Numeric code": 74,
    Latitude: -54.4333,
    Longitude: 3.4,
  },
  {
    Country: "Brazil",
    ISO2: "BR",
    ISO3: "BRA",
    "Numeric code": 76,
    Latitude: -10,
    Longitude: -55,
  },
  {
    Country: "British Indian Ocean Territory",
    ISO2: "IO",
    ISO3: "IOT",
    "Numeric code": 86,
    Latitude: -6,
    Longitude: 71.5,
  },
  {
    Country: "Brunei Darussalam",
    ISO2: "BN",
    ISO3: "BRN",
    "Numeric code": 96,
    Latitude: 4.5,
    Longitude: 114.6667,
  },
  {
    Country: "Brunei",
    ISO2: "BN",
    ISO3: "BRN",
    "Numeric code": 96,
    Latitude: 4.5,
    Longitude: 114.6667,
  },
  {
    Country: "Bulgaria",
    ISO2: "BG",
    ISO3: "BGR",
    "Numeric code": 100,
    Latitude: 43,
    Longitude: 25,
  },
  {
    Country: "Burkina Faso",
    ISO2: "BF",
    ISO3: "BFA",
    "Numeric code": 854,
    Latitude: 13,
    Longitude: -2,
  },
  {
    Country: "Burundi",
    ISO2: "BI",
    ISO3: "BDI",
    "Numeric code": 108,
    Latitude: -3.5,
    Longitude: 30,
  },
  {
    Country: "Cambodia",
    ISO2: "KH",
    ISO3: "KHM",
    "Numeric code": 116,
    Latitude: 13,
    Longitude: 105,
  },
  {
    Country: "Cameroon",
    ISO2: "CM",
    ISO3: "CMR",
    "Numeric code": 120,
    Latitude: 6,
    Longitude: 12,
  },
  {
    Country: "Canada",
    ISO2: "CA",
    ISO3: "CAN",
    "Numeric code": 124,
    Latitude: 60,
    Longitude: -95,
  },
  {
    Country: "Cape Verde",
    ISO2: "CV",
    ISO3: "CPV",
    "Numeric code": 132,
    Latitude: 16,
    Longitude: -24,
  },
  {
    Country: "Cayman Islands",
    ISO2: "KY",
    ISO3: "CYM",
    "Numeric code": 136,
    Latitude: 19.5,
    Longitude: -80.5,
  },
  {
    Country: "Central African Republic",
    ISO2: "CF",
    ISO3: "CAF",
    "Numeric code": 140,
    Latitude: 7,
    Longitude: 21,
  },
  {
    Country: "Chad",
    ISO2: "TD",
    ISO3: "TCD",
    "Numeric code": 148,
    Latitude: 15,
    Longitude: 19,
  },
  {
    Country: "Chile",
    ISO2: "CL",
    ISO3: "CHL",
    "Numeric code": 152,
    Latitude: -30,
    Longitude: -71,
  },
  {
    Country: "China",
    ISO2: "CN",
    ISO3: "CHN",
    "Numeric code": 156,
    Latitude: 35,
    Longitude: 105,
  },
  {
    Country: "Christmas Island",
    ISO2: "CX",
    ISO3: "CXR",
    "Numeric code": 162,
    Latitude: -10.5,
    Longitude: 105.6667,
  },
  {
    Country: "Cocos (Keeling) Islands",
    ISO2: "CC",
    ISO3: "CCK",
    "Numeric code": 166,
    Latitude: -12.5,
    Longitude: 96.8333,
  },
  {
    Country: "Colombia",
    ISO2: "CO",
    ISO3: "COL",
    "Numeric code": 170,
    Latitude: 4,
    Longitude: -72,
  },
  {
    Country: "Comoros",
    ISO2: "KM",
    ISO3: "COM",
    "Numeric code": 174,
    Latitude: -12.1667,
    Longitude: 44.25,
  },
  {
    Country: "Congo",
    ISO2: "CG",
    ISO3: "COG",
    "Numeric code": 178,
    Latitude: -1,
    Longitude: 15,
  },
  {
    Country: "Congo, the Democratic Republic of the",
    ISO2: "CD",
    ISO3: "COD",
    "Numeric code": 180,
    Latitude: 0,
    Longitude: 25,
  },
  {
    Country: "Cook Islands",
    ISO2: "CK",
    ISO3: "COK",
    "Numeric code": 184,
    Latitude: -21.2333,
    Longitude: -159.7667,
  },
  {
    Country: "Costa Rica",
    ISO2: "CR",
    ISO3: "CRI",
    "Numeric code": 188,
    Latitude: 10,
    Longitude: -84,
  },
  {
    Country: "Côte d'Ivoire",
    ISO2: "CI",
    ISO3: "CIV",
    "Numeric code": 384,
    Latitude: 8,
    Longitude: -5,
  },
  {
    Country: "Ivory Coast",
    ISO2: "CI",
    ISO3: "CIV",
    "Numeric code": 384,
    Latitude: 8,
    Longitude: -5,
  },
  {
    Country: "Croatia",
    ISO2: "HR",
    ISO3: "HRV",
    "Numeric code": 191,
    Latitude: 45.1667,
    Longitude: 15.5,
  },
  {
    Country: "Cuba",
    ISO2: "CU",
    ISO3: "CUB",
    "Numeric code": 192,
    Latitude: 21.5,
    Longitude: -80,
  },
  {
    Country: "Cyprus",
    ISO2: "CY",
    ISO3: "CYP",
    "Numeric code": 196,
    Latitude: 35,
    Longitude: 33,
  },
  {
    Country: "Czech Republic",
    ISO2: "CZ",
    ISO3: "CZE",
    "Numeric code": 203,
    Latitude: 49.75,
    Longitude: 15.5,
  },
  {
    Country: "Denmark",
    ISO2: "DK",
    ISO3: "DNK",
    "Numeric code": 208,
    Latitude: 56,
    Longitude: 10,
  },
  {
    Country: "Djibouti",
    ISO2: "DJ",
    ISO3: "DJI",
    "Numeric code": 262,
    Latitude: 11.5,
    Longitude: 43,
  },
  {
    Country: "Dominica",
    ISO2: "DM",
    ISO3: "DMA",
    "Numeric code": 212,
    Latitude: 15.4167,
    Longitude: -61.3333,
  },
  {
    Country: "Dominican Republic",
    ISO2: "DO",
    ISO3: "DOM",
    "Numeric code": 214,
    Latitude: 19,
    Longitude: -70.6667,
  },
  {
    Country: "Ecuador",
    ISO2: "EC",
    ISO3: "ECU",
    "Numeric code": 218,
    Latitude: -2,
    Longitude: -77.5,
  },
  {
    Country: "Egypt",
    ISO2: "EG",
    ISO3: "EGY",
    "Numeric code": 818,
    Latitude: 27,
    Longitude: 30,
  },
  {
    Country: "El Salvador",
    ISO2: "SV",
    ISO3: "SLV",
    "Numeric code": 222,
    Latitude: 13.8333,
    Longitude: -88.9167,
  },
  {
    Country: "Equatorial Guinea",
    ISO2: "GQ",
    ISO3: "GNQ",
    "Numeric code": 226,
    Latitude: 2,
    Longitude: 10,
  },
  {
    Country: "Eritrea",
    ISO2: "ER",
    ISO3: "ERI",
    "Numeric code": 232,
    Latitude: 15,
    Longitude: 39,
  },
  {
    Country: "Estonia",
    ISO2: "EE",
    ISO3: "EST",
    "Numeric code": 233,
    Latitude: 59,
    Longitude: 26,
  },
  {
    Country: "Ethiopia",
    ISO2: "ET",
    ISO3: "ETH",
    "Numeric code": 231,
    Latitude: 8,
    Longitude: 38,
  },
  {
    Country: "Falkland Islands (Malvinas)",
    ISO2: "FK",
    ISO3: "FLK",
    "Numeric code": 238,
    Latitude: -51.75,
    Longitude: -59,
  },
  {
    Country: "Faroe Islands",
    ISO2: "FO",
    ISO3: "FRO",
    "Numeric code": 234,
    Latitude: 62,
    Longitude: -7,
  },
  {
    Country: "Fiji",
    ISO2: "FJ",
    ISO3: "FJI",
    "Numeric code": 242,
    Latitude: -18,
    Longitude: 175,
  },
  {
    Country: "Finland",
    ISO2: "FI",
    ISO3: "FIN",
    "Numeric code": 246,
    Latitude: 64,
    Longitude: 26,
  },
  {
    Country: "France",
    ISO2: "FR",
    ISO3: "FRA",
    "Numeric code": 250,
    Latitude: 46,
    Longitude: 2,
  },
  {
    Country: "French Guiana",
    ISO2: "GF",
    ISO3: "GUF",
    "Numeric code": 254,
    Latitude: 4,
    Longitude: -53,
  },
  {
    Country: "French Polynesia",
    ISO2: "PF",
    ISO3: "PYF",
    "Numeric code": 258,
    Latitude: -15,
    Longitude: -140,
  },
  {
    Country: "French Southern Territories",
    ISO2: "TF",
    ISO3: "ATF",
    "Numeric code": 260,
    Latitude: -43,
    Longitude: 67,
  },
  {
    Country: "Gabon",
    ISO2: "GA",
    ISO3: "GAB",
    "Numeric code": 266,
    Latitude: -1,
    Longitude: 11.75,
  },
  {
    Country: "Gambia",
    ISO2: "GM",
    ISO3: "GMB",
    "Numeric code": 270,
    Latitude: 13.4667,
    Longitude: -16.5667,
  },
  {
    Country: "Georgia",
    ISO2: "GE",
    ISO3: "GEO",
    "Numeric code": 268,
    Latitude: 42,
    Longitude: 43.5,
  },
  {
    Country: "Germany",
    ISO2: "DE",
    ISO3: "DEU",
    "Numeric code": 276,
    Latitude: 51,
    Longitude: 9,
  },
  {
    Country: "Ghana",
    ISO2: "GH",
    ISO3: "GHA",
    "Numeric code": 288,
    Latitude: 8,
    Longitude: -2,
  },
  {
    Country: "Gibraltar",
    ISO2: "GI",
    ISO3: "GIB",
    "Numeric code": 292,
    Latitude: 36.1833,
    Longitude: -5.3667,
  },
  {
    Country: "Greece",
    ISO2: "GR",
    ISO3: "GRC",
    "Numeric code": 300,
    Latitude: 39,
    Longitude: 22,
  },
  {
    Country: "Greenland",
    ISO2: "GL",
    ISO3: "GRL",
    "Numeric code": 304,
    Latitude: 72,
    Longitude: -40,
  },
  {
    Country: "Grenada",
    ISO2: "GD",
    ISO3: "GRD",
    "Numeric code": 308,
    Latitude: 12.1167,
    Longitude: -61.6667,
  },
  {
    Country: "Guadeloupe",
    ISO2: "GP",
    ISO3: "GLP",
    "Numeric code": 312,
    Latitude: 16.25,
    Longitude: -61.5833,
  },
  {
    Country: "Guam",
    ISO2: "GU",
    ISO3: "GUM",
    "Numeric code": 316,
    Latitude: 13.4667,
    Longitude: 144.7833,
  },
  {
    Country: "Guatemala",
    ISO2: "GT",
    ISO3: "GTM",
    "Numeric code": 320,
    Latitude: 15.5,
    Longitude: -90.25,
  },
  {
    Country: "Guernsey",
    ISO2: "GG",
    ISO3: "GGY",
    "Numeric code": 831,
    Latitude: 49.5,
    Longitude: -2.56,
  },
  {
    Country: "Guinea",
    ISO2: "GN",
    ISO3: "GIN",
    "Numeric code": 324,
    Latitude: 11,
    Longitude: -10,
  },
  {
    Country: "Guinea-Bissau",
    ISO2: "GW",
    ISO3: "GNB",
    "Numeric code": 624,
    Latitude: 12,
    Longitude: -15,
  },
  {
    Country: "Guyana",
    ISO2: "GY",
    ISO3: "GUY",
    "Numeric code": 328,
    Latitude: 5,
    Longitude: -59,
  },
  {
    Country: "Haiti",
    ISO2: "HT",
    ISO3: "HTI",
    "Numeric code": 332,
    Latitude: 19,
    Longitude: -72.4167,
  },
  {
    Country: "Heard Island and McDonald Islands",
    ISO2: "HM",
    ISO3: "HMD",
    "Numeric code": 334,
    Latitude: -53.1,
    Longitude: 72.5167,
  },
  {
    Country: "Holy See (Vatican City State)",
    ISO2: "VA",
    ISO3: "VAT",
    "Numeric code": 336,
    Latitude: 41.9,
    Longitude: 12.45,
  },
  {
    Country: "Honduras",
    ISO2: "HN",
    ISO3: "HND",
    "Numeric code": 340,
    Latitude: 15,
    Longitude: -86.5,
  },
  {
    Country: "Hong Kong",
    ISO2: "HK",
    ISO3: "HKG",
    "Numeric code": 344,
    Latitude: 22.25,
    Longitude: 114.1667,
  },
  {
    Country: "Hungary",
    ISO2: "HU",
    ISO3: "HUN",
    "Numeric code": 348,
    Latitude: 47,
    Longitude: 20,
  },
  {
    Country: "Iceland",
    ISO2: "IS",
    ISO3: "ISL",
    "Numeric code": 352,
    Latitude: 65,
    Longitude: -18,
  },
  {
    Country: "India",
    ISO2: "IN",
    ISO3: "IND",
    "Numeric code": 356,
    Latitude: 20,
    Longitude: 77,
  },
  {
    Country: "Indonesia",
    ISO2: "ID",
    ISO3: "IDN",
    "Numeric code": 360,
    Latitude: -5,
    Longitude: 120,
  },
  {
    Country: "Iran, Islamic Republic of",
    ISO2: "IR",
    ISO3: "IRN",
    "Numeric code": 364,
    Latitude: 32,
    Longitude: 53,
  },
  {
    Country: "Iraq",
    ISO2: "IQ",
    ISO3: "IRQ",
    "Numeric code": 368,
    Latitude: 33,
    Longitude: 44,
  },
  {
    Country: "Ireland",
    ISO2: "IE",
    ISO3: "IRL",
    "Numeric code": 372,
    Latitude: 53,
    Longitude: -8,
  },
  {
    Country: "Isle of Man",
    ISO2: "IM",
    ISO3: "IMN",
    "Numeric code": 833,
    Latitude: 54.23,
    Longitude: -4.55,
  },
  {
    Country: "Israel",
    ISO2: "IL",
    ISO3: "ISR",
    "Numeric code": 376,
    Latitude: 31.5,
    Longitude: 34.75,
  },
  {
    Country: "Italy",
    ISO2: "IT",
    ISO3: "ITA",
    "Numeric code": 380,
    Latitude: 42.8333,
    Longitude: 12.8333,
  },
  {
    Country: "Jamaica",
    ISO2: "JM",
    ISO3: "JAM",
    "Numeric code": 388,
    Latitude: 18.25,
    Longitude: -77.5,
  },
  {
    Country: "Japan",
    ISO2: "JP",
    ISO3: "JPN",
    "Numeric code": 392,
    Latitude: 36,
    Longitude: 138,
  },
  {
    Country: "Jersey",
    ISO2: "JE",
    ISO3: "JEY",
    "Numeric code": 832,
    Latitude: 49.21,
    Longitude: -2.13,
  },
  {
    Country: "Jordan",
    ISO2: "JO",
    ISO3: "JOR",
    "Numeric code": 400,
    Latitude: 31,
    Longitude: 36,
  },
  {
    Country: "Kazakhstan",
    ISO2: "KZ",
    ISO3: "KAZ",
    "Numeric code": 398,
    Latitude: 48,
    Longitude: 68,
  },
  {
    Country: "Kenya",
    ISO2: "KE",
    ISO3: "KEN",
    "Numeric code": 404,
    Latitude: 1,
    Longitude: 38,
  },
  {
    Country: "Kiribati",
    ISO2: "KI",
    ISO3: "KIR",
    "Numeric code": 296,
    Latitude: 1.4167,
    Longitude: 173,
  },
  {
    Country: "Korea, Democratic People's Republic of",
    ISO2: "KP",
    ISO3: "PRK",
    "Numeric code": 408,
    Latitude: 40,
    Longitude: 127,
  },
  {
    Country: "Korea, Republic of",
    ISO2: "KR",
    ISO3: "KOR",
    "Numeric code": 410,
    Latitude: 37,
    Longitude: 127.5,
  },
  {
    Country: "South Korea",
    ISO2: "KR",
    ISO3: "KOR",
    "Numeric code": 410,
    Latitude: 37,
    Longitude: 127.5,
  },
  {
    Country: "Kuwait",
    ISO2: "KW",
    ISO3: "KWT",
    "Numeric code": 414,
    Latitude: 29.3375,
    Longitude: 47.6581,
  },
  {
    Country: "Kyrgyzstan",
    ISO2: "KG",
    ISO3: "KGZ",
    "Numeric code": 417,
    Latitude: 41,
    Longitude: 75,
  },
  {
    Country: "Lao People's Democratic Republic",
    ISO2: "LA",
    ISO3: "LAO",
    "Numeric code": 418,
    Latitude: 18,
    Longitude: 105,
  },
  {
    Country: "Latvia",
    ISO2: "LV",
    ISO3: "LVA",
    "Numeric code": 428,
    Latitude: 57,
    Longitude: 25,
  },
  {
    Country: "Lebanon",
    ISO2: "LB",
    ISO3: "LBN",
    "Numeric code": 422,
    Latitude: 33.8333,
    Longitude: 35.8333,
  },
  {
    Country: "Lesotho",
    ISO2: "LS",
    ISO3: "LSO",
    "Numeric code": 426,
    Latitude: -29.5,
    Longitude: 28.5,
  },
  {
    Country: "Liberia",
    ISO2: "LR",
    ISO3: "LBR",
    "Numeric code": 430,
    Latitude: 6.5,
    Longitude: -9.5,
  },
  {
    Country: "Libyan Arab Jamahiriya",
    ISO2: "LY",
    ISO3: "LBY",
    "Numeric code": 434,
    Latitude: 25,
    Longitude: 17,
  },
  {
    Country: "Libya",
    ISO2: "LY",
    ISO3: "LBY",
    "Numeric code": 434,
    Latitude: 25,
    Longitude: 17,
  },
  {
    Country: "Liechtenstein",
    ISO2: "LI",
    ISO3: "LIE",
    "Numeric code": 438,
    Latitude: 47.1667,
    Longitude: 9.5333,
  },
  {
    Country: "Lithuania",
    ISO2: "LT",
    ISO3: "LTU",
    "Numeric code": 440,
    Latitude: 56,
    Longitude: 24,
  },
  {
    Country: "Luxembourg",
    ISO2: "LU",
    ISO3: "LUX",
    "Numeric code": 442,
    Latitude: 49.75,
    Longitude: 6.1667,
  },
  {
    Country: "Macao",
    ISO2: "MO",
    ISO3: "MAC",
    "Numeric code": 446,
    Latitude: 22.1667,
    Longitude: 113.55,
  },
  {
    Country: "Macedonia, the former Yugoslav Republic of",
    ISO2: "MK",
    ISO3: "MKD",
    "Numeric code": 807,
    Latitude: 41.8333,
    Longitude: 22,
  },
  {
    Country: "Madagascar",
    ISO2: "MG",
    ISO3: "MDG",
    "Numeric code": 450,
    Latitude: -20,
    Longitude: 47,
  },
  {
    Country: "Malawi",
    ISO2: "MW",
    ISO3: "MWI",
    "Numeric code": 454,
    Latitude: -13.5,
    Longitude: 34,
  },
  {
    Country: "Malaysia",
    ISO2: "MY",
    ISO3: "MYS",
    "Numeric code": 458,
    Latitude: 2.5,
    Longitude: 112.5,
  },
  {
    Country: "Maldives",
    ISO2: "MV",
    ISO3: "MDV",
    "Numeric code": 462,
    Latitude: 3.25,
    Longitude: 73,
  },
  {
    Country: "Mali",
    ISO2: "ML",
    ISO3: "MLI",
    "Numeric code": 466,
    Latitude: 17,
    Longitude: -4,
  },
  {
    Country: "Malta",
    ISO2: "MT",
    ISO3: "MLT",
    "Numeric code": 470,
    Latitude: 35.8333,
    Longitude: 14.5833,
  },
  {
    Country: "Marshall Islands",
    ISO2: "MH",
    ISO3: "MHL",
    "Numeric code": 584,
    Latitude: 9,
    Longitude: 168,
  },
  {
    Country: "Martinique",
    ISO2: "MQ",
    ISO3: "MTQ",
    "Numeric code": 474,
    Latitude: 14.6667,
    Longitude: -61,
  },
  {
    Country: "Mauritania",
    ISO2: "MR",
    ISO3: "MRT",
    "Numeric code": 478,
    Latitude: 20,
    Longitude: -12,
  },
  {
    Country: "Mauritius",
    ISO2: "MU",
    ISO3: "MUS",
    "Numeric code": 480,
    Latitude: -20.2833,
    Longitude: 57.55,
  },
  {
    Country: "Mayotte",
    ISO2: "YT",
    ISO3: "MYT",
    "Numeric code": 175,
    Latitude: -12.8333,
    Longitude: 45.1667,
  },
  {
    Country: "Mexico",
    ISO2: "MX",
    ISO3: "MEX",
    "Numeric code": 484,
    Latitude: 23,
    Longitude: -102,
  },
  {
    Country: "Micronesia, Federated States of",
    ISO2: "FM",
    ISO3: "FSM",
    "Numeric code": 583,
    Latitude: 6.9167,
    Longitude: 158.25,
  },
  {
    Country: "Moldova, Republic of",
    ISO2: "MD",
    ISO3: "MDA",
    "Numeric code": 498,
    Latitude: 47,
    Longitude: 29,
  },
  {
    Country: "Monaco",
    ISO2: "MC",
    ISO3: "MCO",
    "Numeric code": 492,
    Latitude: 43.7333,
    Longitude: 7.4,
  },
  {
    Country: "Mongolia",
    ISO2: "MN",
    ISO3: "MNG",
    "Numeric code": 496,
    Latitude: 46,
    Longitude: 105,
  },
  {
    Country: "Montenegro",
    ISO2: "ME",
    ISO3: "MNE",
    "Numeric code": 499,
    Latitude: 42,
    Longitude: 19,
  },
  {
    Country: "Montserrat",
    ISO2: "MS",
    ISO3: "MSR",
    "Numeric code": 500,
    Latitude: 16.75,
    Longitude: -62.2,
  },
  {
    Country: "Morocco",
    ISO2: "MA",
    ISO3: "MAR",
    "Numeric code": 504,
    Latitude: 32,
    Longitude: -5,
  },
  {
    Country: "Mozambique",
    ISO2: "MZ",
    ISO3: "MOZ",
    "Numeric code": 508,
    Latitude: -18.25,
    Longitude: 35,
  },
  {
    Country: "Myanmar",
    ISO2: "MM",
    ISO3: "MMR",
    "Numeric code": 104,
    Latitude: 22,
    Longitude: 98,
  },
  {
    Country: "Burma",
    ISO2: "MM",
    ISO3: "MMR",
    "Numeric code": 104,
    Latitude: 22,
    Longitude: 98,
  },
  {
    Country: "Namibia",
    ISO2: "NA",
    ISO3: "NAM",
    "Numeric code": 516,
    Latitude: -22,
    Longitude: 17,
  },
  {
    Country: "Nauru",
    ISO2: "NR",
    ISO3: "NRU",
    "Numeric code": 520,
    Latitude: -0.5333,
    Longitude: 166.9167,
  },
  {
    Country: "Nepal",
    ISO2: "NP",
    ISO3: "NPL",
    "Numeric code": 524,
    Latitude: 28,
    Longitude: 84,
  },
  {
    Country: "Netherlands",
    ISO2: "NL",
    ISO3: "NLD",
    "Numeric code": 528,
    Latitude: 52.5,
    Longitude: 5.75,
  },
  {
    Country: "Netherlands Antilles",
    ISO2: "AN",
    ISO3: "ANT",
    "Numeric code": 530,
    Latitude: 12.25,
    Longitude: -68.75,
  },
  {
    Country: "New Caledonia",
    ISO2: "NC",
    ISO3: "NCL",
    "Numeric code": 540,
    Latitude: -21.5,
    Longitude: 165.5,
  },
  {
    Country: "New Zealand",
    ISO2: "NZ",
    ISO3: "NZL",
    "Numeric code": 554,
    Latitude: -41,
    Longitude: 174,
  },
  {
    Country: "Nicaragua",
    ISO2: "NI",
    ISO3: "NIC",
    "Numeric code": 558,
    Latitude: 13,
    Longitude: -85,
  },
  {
    Country: "Niger",
    ISO2: "NE",
    ISO3: "NER",
    "Numeric code": 562,
    Latitude: 16,
    Longitude: 8,
  },
  {
    Country: "Nigeria",
    ISO2: "NG",
    ISO3: "NGA",
    "Numeric code": 566,
    Latitude: 10,
    Longitude: 8,
  },
  {
    Country: "Niue",
    ISO2: "NU",
    ISO3: "NIU",
    "Numeric code": 570,
    Latitude: -19.0333,
    Longitude: -169.8667,
  },
  {
    Country: "Norfolk Island",
    ISO2: "NF",
    ISO3: "NFK",
    "Numeric code": 574,
    Latitude: -29.0333,
    Longitude: 167.95,
  },
  {
    Country: "Northern Mariana Islands",
    ISO2: "MP",
    ISO3: "MNP",
    "Numeric code": 580,
    Latitude: 15.2,
    Longitude: 145.75,
  },
  {
    Country: "Norway",
    ISO2: "NO",
    ISO3: "NOR",
    "Numeric code": 578,
    Latitude: 62,
    Longitude: 10,
  },
  {
    Country: "Oman",
    ISO2: "OM",
    ISO3: "OMN",
    "Numeric code": 512,
    Latitude: 21,
    Longitude: 57,
  },
  {
    Country: "Pakistan",
    ISO2: "PK",
    ISO3: "PAK",
    "Numeric code": 586,
    Latitude: 30,
    Longitude: 70,
  },
  {
    Country: "Palau",
    ISO2: "PW",
    ISO3: "PLW",
    "Numeric code": 585,
    Latitude: 7.5,
    Longitude: 134.5,
  },
  {
    Country: "Palestinian Territory, Occupied",
    ISO2: "PS",
    ISO3: "PSE",
    "Numeric code": 275,
    Latitude: 32,
    Longitude: 35.25,
  },
  {
    Country: "Panama",
    ISO2: "PA",
    ISO3: "PAN",
    "Numeric code": 591,
    Latitude: 9,
    Longitude: -80,
  },
  {
    Country: "Papua New Guinea",
    ISO2: "PG",
    ISO3: "PNG",
    "Numeric code": 598,
    Latitude: -6,
    Longitude: 147,
  },
  {
    Country: "Paraguay",
    ISO2: "PY",
    ISO3: "PRY",
    "Numeric code": 600,
    Latitude: -23,
    Longitude: -58,
  },
  {
    Country: "Peru",
    ISO2: "PE",
    ISO3: "PER",
    "Numeric code": 604,
    Latitude: -10,
    Longitude: -76,
  },
  {
    Country: "Philippines",
    ISO2: "PH",
    ISO3: "PHL",
    "Numeric code": 608,
    Latitude: 13,
    Longitude: 122,
  },
  {
    Country: "Pitcairn",
    ISO2: "PN",
    ISO3: "PCN",
    "Numeric code": 612,
    Latitude: -24.7,
    Longitude: -127.4,
  },
  {
    Country: "Poland",
    ISO2: "PL",
    ISO3: "POL",
    "Numeric code": 616,
    Latitude: 52,
    Longitude: 20,
  },
  {
    Country: "Portugal",
    ISO2: "PT",
    ISO3: "PRT",
    "Numeric code": 620,
    Latitude: 39.5,
    Longitude: -8,
  },
  {
    Country: "Puerto Rico",
    ISO2: "PR",
    ISO3: "PRI",
    "Numeric code": 630,
    Latitude: 18.25,
    Longitude: -66.5,
  },
  {
    Country: "Qatar",
    ISO2: "QA",
    ISO3: "QAT",
    "Numeric code": 634,
    Latitude: 25.5,
    Longitude: 51.25,
  },
  {
    Country: "Réunion",
    ISO2: "RE",
    ISO3: "REU",
    "Numeric code": 638,
    Latitude: -21.1,
    Longitude: 55.6,
  },
  {
    Country: "Romania",
    ISO2: "RO",
    ISO3: "ROU",
    "Numeric code": 642,
    Latitude: 46,
    Longitude: 25,
  },
  {
    Country: "Russian Federation",
    ISO2: "RU",
    ISO3: "RUS",
    "Numeric code": 643,
    Latitude: 60,
    Longitude: 100,
  },
  {
    Country: "Russia",
    ISO2: "RU",
    ISO3: "RUS",
    "Numeric code": 643,
    Latitude: 60,
    Longitude: 100,
  },
  {
    Country: "Rwanda",
    ISO2: "RW",
    ISO3: "RWA",
    "Numeric code": 646,
    Latitude: -2,
    Longitude: 30,
  },
  {
    Country: "Saint Helena, Ascension and Tristan da Cunha",
    ISO2: "SH",
    ISO3: "SHN",
    "Numeric code": 654,
    Latitude: -15.9333,
    Longitude: -5.7,
  },
  {
    Country: "Saint Kitts and Nevis",
    ISO2: "KN",
    ISO3: "KNA",
    "Numeric code": 659,
    Latitude: 17.3333,
    Longitude: -62.75,
  },
  {
    Country: "Saint Lucia",
    ISO2: "LC",
    ISO3: "LCA",
    "Numeric code": 662,
    Latitude: 13.8833,
    Longitude: -61.1333,
  },
  {
    Country: "Saint Pierre and Miquelon",
    ISO2: "PM",
    ISO3: "SPM",
    "Numeric code": 666,
    Latitude: 46.8333,
    Longitude: -56.3333,
  },
  {
    Country: "Saint Vincent and the Grenadines",
    ISO2: "VC",
    ISO3: "VCT",
    "Numeric code": 670,
    Latitude: 13.25,
    Longitude: -61.2,
  },
  {
    Country: "Saint Vincent & the Grenadines",
    ISO2: "VC",
    ISO3: "VCT",
    "Numeric code": 670,
    Latitude: 13.25,
    Longitude: -61.2,
  },
  {
    Country: "St. Vincent and the Grenadines",
    ISO2: "VC",
    ISO3: "VCT",
    "Numeric code": 670,
    Latitude: 13.25,
    Longitude: -61.2,
  },
  {
    Country: "Samoa",
    ISO2: "WS",
    ISO3: "WSM",
    "Numeric code": 882,
    Latitude: -13.5833,
    Longitude: -172.3333,
  },
  {
    Country: "San Marino",
    ISO2: "SM",
    ISO3: "SMR",
    "Numeric code": 674,
    Latitude: 43.7667,
    Longitude: 12.4167,
  },
  {
    Country: "Sao Tome and Principe",
    ISO2: "ST",
    ISO3: "STP",
    "Numeric code": 678,
    Latitude: 1,
    Longitude: 7,
  },
  {
    Country: "Saudi Arabia",
    ISO2: "SA",
    ISO3: "SAU",
    "Numeric code": 682,
    Latitude: 25,
    Longitude: 45,
  },
  {
    Country: "Senegal",
    ISO2: "SN",
    ISO3: "SEN",
    "Numeric code": 686,
    Latitude: 14,
    Longitude: -14,
  },
  {
    Country: "Serbia",
    ISO2: "RS",
    ISO3: "SRB",
    "Numeric code": 688,
    Latitude: 44,
    Longitude: 21,
  },
  {
    Country: "Seychelles",
    ISO2: "SC",
    ISO3: "SYC",
    "Numeric code": 690,
    Latitude: -4.5833,
    Longitude: 55.6667,
  },
  {
    Country: "Sierra Leone",
    ISO2: "SL",
    ISO3: "SLE",
    "Numeric code": 694,
    Latitude: 8.5,
    Longitude: -11.5,
  },
  {
    Country: "Singapore",
    ISO2: "SG",
    ISO3: "SGP",
    "Numeric code": 702,
    Latitude: 1.3667,
    Longitude: 103.8,
  },
  {
    Country: "Slovakia",
    ISO2: "SK",
    ISO3: "SVK",
    "Numeric code": 703,
    Latitude: 48.6667,
    Longitude: 19.5,
  },
  {
    Country: "Slovenia",
    ISO2: "SI",
    ISO3: "SVN",
    "Numeric code": 705,
    Latitude: 46,
    Longitude: 15,
  },
  {
    Country: "Solomon Islands",
    ISO2: "SB",
    ISO3: "SLB",
    "Numeric code": 90,
    Latitude: -8,
    Longitude: 159,
  },
  {
    Country: "Somalia",
    ISO2: "SO",
    ISO3: "SOM",
    "Numeric code": 706,
    Latitude: 10,
    Longitude: 49,
  },
  {
    Country: "South Africa",
    ISO2: "ZA",
    ISO3: "ZAF",
    "Numeric code": 710,
    Latitude: -29,
    Longitude: 24,
  },
  {
    Country: "South Georgia and the South Sandwich Islands",
    ISO2: "GS",
    ISO3: "SGS",
    "Numeric code": 239,
    Latitude: -54.5,
    Longitude: -37,
  },
  {
    Country: "South Sudan",
    ISO2: "SS",
    ISO3: "SSD",
    "Numeric code": 728,
    Latitude: 8,
    Longitude: 30,
  },
  {
    Country: "Spain",
    ISO2: "ES",
    ISO3: "ESP",
    "Numeric code": 724,
    Latitude: 40,
    Longitude: -4,
  },
  {
    Country: "Sri Lanka",
    ISO2: "LK",
    ISO3: "LKA",
    "Numeric code": 144,
    Latitude: 7,
    Longitude: 81,
  },
  {
    Country: "Sudan",
    ISO2: "SD",
    ISO3: "SDN",
    "Numeric code": 736,
    Latitude: 15,
    Longitude: 30,
  },
  {
    Country: "Suriname",
    ISO2: "SR",
    ISO3: "SUR",
    "Numeric code": 740,
    Latitude: 4,
    Longitude: -56,
  },
  {
    Country: "Svalbard and Jan Mayen",
    ISO2: "SJ",
    ISO3: "SJM",
    "Numeric code": 744,
    Latitude: 78,
    Longitude: 20,
  },
  {
    Country: "Swaziland",
    ISO2: "SZ",
    ISO3: "SWZ",
    "Numeric code": 748,
    Latitude: -26.5,
    Longitude: 31.5,
  },
  {
    Country: "Sweden",
    ISO2: "SE",
    ISO3: "SWE",
    "Numeric code": 752,
    Latitude: 62,
    Longitude: 15,
  },
  {
    Country: "Switzerland",
    ISO2: "CH",
    ISO3: "CHE",
    "Numeric code": 756,
    Latitude: 47,
    Longitude: 8,
  },
  {
    Country: "Syrian Arab Republic",
    ISO2: "SY",
    ISO3: "SYR",
    "Numeric code": 760,
    Latitude: 35,
    Longitude: 38,
  },
  {
    Country: "Taiwan, Province of China",
    ISO2: "TW",
    ISO3: "TWN",
    "Numeric code": 158,
    Latitude: 23.5,
    Longitude: 121,
  },
  {
    Country: "Taiwan",
    ISO2: "TW",
    ISO3: "TWN",
    "Numeric code": 158,
    Latitude: 23.5,
    Longitude: 121,
  },
  {
    Country: "Tajikistan",
    ISO2: "TJ",
    ISO3: "TJK",
    "Numeric code": 762,
    Latitude: 39,
    Longitude: 71,
  },
  {
    Country: "Tanzania, United Republic of",
    ISO2: "TZ",
    ISO3: "TZA",
    "Numeric code": 834,
    Latitude: -6,
    Longitude: 35,
  },
  {
    Country: "Thailand",
    ISO2: "TH",
    ISO3: "THA",
    "Numeric code": 764,
    Latitude: 15,
    Longitude: 100,
  },
  {
    Country: "Timor-Leste",
    ISO2: "TL",
    ISO3: "TLS",
    "Numeric code": 626,
    Latitude: -8.55,
    Longitude: 125.5167,
  },
  {
    Country: "Togo",
    ISO2: "TG",
    ISO3: "TGO",
    "Numeric code": 768,
    Latitude: 8,
    Longitude: 1.1667,
  },
  {
    Country: "Tokelau",
    ISO2: "TK",
    ISO3: "TKL",
    "Numeric code": 772,
    Latitude: -9,
    Longitude: -172,
  },
  {
    Country: "Tonga",
    ISO2: "TO",
    ISO3: "TON",
    "Numeric code": 776,
    Latitude: -20,
    Longitude: -175,
  },
  {
    Country: "Trinidad and Tobago",
    ISO2: "TT",
    ISO3: "TTO",
    "Numeric code": 780,
    Latitude: 11,
    Longitude: -61,
  },
  {
    Country: "Tunisia",
    ISO2: "TN",
    ISO3: "TUN",
    "Numeric code": 788,
    Latitude: 34,
    Longitude: 9,
  },
  {
    Country: "Turkey",
    ISO2: "TR",
    ISO3: "TUR",
    "Numeric code": 792,
    Latitude: 39,
    Longitude: 35,
  },
  {
    Country: "Turkmenistan",
    ISO2: "TM",
    ISO3: "TKM",
    "Numeric code": 795,
    Latitude: 40,
    Longitude: 60,
  },
  {
    Country: "Turks and Caicos Islands",
    ISO2: "TC",
    ISO3: "TCA",
    "Numeric code": 796,
    Latitude: 21.75,
    Longitude: -71.5833,
  },
  {
    Country: "Tuvalu",
    ISO2: "TV",
    ISO3: "TUV",
    "Numeric code": 798,
    Latitude: -8,
    Longitude: 178,
  },
  {
    Country: "Uganda",
    ISO2: "UG",
    ISO3: "UGA",
    "Numeric code": 800,
    Latitude: 1,
    Longitude: 32,
  },
  {
    Country: "Ukraine",
    ISO2: "UA",
    ISO3: "UKR",
    "Numeric code": 804,
    Latitude: 49,
    Longitude: 32,
  },
  {
    Country: "United Arab Emirates",
    ISO2: "AE",
    ISO3: "ARE",
    "Numeric code": 784,
    Latitude: 24,
    Longitude: 54,
  },
  {
    Country: "United Kingdom",
    ISO2: "GB",
    ISO3: "GBR",
    "Numeric code": 826,
    Latitude: 54,
    Longitude: -2,
  },
  {
    Country: "United States",
    ISO2: "US",
    ISO3: "USA",
    "Numeric code": 840,
    Latitude: 38,
    Longitude: -97,
  },
  {
    Country: "United States Minor Outlying Islands",
    ISO2: "UM",
    ISO3: "UMI",
    "Numeric code": 581,
    Latitude: 19.2833,
    Longitude: 166.6,
  },
  {
    Country: "Uruguay",
    ISO2: "UY",
    ISO3: "URY",
    "Numeric code": 858,
    Latitude: -33,
    Longitude: -56,
  },
  {
    Country: "Uzbekistan",
    ISO2: "UZ",
    ISO3: "UZB",
    "Numeric code": 860,
    Latitude: 41,
    Longitude: 64,
  },
  {
    Country: "Vanuatu",
    ISO2: "VU",
    ISO3: "VUT",
    "Numeric code": 548,
    Latitude: -16,
    Longitude: 167,
  },
  {
    Country: "Venezuela, Bolivarian Republic of",
    ISO2: "VE",
    ISO3: "VEN",
    "Numeric code": 862,
    Latitude: 8,
    Longitude: -66,
  },
  {
    Country: "Venezuela",
    ISO2: "VE",
    ISO3: "VEN",
    "Numeric code": 862,
    Latitude: 8,
    Longitude: -66,
  },
  {
    Country: "Viet Nam",
    ISO2: "VN",
    ISO3: "VNM",
    "Numeric code": 704,
    Latitude: 16,
    Longitude: 106,
  },
  {
    Country: "Vietnam",
    ISO2: "VN",
    ISO3: "VNM",
    "Numeric code": 704,
    Latitude: 16,
    Longitude: 106,
  },
  {
    Country: "Virgin Islands, British",
    ISO2: "VG",
    ISO3: "VGB",
    "Numeric code": 92,
    Latitude: 18.5,
    Longitude: -64.5,
  },
  {
    Country: "Virgin Islands, U.S.",
    ISO2: "VI",
    ISO3: "VIR",
    "Numeric code": 850,
    Latitude: 18.3333,
    Longitude: -64.8333,
  },
  {
    Country: "Wallis and Futuna",
    ISO2: "WF",
    ISO3: "WLF",
    "Numeric code": 876,
    Latitude: -13.3,
    Longitude: -176.2,
  },
  {
    Country: "Western Sahara",
    ISO2: "EH",
    ISO3: "ESH",
    "Numeric code": 732,
    Latitude: 24.5,
    Longitude: -13,
  },
  {
    Country: "Yemen",
    ISO2: "YE",
    ISO3: "YEM",
    "Numeric code": 887,
    Latitude: 15,
    Longitude: 48,
  },
  {
    Country: "Zambia",
    ISO2: "ZM",
    ISO3: "ZMB",
    "Numeric code": 894,
    Latitude: -15,
    Longitude: 30,
  },
  {
    Country: "Zimbabwe",
    ISO2: "ZW",
    ISO3: "ZWE",
    "Numeric code": 716,
    Latitude: -20,
    Longitude: 30,
  },
];

export function getCoordinatesFromISO2(ISO2: string): [number, number] {
  const country = data.find((c) => c.ISO2 === ISO2);
  if (country) {
    return [country.Longitude, country.Latitude];
  }
  return [0, 0];
}
