import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import * as echarts from "echarts/core";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useCMSData } from "app/hooks/useCMSData";
import { CanvasRenderer } from "echarts/renderers";
import OpenInNew from "@mui/icons-material/OpenInNew";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { PieChart, PieSeriesOption } from "echarts/charts";

echarts.use([PieChart, CanvasRenderer]);

interface Props {
  value: number;
}

export function GeomapChartODAPanel(props: Props) {
  const cmsData = useCMSData({ returnData: true });
  const chartRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = React.useState(true);

  const onButtonClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current, undefined, {
        renderer: "canvas",
      });

      const option: echarts.ComposeOption<PieSeriesOption> = {
        series: [
          {
            name: "oda",
            type: "pie",
            radius: ["50%", "70%"],
            data: [
              {
                name: "y",
                value: props.value,
                itemStyle: {
                  color: colors.primary.blue,
                },
              },
              {
                name: "x",
                value: 100 - props.value,
                itemStyle: {
                  color: colors.secondary.blueGrayDark,
                },
              },
            ],
            cursor: "default",
            itemStyle: {
              borderWidth: 1,
              borderColor: colors.primary.white,
            },
            label: {
              show: false,
            },
            emphasis: {
              disabled: true,
            },
          },
        ],
      };
      chart.setOption(option);
    }
  }, [chartRef.current, props.value]);

  return (
    <Box
      sx={{
        top: 100,
        width: "209px",
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        alignItems: "center",
        right: open ? 20 : -189,
        transition: "right 0.2s",
        "@media (max-width: 768px)": {
          display: "none",
        },
      }}
    >
      <Button
        aria-label="Open ODA chart"
        variant="contained"
        onClick={onButtonClick}
        sx={{
          padding: 0,
          width: "20px",
          height: "63px",
          display: "flex",
          minWidth: "20px",
          borderRadius: "6px",
          alignItems: "center",
          justifyContent: "center",
          background: colors.primary.blue,
        }}
      >
        <ArrowRight
          fontSize="small"
          htmlColor={colors.primary.white}
          sx={{
            color: colors.primary.white + " !important",
            transform: open ? "rotate(0deg)" : "rotate(180deg)",
          }}
        />
      </Button>
      <Box
        sx={{
          width: "189px",
          height: "100%",
          display: "flex",
          padding: "10px",
          borderRadius: "15px",
          flexDirection: "column",
          boxShadow: colors.shadows.main,
          background: colors.primary.white,
        }}
      >
        <Typography variant="subtitle2">
          {get(cmsData, "viz.unallocableoda", "Geograpically Uncallocable ODA")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            ref={chartRef}
            sx={{
              width: "149px",
              height: "149px",
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              position: "absolute",
            }}
          >
            {props.value}%
          </Typography>
        </Box>
        <Typography
          variant="overline"
          sx={{
            lineHeight: "1.2",
            marginBottom: "10px",
          }}
        >
          {props.value}%{" "}
          {get(
            cmsData,
            "viz.unallocableodaexplanation",
            "of actual exclusive ODA is NOT showing on the map."
          )}
        </Typography>
        <MuiLink component={Link} to="/" target="_blank">
          <Typography
            sx={{ fontSize: "9px" }}
            className="MuiTypography-overline2"
          >
            {get(
              cmsData,
              "viz.explorenoncountryallocableoda",
              "Explore non-country allocable ODA"
            )}
            <OpenInNew
              sx={{
                width: "10px",
                height: "10px",
                marginLeft: "5px",
                marginBottom: "-2px",
              }}
            />
          </Typography>
        </MuiLink>
      </Box>
    </Box>
  );
}
