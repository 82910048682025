/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useCMSData } from "app/hooks/useCMSData";
import { Link as RouteLink } from "react-router-dom";
import { useStoreState } from "app/state/store/hooks";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";
import { getNameFieldBasedOnLang } from "app/utils/getNameFieldBasedOnLang";
import { SearchResultItem } from "app/components/header/components/search/common/SearchResultItem";
import { SearchResultNavigation } from "app/components/header/components/search/common/SearchResultNavigation";
import {
  ResultModel,
  NavResultsModel,
} from "app/components/header/components/search/data";

interface SearchResultsProps {
  cmsData: any;
  width: number;
  value: string;
  loading: boolean;
  close: () => void;
  resultType: string;
  hasMoreOfType: boolean;
  handleResultClick: any;
  handleLoadMore: Function;
  results: NavResultsModel;
  changeResultType: Function;
}

export const SearchResults = (props: SearchResultsProps) => {
  const cmsData = useCMSData({ returnData: true });
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const renderedResults = get(props.results, `[${props.resultType}].data`, []);

  return (
    <Box
      className="search-results-container"
      sx={{
        top: "40px",
        zIndex: 300,
        width: "600px",
        display: "flex",
        position: "absolute",
        borderRadius: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        backgroundColor: colors.primary.white,
        boxShadow: "0 1px 14px rgba(0, 0, 0, 0.12)",
        "@media (max-width: 992px)": {
          width: "calc(100vw - 135px)",
        },
        "@media (max-width: 600px)": {
          width: "calc(100vw - 32px)",
        },
      }}
    >
      <SearchResultNavigation
        results={props.results}
        cmsData={props.cmsData}
        activeTab={props.resultType}
        onChange={props.changeResultType}
      />
      <Box
        sx={{
          zIndex: 10,
          width: "600px",
          visibility: props.loading ? "visible" : "hidden",
          "> div": {
            height: "2px",
            background: colors.secondary.blueGrayLight,
          },
        }}
      >
        <LinearProgress />
      </Box>
      {props.resultType === "Projects" && renderedResults.length > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            padding: "0 24px",
            justifyContent: "flex-end",
            "@media (max-width: 600px)": {
              padding: "12px 0",
              justifyContent: "center",
            },
          }}
        >
          <Link
            to="/viz/projects"
            component={RouteLink}
            sx={{
              fontSize: "14px",
              color: colors.primary.blue,
            }}
            onClick={() => {
              sessionStorage.setItem("searchValue", props.value);
              props.close();
            }}
          >
            {get(cmsData, "general.gotoprojectslist", "Go to projects list")}
          </Link>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          maxHeight: "338px",
          "@media (max-width: 600px)": {
            width: "calc(100vw - 32px)",
          },
          "&::-webkit-scrollbar": {
            width: "6px",
            background: colors.secondary.blueGrayLight,
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "4px",
            background: colors.secondary.blueGrayLight,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            background: colors.primary.blue,
          },
        }}
      >
        {renderedResults.map((resultItem: ResultModel, index: number) => (
          <SearchResultItem
            index={index}
            link={resultItem.link}
            handleResultClick={props.handleResultClick}
            key={`search-result-item-${resultItem.link}-${resultItem.name}`}
            text={
              resultItem[getNameFieldBasedOnLang(currentLanguage)] ??
              resultItem.name
            }
          />
        ))}
      </Box>
      {props.hasMoreOfType && renderedResults.length > 0 && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            padding: "20px 0",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0 0 16px 16px",
            "&:hover": {
              cursor: "pointer",
              background: colors.secondary.blueGrayLight,
            },
          }}
          onClick={() => props.handleLoadMore()}
        >
          <ExpandMore />
        </Box>
      )}
    </Box>
  );
};
