import get from "lodash/get";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import * as echarts from "echarts/core";
import Typography from "@mui/material/Typography";
import {
  BarSeriesOption,
  LineSeriesOption,
  GridComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
  DataZoomComponentOption,
} from "echarts";
import {
  getRange,
  getFinancialValueWithMetricPrefix,
} from "app/utils/getFinancialValueWithMetricPrefix";

export interface BarLineDataItem {
  name: string;
  value: number;
  percentage: number;
  commitment: number;
  itemStyle?: object;
  disbursements: number;
  children?: BarLineDataItem[];
  gni?: number;
  other?: number;
  exclusive?: number;
  priorities?: { [key: string]: number };
  subItems?: {
    [key: string]: {
      name: string;
      name_fi: string;
      name_se: string;
      value: number;
    };
  };
}

export interface BarLineChartProps {
  data: BarLineDataItem[];
  disableDataZoomFeature?: boolean;
  dataVariant: "oda" | "budgetlines" | "priorities" | "sectors";
  barWidth?: number;
  detailPage?: boolean;
}

export function fillYearGaps(data: BarLineDataItem[]): BarLineDataItem[] {
  const years = data.map((item) => parseInt(item.name, 10));
  const minYear = Math.min(...years);
  const maxYear = Math.max(...years);
  const filledYears = [];
  for (let i = minYear; i <= maxYear; i++) {
    filledYears.push(i);
  }
  return filledYears.map((year) => {
    const dataItem = data.find(
      (item) => item.name.toString() === year.toString()
    );
    return (
      dataItem ?? {
        name: year.toString(),
        value: 0,
        percentage: 0,
        commitment: 0,
        disbursements: 0,
        subItems: {},
      }
    );
  });
}

const xAxisConfig = (data: BarLineDataItem[]): XAXisComponentOption => ({
  type: "category",
  data: data.map((item) => item.name),
  axisTick: {
    show: false,
  },
  nameTextStyle: {
    fontSize: "14px",
    fontFamily: "Finlandica",
    color: colors.primary.blue,
  },
  axisLabel: {
    fontSize: 14,
    fontFamily: "Finlandica",
    color: colors.primary.blue,
  },
  axisLine: {
    lineStyle: {
      width: 1,
      color: colors.secondary.grayLight,
    },
  },
});

const yAxisConfig = (
  range: {
    abbr: string;
    index: number;
  },
  max?: number
): YAXisComponentOption => ({
  type: "value",
  name: range.abbr,
  position: "left",
  alignTicks: true,
  max,
  nameTextStyle: {
    fontSize: "14px",
    fontFamily: "Finlandica",
    color: colors.primary.blue,
  },
  axisLabel: {
    fontSize: 14,
    fontFamily: "Finlandica",
    color: colors.primary.blue,
    formatter: (value: number) => {
      return getFinancialValueWithMetricPrefix(value, range.index);
    },
  },
  axisTick: {
    show: false,
  },
});

const gridMargins = {
  top: 50,
  bottom: 100,
  left: 70,
  right: 50,
};

const gridConfig: GridComponentOption = {
  ...gridMargins,
  show: true,
  borderWidth: 1,
  borderColor: colors.secondary.grayLight,
};

export function ODAVariantConfig(
  data: BarLineDataItem[],
  mobile: boolean,
  showGNI: boolean
): echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | GridComponentOption
  | DataZoomComponentOption
> {
  const currentLanguage = "en";
  const range = getRange(data, ["exclusive", "other"], currentLanguage);
  const config: echarts.ComposeOption<
    | BarSeriesOption
    | LineSeriesOption
    | GridComponentOption
    | DataZoomComponentOption
  > = {
    xAxis: xAxisConfig(data),
    yAxis: [
      yAxisConfig(range),
      {
        name: "%",
        type: "value",
        position: "right",
        alignTicks: true,
        nameTextStyle: {
          fontSize: "14px",
          fontFamily: "Finlandica",
          color: colors.primary.blue,
        },
        axisLabel: {
          fontSize: 14,
          fontFamily: "Finlandica",
          color: colors.primary.blue,
        },
      },
    ],
    dataZoom: [
      {
        show: true,
        type: "slider",
        start: !mobile ? 50 : 80,
        end: 100,
      },
    ],
    grid: gridConfig,
    series: [
      {
        data: data.map((item) => item.exclusive ?? 0),
        stack: "total",
        name: range.abbr,
        type: "bar",
        barWidth: !mobile ? 30 : 25,
        itemStyle: {
          borderRadius: [0, 0, 5, 5],
          color: colors.graph.green[500],
        },
        yAxisIndex: 0,
      },
      {
        data: data.map((item) => item.other ?? 0),
        stack: "total",
        name: range.abbr,
        type: "bar",
        barWidth: !mobile ? 30 : 25,
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: colors.secondary.midnightBlue,
        },
        yAxisIndex: 0,
      },
      {
        name: "%",
        type: "line",
        yAxisIndex: 1,
        data: data.map((item) => item.gni ?? 0),
        symbol: "circle",
        symbolSize: 11,
        lineStyle: {
          width: 1,
          color: colors.graph.coral[500],
        },
        itemStyle: {
          borderWidth: 1,
          color: colors.graph.coral[500],
          borderColor: colors.primary.blue,
        },
      },
    ],
  };

  if (!showGNI) {
    // @ts-ignore
    config.series = config.series?.slice(0, 2);
    // @ts-ignore
    config.yAxis = config.yAxis?.slice(0, 1);
  }

  return config;
}

export const budgetLinesColorsIndexing = [
  colors.graph.green[500],
  colors.graph.coral[500],
  colors.graph.burgundy[500],
  colors.graph.graphite[200],
  colors.graph.sand[500],
  colors.graph.lilac[500],
  colors.graph.warmYellow[500],
  colors.graph.darkGrey[300],
  colors.graph.darkGreen[400],
];

export function BudgetLinesVariantConfig(
  data: BarLineDataItem[],
  dataZoomDefaultStart: number = 50
): echarts.ComposeOption<
  BarSeriesOption | GridComponentOption | DataZoomComponentOption
> {
  const currentLanguage = "en";
  const budgetLineKeys = Object.keys(data[data.length - 1].subItems ?? []);
  const range = getRange(data, ["disbursements"], currentLanguage);
  const max = Math.max(...data.map((item) => item.disbursements)) * 1.2;
  return {
    xAxis: xAxisConfig(data),
    yAxis: yAxisConfig(range, Math.floor(max)),
    grid: gridConfig,
    dataZoom: [
      {
        show: true,
        type: "slider",
        start: dataZoomDefaultStart,
        end: 100,
      },
    ],
    series: budgetLineKeys.map((key, index) => {
      let borderRadius: number[] = [0, 0, 0, 0];
      if (index === 0) {
        borderRadius = [0, 0, 5, 5];
      } else if (index === budgetLineKeys.length - 1) {
        borderRadius = [5, 5, 0, 0];
      }
      return {
        data: data.map((item) => (item.subItems ? item.subItems[key] : 0)),
        stack: "total",
        name: range.abbr,
        type: "bar",
        barWidth: 30,
        itemStyle: {
          borderRadius,
          color: budgetLinesColorsIndexing[index],
        },
      };
    }),
  };
}

export const prioritiesColorsIndexing = [
  colors.graph.coral[700],
  colors.graph.lilac[700],
  colors.graph.green[700],
  colors.graph.warmYellow[700],
];

export function PrioritiesVariantConfig(
  data: BarLineDataItem[],
  mobile: boolean
): echarts.ComposeOption<BarSeriesOption | GridComponentOption> {
  const currentLanguage = "en";
  const priorityKeys = Object.keys(data[data.length - 1].subItems ?? []);
  const range = getRange(
    data,
    priorityKeys.map((key: string) => `${key}.value`),
    currentLanguage,
    "subItems"
  );
  const max =
    Math.max(
      ...data.map((item) =>
        Math.max(
          ...Object.values(
            Object.values(item.subItems ?? {}).map((v) => v.value) ?? {}
          )
        )
      )
    ) * 1.2;
  return {
    xAxis: {
      ...xAxisConfig(data),
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      ...yAxisConfig(range, Math.floor(max)),
      splitLine: {
        show: false,
      },
    },
    grid: {
      ...gridMargins,
    },
    series: priorityKeys.map((key, index) => {
      return {
        data: data.map((item) => {
          const value = item.subItems ? item.subItems[key].value : 0;
          return {
            ...item,
            value,
          };
        }),
        name: range.abbr,
        type: "bar",
        stack: key,
        barGap: 0,
        barWidth: 10,
        itemStyle: {
          borderRadius: [3, 3, 0, 0],
          color: prioritiesColorsIndexing[index],
        },
      };
    }),
    dataZoom: mobile
      ? [
          {
            show: true,
            type: "slider",
            start: !mobile ? 100 : 50,
            end: 100,
          },
        ]
      : undefined,
  };
}

export function SectorsVariantConfig(
  data: BarLineDataItem[]
): echarts.ComposeOption<
  BarSeriesOption | GridComponentOption | DataZoomComponentOption
> {
  let flattenedData: {
    name: string;
    name_fi: string;
    name_se: string;
    value: number;
    year: string;
    itemStyle?: {
      color: string;
    };
  }[] = [];
  data.forEach((item) => {
    map(item.subItems).forEach((subItem) => {
      flattenedData.push({ ...subItem, year: item.name });
    });
  });
  flattenedData = orderBy(flattenedData, "name", "desc");
  const currentLanguage = "en";
  const grouped = groupBy(flattenedData, "name");
  const sectorKeys = Object.keys(grouped);
  const years = data.map((item) => item.name);
  const range = getRange(flattenedData, ["value"], currentLanguage);
  const max =
    Math.max(...data.map((item) => sumBy(map(item.subItems), "value"))) * 1.2;
  return {
    xAxis: xAxisConfig(data),
    yAxis: yAxisConfig(range, Math.floor(max)),
    grid: gridConfig,
    dataZoom: [
      {
        show: true,
        type: "slider",
        start: 0,
        end: 100,
      },
    ],
    series: sectorKeys.map((key, index) => {
      let borderRadius: number[] = [0, 0, 0, 0];
      if (index === 0) {
        borderRadius = [0, 0, 5, 5];
      } else if (index === sectorKeys.length - 1) {
        borderRadius = [5, 5, 0, 0];
      }
      return {
        data: years.map((year) => {
          if (grouped[key]) {
            const value = grouped[key].find(
              (item) => item.year === year
            )?.value;
            return value ?? 0;
          }
          return 0;
        }),
        stack: "total",
        name: key,
        type: "bar",
        barWidth: 30,
        itemStyle: {
          borderRadius,
          color: get(grouped[key], "[0].itemStyle.color", "#000"),
        },
      };
    }),
  };
}

export function TooltipTitleNodeWColorCircle(params: {
  year: string;
  colors: string[];
  seriesIndex: number;
  subItems: {
    [key: string]: {
      name: string;
      name_fi: string;
      name_se: string;
      value: number;
      itemStyle?: {
        color: string;
      };
    };
  };
}) {
  const circleColor = get(
    map(params.subItems),
    `[${params.seriesIndex}].itemStyle.color`,
    get(params.colors, `[${params.seriesIndex}]`, "#808080")
  );
  return (
    <Box
      sx={{
        gap: "5px",
        display: "flex",
        flexDirection: "row",
        marginBottom: "16px",
        alignItems: "baseline",
      }}
    >
      <Box
        sx={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: circleColor,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography fontWeight="700" variant="subtitle2">
          Year {params.year}
        </Typography>
        <Typography
          fontWeight="700"
          variant="subtitle2"
          sx={{
            maxWidth: "320px",
            whiteSpace: "wrap",
            overflow: "hidden",
          }}
        >
          {get(
            params.subItems,
            `["${Object.keys(params.subItems)[params.seriesIndex]}"].name`,
            ""
          ).toString()}
        </Typography>
      </Box>
    </Box>
  );
}
