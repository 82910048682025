import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

type Props = {
  cmsData: any;
  value: string;
  isFocused: boolean;
  smallWidth?: string;
  onBlur?: () => void;
  useOpacity?: boolean;
  setValue: (value: string) => void;
  setIsFocused: (isFocused: boolean) => void;
};

const StyledInput = styled(InputBase)(() => ({
  input: {
    fontSize: 16,
    borderRadius: 30,
    color: colors.primary.blue,
    background: "rgba(227, 227, 227, 0.5)",
    boxShadow: "0px 2px 3px 0px rgba(97, 97, 97, 0.20) inset",
    "&::placeholder": {
      color: colors.primary.blue,
      opacity: 1,
    },
  },
}));

export const SearchField = (props: Props) => {
  const unfocusedWidth = props.smallWidth ? props.smallWidth : "144px";

  return (
    <React.Fragment>
      <StyledInput
        value={props.value}
        aria-label="Search in the whole application"
        inputProps={{ "aria-label": "Search in application" }}
        placeholder={get(props.cmsData, "general.search", "Search")}
        sx={{
          width: props.isFocused ? "600px" : unfocusedWidth,
          opacity: props.useOpacity && !props.isFocused ? 0 : 1,
          input: {
            padding: `${
              unfocusedWidth === "0px" && !props.isFocused
                ? "6px 0px 6px 5px"
                : "6px 40px 6px 22px"
            }`,
          },
          "@media (min-width: 992px)": {
            transition: `width 0.5s ease-in-out ${
              props.useOpacity ? ",opacity 0.5s ease-in-out" : ""
            }`,
          },
          "@media (max-width: 992px)": {
            width: props.isFocused ? "calc(100vw - 278px)" : "144px",
          },
          "@media (max-width: 600px)": {
            width: "calc(100vw - 88px)",
            display: props.isFocused ? "flex" : "none",
          },
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.setValue(e.target.value)
        }
        onFocus={() => props.setIsFocused(true)}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur();
          }
        }}
        endAdornment={
          <Box
            sx={{
              right: "8px",
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              alignItems: "center",
              position: "absolute",
              justifyContent: "center",
              display:
                unfocusedWidth === "0px" && !props.isFocused ? "none" : "flex",
            }}
          >
            <Hidden smDown>
              <SearchIcon
                sx={{
                  fill: colors.primary.blue,
                  transform: "rotate(90deg)",
                }}
              />
            </Hidden>
            <Hidden smUp>
              <CloseIcon
                sx={{
                  fill: colors.primary.blue,
                }}
              />
            </Hidden>
          </Box>
        }
      />
      <Box
        sx={{
          right: "8px",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          alignItems: "center",
          position: "absolute",
          justifyContent: "center",
          boxShadow: colors.shadows.inner,
          background: colors.secondary.grayLight,
          display: props.isFocused ? "none" : "flex",
          "@media (min-width: 768px)": {
            display: "none",
          },
        }}
        onClick={() => props.setIsFocused(true)}
      >
        <SearchIcon
          sx={{
            fill: colors.primary.blue,
            transform: "rotate(90deg)",
          }}
        />
      </Box>
    </React.Fragment>
  );
};
