import React from "react";
import get from "lodash/get";
import { useUpdateEffect } from "react-use";
import { useStoreActions, useStoreState } from "app/state/store/hooks";

interface Props {
  loadData?: boolean;
  returnData?: boolean;
}

export function useCMSData(props: Props) {
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  const cmsData = useStoreState((state) => state.cms.formattedData);
  const setCMSData = useStoreActions(
    (actions) => actions.cms.formattedData.setCMSData
  );

  const generalCMSAction = useStoreActions(
    (actions) => actions.cms.general.fetch
  );
  const generalCMSData = useStoreState((state) => state.cms.general.data);

  const priorityAreasCMSAction = useStoreActions(
    (actions) => actions.cms.priorityAreas.fetch
  );
  const priorityAreasCMSData = useStoreState(
    (state) => state.cms.priorityAreas.data
  );

  const vizCMSAction = useStoreActions((actions) => actions.cms.viz.fetch);
  const vizCMSData = useStoreState((state) => state.cms.viz.data);

  const filtersCMSAction = useStoreActions(
    (actions) => actions.cms.filters.fetch
  );
  const filtersCMSData = useStoreState((state) => state.cms.filters.data);

  const menuCMSAction = useStoreActions((actions) => actions.cms.menu.fetch);
  const menuCMSData = useStoreState((state) => state.cms.menu.data);

  const pagesCMSAction = useStoreActions((actions) => actions.cms.pages.fetch);
  const pagesCMSData = useStoreState((state) => state.cms.pages.data);

  const tooltipsCMSAction = useStoreActions(
    (actions) => actions.cms.tooltips.fetch
  );
  const tooltipsCMSData = useStoreState((state) => state.cms.tooltips.data);

  const regionsCMSAction = useStoreActions(
    (actions) => actions.cms.regions.fetch
  );
  const regionsCMSData = useStoreState((state) => state.cms.regions.data);

  const breadcrumbsCMSData = useStoreState(
    (state) => state.cms.breadcrumbs.data
  );
  const breadcrumbsCMSAction = useStoreActions(
    (actions) => actions.cms.breadcrumbs.fetch
  );

  const pageSectionsCMSData = useStoreState(
    (state) => state.cms.pageSections.data
  );
  const pageSectionsCMSAction = useStoreActions(
    (actions) => actions.cms.pageSections.fetch
  );

  const vizTabsCMSData = useStoreState((state) => state.cms.vizTabs.data);
  const vizTabsCMSAction = useStoreActions(
    (actions) => actions.cms.vizTabs.fetch
  );

  function formatCMSData() {
    let newData = {
      general: {},
      viz: {},
      filters: {},
      menu: {},
      pages: {},
      tooltips: {},
      regions: {},
      priorityAreas: {},
      breadcrumbs: {},
      pageSections: {},
      vizTabs: {},
    };
    const items = [
      {
        key: "general",
        data: generalCMSData ?? {},
      },
      {
        key: "viz",
        data: vizCMSData ?? {},
      },
      {
        key: "filters",
        data: filtersCMSData ?? {},
      },
      {
        key: "menu",
        data: menuCMSData ?? {},
      },
      {
        key: "pages",
        data: pagesCMSData ?? {},
      },
      {
        key: "tooltips",
        data: tooltipsCMSData ?? {},
      },
      {
        key: "regions",
        data: regionsCMSData ?? {},
      },
      {
        key: "priorityAreas",
        data: priorityAreasCMSData ?? {},
      },
      {
        key: "breadcrumbs",
        data: breadcrumbsCMSData ?? {},
      },
      {
        key: "pageSections",
        data: pageSectionsCMSData ?? {},
      },
      {
        key: "vizTabs",
        data: vizTabsCMSData ?? {},
      },
    ];
    // swedish default code is "se" in the CMS but client wanted to show "sv" in the url
    const localCurrentLanguage =
      currentLanguage === "sv" ? "se" : currentLanguage;
    items.forEach((item) => {
      let filteredData = {};
      Object.keys(item.data).forEach((key: string) => {
        if (localCurrentLanguage === "en" && key.indexOf("_") === -1) {
          filteredData = {
            ...filteredData,
            [key]: get(item.data, `${key}`, ""),
          };
        } else if (key.indexOf(`_${localCurrentLanguage}`) > -1) {
          filteredData = {
            ...filteredData,
            [`${key.replace(`_${localCurrentLanguage}`, "")}`]: get(
              item.data,
              `${key}`,
              ""
            ),
          };
        } else {
          filteredData = {
            ...filteredData,
            [key]: get(item.data, `${key}`, ""),
          };
        }
      });
      newData = {
        ...newData,
        [item.key]: filteredData,
      };
    });
    setCMSData(newData);
  }

  React.useEffect(() => {
    if (props.loadData) {
      generalCMSAction({
        isCMSfetch: true,
      });
      vizCMSAction({
        isCMSfetch: true,
      });
      filtersCMSAction({
        isCMSfetch: true,
      });
      menuCMSAction({
        isCMSfetch: true,
      });
      pagesCMSAction({
        isCMSfetch: true,
      });
      tooltipsCMSAction({
        isCMSfetch: true,
      });
      regionsCMSAction({
        isCMSfetch: true,
      });
      priorityAreasCMSAction({
        isCMSfetch: true,
      });
      breadcrumbsCMSAction({
        isCMSfetch: true,
      });
      pageSectionsCMSAction({
        isCMSfetch: true,
      });
      vizTabsCMSAction({
        isCMSfetch: true,
      });
    }
  }, []);

  useUpdateEffect(() => {
    if (props.loadData) {
      formatCMSData();
    }
  }, [
    currentLanguage,
    generalCMSData,
    vizCMSData,
    filtersCMSData,
    menuCMSData,
    pagesCMSData,
    tooltipsCMSData,
    regionsCMSData,
    priorityAreasCMSData,
    breadcrumbsCMSData,
    pageSectionsCMSData,
    vizTabsCMSData,
  ]);

  if (props.returnData) {
    return cmsData;
  }

  return null;
}
