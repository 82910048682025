import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useCMSData } from "app/hooks/useCMSData";
import { useStoreState } from "app/state/store/hooks";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink, useLocation } from "react-router-dom";
import {
  BreadcrumbProps,
  BreadcrumbLinkModel,
} from "app/components/breadcrumb/data";

export const Breadcrumbs = (props: BreadcrumbProps) => {
  const location = useLocation();
  const cmsData = useCMSData({ returnData: true });
  const currentLanguage = useStoreState(
    (state) => state.selectedLanguage.value as string
  );

  function getLabel(crumb: BreadcrumbLinkModel) {
    if (!crumb.cmsKey) return crumb.label;
    const cmsLabel = get(cmsData, crumb.cmsKey, null);
    if (cmsLabel !== "" && cmsLabel !== null) return cmsLabel;
    return crumb.label;
  }

  return (
    <MUIBreadcrumbs
      sx={{
        height: "32px",
        display: "flex",
        alignItems: "center",
        ".MuiBreadcrumbs-separator": {
          margin: "0",
        },
      }}
      aria-label="breadcrumb"
    >
      {props.route.map((breadcrumb: BreadcrumbLinkModel, index: number) => {
        // all objects except the last one in the array
        if (index < props.route.length - 1) {
          // does the object contain a value for the path property?
          return breadcrumb.path ? (
            // if so, return a link
            <Link
              component={NavLink}
              key={breadcrumb.label}
              sx={{
                color: colors.primary.blue,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              to={`${breadcrumb.path.replace(":lang", currentLanguage)}${
                location.search
              }`}
            >
              <Typography
                key={breadcrumb.label}
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: colors.primary.blue,
                }}
              >
                {getLabel(breadcrumb)}
              </Typography>
            </Link>
          ) : (
            // if not, return plain text
            <Typography
              key={breadcrumb.label}
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "24px",
                color: colors.primary.blue,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {getLabel(breadcrumb)}
            </Typography>
          );
        }
        // last object in the array represents the current page
        return (
          // return plain text
          <Typography
            key={breadcrumb.label}
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              lineHeight: "24px",
              color: colors.primary.blue,
            }}
          >
            {getLabel(breadcrumb)}
          </Typography>
        );
      })}
    </MUIBreadcrumbs>
  );
};
