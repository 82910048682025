import { APIModel } from "app/state/api";
import { ApiCallModel } from "app/state/api/interfaces";

export const activityPage: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/activity-detail`),
};

export const relatedActvities: ApiCallModel = {
  ...APIModel(`${process.env.REACT_APP_API}/top-10-activities`),
};
