/* eslint-disable no-param-reassign */
import uniq from "lodash/uniq";
import { Action, action } from "easy-peasy";
import { ValueSetValueModel } from "app/state/api/interfaces";

export interface AppliedFiltersStateModel {
  countries: string[];
  regions: string[];
  sectors: string[];
  organisations: string[];
  organisationtypes: string[];
  activitystatus: string[];
  activityscope: string[];
  tag: string[];
  sdg: string[];
  defaultaidtype: string[];
  defaultflowtype: string[];
  defaulttiedstatus: string[];
  collaborationtype: string[];
  policymarker: string[];
  budgetlines: string[];
  humanrights: string[];
  years: string[];
}

export interface AppliedFiltersModel extends AppliedFiltersStateModel {
  setCountries: Action<AppliedFiltersModel, string[]>;
  setRegions: Action<AppliedFiltersModel, string[]>;
  setSectors: Action<AppliedFiltersModel, string[]>;
  setOrganisations: Action<AppliedFiltersModel, string[]>;
  setOrganisationtypes: Action<AppliedFiltersModel, string[]>;
  setActivitystatus: Action<AppliedFiltersModel, string[]>;
  setActivityscope: Action<AppliedFiltersModel, string[]>;
  setTag: Action<AppliedFiltersModel, string[]>;
  setSdg: Action<AppliedFiltersModel, string[]>;
  setDefaultaidtype: Action<AppliedFiltersModel, string[]>;
  setDefaultflowtype: Action<AppliedFiltersModel, string[]>;
  setDefaulttiedstatus: Action<AppliedFiltersModel, string[]>;
  setCollaborationtype: Action<AppliedFiltersModel, string[]>;
  setPolicymarker: Action<AppliedFiltersModel, string[]>;
  setBudgetlines: Action<AppliedFiltersModel, string[]>;
  setHumanrights: Action<AppliedFiltersModel, string[]>;
  setYears: Action<AppliedFiltersModel, string[]>;
  setAllFilters: Action<AppliedFiltersModel, AppliedFiltersStateModel>;
}

export const defaultfilters = {
  countries: [] as string[],
  regions: [] as string[],
  sectors: [] as string[],
  organisations: [] as string[],
  organisationtypes: [] as string[],
  activitystatus: [] as string[],
  activityscope: [] as string[],
  tag: [] as string[],
  sdg: [] as string[],
  defaultaidtype: [] as string[],
  defaultflowtype: [] as string[],
  defaulttiedstatus: [] as string[],
  collaborationtype: [] as string[],
  policymarker: [] as string[],
  budgetlines: [] as string[],
  humanrights: [] as string[],
  years: [] as string[],
};

export const appliedFilters: AppliedFiltersModel = {
  ...defaultfilters,
  setCountries: action((state, payload: string[]) => {
    state.countries = uniq(payload);
  }),
  setRegions: action((state, payload: string[]) => {
    state.regions = uniq(payload);
  }),
  setSectors: action((state, payload: string[]) => {
    state.sectors = uniq(payload);
  }),
  setOrganisations: action((state, payload: string[]) => {
    state.organisations = uniq(payload);
  }),
  setOrganisationtypes: action((state, payload: string[]) => {
    state.organisationtypes = uniq(payload);
  }),
  setActivitystatus: action((state, payload: string[]) => {
    state.activitystatus = uniq(payload);
  }),
  setActivityscope: action((state, payload: string[]) => {
    state.activityscope = uniq(payload);
  }),
  setTag: action((state, payload: string[]) => {
    state.tag = uniq(payload);
  }),
  setSdg: action((state, payload: string[]) => {
    state.sdg = uniq(payload);
  }),
  setDefaultaidtype: action((state, payload: string[]) => {
    state.defaultaidtype = uniq(payload);
  }),
  setDefaultflowtype: action((state, payload: string[]) => {
    state.defaultflowtype = uniq(payload);
  }),
  setDefaulttiedstatus: action((state, payload: string[]) => {
    state.defaulttiedstatus = uniq(payload);
  }),
  setCollaborationtype: action((state, payload: string[]) => {
    state.collaborationtype = uniq(payload);
  }),
  setPolicymarker: action((state, payload: string[]) => {
    state.policymarker = uniq(payload);
  }),
  setBudgetlines: action((state, payload: string[]) => {
    state.budgetlines = uniq(payload);
  }),
  setHumanrights: action((state, payload: string[]) => {
    state.humanrights = uniq(payload);
  }),
  setYears: action((state, payload: string[]) => {
    state.years = payload;
  }),
  setAllFilters: action((state, payload: AppliedFiltersStateModel) => {
    state.countries = uniq(payload.countries);
    state.regions = uniq(payload.regions);
    state.sectors = uniq(payload.sectors);
    state.organisations = uniq(payload.organisations);
    state.organisationtypes = uniq(payload.organisationtypes);
    state.activitystatus = uniq(payload.activitystatus);
    state.activityscope = uniq(payload.activityscope);
    state.tag = uniq(payload.tag);
    state.sdg = uniq(payload.sdg);
    state.defaultaidtype = uniq(payload.defaultaidtype);
    state.defaultflowtype = uniq(payload.defaultflowtype);
    state.defaulttiedstatus = uniq(payload.defaulttiedstatus);
    state.collaborationtype = uniq(payload.collaborationtype);
    state.policymarker = uniq(payload.policymarker);
    state.budgetlines = uniq(payload.budgetlines);
    state.humanrights = uniq(payload.humanrights);
    state.years = payload.years;
  }),
};
