import React from "react";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery, ClickAwayListener } from "@mui/material";
import { formatFinancialValue } from "app/utils/formatFinancialValue";

export interface SankeyChartTooltipProps {
  title: string;
  value: number;
  percentage: number;
  ofWhatLabel: string;
  onClose?: () => void;
  goToDetail?: () => void;
}

export function SankeyChartTooltip(props: SankeyChartTooltipProps) {
  const isTouch = useMediaQuery("(hover: none)");

  const formattedPercentage = React.useMemo(() => {
    const temp = props.percentage.toFixed(2);
    if (temp.endsWith(".00")) {
      return temp.replace(".00", "");
    }
    return temp;
  }, [props.percentage]);

  const formattedValue = React.useMemo(() => {
    return formatFinancialValue(props.value);
  }, [props.value]);

  const onClickAway = () => {
    if (isTouch && props.onClose) {
      props.onClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        sx={{
          width: "370px",
          display: "flex",
          padding: "15px",
          position: "relative",
          borderRadius: "16px",
          flexDirection: "column",
          background: colors.primary.white,
          "@media (max-width: 400px)": {
            width: "calc(100vw - 48px)",
          },
          "*": {
            fontSize: "14px",
            color: colors.primary.blue,
            fontFamily: `Finlandica !important`,
          },
        }}
      >
        <Typography
          fontWeight="700"
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {props.title}
        </Typography>
        <IconButton
          aria-label="close"
          sx={{
            padding: 0,
            top: "10px",
            right: "10px",
            position: "absolute",
            color: colors.primary.blue,
            "@media (min-width: 768px)": {
              display: "none",
            },
          }}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>
        <Box
          sx={{
            gap: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ width: "40%" }}>{formattedValue}</Typography>
          <Typography
            sx={{
              width: "60%",
              textAlign: "right",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {formattedPercentage}% of {props.ofWhatLabel}
          </Typography>
        </Box>
        <Typography
          component="p"
          sx={{
            textWrap: "wrap",
          }}
        >
          {formattedPercentage}% of {props.ofWhatLabel} was allocated to{" "}
          {props.title}.
        </Typography>
        {props.goToDetail && (
          <Box
            sx={{
              gap: "10px",
              display: "none",
              marginTop: "20px",
              justifyContent: "flex-end",
              "@media (hover: none)": {
                display: "flex",
              },
              "> button": {
                padding: "4px 12px",
                borderRadius: "20px",
                textTransform: "none",
              },
            }}
          >
            <Button onClick={props.goToDetail} variant="contained">
              Go to detail
            </Button>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}
