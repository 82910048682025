/* eslint-disable no-param-reassign */
import { action } from "easy-peasy";
import {
  CMSFormattedCollectionsModel,
  CMSFormattedDataModel,
} from "app/state/api/interfaces";

export const formattedCollections: CMSFormattedCollectionsModel = {
  about: {},
  results: {},
  feedback: {},
  statements: {},
  setPagesData: action((state, payload) => {
    state.about = payload.about;
    state.results = payload.results;
    state.feedback = payload.feedback;
    state.statements = payload.statements;
  }),
};

export const formattedData: CMSFormattedDataModel = {
  general: {},
  viz: {},
  filters: {},
  menu: {},
  pages: {},
  tooltips: {},
  regions: {},
  priorityAreas: {},
  breadcrumbs: {},
  pageSections: {},
  vizTabs: {},
  setCMSData: action((state, payload) => {
    state.general = payload.general;
    state.viz = payload.viz;
    state.filters = payload.filters;
    state.menu = payload.menu;
    state.pages = payload.pages;
    state.tooltips = payload.tooltips;
    state.regions = payload.regions;
    state.priorityAreas = payload.priorityAreas;
    state.breadcrumbs = payload.breadcrumbs;
    state.pageSections = payload.pageSections;
    state.vizTabs = payload.vizTabs;
  }),
};
