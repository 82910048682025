import { HomePage } from "app/pages/home";
import { Page } from "app/components/page";
import { SectorPage } from "app/pages/sectorPage";
import { TabView } from "app/pages/common/tabView";
import { SectorsTabView } from "app/pages/sectors";
import { PriorityPage } from "app/pages/priorityPage";
import { ActivityPage } from "app/pages/activityPage";
import { LocationPage } from "app/pages/locationPage";
import { PrioritiesTabView } from "app/pages/priorities";
import { ActivitiesTabView } from "app/pages/activities";
import { OrganisationPage } from "app/pages/organisationPage";
import { SectorsIcon } from "app/assets/section-icons/Sectors";
import { OrganisationsTabView } from "app/pages/organisations";
import { ActivitiesIcon } from "app/assets/section-icons/Activities";
import { CountriesRegionsTabView } from "app/pages/countriesRegions";
import { PrioritiesIcon } from "app/assets/section-icons/Priorities";
import { OrganisationsIcon } from "app/assets/section-icons/Organisations";
import { DisbursementsOverviewTabView } from "app/pages/disbursementsOverview";
import { CountriesRegionsIcon } from "app/assets/section-icons/CountriesRegions";
import { DisbursementsOverviewIcon } from "app/assets/section-icons/DisbursementsOverview";
import { InfopageTemplate } from "app/pages/infopage-template";

export const ROUTES = [
  {
    path: "/:lang",
    element: <Page />,
    errorElement: <div>404</div>,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "disbursements-overview",
        element: (
          <TabView
            header={{
              title: "Disbursements overview",
              titleCMSKey: "vizTabs.DisbursementsOverviewTitle",
              subtitle:
                "Disbursements overview and correlating Finnish ODA throughout years.",
              subtitleCMSKey: "vizTabs.DisbursementsOverviewSubtitle",
              info: "tooltips.overview_disbursements",
              icon: <DisbursementsOverviewIcon />,
            }}
            breadcrumbs={[
              {
                label: "Homepage",
                path: "/:lang",
                cmsKey: "breadcrumbs.homepage",
              },
              {
                label: "Disbursements overview",
                path: "/:lang/disbursements-overview",
                cmsKey: "breadcrumbs.disbursementsOverview",
              },
            ]}
            csvExportInfo={{
              fileName: "Disbursements overview",
              storeKey: "disbursementsOverview.data.vizData",
              headers: [
                {
                  key: "name",
                  label: "Years",
                },
                {
                  key: "exclusive",
                  label: "Exclusive ODA",
                },
                {
                  key: "other",
                  label: "Other ODA",
                },
                {
                  key: "gni",
                  label: "GNI",
                },
                {
                  key: "value",
                  label: "Total disbursements",
                },
              ],
            }}
          >
            <DisbursementsOverviewTabView />
          </TabView>
        ),
      },
      {
        path: "priorities",
        element: (
          <TabView
            header={{
              title: "Priorities",
              titleCMSKey: "vizTabs.PrioritiesTitle",
              subtitle: "Prioritised areas of Finnish ODA.",
              subtitleCMSKey: "vizTabs.PrioritiesSubtitle",
              info: "tooltips.thematic_areas",
              icon: <PrioritiesIcon />,
            }}
            breadcrumbs={[
              {
                label: "Homepage",
                path: "/:lang",
                cmsKey: "breadcrumbs.homepage",
              },
              {
                label: "Priorities",
                path: "/:lang/priorities",
                cmsKey: "breadcrumbs.thematic",
              },
            ]}
            csvExportInfo={{
              fileName: "Priorities",
              storeKey: "prioritiesChart.data.vizData",
              headers: [
                {
                  key: "name",
                  label: "Name",
                },
                {
                  key: "value",
                  label: "Total disbursement",
                },
              ],
            }}
          >
            <PrioritiesTabView />
          </TabView>
        ),
      },
      {
        path: "sectors",
        element: (
          <TabView
            header={{
              title: "Sectors",
              titleCMSKey: "vizTabs.SectorsTitle",
              subtitle: "Sectors for Finnish ODA.",
              subtitleCMSKey: "vizTabs.SectorsSubtitle",
              info: "tooltips.sectors",
              icon: <SectorsIcon />,
            }}
            breadcrumbs={[
              {
                label: "Homepage",
                path: "/:lang",
                cmsKey: "breadcrumbs.homepage",
              },
              {
                label: "Sectors",
                path: "/:lang/sectors",
                cmsKey: "breadcrumbs.sectors",
              },
            ]}
            csvExportInfo={{
              fileName: "Sectors",
              storeKey: "sectorsSunburst.data.vizData.children",
              headers: [
                {
                  key: "name",
                  label: "Category / DAC3 / DAC5",
                },
                {
                  key: "code",
                  label: "Code",
                },
                {
                  key: "value",
                  label: "Total disbursement",
                },
                {
                  key: "commitment",
                  label: "Total commitment",
                },
              ],
            }}
          >
            <SectorsTabView />
          </TabView>
        ),
      },
      {
        path: "countries-regions",
        element: (
          <TabView
            header={{
              title: "Countries & Regions",
              titleCMSKey: "vizTabs.CountriesRegionsTitle",
              subtitle: "Partner countries & regions of Finnish ODA.",
              subtitleCMSKey: "vizTabs.CountriesRegionsSubtitle",
              info: "tooltips.regions",
              icon: <CountriesRegionsIcon />,
            }}
            breadcrumbs={[
              {
                label: "Homepage",
                path: "/:lang",
                cmsKey: "breadcrumbs.homepage",
              },
              {
                label: "Countries & Regions",
                path: "/:lang/countries-regions",
                cmsKey: "breadcrumbs.countriesRegions",
              },
            ]}
            csvExportInfo={{
              fileName: "Countries & Regions",
              storeKey: "countriesRegionsTreemap.data.vizData",
              headers: [
                {
                  key: "name",
                  label: "Name",
                },
                {
                  key: "value",
                  label: "Total disbursement",
                },
                {
                  key: "commitment",
                  label: "Total commitment",
                },
              ],
            }}
          >
            <CountriesRegionsTabView />
          </TabView>
        ),
      },
      {
        path: "organisations",
        element: (
          <TabView
            header={{
              title: "Organisations",
              titleCMSKey: "vizTabs.OrganisationsTitle",
              subtitle: "Partner organisations of Finnish ODA.",
              subtitleCMSKey: "vizTabs.OrganisationsSubtitle",
              info: "tooltips.organisations",
              icon: <OrganisationsIcon />,
            }}
            breadcrumbs={[
              {
                label: "Homepage",
                path: "/:lang",
                cmsKey: "breadcrumbs.homepage",
              },
              {
                label: "Organisations",
                path: "/:lang/organisations",
                cmsKey: "breadcrumbs.organisations",
              },
            ]}
            csvExportInfo={{
              fileName: "Organisations",
              storeKey: "organisationsTreemap.data.vizData",
              headers: [
                {
                  key: "name",
                  label: "Name",
                },
                {
                  key: "value",
                  label: "Total disbursement",
                },
                {
                  key: "commitment",
                  label: "Total commitment",
                },
              ],
            }}
          >
            <OrganisationsTabView />
          </TabView>
        ),
      },
      {
        path: "activities",
        element: (
          <TabView
            header={{
              title: "Activities",
              titleCMSKey: "vizTabs.ActivitiesTitle",
              subtitle: "Activities of Finnish ODA.",
              subtitleCMSKey: "vizTabs.ActivitiesSubtitle",
              info: "tooltips.activities",
              icon: <ActivitiesIcon />,
            }}
            breadcrumbs={[
              {
                label: "Homepage",
                path: "/:lang",
                cmsKey: "breadcrumbs.homepage",
              },
              {
                label: "Activities",
                path: "/:lang/activities",
                cmsKey: "breadcrumbs.activities",
              },
            ]}
            csvExportInfo={{
              fileName: "Activities",
              storeKey: "activities.data.data",
              headers: [
                {
                  key: "title",
                  label: "Name",
                },
                {
                  key: "organisations",
                  label: "Organisation",
                },
                {
                  key: "locations",
                  label: "Country & Region",
                },
                {
                  key: "disbursement",
                  label: "Disbursement",
                },
                {
                  key: "commitment",
                  label: "Commitment",
                },
              ],
            }}
          >
            <ActivitiesTabView />
          </TabView>
        ),
      },
      {
        path: "priorities/:id",
        element: <PriorityPage />,
      },
      {
        path: "countries/:id",
        element: <LocationPage />,
      },
      {
        path: "sectors/:id",
        element: <SectorPage />,
      },
      {
        path: "organisations/:id",
        element: <OrganisationPage />,
      },
      {
        path: "activities/:id",
        element: <ActivityPage />,
      },
      {
        path: "about",
        element: (
          <InfopageTemplate
            collectionKey="about"
            breadcrumb={{
              label: "About the site",
              path: "/:lang/about",
              cmsKey: "breadcrumbs.about",
            }}
          />
        ),
      },
      {
        path: "data",
        element: (
          <InfopageTemplate
            collectionKey="results"
            breadcrumb={{
              label: "About open aid",
              path: "/:lang/data",
              cmsKey: "breadcrumbs.results",
            }}
          />
        ),
      },
      {
        path: "feedback",
        element: (
          <InfopageTemplate
            collectionKey="feedback"
            breadcrumb={{
              label: "Feedback",
              path: "/:lang/feedback",
              cmsKey: "breadcrumbs.feedback",
            }}
          />
        ),
      },
      {
        path: "accessibility",
        element: (
          <InfopageTemplate
            collectionKey="statements"
            breadcrumb={{
              label: "Accessibility statement",
              path: "/:lang/accessibility",
              cmsKey: "breadcrumbs.accessibility",
            }}
          />
        ),
      },
    ],
  },
];
