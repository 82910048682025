import React from "react";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useStoreActions, useStoreState } from "app/state/store/hooks";
import { SearchField } from "app/components/header/components/search/common/SearchField";
import { SearchComponentLayoutProps } from "app/components/header/components/search/data";
import { SearchResults } from "app/components/header/components/search/common/SearchResults";

export function SearchComponentLayout(props: SearchComponentLayoutProps) {
  const isFocused = useStoreState(
    (state) => state.isSearchFocused.value as boolean
  );
  const setIsFocused = useStoreActions(
    (actions) => actions.isSearchFocused.setValue
  );

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={props.onClickAway}>
      <Box
        ref={props.targetRef}
        sx={{
          width: "100%",
          display: "flex",
          paddingLeft: "16px",
          position: "relative",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          "@media (max-width: 768px)": {
            padding: "0",
          },
        }}
      >
        <SearchField
          smallWidth="279px"
          value={props.value}
          isFocused={isFocused}
          cmsData={props.cmsData}
          setValue={props.setValue}
          setIsFocused={setIsFocused}
        />
        {props.value.length > 3 && (
          <SearchResults
            width={props.width}
            value={props.value}
            close={props.close}
            cmsData={props.cmsData}
            loading={props.loading}
            results={props.searchData}
            resultType={props.resultType}
            handleLoadMore={props.loadMore}
            handleResultClick={props.close}
            hasMoreOfType={props.hasMoreOfType}
            changeResultType={props.setResultType}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
}
