import React from "react";
import get from "lodash/get";
import { colors } from "app/theme";
import Box from "@mui/material/Box";
import { useCMSData } from "app/hooks/useCMSData";
import { useStoreState } from "app/state/store/hooks";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import {
  FILTER_TYPES,
  FiltersViewPeriodProps,
} from "app/components/filters/view/data";

export const YEAR_LOW = 2015;
export const YEAR_HIGH = 2025;

export function FiltersViewPeriod(props: FiltersViewPeriodProps) {
  const cmsData = useCMSData({ returnData: true });

  const appliedFromYear = useStoreState((state) =>
    get(state.appliedFilters.years, "[0]", YEAR_LOW)
  );
  const appliedToYear = useStoreState((state) =>
    get(state.appliedFilters.years, "[1]", YEAR_HIGH)
  );

  const [fromActive, setFromActive] = React.useState(true);
  const [toActive, setToActive] = React.useState(false);
  const [selectedFromYear, setSelectedFromYear] = React.useState(
    parseInt(appliedFromYear.toString(), 10)
  );
  const [selectedToYear, setSelectedToYear] = React.useState(
    parseInt(appliedToYear.toString(), 10)
  );

  const years = React.useMemo(() => {
    const t = [];
    for (let i = YEAR_LOW; i <= YEAR_HIGH; i++) {
      t.push(i);
    }
    return t;
  }, []);

  function handleFromClick() {
    if (!fromActive) {
      setFromActive(!fromActive);
      setToActive(!toActive);
    }
  }

  function handleToClick() {
    if (!toActive) {
      setToActive(!toActive);
      setFromActive(!fromActive);
    }
  }

  function handleYearClick(year: number) {
    if (fromActive) {
      setSelectedFromYear(year);
      setFromActive(false);
      setToActive(true);
    } else if (toActive) {
      setSelectedToYear(year);
    }
  }

  useUpdateEffect(() => {
    props.onFilterCheckboxChange(
      [`${selectedFromYear},${selectedToYear}`],
      FILTER_TYPES.PERIOD
    );
  }, [selectedFromYear, selectedToYear]);

  useUpdateEffect(() => {
    setSelectedFromYear(parseInt(appliedFromYear.toString(), 10));
    setSelectedToYear(parseInt(appliedToYear.toString(), 10));
  }, [appliedFromYear, appliedToYear]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        paddingTop: "30px",
        paddingLeft: "20px",
        flexDirection: "column",
        "@media (max-width: 920px)": {
          paddingLeft: "10px",
        },
      }}
    >
      <Box
        sx={{
          gap: "30px",
          width: "100%",
          display: "flex",
          marginBottom: "30px",
          flexDirection: "row",
          "> div": {
            gap: "5px",
            display: "flex",
            fontSize: "14px",
            flexDirection: "column",
            "> button": {
              fontWeight: "700",
              minHeight: "35px",
              cursor: "pointer",
              borderStyle: "none",
              borderRadius: "32px",
              padding: "10px 50px",
              boxShadow: colors.shadows.inner,
              background: colors.primary.white,
              color: colors.primary.blue,
            },
          },
        }}
      >
        <Box>
          {get(cmsData, "filters.yearfrom", "From")}
          <button
            onClick={handleFromClick}
            style={{
              color: fromActive ? colors.primary.white : colors.primary.blue,
              background: fromActive
                ? colors.primary.lightBlueGray
                : colors.primary.white,
            }}
          >
            {selectedFromYear}
          </button>
        </Box>
        <Box>
          {get(cmsData, "filters.yearto", "To")}
          <button
            onClick={handleToClick}
            style={{
              color: toActive ? colors.primary.white : colors.primary.blue,
              background: toActive
                ? colors.primary.lightBlueGray
                : colors.primary.white,
            }}
          >
            {selectedToYear}
          </button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
          "> button": {
            width: "48px",
            height: "48px",
            cursor: "pointer",
            fontWeight: "700",
            borderRadius: "50%",
            borderStyle: "none",
            "&:hover": {
              color: colors.primary.white,
              background: colors.primary.blue,
            },
            "&:disabled": {
              opacity: 0.5,
              cursor: "not-allowed",
            },
          },
        }}
      >
        {years.map((year) => (
          <button
            key={year}
            onClick={() => handleYearClick(year)}
            disabled={
              (selectedFromYear > year && !fromActive) ||
              (selectedToYear < year && !toActive)
            }
            style={{
              color:
                [selectedFromYear, selectedToYear].indexOf(year) > -1
                  ? colors.primary.white
                  : colors.primary.blue,
              background:
                [selectedFromYear, selectedToYear].indexOf(year) > -1
                  ? colors.primary.blue
                  : colors.primary.white,
            }}
          >
            {year}
          </button>
        ))}
      </Box>
    </Box>
  );
}
