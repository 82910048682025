import React from "react";

export const PrioritiesIcon = () => {
  return (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none">
      <g filter="url(#filter0_i_7542_45084)">
        <rect
          y="0.447266"
          width="52"
          height="52"
          rx="7"
          fill="url(#paint0_linear_7542_45084)"
        />
        <g clipPath="url(#clip0_7542_45084)">
          <path
            d="M26 15.5584C19.9862 15.5584 15.1111 20.4335 15.1111 26.4473C15.1111 32.461 19.9862 37.3362 26 37.3362C26.3632 37.3362 26.7222 37.3184 27.0763 37.2836C27.5706 37.2351 28.0494 37.5146 28.2065 37.9858L28.5711 39.0796C28.7597 39.6456 28.4138 40.2529 27.8221 40.3298C27.2257 40.4073 26.6175 40.4473 26 40.4473C18.268 40.4473 12 34.1793 12 26.4473C12 18.7153 18.268 12.4473 26 12.4473C33.732 12.4473 40 18.7153 40 26.4473C40 27.0648 39.96 27.673 39.8825 28.2694C39.8056 28.8611 39.1983 29.207 38.6323 29.0183L37.5385 28.6537C37.0673 28.4967 36.7879 28.0178 36.8364 27.5235C36.8711 27.1695 36.8889 26.8105 36.8889 26.4473C36.8889 20.4335 32.0138 15.5584 26 15.5584Z"
            fill="white"
          />
          <path
            d="M26.6325 28.3446C26.3719 27.5629 27.1156 26.8191 27.8974 27.0797L37.7097 30.3505C38.5549 30.6322 38.6375 31.7952 37.8407 32.1936L34.0759 34.076C33.8824 34.1727 33.7255 34.3297 33.6287 34.5232L31.7463 38.2879C31.3479 39.0848 30.185 39.0021 29.9032 38.1569L26.6325 28.3446Z"
            fill="white"
          />
          <path
            d="M33.7778 26.4473C33.7778 26.9309 33.2925 27.2384 32.8337 27.0855L31.337 26.5866C30.9348 26.4525 30.6715 26.077 30.6004 25.6591C30.2258 23.4571 28.3087 21.7806 26 21.7806C23.4227 21.7806 21.3333 23.8699 21.3333 26.4473C21.3333 28.756 23.0099 30.6731 25.2119 31.0477C25.6298 31.1187 26.0052 31.3821 26.1393 31.7843L26.6382 33.281C26.7911 33.7397 26.4836 34.225 26 34.225C21.7045 34.225 18.2222 30.7428 18.2222 26.4473C18.2222 22.1517 21.7045 18.6695 26 18.6695C30.2955 18.6695 33.7778 22.1517 33.7778 26.4473Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_7542_45084"
          x="0"
          y="0.447266"
          width="52"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_7542_45084"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7542_45084"
          x1="52"
          y1="49.4473"
          x2="3.66667"
          y2="0.447265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D06448" />
          <stop offset="1" stopColor="#FCE2DC" stopOpacity="0.71" />
        </linearGradient>
        <clipPath id="clip0_7542_45084">
          <rect x="12" y="12.4473" width="28" height="28" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
